import React from 'react'

const BusinessInfo = ({ss, sellerUser, exchangeReturn}) => {
  return (
    <div className='mt-3 px-3 business_info'>
        <div>
            {/* <dl className="business_info-list">
                <div className="business_info-item">
                    <dt>상호명</dt>
                    <dd>{sellerUser.businessName}</dd>
                </div>
                
                <div className="business_info-item">
                    <dt>대표자</dt>
                    <dd>{sellerUser.representativeName}</dd>
                </div>

                <div className="business_info-item">
                    <dt>사업자등록정보</dt>
                    <dd>{sellerUser.businessRegistrationNumber}</dd>
                </div>

                <div className="business_info-item">
                    <dt>통신판매업번호</dt>
                    <dd>{sellerUser.businessMailOrderNumber}</dd>
                </div>
                
                <div className="business_info-item">
                    <dt>사업장소재지</dt>
                    <dd>{sellerUser.businessLocation}</dd>
                </div>
            </dl> */}
            <div className={`venus_detail_box pt-1`}>
                {/* <div dangerouslySetInnerHTML={{ __html: content }}></div> */}
                <div className={`${ss.venue_cancel_exchange_return_content}`} dangerouslySetInnerHTML={{ __html: exchangeReturn }}></div>
            </div>
        </div>
    </div>
  )
}

export default BusinessInfo