import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import event from '../../containers/event/event.module.css';
import store from '../../containers/market/store/store.module.css';
import * as auth from '../../apis/auth';
import { format } from 'date-fns';

const EventNotification = () => {
    const {notificationNo} = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    // const notification = location.state?.notification;
    const [notification, setNotification] = useState({});

    useEffect(() => {
        console.log('notificationNo : ', notificationNo);
        notificationSelect();
    }, [notificationNo, location]);

    const notificationSelect = async () => {
        const response = await auth.notificationSelect(notificationNo);
        const data = response.data;

        setNotification(data);
    }

    const KSTformatDate = (date) => {
        if (!date) return '-'; // 값이 없으면 기본값 '-' 반환
        try {
            return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
        } catch (error) {
            console.error('Invalid date:', date);
            return '-'; // 유효하지 않은 날짜 포맷의 경우 기본값 반환
        }
    };

    const onBack = () => {
        navigate(-1);
    }

  return (
    <div className={`${event.notification_container}`}>
        <div className={`${event.notification_box}`}>
            <div className={`${event.notification_header}`}>
                <div><button onClick={onBack}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                <div><span>공지</span></div>
            </div>
            {notification && notification.notificationTitle ? ( // 데이터가 로드된 경우 렌더링
                <div className={`${event.notification_content}`}>
                    <div className={`${event.notification_content_title}`}><span>{notification.notificationTitle}</span></div>
                    <div className={`${event.notification_content_date}`}><span>{KSTformatDate(notification.notificationRegDate)}</span></div>
                    <div className={`${event.notification_content_info}`}>
                        <div dangerouslySetInnerHTML={{ __html: notification.notificationContent }}></div>
                    </div>
                </div>
            ) : (
                <div className={`${store.store_product_detail_loading}`}>
                    <img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" />
                </div> // 로딩 중 표시
            )}
        </div>
    </div>
  )
}

export default EventNotification