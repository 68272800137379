import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import SupportHeader from '../../../components/market/support/SupportHeader.jsx';
import SupportCategory from '../../../components/market/support/SupportCategory.jsx';
import Filter from '../../../components/market/support/Filter.jsx';
import VenueRental from '../../../components/market/support/venue/VenueRental.jsx';
import * as support from '../../../apis/support';
import * as categorys from '../../../apis/category';
import { ko } from 'date-fns/locale';
import { format, differenceInDays } from 'date-fns';
import { LoginContext } from '../../../contexts/LoginContextProvider.jsx';
import ss from '../../../containers/market/support/venue/support_shop.module.css';
import DailyduckInNavigator from '../../../components/footer/DailyduckInNavigator.jsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import DailyduckInNavigatorSupport from '../../../components/footer/DailyduckInNavigatorSupport.jsx';
import store from '../store/store.module.css';

const MarketSupport = () => {
  const { isLogin, login, logout, userInfo, remove } = useContext(LoginContext);

  const location = useLocation();
  const activeCategory = location.state?.activeCategory;

  const { category: routeCategory } = useParams();
  // const [category, setCategory] = useState(routeCategory || 'venue_rental');
  const [category, setCategory] = useState(routeCategory || 'advertisement');

  const [supportData, setSupportData] = useState();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [categoryList, setCategoryList] = useState([]);
  const [subCategory, setSubCategory] = useState([]);

  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [activeSubCategory, setActiveSubCategory] = useState('all');
  const [isHeartClicked, setIsHeartClicked] = useState(false);

  const [showRentalSortList, setShowRentalSortList] = useState(false);
  const [activeSort, setActiveSort] = useState('sort');

  const [supportDataLength, setSupportDataLength] = useState(0);
  
  const [ loading, setLoading ] = useState(false);
  const [ isFetching, setIsFetching ] = useState(false);  
  const [ currentPage, setCurrentPage ] = useState(1); // 현재 페이지
  const [ hasMore, setHasMore]  = useState(true); // 데이터가 더 있는지 여부

  let startdate = startDate ? format(startDate, 'yyyy.MM.dd(eee)', { locale: ko }) : null;
  let enddate = endDate ? format(endDate, 'yyyy.MM.dd(eee)', { locale: ko }) : null;
  const date = startdate && enddate ? `${startdate}~${enddate}` : null;

  const navigate = useNavigate();
  
  const rentalProductListRef = useRef(null);
  
  useEffect(() => {
    categorySelect();
    supportSubCategorySelect();
  }, []);

  useEffect(() => {
    setCategory(routeCategory || 'venue_rental');
    setActiveSubCategory('all');
  }, [routeCategory]);

  useEffect(() => {
    setSupportData([]);
    setCurrentPage(1);
    setHasMore(true);
    setIsFetching(false);
  
    getCategory(category, activeSubCategory, 1);
    supportCategory(category, activeSubCategory);
  
    if (rentalProductListRef.current) {
      rentalProductListRef.current.scrollTop = 0;
    }
  }, [category, activeSubCategory]);
  
  useEffect(() => {
    // '전체' 카테고리를 추가하여 첫 번째 요소로 추가
    const allCategory = {
      supportSubCategoryEngName: 'all',
      supportSubCategoryKorName: '전체'
    };
    const filteredSubCategories = [allCategory, ...subCategory.filter(sub => sub.supportMainCategoryEngName === category)];
    
    // console.log('filteredSubCategories : ', filteredSubCategories);
    setSelectedSubCategory(filteredSubCategories);
  }, [subCategory, category]);

  const backNavigate = () => {
    navigate('/market');
  };

  const handleHeartClick = () => {
    setIsHeartClicked(!isHeartClicked);
  };
  
  const fetchMoreItems = async () => {
    if (!hasMore || isFetching) return;
  
    try {
      setIsFetching(true);
      const response = await support.venueRental(category, activeSubCategory, currentPage + 1);
      const newItems = response.data;
  
      if (newItems.length > 0) {
        setSupportData((prevData) => {
          const updatedData = [...prevData, ...shuffleArray(newItems)];
          
          // 현재 정렬 유지
          return applySort(updatedData, activeSort);
        });
        setCurrentPage((prevPage) => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('데이터 가져오기 실패:', error);
    } finally {
      setIsFetching(false);
    }
  };

  // 무한 스크롤을 감지하는 함수
  const handleScroll = useCallback(() => {
    const element = rentalProductListRef.current;
    if (!element) return;

    const { scrollTop, scrollHeight, clientHeight } = element;

    // 전체 스크롤 높이, 현재 스크롤 위치, 뷰포트 높이 출력 (디버깅용)
    // console.log("현재 스크롤 위치:", scrollTop);
    // console.log("전체 스크롤 높이:", scrollHeight);
    // console.log("뷰포트 높이:", clientHeight);

    // 스크롤 끝에 도달했을 때 fetchMoreItems 호출
    if (scrollTop + clientHeight >= scrollHeight - 150 && !isFetching) {
      // console.log('scrollTop + clientHeight : ', scrollTop + clientHeight);
      // console.log('scrollHeight - 150 : ', scrollHeight - 150);
      setIsFetching(true); // 중복 호출 방지
      fetchMoreItems().finally(() => setIsFetching(false));
    }
  }, [isFetching, fetchMoreItems]);

  // 서브 카테고리가 변경될 때 스크롤을 맨 위로 이동
  useEffect(() => {
    if (rentalProductListRef.current) {
      rentalProductListRef.current.scrollTop = 0;
    }
  }, [activeSubCategory]);
  
  // 스크롤 이벤트 등록
  useEffect(() => {
    const element = rentalProductListRef.current;
    if (!element) return;

    element.addEventListener("scroll", handleScroll);
    return () => element.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);
  
  const supportCategory = async (category, activeSubCategory) => {
    const response = await support.venueRentalLength(category, activeSubCategory);
    const data = response.data;

    setSupportDataLength(data);
  }

  const getCategory = async (category, activeSubCategory, page) => {
    setLoading(true);
    try {
      const response = await support.venueRental(category, activeSubCategory, page);
      const data = await response.data;
  
      if(response.status === 200) {
        
        if(page === 1) {
          // console.log("productList : ", data);
          setSupportData(shuffleArray(data));
        } else {
          setSupportData((prevData) => [...prevData, ...shuffleArray(data)])
        }
      }
      
    } catch (error) {
      console.error("데이터 로딩 실패 : ", error);
    } finally {
      setLoading(false);
    }

  };

  const categorySelect = async () => {
    const response = await categorys.supportCategory();
    const data = response.data;

    const sortedSupportCategory = data.sort((a, b) => a.supportCategoryOrder - b.supportCategoryOrder);
    
    // console.log(data);
    setCategoryList(sortedSupportCategory);
  };
  
  const supportSubCategorySelect = async () => {
    const response = await categorys.supportSubCategorySelect();
    const data = response.data;
    
    const sortedSupportSubCategory = data.sort((a, b) => a.supportSubCategoryOrder - b.supportSubCategoryOrder);
    // console.log('supportSubCategorySelect : ', data);
    setSubCategory(sortedSupportSubCategory);
  }

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const applySort = (data, sortType) => {
    let sortedData = [...data];
  
    switch (sortType) {
      case 'latest':
        sortedData.sort((a, b) => new Date(b.regDate) - new Date(a.regDate));
        break;
      case 'sales':
        sortedData.sort((a, b) => b.saleCount - a.saleCount);
        break;
      case 'review':
        sortedData.sort((a, b) => b.reviewCount - a.reviewCount);
        break;
      case 'low':
        sortedData.sort(
          (a, b) =>
            (a.productsalePrice ?? a.productPrice) - (b.productsalePrice ?? b.productPrice)
        );
        break;
      case 'high':
        sortedData.sort(
          (a, b) =>
            (b.productsalePrice ?? b.productPrice) - (a.productsalePrice ?? a.productPrice)
        );
        break;
      default:
        break;
    }
  
    return sortedData;
  };

  const onRentalSortData = (e) => {
    const sortType = e.target.id;
    setActiveSort(sortType);
  
    // const sortedData = applySort(filteredStoreData, sortType);
    // setFilteredStoreData(sortedData);
  
    setShowRentalSortList(false);
  };

  const hadleSupportMapSearch = () => {
    navigate(`/venue/map/search`)
  }

  return (
    <>
      <div className='support_container'>
        <SupportHeader activeCategory={activeCategory} backNavigate={backNavigate} ss={ss} />
        <SupportCategory category={category} categoryList={categoryList} />
        {
          Array.isArray(selectedSubCategory) && selectedSubCategory.length !== 0 && (
            <div className={`${ss.support_sub_category_box}`}>
              <Swiper
                slidesPerView="auto"
                freeMode={true}
                spaceBetween={10}
                className={`${ss.support_sub_category_swiper}`}
              >
                {selectedSubCategory.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className={`${ss.support_sub_category}`}>
                      <button
                        className={`${activeSubCategory === item.supportSubCategoryEngName ? ss.support_sub_category_active : ''}`}
                        onClick={() => setActiveSubCategory(item.supportSubCategoryEngName)}
                      >
                        {item.supportSubCategoryKorName}
                      </button>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )
        }
        {/* <Filter
          dateRange={dateRange} setDateRange={setDateRange} startDate={startDate} endDate={endDate}
          startdate={startdate} enddate={enddate} date={date} ko={ko} format={format} differenceInDays={differenceInDays}
        /> */}
        {
          loading ?
          <div className={`${store.store_product_detail_loading}`}>
              <img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" />
          </div>
          :
          <VenueRental 
            category={category} isHeartClicked={isHeartClicked} onHeartClick={handleHeartClick} supportData={supportData} date={date} userInfo={userInfo} activeSubCategory={activeSubCategory} 
            rentalProductListRef={rentalProductListRef} supportDataLength={supportDataLength}
          />       
        }
        <div className={`${ss.support_rental_map_search_btn_box}`}>
          <div><button onClick={() => hadleSupportMapSearch()}><span className="material-symbols-outlined">map</span>지도에서 서포트 상품 찾기</button></div>
        </div>
        <DailyduckInNavigatorSupport />
      </div>

      {
        showRentalSortList &&
        <div className={`${store.store_rental_product_sort_modal_container}`}>
          <div className={`${store.store_rental_product_sort_modal_box}`}>
            <div className={`${store.store_rental_product_sort_modal_header}`}>
              <div><button onClick={() => setShowRentalSortList(false)}><span className="material-symbols-outlined">close</span></button></div>
            </div>
            <div className={`${store.store_rental_product_sort_modal_content}`}>
                {['latest', 'sales', 'review', 'low', 'high'].map(type => (
                  <div key={type}>
                    <div>
                      <input 
                        type="radio" 
                        name='rentalSort' 
                        id={type} 
                        onChange={onRentalSortData} 
                        checked={activeSort === type}
                      />
                    </div>
                    <div><label htmlFor={type}>{{
                      latest: '최신순',
                      sales: '판매순',
                      review: '리뷰많은순',
                      low: '낮은가격순',
                      high: '높은가격순'
                    }[type]}</label></div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default MarketSupport;
