import React, { useContext, useEffect, useRef, useState } from 'react';
import event from '../../containers/event/event.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ToastContainer, toast } from 'react-toastify';
import { LoginContext } from '../../contexts/LoginContextProvider';
import * as storeApi from '../../apis/store';
import store from '../../containers/market/store/store.module.css';
import { CSSTransition } from 'react-transition-group';
import admin from '../../admin/admin.module.css';

const EventVote = () => {
    
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const { eventno } = useParams();
    const [isChecked, setIsChecked] = useState(false);
    const [selectedArtist, setSelectedArtist] = useState('');
    const [point, setPoint] = useState(0);

    const [ voteList, setVoteList ] = useState([]);
    const [selectedVotes, setSelectedVotes] = useState([]);
    const [voteType, setVoteType] = useState(false); // 단일/중복 선택 구분
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {eventNumber} = useParams();
    const {userInfo, isLogin} = useContext(LoginContext);
    const location = useLocation(); 

    const { eventInfo } = location.state || {};

    const [ listImage, setListImage ] = useState([]);

    const [ participation, setParticipation ] = useState('');
    const [ showVoteParticipate, setShowVoteParticipate ] = useState(false);
    const [modalHeight, setModalHeight] = useState(window.innerHeight * 0.9);
    const [isDragging, setIsDragging] = useState(false);
    const isDraggingRef = useRef(false);
    const startYRef = useRef(0);
    const initialHeightRef = useRef(modalHeight);
    
    const [ clipboardMessage, setClipboardMessage ] = useState('');

    const [ shared, setShared ] = useState(false);
    const [ showVoteParticipateToggle, setShowVotParticipateToggle ] = useState(true);
    const [ voteParticipateUser, setVoteParticipateUser ] = useState([]);
    const [ showCouponCreate, setShowCouponCreate ] = useState(false);
    const [ showCouponInput, setShowCouponInput ] = useState(false);

    const [ couponName, setCouponName ] = useState('');
    const [ couponNameState, setCouponNameState ] = useState(false);
    const [ couponStateText, setCouponStateText ] = useState('');
    const [ couponStateColor, setCouponStateColor ] = useState('');

    const [ isLoading, setIsLoading ] = useState(false);

    const [ sharedCouponName, setSharedCouponName ] = useState('');

    // useEffect(() => {
    //     console.log('participation : ', participation);
    // }, [participation]);

    useEffect(() => {
        voteParticipateResult();
    }, [voteList]);

    useEffect(() => {
        // console.log('eventInfo : ', eventInfo);

        if (eventInfo) {
            const images = [
                eventInfo.eventImage1,
                eventInfo.eventImage2,
                eventInfo.eventImage3,
                eventInfo.eventImage4,
                eventInfo.eventImage5,
            ].filter(image => image !== null && image !== '');

            setListImage(images);

            setVoteList(eventInfo.voteItems);
            setVoteType(eventInfo.voteType);
        }

        eventConfirm();

    }, [eventInfo, userInfo]);

    const eventConfirm = async () => {
        try {
           // const response = await storeApi.eventVoteConfirm(eventInfo?.eventNumber, userInfo?.userId);
            // const data = response.data;
            // console.log('eventCheck : ', data);
    
            // const today = new Date();
            // const regDate = new Date(data.regDate); // 서버에서 받은 UTC 시간
    
            // // 날짜 문자열로 비교
            // const todayDateString = today.toISOString().split('T')[0]; // 오늘 날짜 (YYYY-MM-DD)
            // const regDateString = regDate.toISOString().split('T')[0]; // 등록 날짜 (YYYY-MM-DD)
    
            // if (todayDateString === regDateString) {
            //     setParticipation('Participation');
            // } else {
            //     setParticipation('');
            // }
            const response = await storeApi.eventVoteCheck(userInfo?.userId);
            const data = response.data;

            const voteCnt = data.voteCnt;
                
            if(voteCnt === 0) {
                setParticipation('Participation');
            } else {
                // console.log('투표권이 있습니다.')
                setParticipation('');
            }
        } catch (error) {
            console.error('Error fetching event confirmation:', error);
        }
    };

    // useEffect(() => {
    //     console.log('listImage : ', listImage);
    // }, [listImage]);

    const voteParticipateResult = async () => {
         try {
             const response = await storeApi.voteParticipateResult(eventNumber);
             const data = response.data;
     
            //  console.log('voteParticipateResult : ', data);
             if(response.status === 200) {
                 setVoteParticipateUser(data);
             } else {
                console.log('데이터가 없습니다.');
             }
         } catch (error) {
            console.error('error : ', error);
         }
    }

    // 선택 변경 핸들러 (단일 선택)
    const handleSingleSelect = (item) => {
        setSelectedVotes([item]); // 단일 선택이므로 배열에 한 개의 객체만 저장
    };

    // 선택 변경 핸들러 (중복 선택)
    const handleMultiSelect = (item) => {
        const exists = selectedVotes.some((vote) => vote.voteText === item.voteText); // 이미 존재 여부 확인
        if (exists) {
            // 이미 선택된 객체라면 제거
            setSelectedVotes(selectedVotes.filter((vote) => vote.voteText !== item.voteText));
        } else {
            // 새 객체 추가
            setSelectedVotes([...selectedVotes, item]);
        }
    };

    const handleBack = () => {
        navigate(-1);
    }
    const handleClose = () => {
        setShowVoteParticipate(false);
    }

    useEffect(() => {
        if (!window.Kakao.isInitialized()) {
            window.Kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
        }
        }, []);

    const transitions = useTransition(shared, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translateY(100%)" },
        config: {
            duration: 300,
        },
    });

    const handleShare = () => {
        setShared(true);
    }
    const handleShardBack = () => {
        setShared(false);
    }
    const handleShareKakaoClick = () => {
        if (window.Kakao) {
            const kakao = window.Kakao;
        
            kakao.Share.sendDefault({
                objectType: 'feed',
                content: {
                title: 'title',
                description: '지금 데일리덕에서 투표하기!',
                imageUrl: `${process.env.PUBLIC_URL}/img/kakaolink40_original.png`,
                link: {
                    mobileWebUrl: 'https://dailyduck.com/event/vote/' + eventNumber,
                    webUrl: 'https://dailyduck.com/event/vote/' + eventNumber,
                },
                },
            });
        }
    }
    const handleTwitterShare = () => {
        const url = `https://dailyduck.com/event/vote/${eventNumber}`;
        const text = `지금 데일리덕에서 투표하기!`;
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            text
        )}&url=${encodeURIComponent(url)}`;
        window.open(twitterUrl);
    };
    const handleNaverLineShare = () => {
        const url = `https://social-plugins.line.me/lineit/share?url=https://dailyduck.com/event/vote/${eventNumber}`
        window.open(url);
    }  

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const handleSmsShare = () => {
        const productLink = `https://dailyduck.com/event/vote/${eventNumber}`;
        const productName = `${eventInfo.eventTitle}`;
        const productDescription = "지금 데일리덕에서 투표하기!";

        if (!isMobile) {
            alert("SMS 공유는 모바일 기기에서만 가능합니다.");
            return;
        }

        const message = encodeURIComponent(`${productName} - ${productDescription}\n링크: ${productLink}`);
        window.location.href = `sms:&body=${message}`;
    }
    const handleClipboardShare = async () => {
        const text = `https://dailyduck.com/event/vote/${eventNumber}`
        if ('clipboard' in navigator) {
            try {
                await navigator.clipboard.writeText(text);
                setClipboardMessage('클립보드에 복사되었습니다.');
                setTimeout(() => setClipboardMessage(''), 2000);
            } catch (err) {
                console.error('클립보드 복사에 실패했습니다: ', err);
            }
        } else {
            // Fallback for older browsers
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            setClipboardMessage('클립보드에 복사되었습니다.');
            setTimeout(() => setClipboardMessage(''), 2000);
            }
    }
    const handleMoreShare = () => {
        const productLink = `https://dailyduck.com/event/vote/${eventNumber}`;
        const productName = `${eventInfo.eventTitle}`;
        const productDescription = "지금 데일리덕에서 투표하기!";

        if (navigator.share) {
            navigator.share({
            title: `${productName}`,
            text: `${productDescription}`,
            url: `${productLink}`,
            })
            .then(() => console.log('공유 성공!'))
            .catch((error) => console.log('공유 실패:', error));
        } else {
            // Web Share API를 지원하지 않는 경우의 대체 처리
            alert('Web Share API를 지원하지 않는 브라우저입니다.');
        }
    }

    const onSubScribe = () => {
        setShowVoteParticipate(true);
        setModalHeight(window.innerHeight * 0.9);
    }

    const formatDate = (date) => {
        const days = ['일', '월', '화', '수', '목', '금', '토']; // 요일 배열
        const dateObj = new Date(date); // Date 객체 생성
        const dayOfWeek = days[dateObj.getUTCDay()]; // 요일 추출 (UTC 기준)
        const formattedDate = date.replace('T', ' ').substring(0, 19); // 기존 형식 유지
        return `${formattedDate} (${dayOfWeek})`; // 요일 추가
    };

    const onLogin = () => {
        navigate('/');
    };

    const handleDivClick = (artistName) => {
        setSelectedArtist(artistName);
        setIsChecked(true); // 라디오 버튼을 체크 상태로 변경
    };

    const changeEvent = (e) => {
        // console.log(e.target.value);
    }

    const onVoteClick = () => {
        // console.log('selectedVotes : ', selectedVotes);

        if(selectedVotes.length === 0) {
            alert('선택된 값이 없습니다.');

            return;
        }

        // userId 추가
        const updatedVotes = selectedVotes.map((vote) => ({
            ...vote, // 기존 객체 속성 유지
            userId: userInfo?.userId, // userId 추가
        }));

        MySwal.fire({
            customClass: {
                popup: `${event.event_vote_popup}`
            },
            html : 
            `
                <div>
                    <div class='${event.event_vote_popup_title}'><span>투표하기</span></div>
                    <div class='${event.event_vote_popup_content}'><span>선택한 후보를 투표하시겠습니까?</span><br/><span>투표 참여시 투표권 1개가 차감됩니다.</span></div>
                    <div class='${event.event_vote_popup_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='vote_cancel'>취소</button>
                      <button class='mx-1' id='vote_success'>확인</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,

        });

        document.addEventListener('click', voteSuccess);
        document.addEventListener('click', voteCancel);

        async function voteSuccess(e) {
            if(e.target && e.target.id == 'vote_success') {
                const response = await storeApi.voteSuccess(updatedVotes);
                const data = response.data;

                // if(point === 0) {
                //     MySwal.fire({
                //         customClass: {
                //             popup: `${event.event_vote_popup}`
                //         },
                //         html : 
                //         `
                //             <div>
                //                 <div class='${event.event_vote_popup_title}'><span>포인트를 충전해주세요.</span></div>
                //                 <div class='${event.event_vote_popup_content}'><span>보유 포인트가 부족합니다.</span><br/><span>포인트를 충전하시겠습니까?</span></div>
                //                 <div class='${event.event_vote_popup_btn_box} py-2 d-flex w-100 justify-content-between'>
                //                   <button class='mx-1' id='vote_point_cancel'>아니오</button>
                //                   <button class='mx-1' id='vote_point_success'>예</button>
                //                 </div>
                //             </div>
                //         `,
                //         showConfirmButton: false,
                //         showCancelButton: false,
                //     });

                //     document.addEventListener('click', votePointSuccess);

                //     function votePointSuccess(e) {
                //         if(e.target && e.target.id == 'vote_point_success') {
                //             navigate('/my/cpr');
                //             MySwal.close();
                //             document.removeEventListener('click', votePointSuccess);
                //         }
                //         if(e.target && e.target.id == 'vote_point_cancel') {
                //             MySwal.close();
                //             document.removeEventListener('click', votePointSuccess);
                //         }
                //     }
                // } else {

                if(response.status === 200) {

                    toast.success('투표가 완료되었습니다.', {
                        position: "bottom-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    setShowVoteParticipate(false);
                    eventConfirm();
                    voteParticipateResult();
                    MySwal.close();
                    document.removeEventListener('click', voteSuccess);
                    document.removeEventListener('click', voteCancel);

                }
                // }
            }
            
        }
        
        function voteCancel(e) {
            if(e.target && e.target.id == 'vote_cancel') {
                MySwal.close();
                document.removeEventListener('click', voteSuccess);
                document.removeEventListener('click', voteCancel);
            }
        }
    }

    const handleMouseDown = (e) => {
        isDraggingRef.current = true;
        setIsDragging(true);
        startYRef.current = e.clientY;
        initialHeightRef.current = modalHeight;
    };

    const handleMouseMove = (e) => {
        if (isDraggingRef.current) {
            const newHeight = modalHeight + (startYRef.current - e.clientY);
            startYRef.current = e.clientY;

            if (newHeight > 150) {
                setModalHeight(newHeight);
            } else {
                setModalHeight(0);
                setTimeout(() => setShowVoteParticipate(false), 300);
            }
        }
    };

    const handleMouseUp = () => {
        isDraggingRef.current = false;
        if (modalHeight > 50) {
            setModalHeight(initialHeightRef.current);
        }
        setIsDragging(false);
    };

    const handleTouchStart = (e) => {
        isDraggingRef.current = true;
        setIsDragging(true);
        startYRef.current = e.touches[0].clientY;
        initialHeightRef.current = modalHeight;
    };

    const handleTouchMove = (e) => {
        if (isDraggingRef.current) {
            const newHeight = modalHeight + (startYRef.current - e.touches[0].clientY);
            startYRef.current = e.touches[0].clientY;

            if (newHeight > 150) {
                setModalHeight(newHeight);
            } else {
                setModalHeight(0);
                setTimeout(() => setShowVoteParticipate(false), 300);
            }
        }
    };

    const handleTouchEnd = () => {
        isDraggingRef.current = false;
        if (modalHeight > 50) {
            setModalHeight(initialHeightRef.current);
        }
        setIsDragging(false);
    };

    const handleVoteParticipateToggle = () => {
        setShowVotParticipateToggle(!showVoteParticipateToggle);
    }

    const handleCouponCreate = () => {
        setShowCouponCreate(true);
    }

    const handleCouponInput = () => {
        setShowCouponInput(true);
    }

    const onCouponCodeAdd = (e) => {
        setCouponName(e.target.value);
    }
    const handleCouponCodeDuplicate = async () => {
        if(couponName === '') {
            alert('쿠폰 코드를 입력후에 수행해주세요.(Please enter the coupon code and then proceed.)');

            return;
        }

        setIsLoading(true);
        try {
            const response = await storeApi.couponNameDuplicateCheck(eventInfo?.eventNumber, couponName);
            const data = response.data;

            if(data === 'SUCCESS') {
                setCouponStateText('사용 가능한 쿠폰명 입니다.');
                setCouponStateColor('green');
                setCouponNameState(true);
            } else if(data === 'DUPLICATE') {
                setCouponStateText('중복된 쿠폰명 입니다.');
                setCouponStateColor('red');
                setCouponNameState(false);
            } else {
                setCouponStateText('사용 불가능한 쿠폰명 입니다.');
                setCouponStateColor('red');
                setCouponNameState(false);
            }
        } catch (error) {
            console.error('error : ', error);
        } finally {
            setIsLoading(false);
        }
    }

    const resetState = () => {
        setCouponNameState(false);
        setCouponStateText('');
        setCouponStateColor('');
        setCouponName('');
    }

    const handleCouponAdd = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>쿠폰 코드를 생성 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_sub_title}'><span>생성될 코드는 (${couponName})이며 이 이벤트에서 쿠폰 코드를 생성할 시 재생성이 불가능합니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>등록</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await storeApi.couponCodeAdd(eventInfo?.eventNumber, userInfo?.userId, couponName);
                    const data = response.data;
                    // const data = 'SUCCESS';
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>쿠폰 코드 생성이 완료 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                resetState();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else if(data === 'REGISTE') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>이미 이벤트 쿠폰 코드를 생성한 계정입니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                resetState();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>쿠폰 코드 생성에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const onSharedCoupon = (e) => {
        let sharedCoupon = e.target.value;

        setSharedCouponName(sharedCoupon);
    }

    const handleSharedCoupon = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>쿠폰 코드를 사용 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_sub_title}'><span>사용될 코드는 (${sharedCouponName})이며 이 코드를 사용할 시 중복 사용은 불가능합니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>사용</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await storeApi.couponCodeUse(eventInfo?.eventNumber, userInfo?.userId, sharedCouponName);
                    const data = response.data;
                    // const data = 'SUCCESS';
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>쿠폰 코드 사용이 완료 되어 투표권 ${eventInfo?.voteCnt}개가 지급 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                // resetState();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else if(data === 'REGISTE') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>본인이 생성한 쿠폰코드는 사용할 수 없습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                // resetState();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else if(data === 'DUPLICATE') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>이미 사용하신 쿠폰 코드입니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                resetState();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else if(data === 'NOCOUPON') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>해당 쿠폰코드는 존재하지 않는 쿠폰코드입니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                // resetState();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>쿠폰 코드 사용에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    return (
        <>
            <div className={`${event.event_raffle_container} `}>
                <div className={`${event.event_product_detail_header}`}>
                    <div className={`${event.event_product_detail_header_left}`}><button onClick={() => handleBack()}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                    <div className={`${event.event_product_detail_header_right}`}><button><span className="material-symbols-outlined" onClick={() => handleShare()}>share</span></button></div>
                </div>
                <div className={`${event.event_product_detail_img_box}`}>
                <div>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                        clickable: true,
                        }}
                        // navigation={true}
                        modules={[Pagination, Navigation]}
                        className={`${event.event_raffle_swiper}`}
                    >
                        {
                            listImage.map((item, index) => (
                                <SwiperSlide key={index}><img src={item} alt="이미지" /></SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
                </div>
                <div className='px-3 py-3'>
                <div className='border-bottom position-relative'>
                    {
                        eventInfo && eventInfo?.codeType &&
                        <div className={`${event.event_vote_coupon_box}`}>
                            <div className={`${event.event_vote_coupon_create}`}><button onClick={() => handleCouponCreate()}>쿠폰 코드 생성</button></div>
                            <div className={`${event.event_vote_coupon_input}`}><button onClick={() => handleCouponInput()}>쿠폰 코드 입력</button></div>
                        </div>
                    }
                    <div className={`${event.event_raffle_info_product_box}`}><span>{eventInfo.eventTitle}</span></div>
                    <div className={`${event.event_raffle_info_shop_box}`}><span>{eventInfo.eventSubTitle}</span></div>
                    {/* <div className={`${event.event_raffle_info_price_box}`}><span>{eventInfo.eventPrice.toLocaleString()}원</span></div> */}
                    <div className={`${event.event_raffle_info_people_box}`}><span>총 투표 수는 {voteParticipateUser.length}표 입니다.</span></div>
                    <div className='py-3'>
                    <div className={`${event.event_raffle_info_date_box}`}>
                        <div><span>투표 기간</span></div>
                        <div><span>{formatDate(eventInfo.eventStartDate)} ~ {formatDate(eventInfo.eventEndDate)}</span></div>
                    </div>
                    <div className={`${event.event_raffle_info_date_box}`}>
                        <div><span>당첨자 발표일</span></div>
                        <div><span>{formatDate(eventInfo.eventWinnerDate)}</span></div>
                    </div>
                    <div className={`${event.event_vote_participate_box}`}>
                        <button className={`${event.event_vote_participate_box_drop_btn}`} onClick={() => handleVoteParticipateToggle()}>
                            <div><span>투표결과</span></div>
                            <div><span className="material-symbols-outlined">{showVoteParticipateToggle ? `arrow_drop_up` : `arrow_drop_down`}</span></div>
                        </button>
                            <CSSTransition in={showVoteParticipateToggle} timeout={300} classNames="review" unmountOnExit>
                                <div>
                                {
                                    showVoteParticipateToggle &&
                                    eventInfo &&
                                    eventInfo.voteItems &&
                                    eventInfo.voteItems.map((item, index) => {
                                        // 해당 항목에 대한 투표 수 계산
                                        const voteCount = voteParticipateUser.filter(
                                            (vote) => vote.voteText === item.voteText
                                        ).length;

                                        // 총 투표 수 계산
                                        const totalVotes = voteParticipateUser.length;

                                        // 비율 계산 (소수점 2자리)
                                        const percentage = totalVotes > 0 ? ((voteCount / totalVotes) * 100).toFixed(2) : 0;

                                        return (
                                            <div key={index} className={`${event.event_vote_participate_box_item}`}>
                                                <div>
                                                    <img src={`${item.voteImage}`} alt={item.voteText} />
                                                    <div>
                                                        <div>
                                                            <span>{item.voteText}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={`${event.vote_bar_container}`}>
                                                        <div 
                                                            className={`${event.vote_bar}`} 
                                                            style={{ width: `${percentage}%` }}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div><span>{percentage}%</span></div>
                                                    <div><span>({voteCount}표)</span></div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                </div>
                            </CSSTransition>
                    </div>
                    {/* <div className={`${event.event_raffle_info_date_box}`}>
                        <div><span>당첨자 구매기간</span></div>
                        <div><span>5.4 (목) 12:00 ~ 5.4 (목) 14:00</span></div>
                    </div> */}
                    {/* <div className={`${event.event_raffle_info_date_box}`}>
                        <div><span>안내사항</span></div>
                        <div><span>안내사항 내용입니다. 안내사항 내용입니다. 안내사항 내용입니다.</span></div>
                    </div> */}
                    </div>
                </div>
                </div>
                {/* <div className='px-3 pt-3 pb-4 border-bottom'>
                    <div className={`${event.event_spare_buy_date_box}`}><span>예비 당첨자 구매기간 안내</span><button>자세히 보기</button></div>
                    <div className={`${event.event_spare_ment_box}`}><span>당첨자가 구매하지 않았을 시, 추가 당첨 기회를 드립니다.</span></div>
                </div> */}
                <div className={`${event.event_raffle_content_box}`}>
                    <div dangerouslySetInnerHTML={{ __html: eventInfo.eventContent}}></div>
                </div>
                {
                    isLogin ?
                    <div className={`${event.event_raffle_footer_box} border-top`}>
                        {
                            participation === 'Participation' ?
                            <div className={`${event.event_raffle_footer_participation}`}><span>투표권이 없어요😭😭</span></div>
                            :
                            <div className='px-3'><button onClick={onSubScribe}>투표하기</button></div>
                        }
                    </div>
                    :
                    <div className={`${event.event_raffle_footer_box} border-top`}>
                        <div><button onClick={onLogin}>로그인 후 이벤트 참여하기</button></div>
                    </div>
                }
                {
                shared &&
                <div className={`${store.store_shared_background}`} >
                    {transitions((style, item) =>
                        item ? 
                        <animated.div className={`${store.store_shard_box}`} style={style}>
                            <div className='pt-5 px-3 d-flex justify-content-between'>
                                <div><h5>공유하기</h5></div>
                                <div><button className={`${store.store_shared_close}`} onClick={handleShardBack}><span className="material-symbols-outlined">close</span></button></div>
                            </div>
                            <div className='d-flex justify-content-center py-3'>
                                <div className={`${store.store_shard_btn_box}`}>
                                    <button onClick={handleShareKakaoClick}>
                                        <div>
                                            <div><img src="../../img/icon/share/kakaotalk.png" alt="카카오톡" /></div>
                                            <div className='py-1'><span>카카오톡</span></div>
                                        </div>
                                    </button>
                                </div>
                                <div className={`${store.store_shard_btn_box}`}>
                                    <button onClick={handleTwitterShare}>
                                        <div>
                                            <div><img src="../../img/icon/share/twiter.png" alt="트위터" /></div>
                                            <div className='py-1'><span>트위터</span></div>
                                        </div>
                                    </button>
                                </div>
                                <div className={`${store.store_shard_btn_box}`}>
                                    <button onClick={handleNaverLineShare}>
                                        <div>
                                            <div><img src="../../img/icon/share/line.png" alt="라인" /></div>
                                            <div className='py-1'><span>라인</span></div>
                                        </div>
                                    </button>
                                </div>
                                <div className={`${store.store_shard_btn_box}`}>
                                    <button onClick={handleSmsShare}>
                                        <div>
                                            <div><img src="../../img/icon/share/message.png" alt="SMS" /></div>
                                            <div className='py-1'><span>SMS</span></div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className={`${store.store_shard_btn_bottom_box}`}>
                                    <button onClick={() => handleClipboardShare()}>
                                        <div>
                                            <div><img src="../../img/icon/share/url.png" alt="" /></div>
                                            <div><span>URL</span></div>
                                        </div>
                                    </button>
                                </div>
                                <div className={`${store.store_shard_btn_bottom_box}`}>
                                    <button onClick={handleMoreShare}>
                                        <div>
                                            <div><img src="../../img/icon/share/more.png" alt="" /></div>
                                            <div><span>더보기</span></div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            {
                                clipboardMessage !== '' ? 
                                <div className={`${store.store_clipboard_box}`}><span>{clipboardMessage}</span></div>
                                :
                                null
                            }
                        </animated.div>
                        : null
                    )}
                </div>
            }
            </div>
            {
                showVoteParticipate &&
                <div 
                    className={`${event.event_vote_container}`}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <div 
                        className={`${event.event_vote_box}`}
                        style={{
                          height: `${modalHeight}px`,
                          transition: isDragging ? 'none' : 'height 0.3s ease', // 드래그 중에는 애니메이션 제거
                        }}
                    >
                        <div 
                            className={`${event.event_vote_box_header}`}
                            onMouseDown={handleMouseDown}
                            onTouchStart={handleTouchStart}
                            style={{ cursor: 'grab' }}
                        >
                            <div><button onClick={() => handleClose()}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${event.event_vote_top_content}`}>
                            <div>
                                <div className={`${event.event_vote_top_title}`}><span>{eventInfo?.eventTitle}</span></div>
                                <div className={`${event.event_vote_top_sub_title} pt-3`}><span>{eventInfo?.eventSubTitle}</span></div>
                            </div>
                            <div>
                                {
                                    Array.isArray(voteList) && voteList.map((item, index) => (
                                        <div 
                                            key={index} 
                                            className={`${event.event_vote_content}`} 
                                            // onClick={() => handleDivClick(item.voteText)}
                                            onClick={() =>
                                                voteType
                                                ? handleMultiSelect(item) // 중복 선택
                                                : handleSingleSelect(item) // 단일 선택
                                            }
                                        >
                                            <div className={`${event.event_vote_content_one}`}><span>{index + 1}</span></div>
                                            <div className={`${event.event_vote_content_two}`}>
                                                <div>
                                                    <img src={item.voteImage} alt={item.voteText} />
                                                </div>
                                            </div>
                                            <div className={`${event.event_vote_content_three}`}>
                                                <div><span>{item.voteText}</span></div>
                                                <div><span>({item.voteSubText})</span></div>
                                                <div><span>{item.votes}</span></div>
                                            </div>
                                            <div className={`${event.event_vote_content_four}`}>
                                            {/* <label htmlFor={`radio-${item.voteListNo}`} className={`${event.styledLabel}`}>
                                                <input 
                                                    type="radio"
                                                    className={`${event.styledRadio}`}
                                                    value={item.voteText} 
                                                    name={'vote'} 
                                                    id={`radio-${item.voteListNo}`} 
                                                    checked={selectedArtist === item.voteText}
                                                    onChange={changeEvent}
                                                />
                                            </label> */}
                                                {voteType ? (
                                                    <input type="checkbox" checked={selectedVotes.some((vote) => vote.voteText === item.voteText)} readOnly />
                                                ) : (
                                                    <input type="radio" name="single_vote" checked={selectedVotes.some((vote) => vote.voteText === item.voteText)} readOnly />
                                                )}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className={`${event.event_vote_footer}`}>
                            <div className='pt-3 pb-1'><span>{eventInfo?.voteType ? `*중복 선택이 가능한 투표입니다.` : `*단일 선택이 가능한 투표입니다.`}</span></div>
                            <div><button className={`${selectedVotes.length !== 0 ? event.vote_footer_btn_active : ''}`} onClick={onVoteClick} disabled={selectedVotes.length === 0}>투표하기</button></div>
                        </div>
                    </div>
                </div>
            }
            <ToastContainer />
            {
                showCouponCreate &&
                <div className={`${event.event_coupon_modal_container}`}>
                    <div className={`${event.event_coupon_modal_box}`}>
                        <div className={`${event.event_coupon_modal_header}`}>
                            <div><span>쿠폰코드 등록</span></div>
                            <div><button onClick={() => setShowCouponCreate(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${event.event_coupon_modal_content}`}>
                            <div><span>*계정당 한번 쿠폰코드 등록이 가능하며 동일한 쿠폰명은 사용이 불가능하니 중복검사를 꼭 진행해주세요! (등록된 쿠폰명은 여러 계정에서 사용 가능합니다.)</span></div>
                            <div className={`${event.event_coupon_modal_content_add_check}`}>
                                <input type="text" value={couponName} onChange={(e) => onCouponCodeAdd(e)}/>
                                <button onClick={() => handleCouponCodeDuplicate()}>중복체크</button>
                            </div>
                            {
                                couponStateText !== '' &&
                                <div className={`${event.event_coupon_modal_content_check_text}`}><span style={{ color: couponStateColor }}>{couponStateText}</span></div>
                            }
                            <div className={`${event.event_coupon_modal_content_btn_box}`}><button className={`${couponNameState ? event.event_coupon_active : ''}`} disabled={!couponNameState} onClick={() => handleCouponAdd()}>등록</button></div>
                        </div>
                    </div>
                </div>
            }
            {
                showCouponInput &&
                <div className={`${event.event_coupon_modal_container}`}>
                    <div className={`${event.event_coupon_modal_box}`}>
                        <div className={`${event.event_coupon_modal_header}`}>
                            <div><span>쿠폰코드 입력</span></div>
                            <div><button onClick={() => setShowCouponInput(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${event.event_coupon_modal_content}`}>
                            <div><span>공유된 쿠폰 코드를 입력해주세요. 자신이 생성한 쿠폰 코드는 사용이 불가능합니다.</span></div>
                            <div className={`${event.event_coupon_modal_content_add_check}`}><input type="text" value={sharedCouponName} onChange={(e) => onSharedCoupon(e)}/></div>
                            <div className={`${event.event_coupon_modal_content_btn_box}`}><button className={`${sharedCouponName !== '' ? event.event_coupon_active : ''}`} onClick={() => handleSharedCoupon()} disabled={sharedCouponName === ''}>사용하기</button></div>
                        </div>
                    </div>
                </div>
            }
        </>
      )
}

export default EventVote