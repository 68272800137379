import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import admin from '../../admin/admin.module.css';

const GoogleCallback = () => {

    const { isLogin, login, logout, remove, loginCheck } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);
  
  const [googleExist, setGoogleExist] = useState(true);
  const [ joinObject, setJoinObject ] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    const googleUserInfo = async (code, state) => {
        try {
          const response = await auth.googleUserInfo(code, state);
          const data = response.data;

          if (response.status === 200) {
            // console.log('Google Login successful:', data);
            setUserInfo(data);

            checkUserExists(data);

            // joinObject에 넣을 새로운 객체 구성
            const newJoinObject = {
              userProfile : data.picture,
              userName: data.name,
              userId: data.userId,
              userEmail: data.email,
              // userPw: userList.userPw,
              // userRepublic: userList.userRepublic,
              // userTel: phone,
              userNick: data.name,
              // userGender: userInfo.gender,
              // userBirth: birth, // 재구성한 birth를 새로운 속성으로 추가
              // recode: inputValue,
              google: data.userId
            };

            setJoinObject(newJoinObject);

            // 로그인 후 리디렉션
            // navigate('/market');
          } else {
            console.error('Google login failed:', data);
          }
        } catch (error) {
          console.error('Google login error:', error);
        }
      };
  
      if (code) {
        googleUserInfo(code, state);
      } else {
        console.error('No code found in URL');
      }
  }, [navigate]);
  useEffect(() => {
    // console.log('userInfo : ', userInfo);
  }, [userInfo]);

  const checkUserExists = async (user) => {
    const response = await auth.googleUserSearch(user.userId);
    const data = response.data;

    // console.log('checkUserExists : ', data);
  
    if (data === 'exist') {
      const userSelectResponse = await auth.googleUserSelect(user.userId);
      const userSlectData = userSelectResponse.data;
  
      // 로그인 처리를 위한 토큰 요청
      const tokenResponse = await auth.getToken({ userId: userSlectData.userId });
      const tokenData = tokenResponse.data;
  
      if (tokenResponse.status === 200) {
        const token = tokenResponse.data.token;
  
        // 토큰을 쿠키와 로컬 스토리지에 저장
        Cookies.set("accessToken", token);
        // localStorage.setItem("accessToken", token);

        // console.log('navigator.userAgent : ', navigator.userAgent);
  
        // 모바일과 웹 환경 구분
        if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          // 모바일인 경우 Custom URL Scheme 사용하여 앱으로 리디렉션
          window.location.href = `myapp://auth?code=${token}`;
          // console.log('모바일에서 수행', token);
        } else {
          // 웹 환경인 경우 페이지 이동
          await loginCheck();
          navigate('/market');
        }
      }
    } else {
      setGoogleExist(false);
    }
  };
  

  const join = async () => {
    navigate('/google/join', { state: { google: userInfo } });
    // const response = await auth.googleJoin(joinObject);
    // const data = await response.data;

    // if(data === 'SUCCESS') {
    //   MySwal.fire({
    //     customClass: {
    //         popup: `${admin.admin_seller_add_popup}`
    //     },
    //     html : 
    //     `
    //     <div>
    //     <div class='${admin.admin_seller_add_title}'><span>회원가입이 완료 되었습니다.</span></div>
    //     <div class='${admin.admin_seller_add_sub_title}'><span></span></div>
    //     <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
    //     <button class='mx-1' id='seller_add_confirm'>확인</button>
    //     </div>
    //     </div>
    //     `,
    //     showConfirmButton: false,
    //     showCancelButton: false,
    // });
    // document.addEventListener('click', sellerAddConfirm);
    
    // function sellerAddConfirm(e) {
    //     if(e.target && e.target.id == 'seller_add_confirm') {
    //         MySwal.close();
    //         // success('/market', { state: {key: 'test2'}});
    //         // login( joinObject.userId, joinObject.userPw );
    //         checkUserExists(joinObject);
    //         document.removeEventListener('click', sellerAddConfirm);
    //     }
    // }

      
    // } else {
    //   alert(`Server Error ${data}`);
    // }
  }

    return (
        <div>
      <div className='twitter_login_info_img_box'>
        <img src="../../img/logo_pink2.png" alt="데일리덕" />
      </div>
      {userInfo && !googleExist ? (
        <div className='twitter_login_info_box'>
          <div>
            <img src={userInfo.picture} alt="Profile" />
          </div>
          <div>
            <span>{userInfo.id}</span>
          </div>
          <div>
            <span>{userInfo.name}</span>
          </div>
          <div>
            <span>{userInfo.email}</span>
          </div>
          <div className='twitter_login_info_btn'>
            <button onClick={join}>데일리덕 가입하기</button>
          </div>
        </div>
      ) : (
        <p>사용자 정보를 확인 중입니다...</p>
      )}
    </div>
    );
};

export default GoogleCallback;
