import React, { useEffect, useState } from 'react';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { CSSTransition } from 'react-transition-group';
import axios from 'axios';

const MyOrderDetail = ({ showMyOrder, setShowMyOrder, selectOrder, dailyduck, setShowOD, onExchange, 
    formatDate, onPaymentCancel, onRefund, onPurchaseConfirm, onReviewWrite, showReturnRefuse, 
    onSelectPurchaseConfirm, onDeliverySelectPurchaseConfirm, onDeliverySearch }) => {

    const [showPrice, setShowPrice] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    
    const [exchangeRate, setExchangeRate] = useState(null);

    const [openStates, setOpenStates] = useState(false);
    const [openStates2, setOpenStates2] = useState(false);
    const [openStates3, setOpenStates3] = useState(false);

    const [showContentForm, setShowContentForm] = useState(false);

    const toggleOpen = () => { setOpenStates(!openStates); };
    const toggleOpen2 = () => { setOpenStates2(!openStates2); };
    const toggleOpen3 = () => { setOpenStates3(!openStates3); };

    useEffect(() => {
        fetchExchangeRate();
    }, [])

    // useEffect(() => {
    //     console.log('selectOrder : ', selectOrder);
    // }, [selectOrder])

    const fetchExchangeRate = async () => {
        try {
            const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
            setExchangeRate(response.data.rates.KRW);
        } catch (error) {
            console.error("환율 정보를 가져오는 중 오류 발생", error);
        }
    };

    const transitions = useTransition(showMyOrder, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });
    const handleMyOrderBack = () => {
        setShowMyOrder(false);
    }

    const calculateTotalPrice = () => {
        // 기본 금액 계산 (principalAmount * totalQuantity)
        // const baseTotal = parseInt(selectOrder.principalAmount) * selectOrder.totalQuantity;
        const baseTotal = parseInt(selectOrder.paymentPrice - selectOrder.deliveryPrice);
    
        // 옵션 금액 계산 (옵션이 있는 경우)
        // const optionsTotal = selectOrder.options.reduce((total, option) => {
        //     return total + (option.addPrice * option.quantity);
        // }, 0);
    
        // // 총 금액 합산
        // const total = baseTotal + optionsTotal;
        const total = baseTotal;
    
        // 로컬라이즈된 문자열로 반환
        return total;
    };

    return (
        <>
            {
                showMyOrder &&
                <div className={`${dailyduck.myorderdetail_container} pb-5 mb-5`}>
                    {transitions((style, item) =>
                        item ?
                            <animated.div className={dailyduck.myorderdetail_box} style={style}>
                                <div className={dailyduck.myorderdetail_header}>
                                    <div>
                                        <button onClick={() => handleMyOrderBack()} className='reservation_pay_close'>
                                            <span className="material-symbols-outlined">arrow_back_ios</span>
                                        </button>
                                    </div>
                                    <div>
                                        <div>
                                            <span>주문상세</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${dailyduck.my_order_detail_content_box}`}>
                                    <div className={`${dailyduck.my_order_detail_content_order_number}`}>
                                        <div>
                                            <div><span>{selectOrder.paymentNumber}</span></div>
                                            <div>{formatDate(selectOrder.paymentRegDate)}</div>
                                        </div>
                                    </div>

                                    <div className='bg-white'>
                                        <div className='py-2 px-3'>
                                            <div className={`py-3`}>{selectOrder.paymentState}</div>
                                            <div className={`border-bottom d-flex pb-3 ${dailyduck.my_order_detail_box}`}>
                                                <div className={`${dailyduck.my_order_detail_img}`}><img src={selectOrder.paymentImg} alt="앨범이미지" /></div>
                                                <div className={`${dailyduck.my_order_detail_info}`}>
                                                    <div><span>상점명</span></div>
                                                    <div><span>{selectOrder.paymentProductName}</span></div>
                                                    <div>
                                                        {
                                                            selectOrder && selectOrder.options && selectOrder.options.map((item, index) => (
                                                                <div key={index}><span>{item.selectionOptionName} : {item.selectionOptionValue} </span><span>/ {item.quantity}개</span></div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                selectOrder.contentForm && selectOrder.contentForm.length > 0 &&
                                                <div className={`${dailyduck.payment_content_form_select}`}><button onClick={() => setShowContentForm(true)}>입력값 보기</button></div>
                                            }
                                        </div>
                                        <div className='px-3 py-1'>
                                            <div className='w-100'>
                                                <button className={`${dailyduck.my_order_detail_content_payment_toggle_btn}`} onClick={() => toggleOpen()}>
                                                    <div><span>주문금액(상세)</span></div>
                                                    <div>
                                                        <span>{selectOrder.paymentType !== '해외간편결제' ? (parseInt(selectOrder.paymentPrice)).toLocaleString() + '원' : selectOrder.paymentPrice + ' USD'}</span>
                                                        <span className="material-symbols-outlined">{openStates ? "expand_less" : "expand_more"}</span>
                                                    </div>
                                                </button>
                                            </div>
                                            {/* 펼쳐지는 영역 시작 */}
                                            <CSSTransition in={openStates} timeout={500} classNames="review" unmountOnExit>
                                                <div className={`${dailyduck.my_order_detail_content_payment_info}`}>
                                                    <div>
                                                        <div><span>상품금액</span></div>
                                                        <div><span>{selectOrder.paymentType !== '해외간편결제' ? calculateTotalPrice().toLocaleString() + '원' : (calculateTotalPrice() / exchangeRate).toFixed(2) + ' USD'}</span></div>
                                                    </div>
                                                    <div>
                                                        <div><span>배송비</span></div>
                                                        <div><span>{selectOrder.paymentType !== '해외간편결제' ? selectOrder.deliveryPrice.toLocaleString() + '원' : (selectOrder.deliveryPrice / exchangeRate).toFixed(2) + ' USD'}</span></div>
                                                    </div>
                                                    <div>
                                                        <div><span>총 결제금액</span></div>
                                                        <div><span>{selectOrder.paymentType !== '해외간편결제' ? (parseInt(selectOrder.paymentPrice)).toLocaleString() + '원' : selectOrder.paymentPrice + ' USD'}</span></div>
                                                    </div>
                                                </div>
                                            </CSSTransition>
                                            {/* 펼쳐지는 영역 끝 */}
                                            <div className='py-3'>
                                                {
                                                    selectOrder.paymentState == '결제완료' &&
                                                    <div className='d-flex w-100 justify-content-center px-3 pb-3 align-items-center'>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_check}`} onClick={() => onPaymentCancel(selectOrder)}>취소 요청</button></div>
                                                    </div>
                                                }
                                                {
                                                    selectOrder.paymentState == '배송준비' &&
                                                    <div className='d-flex w-100 justify-content-center px-3 pb-3'>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_check}`} onClick={() => onPaymentCancel(selectOrder)}>취소 요청</button></div>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_confirm}`}>배송지 변경</button></div>
                                                    </div>
                                                }
                                                {
                                                    selectOrder.paymentState == '배송중' &&
                                                    <div className='d-flex w-100 justify-content-center px-3 pb-3'>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_check}`} onClick={() => onDeliverySearch(selectOrder)}>배송 조회</button></div>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_confirm}`} onClick={() => onDeliverySelectPurchaseConfirm(selectOrder)}>구매 확정</button></div>
                                                    </div>
                                                }
                                                {
                                                    selectOrder.paymentState == '배송완료' && selectOrder.purchaseConfirmation === false &&
                                                    <div className='d-flex w-100 justify-content-center px-3 pb-3'>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_check}`} onClick={() => onRefund(selectOrder)}>반품신청</button></div>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_check}`} onClick={() => onExchange(selectOrder)}>교환신청</button></div>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_confirm}`} onClick={() => onSelectPurchaseConfirm(selectOrder)}>구매 확정</button></div>
                                                    </div>
                                                }
                                                {
                                                    selectOrder.purchaseConfirmation === true &&
                                                    <div className='d-flex w-100 justify-content-center px-3 pb-3'>
                                                        {/* <div className='w-100 text-center'><button className={`${dailyduck.my_order_review_check}`} disabled={true}>환불 요청</button></div> */}
                                                        <div className='w-100 text-center'><button className={`w-100 ${item.reviewState === false ? dailyduck.my_order_review_confirm : dailyduck.my_order_review_check}`} disabled={selectOrder.reviewState} onClick={() => onReviewWrite(selectOrder)}>리뷰 작성</button></div>
                                                    </div>
                                                }
                                                    <div className={`${dailyduck.my_order_retrun_refuse_btn}`}>
                                                    {
                                                        selectOrder.cancelRefuseText != null &&
                                                        <div><button onClick={() => showReturnRefuse(selectOrder.cancelRefuseText)}>취소거절사유</button></div>
                                                    }
                                                    {
                                                        selectOrder.returnRefuseText != null &&
                                                        <div><button onClick={() => showReturnRefuse(selectOrder.returnRefuseText)}>반품거절사유</button></div>
                                                    }
                                                    {
                                                        selectOrder.exchangeRefuseText != null &&
                                                        <div><button onClick={() => showReturnRefuse(selectOrder.exchangeRefuseText)}>교환거절사유</button></div>
                                                    }
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-2 bg-white'>
                                        <div className='w-100'>
                                            <button className={`d-flex justify-content-between py-3 px-3 w-100 border-0 bg-body`} onClick={() => toggleOpen2()}>
                                                <div><span>배송지</span></div>
                                                <div>
                                                    <span className="material-symbols-outlined">{openStates2 ? "expand_less" : "expand_more"}</span>
                                                </div>
                                            </button>
                                        </div>
                                        {/* 펼쳐지는 영역 시작 */}
                                        <CSSTransition in={openStates2} timeout={500} classNames="review" unmountOnExit>
                                            <div className='px-3'>
                                                <div className='pb-3 border-bottom'>
                                                    <div className={`d-flex justify-content-start py-1`}>
                                                        <div className={`${dailyduck.my_order_detaile_title}`}><span>수령인</span></div>
                                                        <div className={`${dailyduck.my_order_detaile_content}`}><span>{selectOrder.deliveryUser}</span></div>
                                                    </div>
                                                    <div className={`d-flex justify-content-start py-1`}>
                                                        <div className={`${dailyduck.my_order_detaile_title}`}><span>핸드폰</span></div>
                                                        <div className={`${dailyduck.my_order_detaile_content}`}><span>{selectOrder.deliveryPhone}</span></div>
                                                    </div>
                                                </div>
                                                <div className='py-3'>
                                                    <div className={`d-flex justify-content-start py-1`}>
                                                        <div className={`${dailyduck.my_order_detaile_title}`}><span>우편번호</span></div>
                                                        <div className={`${dailyduck.my_order_detaile_content}`}><span>{selectOrder.zipcode}</span></div>
                                                    </div>
                                                    <div className={`d-flex justify-content-start py-1`}>
                                                        <div className={`${dailyduck.my_order_detaile_title}`}>주소</div>
                                                        <div className={`${dailyduck.my_order_detaile_content}`}>{selectOrder.deliveryAddress}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CSSTransition>
                                        {/* 펼쳐지는 영역 끝 */}
                                    </div>
                                    <div className='bg-white'>
                                        <div className='w-100'>
                                            <button className={`d-flex justify-content-between py-3 mt-2 px-3 w-100 border-0 bg-body`} onClick={() => toggleOpen3()}>
                                                <div><span>결제정보</span></div>
                                                <div>
                                                    <span className="material-symbols-outlined">{openStates3 ? "expand_less" : "expand_more"}</span>
                                                </div>
                                            </button>
                                        </div>
                                        {/* 펼쳐지는 영역 시작 */}
                                        <CSSTransition in={openStates3} timeout={500} classNames="review" unmountOnExit>
                                            <div>
                                                <div className='px-3 pb-3 border-bottom'>
                                                    <div className={`d-flex justify-content-between py-1`}>
                                                        <div><span>총 상품금액</span></div>
                                                        <div><span>{selectOrder.paymentType !== '해외간편결제' ? calculateTotalPrice().toLocaleString() + '원' : (calculateTotalPrice() / exchangeRate).toFixed(2) + ' USD'}</span></div>
                                                    </div>
                                                    <div className={`d-flex justify-content-between py-1`}>
                                                        <div><span>총 배송비</span></div>
                                                        <div><span>{selectOrder.paymentType !== '해외간편결제' ? selectOrder.deliveryPrice.toLocaleString() + '원' : (selectOrder.deliveryPrice / exchangeRate).toFixed(2) + ' USD'}</span></div>
                                                    </div>
                                                    <div className={`d-flex justify-content-between py-1`}>
                                                        <div><span>포인트 사용</span></div>
                                                        <div><span>(-) {selectOrder.point}</span></div>
                                                    </div>
                                                    <div className={`d-flex justify-content-between py-1`}>
                                                        <div><span>쿠폰 사용</span></div>
                                                        <div><span>(-) {selectOrder.coupon}</span></div>
                                                    </div>
                                                    <div className={`d-flex justify-content-between py-1`}>
                                                        <div><span>결제수단</span></div>
                                                        <div><span>{selectOrder.paymentType}</span></div>
                                                    </div>
                                                </div>
                                                <div className='px-3 py-3'>
                                                    <div className={`d-flex justify-content-between`}>
                                                        <div><span>총 결제금액</span></div>
                                                        <div><span>{selectOrder.paymentType !== '해외간편결제' ? (parseInt(selectOrder.paymentPrice)).toLocaleString() + '원' : selectOrder.paymentPrice + ' USD'}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CSSTransition>
                                        {/* 펼쳐지는 영역 끝 */}
                                    </div>
                                </div>
                            </animated.div>
                            : null
                    )
                    }
                </div>
            }

            {
                showContentForm &&
                <div className={`${dailyduck.payment_content_form_container}`}>
                    <div className={`${dailyduck.payment_content_form_box}`}>
                        <div className={`${dailyduck.payment_content_form_header}`}>
                            <div><span>입력값</span></div>
                            <div><button onClick={() => setShowContentForm(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${dailyduck.payment_content_form_content}`}>
                            {
                                selectOrder.contentForm && selectOrder.contentForm.map((item, index) => (
                                    <div className={`${dailyduck.payment_content_form_content_item}`} key={index}>
                                        <div><span>{item.question}</span></div>
                                        <div><span>{item.answer}</span></div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default MyOrderDetail;
