import { Select } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as category from '../../../../../apis/category';
import * as support from '../../../../../apis/support';
import * as sellerapi from '../../../../../apis/seller';
import TextEditor2 from './TextEditor2';
import TextEditor from './TextEditor';
import seller from '../../../../seller.module.css';
import { LoginContext } from '../../../../../contexts/LoginContextProvider';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import SellerSalesMain from '../sales/SellerSalesMain';
import AdminCombinationManager from './AdminCombinationManager';

const { Option } = Select;

const SellerEditMain = () => {
    const { isLogin, sellerlogout, userInfo, loginCheck, roles } = useContext(LoginContext);
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const [data, setData] = useState("");
    const [data2, setData2] = useState("");    
    
    const [productList, setProductList] = useState({
        productNo : '', productNumber : '', productName : '', mainCategory: 'support', middleCategory: '', subCategory: '', favorityArtist: '',
        relatedProduct: '', setAmount: '', regularPrice: '', salePrice : '', discount : '', mainItem : [], mainItemOption: [], combinationOption: {},
        representativeImage : '', listImage1 : '', listImage2 : '', listImage3 : '', listImage4 : '', userId : userInfo?.userId,
        detailContents : '', faq : [], dateSelect : '', photoSelect : '', contentSelect : '', exchangeReturn : '', minDateState : false, minDate : 0,
        infoTapState : false, calendarSetting : false, mapUseState: false, address : '', detailAddress : '', lat : '', lng : ''
    });

    const [selected, setSelected] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [selectedDiscount, setSelectedDiscount] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [products, setProducts] = useState([{ options: [], useProductQuantity: false, useOptions: false, combinationUseOptions: false }]);
    const [productName, setProductName] = useState('');
    const [productInfo, setProductInfo] = useState('');
    const [charCount, setCharCount] = useState(0); // State to track character count
    const charLimit = 500; // Character limit
    
    const [imageURL, setImageURL] = useState(null);
    const [fileName, setFileName] = useState('');
    const [imageRepresetativePreview, setImageRepresetativePreview] = useState('');
    const [imageListImgPreview1, setImageListImgPreview1] = useState('');
    const [imageListImgPreview2, setImageListImgPreview2] = useState('');
    const [imageListImgPreview3, setImageListImgPreview3] = useState('');
    const [imageListImgPreview4, setImageListImgPreview4] = useState('');

    const [mainCategory, setMainCategory] = useState('서포트');
    const [mainCategorySelect, setMainCategorySelect] = useState('');
    const [middleCategory, setMiddleCategory] = useState([]);
    const [middleCategorySelect, setMiddleCategorySelect] = useState('');
    const [subCategory, setSubCategory] = useState([]);
    const [selectSubCategory, setSelectSubCategory] = useState([]);
    const [subCategorySelect, setSubCategorySelect] = useState([]);
    
    const [ showFaqModal, setShowFaqModal ] = useState(false);
    const [faqList, setFaqList] = useState([]);
    const [selectedFaqs, setSelectedFaqs] = useState([]); // 체크된 FAQ를 저장할 상태

    const [ showFaqAdd, setShowFaqAdd ] = useState(false);

    const [minDateState, setMinDateState] = useState(false); // 최소일수 사용 여부
    const [minDate, setMinDate] = useState(0); // 최소일수 값
    
    const [showInfoTapState, setShowInfoTapState] = useState(false);
    const [infoTapState, setInfoTapState] = useState(false);
    const [calendarSetting, setCalendarSetting] = useState(false);

    const [address, setAddress] = useState(""); // 입력된 주소
    const [addressState, setAddressState] = useState(false);
    const [detailAddress, setDetailAddress] = useState('') // 정확한 위치 주소소
    const [coordinates, setCoordinates] = useState({ lat: 37.5665, lng: 126.9780 }); // 기본 좌표 (서울)
    const [mapUseState, setMapUseState] = useState(false);
    const mapRef = useRef(null); // 지도 참조
    const googleMapRef = useRef(null); // Google Map 객체 참조
    const markerRef = useRef(null); // 마커 참조

    useEffect(() => {
        if (mapUseState) {
            loadGoogleMapsScript(() => {
                if (mapRef.current) {
                    initializeMap();
                }
            });
        }
    }, [mapUseState]);

    useEffect(() => {
        faqListSelect();

        setProductList({
            ...productList,
            userId : userInfo?.userId
        })
      }, [userInfo]);

      const loadGoogleMapsScript = (callback) => {
          const existingScript = document.getElementById("googleMapsScript");
          if (!existingScript) {
              const script = document.createElement("script");
              script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
              script.id = "googleMapsScript";
              script.async = true;
              script.defer = true;
              script.onload = callback;
              document.body.appendChild(script);
          } else {
              if (callback) callback();
          }
      };
      
      const faqListSelect = async () => {
        const response = await sellerapi.sellerFaqSelect(userInfo && userInfo.userId);
        const data = response.data;
    
        // console.log(data);
        setFaqList(data);
    
      }

      const updateProductList = (newProducts) => {
        setProducts(newProducts);
        setProductList(currentProductList => ({
            ...currentProductList,
            mainItem: newProducts.map((product, index) => ({
                mainItemNo: product.mainItemNo || '',
                mainItemIndex: index + 1, // 1부터 순차적으로 증가하는 값 설정
                mainItemImg: product.imageUrl || '',
                mainItemPrice: product.mainItemPrice || '',
                mainItemOptionState: product.useOptions || false,
                mainItemCombinationState : product.combinationUseOptions || false,
                mainItemProductQuantity : product.useProductQuantity || false,
                mainOptionName: product.mainOptionName || '',
                userId: userInfo.userId || '',
                productNumber: '' // 서버에 전송 후 설정 예정
            })),
            mainItemOption: newProducts.flatMap((product, index) => 
                product.options.map(option => ({
                    mainItemIndex: index + 1, // mainItemIndex를 옵션에도 동일하게 할당
                    optionType: option.type || '',
                    optionTitle: option.title || '',
                    optionRequired: option.required || false,
                    optionPrice: option.type === 'shortAnswer' ? option.price || 0 : (option.items ? option.items.map(item => item.price).join(';') : 0),
                    optionName: option.items ? option.items.map(item => item.name).join(';') : '',
                    optionDateTime: option.dateTime || false,
                    useQuantity : option.useQuantity || false
                }))
            )
        }));
    };

    const handleCheckboxChange = (faqItem) => {
        setSelectedFaqs((prevSelected) => {
            if (prevSelected.includes(faqItem)) {
                return prevSelected.filter(item => item !== faqItem);
            } else {
                return [...prevSelected, faqItem];
            }
        });
    };

    const handleFaqSelect = () => {
        const selectedFaqNos = selectedFaqs.map(faq => faq.sellerFaqNo);
        setProductList((prevProductList) => ({
            ...prevProductList,
            faq: selectedFaqNos // 배열로 저장
        }));
        setShowFaqModal(false);
    };

    const handleRemoveFaq = (faqItem) => {
        setSelectedFaqs((prevSelected) =>
            prevSelected.filter((item) => item.sellerFaqNo !== faqItem.sellerFaqNo)
        );

        setProductList((prevProductList) => ({
            ...prevProductList,
            faq: prevProductList.faq.filter((faqNo) => faqNo !== faqItem.sellerFaqNo),
        }));
    };
  

    const handleChange = (e) => { 
        const relatedProduct = e.target.id;
        setSelected(e.target.id);
        setProductList(currentProductList => ({
            ...currentProductList,
            relatedProduct: relatedProduct
        }));
    };

    const handlePriceChange = (e) => { 
        const priceSetting = e.target.id;
        setSelectedPrice(e.target.id); 
        setProductList(currentProductList => ({
            ...currentProductList,
            setAmount: priceSetting
        }));
    };

    const regularPrice = (e) => {
        const price = e.target.value;
        setProductList(currentProductList => ({
            ...currentProductList,
            regularPrice: price
        }));
    };

    const salePrice = (e) => {
        const price = e.target.value;
        setProductList(currentProductList => ({
            ...currentProductList,
            salePrice: price
        }));
    };

    const handleDiscountChange = (e) => {
        const selectDiscount = e.target.id; 
        setSelectedDiscount(e.target.id); 
        setProductList(currentProductList => ({
            ...currentProductList,
            discount: selectDiscount
        }));
    };

    const handleOptionChange = (e) => {
        const option = e.target.id;
        setSelectedOption(e.target.id); 
        setProductList(currentProductList => ({
            ...currentProductList,
            options: option
        }));
    };


    const handleRepresentativeInputClick = () => {
        document.getElementById('hiddenRepresentativeInput').click();
    };

    const handleListImgInputClick1 = () => {
        document.getElementById('hiddenListImgInput1').click();
    };

    const handleListImgInputClick2 = () => {
        document.getElementById('hiddenListImgInput2').click();
    };

    const handleListImgInputClick3 = () => {
        document.getElementById('hiddenListImgInput3').click();
    };

    const handleListImgInputClick4 = () => {
        document.getElementById('hiddenListImgInput4').click();
    };

    const representativeImageAdd = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        setImageRepresetativePreview(imageUrl);
        setProductList(currentProductList => ({
            ...currentProductList,
            representativeImage: imageUrl
        }));
        
        e.target.value = '';
    };

    const handleRepresentativeDeleteClick = async () => {
        try {
            const queryParams = imageRepresetativePreview.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setImageRepresetativePreview('');
            setProductList(currentProductList => ({
                ...currentProductList,
                representativeImage: ''
            }));
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    const listImageAdd1 = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        setImageListImgPreview1(imageUrl);
        setProductList(currentProductList => ({
            ...currentProductList,
            listImage1: imageUrl
        }));
        e.target.value = '';
    };

    const handleListImgDeleteClick1 = async () => {
        try {
            const queryParams = imageListImgPreview1.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setImageListImgPreview1('');
            setProductList(currentProductList => ({
                ...currentProductList,
                listImage1: ''
            }));
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    const listImageAdd2 = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        setImageListImgPreview2(imageUrl);
        setProductList(currentProductList => ({
            ...currentProductList,
            listImage2: imageUrl
        }));
        e.target.value = '';
    };

    const handleListImgDeleteClick2 = async () => {
        try {
            const queryParams = imageListImgPreview2.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setImageListImgPreview2('');
            setProductList(currentProductList => ({
                ...currentProductList,
                listImage2: ''
            }));
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    const listImageAdd3 = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        setImageListImgPreview3(imageUrl);
        setProductList(currentProductList => ({
            ...currentProductList,
            listImage3: imageUrl
        }));
        e.target.value = '';
    };

    const handleListImgDeleteClick3 = async () => {
        try {
            const queryParams = imageListImgPreview3.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setImageListImgPreview3('');
            setProductList(currentProductList => ({
                ...currentProductList,
                listImage3: ''
            }));
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    const listImageAdd4 = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        setImageListImgPreview4(imageUrl);
        setProductList(currentProductList => ({
            ...currentProductList,
            listImage4: imageUrl
        }));
        e.target.value = '';
    };

    const handleListImgDeleteClick4 = async () => {
        try {
            const queryParams = imageListImgPreview4.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setImageListImgPreview4('');
            setProductList(currentProductList => ({
                ...currentProductList,
                listImage4: ''
            }));
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    const totalImgDelete = () => {
        handleRepresentativeDeleteClick();
        handleListImgDeleteClick1();
        handleListImgDeleteClick2();
        handleListImgDeleteClick3();
        handleListImgDeleteClick4();
    };

    useEffect(() => {
        // console.log(imageRepresetativePreview);
    },[imageRepresetativePreview]);

    useEffect(() => {
        // console.log(productList);
    }, [productList]);
    
    const onProductName = (e) => {
        const newName = e.target.value;
        // console.log(newName);
        setProductName(newName);
        setProductList(currentProductList => ({
            ...currentProductList,
            productName: newName
        }));
    };

    const onProductInfo = (e) => {
        const newInfo = e.target.value;
        
        // Check if the new input exceeds the limit
        if (newInfo.length > charLimit) {
            alert('입력 가능한 문자수가 초과하였습니다.'); // Alert when limit is exceeded
            return; // Prevent further action
        }

        setProductInfo(newInfo);
        setCharCount(newInfo.length); // Update character count

        // Update product list or other state as needed
        setProductList((currentProductList) => ({
            ...currentProductList,
            productInfo: newInfo,
        }));
    };

    useEffect(() => {
        middleCategoryData();
        supportSubCategorySelect();
    }, []);

    const middleCategoryData = async () => {
        const response = await category.supportCategory();
        const data = response.data;
        setMiddleCategory(data);
    };

    const supportSubCategorySelect = async () => {
        const response = await category.supportSubCategorySelect();
        const data = response.data;

        // console.log('supportSubCategorySelect : ', data);
        setSubCategory(data);
    }

    const onMainCategory = (e) => {
        // console.log(e);
        const newCategory = e;
        setMainCategorySelect(newCategory);
        setProductList(currentProductList => ({
            ...currentProductList,
            mainCategory: newCategory
        }));
    };

    const onMiddleCategory = (value) => {
        setMiddleCategorySelect(value);

        // 선택된 중분류에 맞는 소분류 필터링
        const filteredSubCategories = subCategory.filter(sub => sub.supportMainCategoryEngName === value);
        // console.log('filteredSubCategories : ', filteredSubCategories);
        setSelectSubCategory(filteredSubCategories);

        // 소분류 초기화
        setSubCategorySelect('');
        setProductList(currentProductList => ({
            ...currentProductList,
            middleCategory : value,
            subCategory: ''
        }));
    };

    const onSubCategory = (value) => {
        setSubCategorySelect(value);
        setProductList(currentProductList => ({
            ...currentProductList,
            subCategory: value
        }));
    };

    const onDetailContent = (data) => {
        setProductList(currentProductList => ({
            ...currentProductList,
            detailContents: data
        }));
    };

    const onExchangeReturn = (data) => {
        setProductList(currentProductList => ({
            ...currentProductList,
            exchangeReturn: data
        }));
    };

    useEffect(() => {
        if(mainCategorySelect === '장소대관') {
            setMiddleCategory([
                {middleCategoryNo: 1, middleCategoryName: '어드민에서 설정한 카테고리1'},
                {middleCategoryNo: 2, middleCategoryName: '어드민에서 설정한 카테고리2'},
                {middleCategoryNo: 3, middleCategoryName: '어드민에서 설정한 카테고리3'},
                {middleCategoryNo: 4, middleCategoryName: '어드민에서 설정한 카테고리4'}
            ]);
        } else {
            setMiddleCategory([]);
        }
    }, [mainCategorySelect]);

    const handleAddProduct = () => {
        const newProducts = [...products, { options: [], useOptions: false }];
        updateProductList(newProducts);
    };

    const handleAddOption = (productIndex) => {
        const newProducts = [...products];
        newProducts[productIndex].options.push({ type: '', items: [] });
        updateProductList(newProducts);
    };

    const handleAddOptionItem = (productIndex, optionIndex) => {
        const newProducts = [...products];
        newProducts[productIndex].options[optionIndex].items = newProducts[productIndex].options[optionIndex].items || [];
        newProducts[productIndex].options[optionIndex].items.push({ name: '', price: 0 });
        updateProductList(newProducts);
    };

    const handleRemoveOption = (productIndex, optionIndex) => {
        const newProducts = [...products];
        newProducts[productIndex].options = newProducts[productIndex].options.filter((_, i) => i !== optionIndex);
        updateProductList(newProducts);
    };

    const handleRemoveOptionItem = (productIndex, optionIndex, itemIndex) => {
        const newProducts = [...products];
        newProducts[productIndex].options[optionIndex].items = newProducts[productIndex].options[optionIndex].items.filter((_, i) => i !== itemIndex);
        updateProductList(newProducts);
    };

    const handleRemoveProduct = (productIndex) => {
        const newProducts = [...products];
        newProducts.splice(productIndex, 1);
        updateProductList(newProducts);
    };

    const handleTypeChange = (value, productIndex, optionIndex) => {
        // console.log('value : ', value);
        const newProducts = [...products];
        newProducts[productIndex].options[optionIndex].type = value;
        newProducts[productIndex].options[optionIndex].items = newProducts[productIndex].options[optionIndex].items || [];
    
        // '단일선택' 또는 '중복선택'일 경우에만 useQuantity 속성 추가
        if (value === 'singleSelection' || value === 'multipleSelection') {
            newProducts[productIndex].options[optionIndex].useQuantity = newProducts[productIndex].options[optionIndex].useQuantity || false;
        } else {
            delete newProducts[productIndex].options[optionIndex].useQuantity;
        }

        // newProducts[productIndex].options[optionIndex].dateTime = value === 'dateSelection';
    
        updateProductList(newProducts);
    };

    const handleOptionItemChange = (e, productIndex, optionIndex, itemIndex, field, fieldFormat) => {
        const inputValue = e.target.value;
    
        // 숫자 이외의 값 제거
        const numericValue = unformatNumber(inputValue).replace(/[^0-9]/g, "");
    
        // 숫자를 포맷해서 표시
        const formatPrice = formatNumber(numericValue);
    
        const newProducts = [...products];
    
        // `items[itemIndex]`가 객체가 아닌 경우 초기화
        if (typeof newProducts[productIndex].options[optionIndex].items[itemIndex] !== 'object') {
            newProducts[productIndex].options[optionIndex].items[itemIndex] = { name: "", price: 0, formatPrice: "" };
        }
    
        // 값 업데이트
        newProducts[productIndex].options[optionIndex].items[itemIndex][field] = field === 'price' ? parseInt(numericValue, 10) : inputValue;
        if (fieldFormat === 'formatPrice') {
            newProducts[productIndex].options[optionIndex].items[itemIndex][fieldFormat] = formatPrice;
        }
    
        updateProductList(newProducts);
    };
  
    const handleUseProductQuantityChange = (productIndex, useProductQuantity) => {
        const newProducts = [...products];
        newProducts[productIndex].useProductQuantity = useProductQuantity;
        updateProductList(newProducts);
    };

    const handleUseOptionsChange = (productIndex, useOptions) => {
        const newProducts = [...products];
        newProducts[productIndex].useOptions = useOptions;
        updateProductList(newProducts);
    };

    const handleCombinationUseOptionsChange = (productIndex, combinationUseOptions) => {
        const newProducts = [...products];
        newProducts[productIndex].combinationUseOptions = combinationUseOptions;
        updateProductList(newProducts);
    };

    const handleOptionTitleChange = (e, productIndex, optionIndex) => {
      const newProducts = [...products];
      newProducts[productIndex].options[optionIndex].title = e.target.value;
      updateProductList(newProducts);
    };

    const handleShortAnswerPriceChange = (e, productIndex, optionIndex) => {

        const inputValue = e.target.value;
        
        const numericValue = unformatNumber(inputValue).replace(/[^0-9]/g, "");
        
        const formatPrice = formatNumber(numericValue);

        const newProducts = [...products];
        newProducts[productIndex].options[optionIndex].price = parseInt(numericValue, 10) || 0;
        newProducts[productIndex].options[optionIndex].formatPrice = formatPrice;
        updateProductList(newProducts);
  };
    

  const renderOptionContent = (option, productIndex, optionIndex) => {
    switch (option.type) {
        case 'shortAnswer':
            return (
                <div key={optionIndex} className={`${seller.seller_edit_option_select_short_answer}`}>
                    <div><input type="text" placeholder='입력하고 싶은 레터링을 추가해 주세요.' value={option.title || ''} onChange={(e) => handleOptionTitleChange(e, productIndex, optionIndex)} /></div>
                    <div><span>금액추가</span></div>
                    <div>
                        <div><span>&#8361;</span></div>
                        <div>
                            <input
                                type="text"
                                placeholder='0'
                                value={products[productIndex].options[optionIndex].formatPrice || ""}
                                onChange={(e) => handleShortAnswerPriceChange(e, productIndex, optionIndex)}
                            />
                        </div>
                    </div>
                </div>
            );
        case 'singleSelection':
        case 'multipleSelection':
            return (
                <div key={optionIndex} className={`${seller.seller_edit_option_select_single_selection}`}>
                    <div><input type="text" placeholder={`${option.type === 'singleSelection' ? '단일선택' : '중복선택'} 제목을 입력해주세요.`} value={option.title || ''} onChange={(e) => handleOptionTitleChange(e, productIndex, optionIndex)} /></div>
                    {option.items.map((item, itemIndex) => (
                        <div key={itemIndex} className={`${seller.seller_edit_option_select_single_selection_add}`}>
                            <div><input type="text" placeholder='옵션명' value={item.name} onChange={(e) => handleOptionItemChange(e, productIndex, optionIndex, itemIndex, 'name')} /></div>
                            <div><span>금액추가</span></div>
                            <div>
                                <div><span>&#8361;</span></div>
                                <div>
                                    <input 
                                        type="text" 
                                        placeholder='0' 
                                        value={products[productIndex].options[optionIndex].items[itemIndex].formatPrice || ""} // 포맷된 값
                                        onChange={(e) => handleOptionItemChange(e, productIndex, optionIndex, itemIndex, 'price', 'formatPrice')} 
                                    />
                                </div>
                            </div>
                            <div className='ps-3'>
                                <button onClick={() => handleRemoveOptionItem(productIndex, optionIndex, itemIndex)}><span className="material-symbols-outlined">cancel</span></button>
                            </div>
                        </div>
                    ))}
                    <div className={`${seller.seller_edit_option_select_single_selection_add_btn}`}><button onClick={() => handleAddOptionItem(productIndex, optionIndex)}>옵션추가</button></div>
                </div>
            );
        case 'dateSelection':
            return (
                <div key={optionIndex} className={`${seller.seller_edit_option_select_date}`}>
                    <div><input type="text" placeholder='ex) 언제까지 배달을 희망하시나요?' value={option.title || ''} onChange={(e) => handleOptionTitleChange(e, productIndex, optionIndex)} /></div>
                    <div className='py-2'>
                        <div>
                            <input 
                                type="checkbox" 
                                checked={option.dateTime || false}
                                onChange={(e) => {
                                    const newProducts = [...products];
                                    newProducts[productIndex].options[optionIndex].dateTime = e.target.checked;
                                    updateProductList(newProducts);
                                }}
                            />
                            <label className='ps-1' htmlFor="">시간 포함</label>
                        </div>
                    </div>
                </div>
            );
        case 'fileUpload':
            return (
                <div key={optionIndex} className={`${seller.seller_edit_option_select_file_upload}`}>
                    <div><input type="text" placeholder='ex) 인쇄할 이미지 파일을 올려주세요.' value={option.title || ''} onChange={(e) => handleOptionTitleChange(e, productIndex, optionIndex)} /></div>
                    <div className='py-2'>
                        <div><span>최대 50mb 첨부 가능</span></div>
                    </div>
                </div>
            );
        default:
            return null;
    }
};


    const getOptionLabel = (value) => {
        switch (value) {
            case 'shortAnswer':
                return '단답형';
            case 'singleSelection':
                return '단일선택';
            case 'multipleSelection':
                return '중복선택';
            case 'dateSelection':
                return '날짜선택';
            case 'fileUpload':
                return '파일 업로드';
            default:
                return '';
        }
    };

    const handleImageAdd = (productIndex) => {
        document.getElementById(`hiddenImageInput_${productIndex}`).click();
    };

    const imageAdd = async (e, productIndex) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        const newProducts = [...products];
        newProducts[productIndex].imageUrl = imageUrl;
        updateProductList(newProducts);
    };

    
    const handleImageRemove = (productIndex) => {
        const newProducts = [...products];
        newProducts[productIndex].imageUrl = ''; // 이미지 URL 초기화
        updateProductList(newProducts);
    };


    const onProductCheck = () => {
      return productList.productName != '' && productList.listImage1 != '' && productList.listImage2 != '' &&
      productList.listImage3 != '' && productList.listImage4 != '' && productList.middleCategory != '' &&
      productList.representativeImage != '' && productList.mainItem.length > 0 && productList.detailContents != ''
    }

    const onProductSave = async () => {
        const middleCategory = productList.middleCategory;
        const formatMiddleCategory = middleCategory.join(";");

        const productData = {
            ...productList,
            faq: productList.faq.join(","),
            address: address,
            detailAddress: detailAddress,
            lat : String(coordinates.lat),
            lng : String(coordinates.lng),
            middleCategory : formatMiddleCategory
        };

        // console.log('productData : ',productData);

        const response = await sellerapi.supportProductAdd(productData, userInfo && userInfo.userId);
        const data = response.data;

        // console.log(data);

        if(data === 'SUCCESS') {
            MySwal.fire({
                customClass: {
                    popup: `${seller.seller_date_save_popup}`
                },
                html : 
                `
                    <div>
                        <div class='${seller.seller_date_save_title}'><span>상품 등록 완료</span></div>
                        <div class='${seller.seller_date_save_content}'><span>상품 등록이 완료되어 저장되었습니다.</span></div>
                        <div class='${seller.seller_date_save_btn} py-2'><button id='seller_sales_save'>확인</button></div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
    
            document.addEventListener('click', productSaveSuccess);
    
            function productSaveSuccess(e) {
            if(e.target && e.target.id == 'seller_sales_save') {
                navigate('/seller/products');
                MySwal.close();
                document.removeEventListener('click', productSaveSuccess);
            }
            }
        }
    };
    
    const sellerFaqAdd = () => {
        setShowFaqAdd(true);
    }

    const onFaqSelect = () => {
        faqListSelect();
        setShowFaqModal(true);
    }

    const handleMinDateChange = (e) => {
        setMinDate(e.target.value); // 최소일수 값 업데이트
        
        setProductList({
            ...productList, minDate : e.target.value
        })
    };
    
    const handleMinDateStateChange = (state) => {
        // console.log('state : ', state);
        setMinDateState(state); // true/false 상태 업데이트
        
        if (state) {
            setProductList({
                ...productList, minDateState : state
            })
        }

        if(!state) {
            setProductList({
                ...productList, minDateState : state, minDate : 0
            })
            setMinDate(0); // "사용안함"일 경우 최소일수 값 초기화

        }
    };

    const handleProductPrice = (productIndex, e) => {
        const inputValue = e.target.value;

        // 숫자 이외의 값 제거
        const numericValue = unformatNumber(inputValue).replace(/[^0-9]/g, "");

        // 숫자를 포맷해서 표시
        const formatted = formatNumber(numericValue);

        // products 리스트 업데이트
        const newProducts = [...products];
        newProducts[productIndex].mainItemPrice = numericValue; // 콤마 없는 숫자를 저장
        newProducts[productIndex].formattedPrice = formatted;
        updateProductList(newProducts);
    }

    const formatNumber = (value) => {
        if (!value) return '';
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    
    const unformatNumber = (value) => {
        return value.replace(/,/g, ""); // 콤마 제거
    };

    const onInfoOptionSelect = (e) => {
        let infoOption = e.target.id;

        if (infoOption === 'info_true') {
            setInfoTapState(true);
            setProductList((prevInfo) => ({ ...prevInfo, infoTapState: true}));
        }
        if (infoOption === 'info_false') {
            setInfoTapState(false);
            setProductList((prevInfo) => ({ ...prevInfo, infoTapState: false}));
        }
    }
    const onCalendarSettingBtn = (e) => {
        let reservation_unit = e.target.id;

        if (reservation_unit === 'unit_true') {
            setCalendarSetting(true);
            setProductList((prevInfo) => ({ ...prevInfo, calendarSetting: true}));
        }
        if (reservation_unit === 'unit_false') {
            setCalendarSetting(false);
            setProductList((prevInfo) => ({ ...prevInfo, calendarSetting: false}));
        }
    }

    const initializeMap = () => {
        const map = new window.google.maps.Map(mapRef.current, {
            center: coordinates,
            zoom: 15,
        });
        googleMapRef.current = map;

        const marker = new window.google.maps.Marker({
            position: coordinates,
            map,
            draggable: true,
        });
        markerRef.current = marker;

        // 마커 이동 이벤트
        marker.addListener("dragend", async () => {
            const newPos = marker.getPosition();
            const newCoordinates = { lat: newPos.lat(), lng: newPos.lng() };

            // 좌표 업데이트
            console.log("newCoordinates: ", newCoordinates);
            setCoordinates(newCoordinates);

            // Reverse Geocoding으로 주소 가져오기
            const newAddress = await reverseGeocode(newCoordinates.lat, newCoordinates.lng);

            // "대한민국" 제거
            const formattedAddress = newAddress.replace("대한민국", "").trim();

            console.log("newAddress without 대한민국: ", formattedAddress);
            setAddress(formattedAddress);
        });
    };
    
    const geocodeAddress = async () => {
        const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
        )}&key=${apiKey}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.status === "OK" && data.results.length > 0) {
                console.log('지도 데이터 값 : ', data);
                const location = data.results[0].geometry.location;
                console.log('location : ', location);
                setAddressState(true);
                setCoordinates({ lat: location.lat, lng: location.lng });
                updateMap(location.lat, location.lng); // 지도 업데이트
            } else {
                alert("주소를 찾을 수 없습니다. 정확히 입력해주세요.");
            }
        } catch (error) {
            console.error("Geocoding API 호출 오류:", error);
            alert("주소를 변환하는 중 오류가 발생했습니다.");
        }
    };

    const updateMap = (lat, lng) => {
        if (googleMapRef.current && markerRef.current) {
            const latLng = new window.google.maps.LatLng(lat, lng);
            googleMapRef.current.setCenter(latLng);
            markerRef.current.setPosition(latLng);
        }
    };

    const onMapUseBtn = (e) => {
        if(e.target.id === "map_true") {
            setMapUseState(true);
            setProductList((prevInfo) => ({ ...prevInfo, mapUseState: true}));
        }
        
        if(e.target.id === "map_false") {
            setMapUseState(false);
            setAddressState(false);
            setAddress('');
            setDetailAddress('');
            setProductList((prevInfo) => ({ ...prevInfo, mapUseState: false}));
        }
    };

    // Reverse Geocoding 함수
    const reverseGeocode = async (lat, lng) => {
        const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.status === "OK" && data.results.length > 0) {
                // 첫 번째 결과의 formatted_address 반환
                return data.results[0].formatted_address;
            } else {
                console.error("Reverse Geocoding 실패:", data.status);
                return "주소를 가져올 수 없습니다.";
            }
        } catch (error) {
            console.error("Reverse Geocoding API 호출 오류:", error);
            return "주소를 가져오는 중 오류가 발생했습니다.";
        }
    };

    return (
        <>
            <div className={`px-3 ${seller.seller_right_container}`}>
                <div>
                    <div className={`pt-3 pb-2 border-bottom ${seller.seller_edit_title}`}><span>상품 등록</span></div>
                    <div className={`pt-5 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                        <div><span>메인 이미지 등록</span></div>
                        <div><span>펼치기</span><span><span className="material-symbols-outlined">expand_more</span></span></div>
                    </div>
                    <div className='d-flex w-100 border-bottom align-items-center py-3'>
                        <div className={`${seller.seller_settlement_left}`}><span>썸네일 이미지 등록</span></div>
                        <div className={`${seller.seller_settlement_middle} px-3`}>
                            <div className={`${seller.seller_settlement_store_profile_img} position-relative`}>
                                {imageRepresetativePreview === '' ? 
                                    <div className={`position-absolute ${seller.seller_settlement_default_img}`}>
                                        <img src="../img/seller/icon/default2.png" alt="" />
                                    </div>
                                    :
                                    <img src={imageRepresetativePreview === '' ? '' : imageRepresetativePreview} alt="상점 프로필" /> 
                                }
                            </div>
                            <div className='pt-2'>
                                <input
                                    id="hiddenRepresentativeInput"
                                    type="file"
                                    onChange={(event) => representativeImageAdd(event)}
                                    style={{display: 'none'}}
                                />
                                <button className='bg-body py-1 px-3 me-1 rounded border border-1' onClick={() => handleRepresentativeInputClick()} >등록</button>
                                <button className='bg-body py-1 px-3 ms-1 rounded border border-1' onClick={() => handleRepresentativeDeleteClick()} >삭제</button>
                            </div>
                        </div>
                        <div className={`${seller.seller_settlement_right}`}>
                            <div><span>*업로드 가능한 파일 확장자: jpg, png, heic</span></div>
                            <div><span>*이미지 용량: 1MB 이하</span></div>
                        </div>
                    </div>
                    <div className={`pt-5 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                        <div><span>리스트 이미지 등록</span></div>
                        <div><span>펼치기</span><span><span className="material-symbols-outlined">expand_more</span></span></div>
                    </div>
                    <div className={`${seller.seller_representative_list_img_box}`}>
                        <div className={`${seller.seller_edit_left}`}><span>리스트 이미지</span></div>
                        <div className={`${seller.seller_edit_right} row`}>
                            <div className='col px-3 py-3'>
                                <div className={`${seller.seller_edit_img_box}`}>
                                    {imageListImgPreview1 === '' ?
                                        <img src="../img/seller/icon/default2.png" alt="" />
                                        :
                                        <img className={`${seller.representativeImg}`} src={imageListImgPreview1 === '' ? '' : imageListImgPreview1} alt="" />
                                    }
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='pt-2'>
                                        <button className={`${seller.seller_edit_listimg_add}`} onClick={() => handleListImgInputClick1()} >등록</button>
                                        <input
                                            id="hiddenListImgInput1"
                                            type="file"
                                            onChange={(event) => listImageAdd1(event)}
                                            style={{display: 'none'}}
                                        />
                                    </div>
                                    <div className='pt-2 ps-2'><button className={`${seller.seller_edit_listimg_cancel}`} onClick={() => handleListImgDeleteClick1()} >삭제</button></div>
                                </div>
                            </div>
                            <div className='col px-3 py-3'>
                                <div className={`${seller.seller_edit_img_box}`}>
                                    {imageListImgPreview2 === '' ?
                                        <img src="../img/seller/icon/default2.png" alt="" />
                                        :
                                        <img className={`${seller.representativeImg}`} src={imageListImgPreview2 === '' ? '' : imageListImgPreview2} alt="" />
                                    }
                                </div>
                                <div className='d-flex w-100 align-items-center'>
                                    <div className='pt-2'>
                                        <button className={`${seller.seller_edit_listimg_add}`} onClick={() => handleListImgInputClick2()} >등록</button>
                                        <input
                                            id="hiddenListImgInput2"
                                            type="file"
                                            onChange={(event) => listImageAdd2(event)}
                                            style={{display: 'none'}}
                                        />
                                    </div>
                                    <div className='pt-2 ps-2'><button className={`${seller.seller_edit_listimg_cancel}`} onClick={() => handleListImgDeleteClick2()} >삭제</button></div>
                                </div>
                            </div>
                            <div className='col px-3 py-3'>
                                <div className={`${seller.seller_edit_img_box}`}>
                                    {imageListImgPreview3 === '' ?
                                        <img src="../img/seller/icon/default2.png" alt="" />
                                        :
                                        <img className={`${seller.representativeImg}`} src={imageListImgPreview3 === '' ? '' : imageListImgPreview3} alt="" />
                                    }
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='pt-2'>
                                        <button className={`${seller.seller_edit_listimg_add}`} onClick={() => handleListImgInputClick3()} >등록</button>
                                        <input
                                            id="hiddenListImgInput3"
                                            type="file"
                                            onChange={(event) => listImageAdd3(event)}
                                            style={{display: 'none'}}
                                        />
                                    </div>
                                    <div className='pt-2 ps-2'><button className={`${seller.seller_edit_listimg_cancel}`} onClick={() => handleListImgDeleteClick3()} >삭제</button></div>
                                </div>
                            </div>
                            <div className='col px-3 py-3'>
                                <div className={`${seller.seller_edit_img_box}`}>
                                    {imageListImgPreview4 === '' ?
                                        <img src="../img/seller/icon/default2.png" alt="" />
                                        :
                                        <img className={`${seller.representativeImg}`} src={imageListImgPreview4 === '' ? '' : imageListImgPreview4} alt="" />
                                    }
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='pt-2'>
                                        <button className={`${seller.seller_edit_listimg_add}`} onClick={() => handleListImgInputClick4()} >등록</button>
                                        <input
                                            id="hiddenListImgInput4"
                                            type="file"
                                            onChange={(event) => listImageAdd4(event)}
                                            style={{display: 'none'}}
                                        />
                                    </div>
                                    <div className='pt-2 ps-2'><button className={`${seller.seller_edit_listimg_cancel}`} onClick={() => handleListImgDeleteClick4()} >삭제</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='border-bottom'>
                        <div className={`pt-5 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                            <div><span>상품명 등록</span></div>
                            <div><span>펼치기</span><span><span className="material-symbols-outlined">expand_more</span></span></div>
                        </div>
                        <div className={`${seller.seller_edit_product_name_box} py-3`}>
                            <div className={`${seller.seller_edit_left}`}>상품명</div>
                            <div className={`${seller.seller_edit_right}`}>
                                <input className={`${seller.seller_edit_input_w100}`} type="text" placeholder='상품명을 입력해주세요.' onChange={(e) => onProductName(e)} />
                            </div>
                        </div>
                    </div>
                    <div className='border-bottom'>
                        <div className={`pt-5 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                            <div><span>상품소개 등록</span></div>
                            <div>
                                <span>펼치기</span>
                                <span><span className="material-symbols-outlined">expand_more</span></span>
                            </div>
                        </div>
                        <div className={`${seller.seller_edit_product_name_box} py-3`}>
                            <div className={`${seller.seller_edit_left}`}>상품소개</div>
                            <div className={`${seller.seller_edit_right} ${seller.seller_edit_product_info}`}>
                                <textarea
                                    className={`${seller.seller_edit_textarea_w100}`}
                                    placeholder='상품소개를 입력해주세요(ex. 무드가 있는 데일리덕에서 최애를 빛내보세요!)'
                                    onChange={onProductInfo}
                                    value={productInfo} // Controlled input with state value
                                ></textarea>
                                <div className={`${seller.seller_edit_product_info_txt_cnt}`}><span>{charCount}/{charLimit}</span></div> {/* Character count display */}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={`pt-5 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                            <div><span>상품 분류</span></div>
                            <div><span>펼치기</span><span><span className="material-symbols-outlined">expand_more</span></span></div>
                        </div>
                        <div className='d-flex w-100 align-items-center py-3 border-bottom'>
                            <div className={`${seller.seller_edit_three_left}`}>대분류</div>
                            <div className={`${seller.seller_edit_three_middle} pe-3`}>
                                <Select className={`${seller.seller_edit_select}`} 
                                    placeholder="카테고리를 선택해주세요." 
                                    onChange={(e) => onMainCategory(e)}
                                    value={'support'}
                                >
                                    <Option value="support">서포트</Option>
                                </Select>
                            </div>
                            <div className={`${seller.seller_edit_three_right}`}><span></span></div>
                        </div>
                        <div className='d-flex w-100 align-items-center py-3 border-bottom'>
                            <div className={`${seller.seller_edit_three_left}`}>중분류</div>
                            <div className={`${seller.seller_edit_three_middle} pe-3`}>
                                <Select className={`${seller.seller_edit_select}`} placeholder="카테고리를 선택해주세요." onChange={(e) => onMiddleCategory(e)}>
                                    {middleCategory.map((item, index) => (
                                        <Option key={index} value={item.dailyduckSupportcategoryEngName}>{item.dailyduckSupportcategoryName}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className={`${seller.seller_edit_three_right}`}><span></span></div>
                        </div>
                        <div className='d-flex w-100 align-items-center py-3 border-bottom'>
                            <div className={`${seller.seller_edit_three_left}`}>소분류</div>
                            <div className={`${seller.seller_edit_three_middle} pe-3`}>
                                <Select 
                                    className={`${seller.seller_edit_select}`}
                                    placeholder="카테고리를 선택해주세요." 
                                    onChange={onSubCategory} 
                                    value={subCategorySelect || '카테고리를 선택해주세요.'}
                                    disabled={selectSubCategory.length === 0} // 소분류가 없을 경우 비활성화
                                >
                                    {selectSubCategory.map((item, index) => (
                                        <Option key={index} value={item.supportSubCategoryEngName}>
                                            {item.supportSubCategoryKorName}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className={`${seller.seller_edit_three_right}`}><span></span></div>
                        </div>
                    </div>
                    {/* 최소일수 */}
                    <div className='border-bottom'>
                        <div className={`pt-5 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                            <div><span>최소일수 사용여부</span></div>
                            <div><span>펼치기</span><span><span className="material-symbols-outlined">expand_more</span></span></div>
                        </div>
                        <div className={`${seller.seller_edit_product_min_date_box} py-3`}>
                            <div className={`${seller.seller_edit_product_min_date_left}`}>최소일수</div>
                            <div className={`${seller.seller_edit_product_min_date_right}`}>
                                <div>
                                    <div>
                                        <input
                                            type="radio"
                                            name="minDate"
                                            id="min_date_true"
                                            checked={minDateState}
                                            onChange={() => handleMinDateStateChange(true)}
                                        />
                                        <label htmlFor="min_date_true">사용함</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            name="minDate"
                                            id="min_date_false"
                                            checked={!minDateState}
                                            onChange={() => handleMinDateStateChange(false)}
                                        />
                                        <label htmlFor="min_date_false">사용안함</label>
                                    </div>
                                </div>
                                {minDateState && (
                                    <div className={`${seller.seller_edit_product_min_date_input_box} py-3`}>
                                        <input
                                            type="number"
                                            id="minDateInput"
                                            value={minDate}
                                            onChange={handleMinDateChange}
                                            placeholder="최소일수를 입력하세요"
                                            className={`${seller.seller_edit_input}`}
                                        />
                                        <label htmlFor="minDateInput">일</label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* 인포탭 */}
                    <div className='border-bottom'>
                        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                            <div><span>휴무일/위치/달력 노출 여부</span></div>
                            <div><span>펼치기</span><span><span className="material-symbols-outlined">expand_more</span></span></div>
                        </div>
                        <div className={`${seller.seller_edit_product_min_date_box} py-3`}>
                            <div className={`${seller.seller_edit_product_min_date_left}`}>노출여부</div>
                            <div className={`${seller.seller_edit_product_min_date_right}`}>
                                <div>
                                    <div>
                                        <input
                                            type="radio"
                                            name="info"
                                            id="info_true"
                                            checked={infoTapState}
                                            onChange={onInfoOptionSelect}
                                        />
                                        <label htmlFor="info_true">노출</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            name="info"
                                            id="info_false"
                                            checked={!infoTapState}
                                            onChange={onInfoOptionSelect}
                                        />
                                        <label htmlFor="info_false">비노출</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 예약단위설정 */}
                    <div className='border-bottom'>
                        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                            <div><span>예약 단위 설정</span></div>
                            <div><span>펼치기</span><span><span className="material-symbols-outlined">expand_more</span></span></div>
                        </div>
                        <div className={`${seller.seller_edit_product_min_date_box} py-3`}>
                            <div className={`${seller.seller_edit_product_min_date_left}`}>설정여부</div>
                            <div className={`${seller.seller_edit_product_min_date_right}`}>
                                <div>
                                    <div>
                                        <input
                                            type="radio"
                                            name="reservationUnit"
                                            id="unit_true"
                                            checked={calendarSetting}
                                            onChange={onCalendarSettingBtn}
                                        />
                                        <label htmlFor="unit_true">사용</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            name="reservationUnit"
                                            id="unit_false"
                                            checked={!calendarSetting}
                                            onChange={onCalendarSettingBtn}
                                        />
                                        <label htmlFor="unit_false">사용안함</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 주소 서비스 설정 */}
                    <div className='border-bottom'>
                        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                            <div><span>주소 서비스 설정</span></div>
                            <div><span>펼치기</span><span><span className="material-symbols-outlined">expand_more</span></span></div>
                        </div>
                        <div className={`${seller.seller_edit_product_min_date_box} py-3`}>
                            <div className={`${seller.seller_edit_product_min_date_left}`}>지도 노출</div>
                            <div className={`${seller.seller_edit_product_min_date_right}`}>
                                <div>
                                    <div>
                                        <input
                                            type="radio"
                                            name="mapUnit"
                                            id="map_true"
                                            checked={mapUseState}
                                            onChange={onMapUseBtn}
                                        />
                                        <label htmlFor="map_true">사용</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            name="mapUnit"
                                            id="map_false"
                                            checked={!mapUseState}
                                            onChange={onMapUseBtn}
                                        />
                                        <label htmlFor="map_false">사용안함</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 주소 입력 및 검색 */}
                    {mapUseState && (
                        <div className={`${seller.seller_edit_google_address_search_box}`}>
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder="주소를 입력하세요"
                            />
                            <button onClick={geocodeAddress}>주소 검색</button>
                        </div>
                    )}
                    {
                        addressState &&
                        <div className={`${seller.seller_edit_google_address_detail_box}`}>
                            <input 
                                type="text" 
                                value={detailAddress}
                                placeholder='정확한 위치를 입력해주세요.'
                                onChange={(e) => setDetailAddress(e.target.value)}
                            />
                        </div>
                    }

                    {/* 지도 렌더링 */}
                    {mapUseState && (
                        <div id="map" ref={mapRef} style={{ width: "100%", height: "400px", marginTop: "20px" }}></div>
                    )}
                    <div>
                        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>상품 정보</span></div>
                        <div className='py-3'>
                            {products.map((product, productIndex) => (
                                <div key={productIndex}>
                                    <div className={`${seller.seller_edit_product_info_box}`}>
                                        <button className={`${seller.seller_edit_product_info_delete}`} onClick={() => handleRemoveProduct(productIndex)}>
                                            <span className="material-symbols-outlined">cancel</span>
                                        </button>
                                        <div>
                                        <div className={`${seller.seller_edit_product_info_img_box}`}>
                                                {product.imageUrl ? (
                                                <div className={`${seller.seller_edit_product_info_img_with_delete}`}>
                                                    <img src={product.imageUrl} alt="Product" />
                                                    <button
                                                        className={`${seller.seller_edit_product_info_img_with_delete_btn}`}
                                                        onClick={() => handleImageRemove(productIndex)}
                                                    >
                                                        <span className="material-symbols-outlined">cancel</span>
                                                    </button>
                                                </div>
                                                ) : (
                                                <button className={`${seller.seller_edit_product_info_add_btn}`} onClick={() => handleImageAdd(productIndex)}>+</button>
                                                )}
                                                <input
                                                    id={`hiddenImageInput_${productIndex}`}
                                                    type="file"
                                                    onChange={(event) => imageAdd(event, productIndex)}
                                                    style={{ display: 'none' }}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${seller.seller_edit_product_info_content_box}`}>
                                            <div className={`${seller.seller_edit_product_info_option_name}`}>
                                                <div><span>상품 이름</span></div>
                                                <div>
                                                    <div><input type="text" onChange={(e) => {
                                                        const newProducts = [...products];
                                                        newProducts[productIndex].mainOptionName = e.target.value;
                                                        updateProductList(newProducts);
                                                    }} /></div>
                                                </div>
                                            </div>
                                            <div className={`${seller.seller_edit_product_info_price_box} py-2`}>
                                                <div><span>판매가</span></div>
                                                <div>
                                                    <div>
                                                        <input 
                                                            type="text" 
                                                            placeholder='0'
                                                            value={products[productIndex].formattedPrice || ""}
                                                            onChange={(e) => handleProductPrice(productIndex, e)}
                                                            // onChange={(e) => {
                                                            //     const newProducts = [...products];
                                                            //     newProducts[productIndex].mainItemPrice = e.target.value;
                                                            //     updateProductList(newProducts);
                                                            // }} 
                                                        />
                                                    </div>
                                                    <div><span>&#8361;</span></div>
                                                </div>
                                            </div>
                                            <div className={`${seller.seller_edit_product_info_option_box} py-2`}>
                                                <div>상품 수량 사용여부</div>
                                                <div>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            name={`product_quantitu_${productIndex}`}
                                                            id={`quantity_true_${productIndex}`}
                                                            checked={product.useProductQuantity}
                                                            onChange={() => handleUseProductQuantityChange(productIndex, true)}
                                                        />
                                                        <label className='ps-1' htmlFor={`quantity_true_${productIndex}`}>사용함</label>
                                                    </div>
                                                    <div className='ps-5'>
                                                        <input
                                                            type="radio"
                                                            name={`product_quantitu_${productIndex}`}
                                                            id={`quantity_false_${productIndex}`}
                                                            checked={!product.useProductQuantity}
                                                            onChange={() => handleUseProductQuantityChange(productIndex, false)}
                                                        />
                                                        <label className='ps-1' htmlFor={`quantity_false_${productIndex}`}>사용안함</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${seller.seller_edit_product_info_option_box} py-2`}>
                                                <div>옵션 사용여부</div>
                                                <div>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            name={`option_select_${productIndex}`}
                                                            id={`option_true_${productIndex}`}
                                                            checked={product.useOptions}
                                                            onChange={() => handleUseOptionsChange(productIndex, true)}
                                                        />
                                                        <label className='ps-1' htmlFor={`option_true_${productIndex}`}>사용함</label>
                                                    </div>
                                                    <div className='ps-5'>
                                                        <input
                                                            type="radio"
                                                            name={`option_select_${productIndex}`}
                                                            id={`option_false_${productIndex}`}
                                                            checked={!product.useOptions}
                                                            onChange={() => handleUseOptionsChange(productIndex, false)}
                                                        />
                                                        <label className='ps-1' htmlFor={`option_false_${productIndex}`}>사용안함</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                product.useOptions &&
                                                <div className={`${seller.seller_edit_product_info_option_box} py-2`}>
                                                    <div><span>조합옵션 사용여부</span></div>
                                                    <div>
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                name={`combination_option_select_${productIndex}`}
                                                                id={`combination_option_true_${productIndex}`}
                                                                checked={product.combinationUseOptions}
                                                                onChange={() => handleCombinationUseOptionsChange(productIndex, true)}
                                                            />
                                                            <label className='ps-1' htmlFor={`combination_option_true_${productIndex}`}>사용함</label>
                                                        </div>
                                                        <div className='ps-5'>
                                                            <input
                                                                type="radio"
                                                                name={`combination_option_select_${productIndex}`}
                                                                id={`combination_option_false_${productIndex}`}
                                                                checked={!product.combinationUseOptions}
                                                                onChange={() => handleCombinationUseOptionsChange(productIndex, false)}
                                                            />
                                                            <label className='ps-1' htmlFor={`combination_option_false_${productIndex}`}>사용안함</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {product.useOptions && !product.combinationUseOptions && (
                                        <div className={`${seller.seller_edit_product_info_option_detail_box}`}>
                                            {product.options.map((option, optionIndex) => (
                                                <div key={optionIndex} className={`${seller.seller_edit_option_select}`}>
                                                    <div className={`${seller.seller_edit_option_select_close}`}>
                                                        <button onClick={() => handleRemoveOption(productIndex, optionIndex)}><span className="material-symbols-outlined">cancel</span></button>
                                                    </div>
                                                    <div className={`${seller.seller_edit_option_select_add}`}>
                                                        <div><span>옵션 추가</span></div>
                                                        <div className='me-3'>
                                                            <Select
                                                                className={`${seller.seller_edit_option_select_type}`}
                                                                onChange={(value) => handleTypeChange(value, productIndex, optionIndex)}
                                                                options={[
                                                                    { value: 'shortAnswer', label: '단답형' },
                                                                    { value: 'singleSelection', label: '단일선택' },
                                                                    { value: 'multipleSelection', label: '중복선택' },
                                                                    { value: 'dateSelection', label: '날짜선택' },
                                                                    { value: 'fileUpload', label: '파일 업로드' }
                                                                ]}
                                                                value={option.type ? { value: option.type, label: getOptionLabel(option.type) } : null}
                                                            />
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                checked={option.required || false}
                                                                onChange={(e) => {
                                                                    const newProducts = [...products];
                                                                    newProducts[productIndex].options[optionIndex].required = e.target.checked;
                                                                    updateProductList(newProducts);
                                                                }}
                                                            />
                                                            <label className='ps-1'>필수</label>
                                                        </div>
                                                        {/* 단일선택 또는 중복선택일 경우에만 수량사용 체크박스 표시 */}
                                                        {(option.type === 'singleSelection' || option.type === 'multipleSelection') && (
                                                            <div className='ps-3'>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={option.useQuantity || false}
                                                                    onChange={(e) => {
                                                                        const newProducts = [...products];
                                                                        newProducts[productIndex].options[optionIndex].useQuantity = e.target.checked;
                                                                        updateProductList(newProducts);
                                                                    }}
                                                                />
                                                                <label className='ps-1'>수량사용</label>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {renderOptionContent(option, productIndex, optionIndex)}
                                                </div>
                                            ))}
                                            <div className={`${seller.seller_edit_option_select_add_btn}`}>
                                                <button onClick={() => handleAddOption(productIndex)}><span className="material-symbols-outlined">add</span></button>
                                            </div>
                                        </div>
                                    )}
                                    {product.useOptions && product.combinationUseOptions &&
                                        <AdminCombinationManager seller={seller} productList={productList} setProductList={setProductList}/>
                                    }
                                </div>
                            ))}
                            <div className={`${seller.seller_edit_product_info_add_btn}`}>
                                <button onClick={handleAddProduct}>상품추가</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>제품 상세</span></div>
                        <div className='py-3'>
                            <div><span>상세 내용</span></div>
                            <div className='position-relative'>
                                <TextEditor setData={setData} seller={seller} fileName={fileName} setFileName={setFileName} onDetailContent={onDetailContent} data={data} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>FAQ (선택)</span></div>
                        <div className='d-flex w-100 align-items-center py-3'>
                            <div className={`${seller.seller_edit_left}`}><span>자주쓰는 FAQ</span></div>
                            <div className={`${seller.seller_edit_right}`}>
                                <div><button className={`${seller.seller_edit_faq_select_btn}`} onClick={() => onFaqSelect()}>FAQ 선택</button></div>
                                <div className='py-3'><span className='dd-fs-7 ps-2'>*선택 시 상품의 FAQ 페이지에 노출됩니다.</span></div>
                            </div>
                        </div>
                        <div className='d-flex w-100 align-items-center py-3'>
                            <div className={`${seller.seller_edit_left}`}><span>FAQ 신규 등록</span></div>
                            <div className={`${seller.seller_edit_right}`}>
                                <div className='py-1'>
                                    <button className={`${seller.seller_edit_faq_add_btn}`} onClick={() => sellerFaqAdd()}>질문 추가</button>
                                </div>
                            </div>
                        </div>
                        {
                            selectedFaqs.length > 0 &&
                            <div className={`${seller.seller_edit_faq_select_item_box}`}>
                                <div className={`${seller.seller_edit_faq_select_item_title}`}><span>선택된 FAQ</span></div>
                                <div className={`${seller.seller_edit_faq_select_item_content}`}>
                                    {
                                        selectedFaqs.map((item, index) => {
                                            return (
                                                <div className={`${seller.seller_edit_faq_select_item_content_item}`}>
                                                    <div className={`${seller.seller_edit_faq_select_item_content_item_question}`}>
                                                        <div><span>질문</span></div>
                                                        <div><span>{item.question}</span></div>
                                                    </div>
                                                    <div className={`${seller.seller_edit_faq_select_item_content_item_answer}`}>
                                                        <div><span>답변</span></div>
                                                        <div><span>{item.answer}</span></div>
                                                    </div>
                                                    <div className={`${seller.seller_edit_faq_select_item_content_item_delete}`}><button onClick={() => handleRemoveFaq(item)}><span className="material-symbols-outlined">remove</span></button></div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>교환/반품 규정</span></div>
                        <div className='py-3'>
                            <TextEditor2 setData2={setData2} onExchangeReturn={onExchangeReturn} data2={data2}/>
                        </div>
                        {/* <div>{data2}</div> */}
                    </div>
                    <div className='py-5 text-center'>
                        <button 
                        className={`${seller.seller_edit_produt_add_btn} ${onProductCheck() && seller.seller_edit_produt_add_btn_active}`} 
                        disabled={!onProductCheck()}
                        onClick={() => onProductSave()}
                        >
                            상품등록
                        </button>
                    </div>
                </div>
            </div>
            {
                showFaqAdd && 
                <div className={`${seller.seller_edit_produt_faq_add_modal_container}`}>
                    <div className={`${seller.seller_edit_produt_faq_add_modal_box}`}>
                        <div className={`${seller.seller_edit_produt_faq_add_modal_header}`}>
                            <div><button onClick={() => setShowFaqAdd(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${seller.seller_edit_produt_faq_add_modal_content}`}>
                            <SellerSalesMain />
                        </div>
                    </div>
                </div>
            }
            {
                showFaqModal &&
                <div className={`${seller.seller_edit_produt_faq_select_modal_container}`}>
                    <div className={`${seller.seller_edit_produt_faq_select_modal_box}`}>
                        <div className={`${seller.seller_edit_produt_faq_select_modal_header}`}>
                            <div><span>FAQ 선택</span></div>
                            <div><button onClick={() => {setShowFaqModal(false); setSelectedFaqs([]);}}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${seller.seller_edit_produt_faq_select_modal_content}`}>
                            {
                                Array.isArray(faqList) && faqList.map((item, index) => (
                                    <div key={index} className={`${seller.seller_edit_produt_faq_select_modal_content_item}`}>
                                        <div>
                                            <input
                                                type="checkbox"
                                                checked={selectedFaqs.includes(item)}
                                                onChange={() => handleCheckboxChange(item)}
                                            />
                                        </div>
                                        <div>
                                            <div className={`${seller.seller_edit_produt_faq_select_modal_content_question}`}>
                                                <div><span>질문</span></div>
                                                <div><span>{item.question}</span></div>
                                            </div>
                                            <div className={`${seller.seller_edit_produt_faq_select_modal_content_answer}`}>
                                                <div><span>답변</span></div>
                                                <div><span>{item.answer}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={`${seller.seller_edit_produt_faq_select_modal_footer}`}>
                            <div><button onClick={() => {setShowFaqModal(false); setSelectedFaqs([]);}}>취소</button></div>
                            <div><button onClick={handleFaqSelect}>선택</button></div>
                        </div>
                    </div>
                </div>
            }
        </>

    );
};

export default SellerEditMain;
