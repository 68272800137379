import React, { useContext, useEffect, useState } from 'react';
import { animated } from 'react-spring';
import { useTransition } from '@react-spring/core';
import { useDropzone } from "react-dropzone";
import { Swiper, SwiperSlide } from 'swiper/react';
import StarRating from './StarRating'; // StarRating 컴포넌트를 import 합니다.
import dailyduck from '../../containers/my/MyContainner.module.css';
import * as supportApi from '../../apis/support';
import * as storeApi from '../../apis/store';
import * as auth from '../../apis/auth';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginContext } from '../../contexts/LoginContextProvider';

const ReviewWrite = () => {

    const { userInfo } = useContext(LoginContext);
    const location = useLocation();
    const navigate = useNavigate();

    const reservation = location.state?.reservation;
    const support = location.state?.support;
    const storePayment = location.state?.storePayment;
    const store = location.state?.store;

    const [ selectedReview, setSelectedReview ] = useState(null);
    const [ selectedStore, setSelectedStore ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        // console.log('reservation : ', reservation);
        // console.log('support : ', support);
        // console.log('storePayment : ', storePayment);
        // console.log('store : ', store);
    }, [reservation, support, storePayment, store])

    const [images, setImages] = useState([]);
    const [ceoSay, setCeoSay] = useState('');
    const message = '리뷰를 작성해주세요.';
    const maxLength = 500;
    const [rating, setRating] = useState(0);

    const handleReviewWriteBack = () => {
        navigate(-1);
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            // 기존 이미지와 새로 추가된 이미지를 결합
            const newFiles = [
                ...images, 
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            ];
            
            // 최대 4개의 이미지만 유지
            const limitedFiles = newFiles.slice(0, 4);
            setImages(limitedFiles);
        },
    });

    const removeImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };

    const onCeoSay = (e) => {
        const { value } = e.target;
        if (value.length <= maxLength) {
            setCeoSay(value);
        }
    }

    useEffect(() => {
        // console.log(rating);
    }, [rating]);

    useEffect(() => {
        // console.log('selectedReview : ', selectedReview);
    }, [selectedReview])

    useEffect(() => {
        // console.log('images : ', images);
    }, [images]);

    const onReviewSave = async () => {
        setIsLoading(true);
        const fileList = [];
        if(images.length > 0) {
            const formData = new FormData();
            images.forEach(image => {
                formData.append('images', image);
            });
    
            const config = {
                headers: {
                  'content-type': 'multipart/form-data'
                }
            };
    
            try {
                const response = await supportApi.uploadImagesToServer(formData, config);
                const data = response.data;
    
                for (let i = 0; i < data.length; i++) {
                    const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data[i])}`;
                    fileList.push(imageUrl);
                }
            } catch (error) {
                console.error('Image upload failed:', error);
                return;
            }
        }
    
        const reservationNo = reservation.rsNo;
        const userId = reservation.userId;
        const sellerId = reservation.sellerId;
        const productNumber = reservation.productNumber;
    
        try {
            const response = await supportApi.supportReviewAdd(reservationNo, ceoSay, fileList, userId, sellerId, rating, productNumber);
            const data = response.data;
    
            if(data === 'SUCCESS') {
                setIsLoading(false);
                alert('리뷰 등록이 완료되었습니다.');
                navigate(-1);
            }
        } catch (error) {
            console.error('Review save failed:', error);
        }
    }
    const onReviewStoreSave = async () => {
        setIsLoading(true);
        const fileList = [];
        if(images.length > 0) {
            const formData = new FormData();
            images.forEach(image => {
                formData.append('images', image);
            });
    
            const config = {
                headers: {
                  'content-type': 'multipart/form-data'
                }
            };
    
            try {
                const response = await supportApi.uploadImagesToServer(formData, config);
                const data = response.data;
    
                for (let i = 0; i < data.length; i++) {
                    const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data[i])}`;
                    fileList.push(imageUrl);
                }
            } catch (error) {
                console.error('Image upload failed:', error);
                return;
            }
        }
    
        const paymentNo = storePayment.paymentNo;
        const userId = storePayment.userId;
        const sellerId = storePayment.sellerId;
        const productNumber = storePayment.paymentProductNumber;
    
        try {
            const response = await storeApi.storeReviewAdd(paymentNo, ceoSay, fileList, userId, sellerId, rating, productNumber);
            const data = response.data;
    
            if(data === 'SUCCESS') {
                setIsLoading(false);
                alert('리뷰 등록이 완료되었습니다.');
                navigate(-1);
            }
        } catch (error) {
            console.error('Review save failed:', error);
        }
    }
    
    return (
        <>
            {
                isLoading &&
                <div className={`${dailyduck.loading_page}`}>
                    <div><img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" /></div>
                </div>
            }        
            {
                reservation !== undefined && support !== undefined &&
                <div className={`${dailyduck.my_review_container}`}>
                        <div className={dailyduck.mycouponporint_box}>
                            <div className={`${dailyduck.my_review_header}`}>
                                <div>
                                    <button onClick={() => handleReviewWriteBack()}>
                                        <span className="material-symbols-outlined">arrow_back_ios</span>
                                    </button>
                                </div>
                                <div>
                                    <div>
                                        <span>리뷰 작성</span>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white px-3 py-2'>
                                <div className={`${dailyduck.my_review_write_box} py-2`}>
                                    <div className={`${dailyduck.my_review_write_img} py-2 px-2`}><img src={reservation.mainItemImg} alt="" /></div>
                                    <div className={`${dailyduck.my_review_write_content}`}>
                                        <div><span>{support.shopName}</span></div>
                                        <div><span>{support.productName}</span></div>
                                        <div><span>&#8361;{reservation.amount.toLocaleString()}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white py-3 px-3'>
                                <div>별점</div>
                                <div>
                                    <div className={`${dailyduck.my_review_write_wrap} ps-3`}>
                                        <StarRating rating={rating} setRating={setRating} /> {/* StarRating 컴포넌트를 사용합니다. */}
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white py-3 px-3'>
                                <div className='pb-2'>리뷰 내용</div>
                                <div className={`${dailyduck.my_review_write_textbox}`}>
                                    <textarea
                                        name=""
                                        id=""
                                        cols="30"
                                        rows="10"
                                        placeholder={`${message}`}
                                        onChange={(e) => onCeoSay(e)}
                                        value={ceoSay}
                                    >
                                    </textarea>
                                    <div className='counter'>{`${ceoSay.length}/${maxLength}`}</div>
                                </div>
                            </div>
                            <div className='px-3 py-3 bg-white'>
                                <div className='d-flex justify-content-between pb-1'>
                                    <div><span>사진(선택)</span></div>
                                    <div><span className='gray_round'>선택</span></div>
                                </div>
                                <div className='request_input_img'>
                                    <div className='request_drop'>
                                        <div className="drop_box" {...getRootProps()} >
                                            <input {...getInputProps()} />
                                            <span className="material-symbols-outlined">add</span>
                                        </div>
                                    </div>
                                    <div className='request_slider'>
                                        <Swiper
                                            slidesPerView={3}
                                            spaceBetween={15}
                                            freeMode={true}
                                            className="mySwiper"
                                        >
                                            {images.map((image, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className='request_slider_imgBox'>
                                                        <img src={image.preview} alt={`preview ${index}`} />
                                                        <button onClick={() => removeImage(index)}><span className="material-symbols-outlined">close</span></button>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                                <div className='pt-2 pb-4 border-bottom'><span>*사진은 최대 4개까지 등록 가능합니다.</span></div>
                                <div className={`py-3 d-flex justify-content-center ${dailyduck.my_review_write_success_btn}`}>
                                    <button onClick={() => onReviewSave()}>등록하기</button>
                                </div>
                            </div>
                        </div>
                </div>
            }
            {
                storePayment !== undefined && store !== undefined &&
                <div className={`${dailyduck.my_review_container}`}>
                        <div className={dailyduck.mycouponporint_box}>
                            <div className={`${dailyduck.my_review_header}`}>
                                <div>
                                    <button onClick={() => handleReviewWriteBack()}>
                                        <span className="material-symbols-outlined">arrow_back_ios</span>
                                    </button>
                                </div>
                                <div>
                                    <div>
                                        <span>리뷰 작성</span>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white px-3 py-2'>
                                <div className={`${dailyduck.my_review_write_box} py-2`}>
                                    <div className={`${dailyduck.my_review_write_img} py-2 px-2`}><img src={storePayment.paymentImg} alt="" /></div>
                                    <div className={`${dailyduck.my_review_write_content}`}>
                                        <div><span>{storePayment.shopName}</span></div>
                                        <div><span>{storePayment.paymentProductName}</span></div>
                                        <div><span>&#8361;{parseInt(storePayment.paymentPrice).toLocaleString()}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white py-3 px-3'>
                                <div>별점</div>
                                <div>
                                    <div className={`${dailyduck.my_review_write_wrap} ps-3`}>
                                        <StarRating rating={rating} setRating={setRating} /> {/* StarRating 컴포넌트를 사용합니다. */}
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white py-3 px-3'>
                                <div className='pb-2'>리뷰 내용</div>
                                <div className={`${dailyduck.my_review_write_textbox}`}>
                                    <textarea
                                        name=""
                                        id=""
                                        cols="30"
                                        rows="10"
                                        placeholder={`${message}`}
                                        onChange={(e) => onCeoSay(e)}
                                        value={ceoSay}
                                    >
                                    </textarea>
                                    <div className='counter'>{`${ceoSay.length}/${maxLength}`}</div>
                                </div>
                            </div>
                            <div className='px-3 py-3 bg-white'>
                                <div className='d-flex justify-content-between pb-1'>
                                    <div><span>사진(선택)</span></div>
                                    <div><span className='gray_round'>선택</span></div>
                                </div>
                                <div className='request_input_img'>
                                    <div className='request_drop'>
                                        <div className="drop_box" {...getRootProps()} >
                                            <input {...getInputProps()} />
                                            <span className="material-symbols-outlined">add</span>
                                        </div>
                                    </div>
                                    <div className='request_slider'>
                                        <Swiper
                                            slidesPerView={3}
                                            spaceBetween={15}
                                            freeMode={true}
                                            className="mySwiper"
                                        >
                                            {images.map((image, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className='request_slider_imgBox'>
                                                        <img src={image.preview} alt={`preview ${index}`} />
                                                        <button onClick={() => removeImage(index)}><span className="material-symbols-outlined">close</span></button>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                                <div className='pt-2 pb-4 border-bottom'><span>*사진은 최대 4개까지 등록 가능합니다.</span></div>
                                <div className={`py-3 d-flex justify-content-center ${dailyduck.my_review_write_success_btn}`}>
                                    {
                                        reservation !== undefined && support !== undefined &&
                                        <button onClick={() => onReviewSave()}>등록하기</button>
                                    }
                                    {
                                        storePayment !== undefined && store !== undefined &&
                                        <button onClick={() => onReviewStoreSave()}>등록하기</button>
                                    }
                                </div>
                            </div>
                        </div>
                </div>
            }
        </>
    )
}

export default ReviewWrite;
