import React, { createContext, useEffect, useState } from 'react';
import api from '../apis/api';
import Cookies from 'js-cookie';
import * as auth from '../apis/auth';
import { useNavigate } from 'react-router-dom';
import * as Swal from '../apis/alert';
import { toast } from 'react-toastify';

export const LoginContext = createContext();
LoginContext.displayName = 'LoginContextName'

/**
 *  로그인 
 *  ✅ 로그인 체크
 *  ✅ 로그인
 *  ✅ 로그아웃
 *  
 *  🔐 로그인 세팅
 *  🔓 로그아웃 세팅
*/
const LoginContextProvider = ({ children }) => {
    /*
        상태
        - 로그인 여부
        - 유저 정보
        - 권한 정보
        - 아이디 저장
    */
   /* -----------------------[State]-------------------------- */
    // 로그인 여부
    const [isLogin, setLogin] = useState(false);

    // 유저 정보
    const [userInfo, setUserInfo] = useState(null)

    // 권한 정보
    const [roles, setRoles] = useState({isUser : false, isStore : false, isSupport : false, isAdmin : false})

    // 아이디 저장
    const [remberUserId, setRemberUserId] = useState()

    // 이메일 회원가입
    const [showAgree, setShowAgree] = useState(false);
    
    // 유저 구분
    const [userClassification, setUserClassification] = useState('');

    // 권한 타입
    const [roleListCheck, setroleListCheck] = useState('');

    // 로그인 상태 체크
    const [isUserInfoLoaded, setIsUserInfoLoaded] = useState(false);

    /* -------------------------------------------------------- */

    /* -----------------------[국가 정보]----------------------- */
    // 하드코딩된 국가 데이터
    const countries = [ 
        {name: 'Republic of Korea', krname:'한국', code: 'KR', number: '+82', img: `${process.env.PUBLIC_URL}/img/icon/country/South_Korea_(KR).png`},
        {name: 'United States', krname:'미국', code: 'US', number: '+1', img: `${process.env.PUBLIC_URL}/img/icon/country/United_States_of_America_(US).png`},
        
        {name: "Japan", krname: "일본", code: "JP", number: "+81", img: `${process.env.PUBLIC_URL}/img/icon/country/Japan(JP).png`},
        {name: "China", krname: "중국", code: "CN", number: "+86", img: `${process.env.PUBLIC_URL}/img/icon/country/China(CN).png`},
        {name: "Afghanistan", krname: "아프가니스탄", code: "AF", number: "+93", img: `${process.env.PUBLIC_URL}/img/icon/country/Afghanistan(AF).png`},
        {name: "Albania", krname: "알바니아", code: "AL", number: "+355", img: `${process.env.PUBLIC_URL}/img/icon/country/Albania(AL).png`},
        {name: "Algeria", krname: "알제리", code: "DZ", number: "+355", img: `${process.env.PUBLIC_URL}/img/icon/country/Algeria(DZ).png`},
        {name: "Andorra", krname: "안도라", code: "AD", number: "+376", img: `${process.env.PUBLIC_URL}/img/icon/country/Andorra(AD).png`},
        {name: "Angola", krname: "앙골라", code: "AO", number: "+244", img: `${process.env.PUBLIC_URL}/img/icon/country/Angola(AO).png`},
        {name: "Anguilla", krname: "앵귈라", code: "AI", number: "+1", img: `${process.env.PUBLIC_URL}/img/icon/country/Anguilla(AI).png`},
        {name: "Antarctica", krname: "남극", code: "AQ", number: "+672", img: `${process.env.PUBLIC_URL}/img/icon/country/Antarctica(AQ).png`},
        {name: "Antigua and Barbuda", krname: "앤티가 바부다", code: "AG", number: "+1268", img: `${process.env.PUBLIC_URL}/img/icon/country/AntiguaandBarbuda(AG).png`},
        
        {name: "Argentina", krname: "아르헨티나", code: "AR", number: "+54", img: `${process.env.PUBLIC_URL}/img/icon/country/Argentina(AR).png`},
        {name: "American Samoa", krname: "미국령 사모아", code: "AS", number: "+1", img: `${process.env.PUBLIC_URL}/img/icon/country/AmericanSamoa(AS).png`},
        {name: "Armenia", krname: "아르메니아", code: "AM", number: "+374", img: `${process.env.PUBLIC_URL}/img/icon/country/Armenia(AM).png`},
        {name: "Aruba", krname: "아루바", code: "AW", number: "+297", img: `${process.env.PUBLIC_URL}/img/icon/country/Aruba(AW).png`},
        {name: "Australia", krname: "오스트레일리아", code: "AU", number: "+61", img: `${process.env.PUBLIC_URL}/img/icon/country/Australia(AU).png`},
        {name: "Austria", krname: "오스트리아", code: "AT", number: "+43", img: `${process.env.PUBLIC_URL}/img/icon/country/Austria(AT).png`},
        {name: "Azerbaijan", krname: "아제르바이잔", code: "AZ", number: "+994", img: `${process.env.PUBLIC_URL}/img/icon/country/Azerbaijan(AZ).png`},
        {name: "Bahamas", krname: "바하마", code: "BS", number: "+1242", img: `${process.env.PUBLIC_URL}/img/icon/country/Bahamas(BS).png`},
        {name: "Bahrain", krname: "바레인", code: "BH", number: "+973", img: `${process.env.PUBLIC_URL}/img/icon/country/Bahrain(BH).png`},
        {name: "Bangladesh", krname: "방글라데시", code: "BD", number: "+880", img: `${process.env.PUBLIC_URL}/img/icon/country/Bangladesh(BD).png`},
        {name: "Barbados", krname: "바베이도스", code: "BB", number: "+1246", img: `${process.env.PUBLIC_URL}/img/icon/country/Barbados(BB).png`},
        {name: "Belarus", krname: "벨라루스", code: "BY", number: "+375", img: `${process.env.PUBLIC_URL}/img/icon/country/Belarus(BY).png`},
        {name: "Belgium", krname: "벨기에", code: "BE", number: "+32", img: `${process.env.PUBLIC_URL}/img/icon/country/Belgium(BE).png`},
        {name: "Belize", krname: "벨리즈", code: "BZ", number: "+501", img: `${process.env.PUBLIC_URL}/img/icon/country/Belize(BZ).png`},
        {name: "Benin", krname: "베냉", code: "BJ", number: "+229", img: `${process.env.PUBLIC_URL}/img/icon/country/Benin(BJ).png`},
        {name: "Bermuda", krname: "버뮤다", code: "BM", number: "+1441", img: `${process.env.PUBLIC_URL}/img/icon/country/Bermuda(BM).png`},
        {name: "Bhutan", krname: "부탄", code: "BT", number: "+975", img: `${process.env.PUBLIC_URL}/img/icon/country/Bhutan(BT).png`},
        {name: "Bolivia", krname: "볼리비아", code: "BO", number: "+591", img: `${process.env.PUBLIC_URL}/img/icon/country/Bolivia(PlurinationalStateof)(BO).png`},
        {name: "Bosnia and Herzegovina", krname: "보스니아 헤르체고비나", code: "BA", number: "+387", img: `${process.env.PUBLIC_URL}/img/icon/country/BosniaandHerzegovina(BA).png`},
        {name: "Botswana", krname: "보츠와나", code: "BW", number: "+267", img: `${process.env.PUBLIC_URL}/img/icon/country/Botswana(BW).png`},
        {name: "Brazil", krname: "브라질", code: "BR", number: "+55", img: `${process.env.PUBLIC_URL}/img/icon/country/Brazil(BR).png`},
 
        {name: "British Indian Ocean Territory", krname: "영국령 인도양 지역", code: "IO", number: "+246", img: `${process.env.PUBLIC_URL}/img/icon/country/BritishIndianOceanTerritory(IO).png`},
        {name: "British Virgin Islands", krname: "영국령 버진아일랜드", code: "VG", number: "+1284", img: `${process.env.PUBLIC_URL}/img/icon/country/VirginIslands(British)(VG).png`},
        {name: "Brunei", krname: "브루나이", code: "BN", number: "+673", img: `${process.env.PUBLIC_URL}/img/icon/country/BruneiDarussalam(BN).png`},
        {name: "Bulgaria", krname: "불가리아", code: "BG", number: "+359", img: `${process.env.PUBLIC_URL}/img/icon/country/Bulgaria(BG).png`},
        {name: "Burkina Faso", krname: "부르키나파소", code: "BF", number: "+226", img: `${process.env.PUBLIC_URL}/img/icon/country/BurkinaFaso(BF).png`},
        {name: "Burundi", krname: "부룬디", code: "BI", number: "+257", img: `${process.env.PUBLIC_URL}/img/icon/country/Burundi(BI).png`},
        {name: "Cambodia", krname: "캄보디아", code: "KH", number: "+855", img: `${process.env.PUBLIC_URL}/img/icon/country/Cambodia(KH).png`},
        {name: "Cameroon", krname: "카메룬", code: "CM", number: "+237", img: `${process.env.PUBLIC_URL}/img/icon/country/Cameroon(CM).png`},
        {name: "Canada", krname: "캐나다", code: "CA", number: "+1", img: `${process.env.PUBLIC_URL}/img/icon/country/Canada(CA).png`},
        {name: "Cape Verde", krname: "카보베르데", code: "CV", number: "+238", img: `${process.env.PUBLIC_URL}/img/icon/country/CapeVerde(CV).png`},
        {name: "Cayman Islands", krname: "케이맨 제도", code: "KY", number: "+1345", img: `${process.env.PUBLIC_URL}/img/icon/country/CaymanIslands(KY).png`},
        {name: "Central African Republic", krname: "중앙아프리카 공화국", code: "CF", number: "+236", img: `${process.env.PUBLIC_URL}/img/icon/country/CentralAfricanRepublic(CF).png`},
        {name: "Chad", krname: "차드", code: "TD", number: "+235", img: `${process.env.PUBLIC_URL}/img/icon/country/Chad(TD).png`},
        {name: "Chile", krname: "칠레", code: "CL", number: "+56", img: `${process.env.PUBLIC_URL}/img/icon/country/Chile(CL).png`},
        {name: "Christmas Island", krname: "크리스마스 섬", code: "CX", number: "+61", img: `${process.env.PUBLIC_URL}/img/icon/country/ChristmasIsland(CX).png`},
        {name: "Cocos Islands", krname: "코코스 제도", code: "CC", number: "+61", img: `${process.env.PUBLIC_URL}/img/icon/country/Cocos(Keeling)Islands(CC).png`},
        {name: "Colombia", krname: "콜롬비아", code: "CO", number: "+57", img: `${process.env.PUBLIC_URL}/img/icon/country/Colombia(CO).png`},
        {name: "Comoros", krname: "코모로", code: "KM", number: "+269", img: `${process.env.PUBLIC_URL}/img/icon/country/Comoros(KM).png`},
        {name: "Cook Islands", krname: "쿡 제도", code: "CK", number: "+682", img: `${process.env.PUBLIC_URL}/img/icon/country/CookIslands(CK).png`},
        {name: "Costa Rica", krname: "코스타리카", code: "CR", number: "+506", img: `${process.env.PUBLIC_URL}/img/icon/country/CostaRica(CR).png`},
        {name: "Croatia", krname: "크로아티아", code: "HR", number: "+385", img: `${process.env.PUBLIC_URL}/img/icon/country/Croatia(HR).png`},
        {name: "Cuba", krname: "쿠바", code: "CU", number: "+53", img: `${process.env.PUBLIC_URL}/img/icon/country/Cuba(CU).png`},
        {name: "Curacao", krname: "쿠라사오", code: "CW", number: "+599", img: `${process.env.PUBLIC_URL}/img/icon/country/Curacao(CW).png`},
        {name: "Cyprus", krname: "키프로스", code: "CY", number: "+357", img: `${process.env.PUBLIC_URL}/img/icon/country/Cyprus(CY).png`},
        {name: "Czech Republic", krname: "체코", code: "CZ", number: "+420", img: `${process.env.PUBLIC_URL}/img/icon/country/CzechRepublic(CZ).png`},
        {name: "Democratic Republic of the Congo", krname: "콩고 민주 공화국", code: "CD", number: "+243", img: `${process.env.PUBLIC_URL}/img/icon/country/DemocraticRepublicoftheCongo(CD).png`},
        {name: "Denmark", krname: "덴마크", code: "DK", number: "+45", img: `${process.env.PUBLIC_URL}/img/icon/country/Denmark(DK).png`},
        {name: "Dijibouti", krname: "지부티", code: "DJ", number: "+253", img: `${process.env.PUBLIC_URL}/img/icon/country/Dijibouti(DJ).png`},
        {name: "Dominica", krname: "도미니카 연방", code: "DM", number: "+1767", img: `${process.env.PUBLIC_URL}/img/icon/country/Dominica(DM).png`},
        {name: "Dominican Republic", krname: "도미니카 공화국", code: "DO", number: "+1809", img: `${process.env.PUBLIC_URL}/img/icon/country/DominicanRepublic(DO).png`},
        {name: "East Timor", krname: "동티모르", code: "TL", number: "+670", img: `${process.env.PUBLIC_URL}/img/icon/country/Timor-Leste(TL).png`},
        {name: "Ecuador", krname: "에콰도르", code: "EC", number: "+593", img: `${process.env.PUBLIC_URL}/img/icon/country/Ecuador(EC).png`},
        {name: "Egypt", krname: "이집트", code: "EG", number: "+20", img: `${process.env.PUBLIC_URL}/img/icon/country/Egypt(EG).png`},

        {name: "El Salvador", krname: "엘살바도르", code: "SV", number: "+503", img: `${process.env.PUBLIC_URL}/img/icon/country/ElSalvador(SV).png`},
        {name: "Equatorial Guinea", krname: "적도 기니", code: "GQ", number: "+240", img: `${process.env.PUBLIC_URL}/img/icon/country/EquatorialGuinea(GQ).png`},
        {name: "Eritrea", krname: "에리트레아", code: "ER", number: "+240", img: `${process.env.PUBLIC_URL}/img/icon/country/Eritrea(ER).png`},
        {name: "Estonia", krname: "에스토니아", code: "EE", number: "+372", img: `${process.env.PUBLIC_URL}/img/icon/country/Estonia(EE).png`},
        {name: "Ethiopia", krname: "에티오피아", code: "ET", number: "+251", img: `${process.env.PUBLIC_URL}/img/icon/country/Ethiopia(ET).png`},
        {name: "Falkland Islands", krname: "포클랜드 제도", code: "FK", number: "+500", img: `${process.env.PUBLIC_URL}/img/icon/country/FalklandIslands(FK).png`},
        {name: "Faroe Islands", krname: "페로 제도", code: "FO", number: "+298", img: `${process.env.PUBLIC_URL}/img/icon/country/FaroeIslands(FO).png`},
        {name: "Fiji", krname: "피지", code: "FJ", number: "+679", img: `${process.env.PUBLIC_URL}/img/icon/country/Fiji(FJ).png`},
        {name: "Finland", krname: "핀란드", code: "FI", number: "+358", img: `${process.env.PUBLIC_URL}/img/icon/country/Finland(FI).png`},
        {name: "France", krname: "프랑스", code: "FR", number: "+33", img: `${process.env.PUBLIC_URL}/img/icon/country/France(FR).png`},
        {name: "French Polynesia", krname: "프랑스령 폴리네시아", code: "PF", number: "+689", img: `${process.env.PUBLIC_URL}/img/icon/country/FrenchPolynesia(PF).png`},
        {name: "Gabon", krname: "가봉", code: "GA", number: "+241", img: `${process.env.PUBLIC_URL}/img/icon/country/Gabon(GA).png`},
        {name: "Gambia", krname: "감비아", code: "GM", number: "+220", img: `${process.env.PUBLIC_URL}/img/icon/country/Gambia(GM).png`},
        {name: "Georgia", krname: "조지아", code: "GE", number: "+995", img: `${process.env.PUBLIC_URL}/img/icon/country/Georgia(GE).png`},
        {name: "Germany", krname: "독일", code: "DE", number: "+49", img: `${process.env.PUBLIC_URL}/img/icon/country/Germany(DE).png`},
        {name: "Ghana", krname: "가나", code: "GH", number: "+233", img: `${process.env.PUBLIC_URL}/img/icon/country/Ghana(GH).png`},
        {name: "Gibraltar", krname: "지브롤터", code: "GI", number: "+350", img: `${process.env.PUBLIC_URL}/img/icon/country/Gibraltar(GI).png`},
        {name: "Greece", krname: "그리스", code: "GR", number: "+30", img: `${process.env.PUBLIC_URL}/img/icon/country/Greece(GR).png`},
        {name: "Greenland", krname: "그린란드", code: "GL", number: "+299", img: `${process.env.PUBLIC_URL}/img/icon/country/Greenland(GL).png`},
        {name: "Grenada", krname: "그레나다", code: "GD", number: "+1473", img: `${process.env.PUBLIC_URL}/img/icon/country/Grenada(GD).png`},
        {name: "Guam", krname: "괌", code: "GU", number: "+1671", img: `${process.env.PUBLIC_URL}/img/icon/country/Guam(GU).png`},
        {name: "Guatemala", krname: "과테말라", code: "GT", number: "+502", img: `${process.env.PUBLIC_URL}/img/icon/country/Guatemala(GT).png`},
        {name: "Guernsey", krname: "건지", code: "GG", number: "+441481", img: `${process.env.PUBLIC_URL}/img/icon/country/Guernsey(GG).png`},
        {name: "Guinea", krname: "기니", code: "GN", number: "+224", img: `${process.env.PUBLIC_URL}/img/icon/country/Guinea(GN).png`},
        {name: "Guinea-Bissau", krname: "기니비사우", code: "GW", number: "+254", img: `${process.env.PUBLIC_URL}/img/icon/country/Guinea-Bissau(GW).png`},
        {name: "Guyana", krname: "가이아나", code: "GY", number: "+592", img: `${process.env.PUBLIC_URL}/img/icon/country/Guyana(GY).png`},
        {name: "Haiti", krname: "아이티", code: "HT", number: "+509", img: `${process.env.PUBLIC_URL}/img/icon/country/Haiti(HT).png`},
        {name: "Honduras", krname: "온두라스", code: "HN", number: "+5504", img: `${process.env.PUBLIC_URL}/img/icon/country/Honduras(HN).png`},
        {name: "Hong Kong", krname: "홍콩", code: "HK", number: "+852", img: `${process.env.PUBLIC_URL}/img/icon/country/HongKong(HK).png`},
        {name: "Hungary", krname: "헝가리", code: "HU", number: "+36", img: `${process.env.PUBLIC_URL}/img/icon/country/Hungary(HU).png`},
        {name: "Iceland", krname: "아이슬란드", code: "IS", number: "+354", img: `${process.env.PUBLIC_URL}/img/icon/country/Iceland(IS).png`},
        {name: "India", krname: "인도", code: "IN", number: "+91", img: `${process.env.PUBLIC_URL}/img/icon/country/India(IN).png`},
        {name: "Indonesia", krname: "인도네시아", code: "ID", number: "+62", img: `${process.env.PUBLIC_URL}/img/icon/country/Indonesia(ID).png`},
        {name: "Iran", krname: "이란", code: "IR", number: "+98", img: `${process.env.PUBLIC_URL}/img/icon/country/Iran(IslamicRepublicof)(IR).png`},
        {name: "Iraq", krname: "이라크", code: "IQ", number: "+964", img: `${process.env.PUBLIC_URL}/img/icon/country/Iraq(IQ).png`},
        {name: "Ireland", krname: "아일랜드", code: "IE", number: "+353", img: `${process.env.PUBLIC_URL}/img/icon/country/Ireland(IE).png`},
        {name: "Isle of Man", krname: "맨 섬", code: "IM", number: "+441624", img: `${process.env.PUBLIC_URL}/img/icon/country/IsleofMan(IM).png`},

        {name: "Israel", krname: "이스라엘", code: "IL", number: "+972", img: `${process.env.PUBLIC_URL}/img/icon/country/Israel(IL).png`},
        {name: "Italy", krname: "이탈리아", code: "IT", number: "+39", img: `${process.env.PUBLIC_URL}/img/icon/country/Italy(IT).png`},
        {name: "Côte d'Ivoire", krname: "코트디부아르", code: "CI", number: "+225", img: `${process.env.PUBLIC_URL}/img/icon/country/CotedIvoire(CI).png`},
        {name: "Jamaica", krname: "자메이카", code: "JM", number: "+1876", img: `${process.env.PUBLIC_URL}/img/icon/country/Jamaica(JM).png`},
        {name: "Jersey", krname: "저지", code: "JE", number: "+441534", img: `${process.env.PUBLIC_URL}/img/icon/country/Jersey(JE).png`},
        {name: "Jordan", krname: "요르단", code: "JO", number: "+962", img: `${process.env.PUBLIC_URL}/img/icon/country/Jordan(JO).png`},
        {name: "Kazakhstan", krname: "카자흐스탄", code: "KZ", number: "+7", img: `${process.env.PUBLIC_URL}/img/icon/country/Kazakhstan(KZ).png`},
        {name: "Kenya", krname: "케냐", code: "KE", number: "+254", img: `${process.env.PUBLIC_URL}/img/icon/country/Kenya(KE).png`},
        {name: "Kiribati", krname: "키리바시", code: "KI", number: "+686", img: `${process.env.PUBLIC_URL}/img/icon/country/Kiribati(KI).png`},
        {name: "Kosovo", krname: "코소보", code: "XK", number: "+383", img: `${process.env.PUBLIC_URL}/img/icon/country/Kosovo(XK).png`},
        {name: "Kuwait", krname: "쿠웨이트", code: "KW", number: "+965", img: `${process.env.PUBLIC_URL}/img/icon/country/Kuwait(KW).png`},
        {name: "Kyrgyzstan", krname: "키르기스스탄", code: "KG", number: "+996", img: `${process.env.PUBLIC_URL}/img/icon/country/Kyrgyzstan(KG).png`},
        {name: "Laos", krname: "라오스", code: "LA", number: "+856", img: `${process.env.PUBLIC_URL}/img/icon/country/Laos(LA).png`},
        {name: "Latvia", krname: "라트비아", code: "LV", number: "+371", img: `${process.env.PUBLIC_URL}/img/icon/country/Latvia(LV).png`},
        {name: "Lebanon", krname: "레바논", code: "LB", number: "+961", img: `${process.env.PUBLIC_URL}/img/icon/country/Lebanon(LB).png`},
        {name: "Lesotho", krname: "레소토", code: "LS", number: "+266", img: `${process.env.PUBLIC_URL}/img/icon/country/Lesotho(LS).png`},
        {name: "Liberia", krname: "라이베리아", code: "LR", number: "+218", img: `${process.env.PUBLIC_URL}/img/icon/country/Liberia(LR).png`},
        {name: "Libya", krname: "리비아", code: "LY", number: "+218", img: `${process.env.PUBLIC_URL}/img/icon/country/Libya(LY).png`},
        {name: "Liechtenstein", krname: "리히텐슈타인", code: "LI", number: "+423", img: `${process.env.PUBLIC_URL}/img/icon/country/Liechtenstein(LI).png`},
        {name: "Lithuania", krname: "리투아니아", code: "LT", number: "+370", img: `${process.env.PUBLIC_URL}/img/icon/country/Lithuania(LT).png`},
        {name: "Luxembourg", krname: "룩셈부르크", code: "LU", number: "+352", img: `${process.env.PUBLIC_URL}/img/icon/country/Luxembourg(LU).png`},
        {name: "Macau", krname: "마카오", code: "MO", number: "+853", img: `${process.env.PUBLIC_URL}/img/icon/country/Macau(MO).png`},
        {name: "Macedonia", krname: "마케도니아", code: "MK", number: "+389", img: `${process.env.PUBLIC_URL}/img/icon/country/NorthMacedonia(MK).png`},
        {name: "Madagascar", krname: "마다가스카르", code: "MG", number: "+261", img: `${process.env.PUBLIC_URL}/img/icon/country/Madagascar(MG).png`},
        {name: "Malawi", krname: "말라위", code: "MW", number: "+265", img: `${process.env.PUBLIC_URL}/img/icon/country/Malawi(MW).png`},
        {name: "Malaysia", krname: "말레이시아", code: "MY", number: "+60", img: `${process.env.PUBLIC_URL}/img/icon/country/Malaysia(MY).png`},
        {name: "Maldives", krname: "몰디브", code: "MV", number: "+960", img: `${process.env.PUBLIC_URL}/img/icon/country/Maldives(MV).png`},
        {name: "Mali", krname: "말리", code: "ML", number: "+223", img: `${process.env.PUBLIC_URL}/img/icon/country/Mali(ML).png`},
        {name: "Malta", krname: "몰타", code: "MT", number: "+356", img: `${process.env.PUBLIC_URL}/img/icon/country/Malta(MT).png`},
        {name: "Marshall Islands", krname: "마셜 제도", code: "MH", number: "+692", img: `${process.env.PUBLIC_URL}/img/icon/country/MarshallIslands(MH).png`},
        {name: "Mauritania", krname: "모리타니", code: "MR", number: "+222", img: `${process.env.PUBLIC_URL}/img/icon/country/Mauritania(MR).png`},
        {name: "Mauritius", krname: "모리셔스", code: "MU", number: "+230", img: `${process.env.PUBLIC_URL}/img/icon/country/Mauritius(MU).png`},
        {name: "Mayotte", krname: "마요트", code: "YT", number: "+262", img: `${process.env.PUBLIC_URL}/img/icon/country/Mayotte(YT).png`},
        {name: "Mexico", krname: "멕시코", code: "MX", number: "+52", img: `${process.env.PUBLIC_URL}/img/icon/country/Mexico(MX).png`},
        {name: "Micronesia", krname: "미크로네시아", code: "FM", number: "+691", img: `${process.env.PUBLIC_URL}/img/icon/country/FederatedStatesofMicronesia(FM).png`},
        {name: "Moldova", krname: "몰도바", code: "MD", number: "+373", img: `${process.env.PUBLIC_URL}/img/icon/country/Moldova(MD).png`},
        {name: "Monaco", krname: "모나코", code: "MC", number: "+377", img: `${process.env.PUBLIC_URL}/img/icon/country/Monaco(MC).png`},
        {name: "Mongolia", krname: "몽골", code: "MN", number: "+976", img: `${process.env.PUBLIC_URL}/img/icon/country/Mongolia(MN).png`},
        {name: "Montenegro", krname: "몬테네그로", code: "ME", number: "+382", img: `${process.env.PUBLIC_URL}/img/icon/country/Montenegro(ME).png`},
        {name: "Montserrat", krname: "몬트세랫", code: "MS", number: "+1664", img: `${process.env.PUBLIC_URL}/img/icon/country/Montserrat(MS).png`},
        {name: "Morocco", krname: "모로코", code: "MA", number: "+212", img: `${process.env.PUBLIC_URL}/img/icon/country/Morocco(MA).png`},
        {name: "Mozambique", krname: "모잠비크", code: "MZ", number: "+258", img: `${process.env.PUBLIC_URL}/img/icon/country/Mozambique(MZ).png`},
        {name: "Myanmar", krname: "미얀마", code: "MM", number: "+95", img: `${process.env.PUBLIC_URL}/img/icon/country/Myanmar(MM).png`},
        {name: "Namibia", krname: "나미비아", code: "NA", number: "+264", img: `${process.env.PUBLIC_URL}/img/icon/country/Namibia(NA).png`},
        {name: "Nauru", krname: "나우루", code: "NR", number: "+674", img: `${process.env.PUBLIC_URL}/img/icon/country/Nauru(NR).png`},
        {name: "Nepal", krname: "네팔", code: "NP", number: "+977", img: `${process.env.PUBLIC_URL}/img/icon/country/Nepal(NP).png`},
        {name: "Netherlands", krname: "네덜란드", code: "NL", number: "+31", img: `${process.env.PUBLIC_URL}/img/icon/country/Netherlands(NL).png`},
        {name: "Netherlands Antilles", krname: "네덜란드령 안틸레스", code: "AN", number: "+599", img: `${process.env.PUBLIC_URL}/img/icon/country/NetherlandsAntilles(AN).png`},
        {name: "New Caledonia", krname: "누벨칼레도니", code: "NC", number: "+687", img: `${process.env.PUBLIC_URL}/img/icon/country/NewCaledonia(NC).png`},
        {name: "New Zealand", krname: "뉴질랜드", code: "NZ", number: "+64", img: `${process.env.PUBLIC_URL}/img/icon/country/NewZealand(NZ).png`},
        {name: "Nicaragua", krname: "니카라과", code: "NI", number: "+505", img: `${process.env.PUBLIC_URL}/img/icon/country/Nicaragua(NI).png`},
        {name: "Niger", krname: "니제르", code: "NE", number: "+227", img: `${process.env.PUBLIC_URL}/img/icon/country/Niger(NE).png`},
        {name: "Nigeria", krname: "나이지리아", code: "NG", number: "+234", img: `${process.env.PUBLIC_URL}/img/icon/country/Nigeria(NG).png`},
        {name: "Niue", krname: "니우에", code: "NU", number: "+683", img: `${process.env.PUBLIC_URL}/img/icon/country/Niue(NU).png`},
        {name: "North Korea", krname: "북한", code: "KP", number: "+850", img: `${process.env.PUBLIC_URL}/img/icon/country/NorthKorea(KP).png`},
        {name: "Northern Mariana Islands", krname: "북마리아나 제도", code: "MP", number: "+1670", img: `${process.env.PUBLIC_URL}/img/icon/country/NorthernMarianaIslands(MP).png`},
        {name: "Norway", krname: "노르웨이", code: "NO", number: "+47", img: `${process.env.PUBLIC_URL}/img/icon/country/Norway(NO).png`},
        {name: "Oman", krname: "오만", code: "OM", number: "+968", img: `${process.env.PUBLIC_URL}/img/icon/country/Oman(OM).png`},
        {name: "Pakistan", krname: "파키스탄", code: "PK", number: "+92", img: `${process.env.PUBLIC_URL}/img/icon/country/Pakistan(PK).png`},
        {name: "Palau", krname: "팔라우", code: "PW", number: "+680", img: `${process.env.PUBLIC_URL}/img/icon/country/Palau(PW).png`},
        {name: "Palestine", krname: "팔레스타인", code: "PS", number: "+970", img: `${process.env.PUBLIC_URL}/img/icon/country/StateofPalestine(PS).png`},
        {name: "Panama", krname: "파나마", code: "PA", number: "+507", img: `${process.env.PUBLIC_URL}/img/icon/country/Panama(PA).png`},
        {name: "Papua New Guinea", krname: "파푸아뉴기니", code: "PG", number: "+675", img: `${process.env.PUBLIC_URL}/img/icon/country/PapuaNewGuinea(PG).png`},
        {name: "Paraguay", krname: "파라과이", code: "PY", number: "+595", img: `${process.env.PUBLIC_URL}/img/icon/country/Paraguay(PY).png`},
        {name: "Peru", krname: "페루", code: "PE", number: "+51", img: `${process.env.PUBLIC_URL}/img/icon/country/Peru(PE).png`},
        {name: "Philippines", krname: "필리핀", code: "PH", number: "+63", img: `${process.env.PUBLIC_URL}/img/icon/country/Philippines(PH).png`},
        {name: "Pitcairn", krname: "핏케언 제도", code: "PN", number: "+64", img: `${process.env.PUBLIC_URL}/img/icon/country/Pitcairn(PN).png`},
        {name: "Poland", krname: "폴란드", code: "PL", number: "+48", img: `${process.env.PUBLIC_URL}/img/icon/country/Poland(PL).png`},
        {name: "Portugal", krname: "포르투갈", code: "PT", number: "+351", img: `${process.env.PUBLIC_URL}/img/icon/country/Portugal(PT).png`},
        {name: "Puerto Rico", krname: "푸에르토리코", code: "PR", number: "+1787", img: `${process.env.PUBLIC_URL}/img/icon/country/PuertoRico(PR).png`},
        {name: "Qatar", krname: "카타르", code: "QA", number: "+974", img: `${process.env.PUBLIC_URL}/img/icon/country/Qatar(QA).png`},
        {name: "Republic of the Congo", krname: "콩고 공화국", code: "CG", number: "+242", img: `${process.env.PUBLIC_URL}/img/icon/country/RepublicoftheCongo(CG).png`},
        {name: "Reunion Islands", krname: "레위니옹", code: "RE", number: "+262", img: `${process.env.PUBLIC_URL}/img/icon/country/ReunionIslands(RE).png`},
        {name: "Romania", krname: "루마니아", code: "RO", number: "+40", img: `${process.env.PUBLIC_URL}/img/icon/country/Romania(RO).png`},
        {name: "Russia", krname: "러시아", code: "RU", number: "+7", img: `${process.env.PUBLIC_URL}/img/icon/country/Russia(RU).png`},
        {name: "Rwanda", krname: "르완다", code: "RW", number: "+250", img: `${process.env.PUBLIC_URL}/img/icon/country/Rwanda(RW).png`},
        {name: "Saint Barthelemy", krname: "생바르텔레미", code: "BL", number: "+590", img: `${process.env.PUBLIC_URL}/img/icon/country/SaintBarthelemy(BL).png`},
        {name: "Saint Helena", krname: "세인트헬레나", code: "SH", number: "+290", img: `${process.env.PUBLIC_URL}/img/icon/country/SaintHelena,AscensionandTristandaCunha(SH).png`},
        {name: "Saint Kitts and Nevis", krname: "세인트키츠 네비스", code: "KN", number: "+1869", img: `${process.env.PUBLIC_URL}/img/icon/country/SaintKittsandNevis(KN).png`},
        {name: "Saint Lucia", krname: "세인트루시아", code: "LC", number: "+1758", img: `${process.env.PUBLIC_URL}/img/icon/country/SaintLucia(LC).png`},
        {name: "Saint Martin", krname: "생마르탱", code: "MF", number: "+590", img: `${process.env.PUBLIC_URL}/img/icon/country/SaintMartin(MF).png`},
        {name: "Saint Pierre and Miquelon", krname: "생피에르 미클롱", code: "PM", number: "+508", img: `${process.env.PUBLIC_URL}/img/icon/country/SaintPierreandMiquelon(PM).png`},
        {name: "Saint Vincent and the Grenadines", krname: "세인트빈센트 그레나딘", code: "VC", number: "+1784", img: `${process.env.PUBLIC_URL}/img/icon/country/SaintVincentandtheGrenadines(VC).png`},
        {name: "Samoa", krname: "사모아", code: "WS", number: "+685", img: `${process.env.PUBLIC_URL}/img/icon/country/Samoa(WS).png`},
        {name: "San Marino", krname: "산마리노", code: "SM", number: "+378", img: `${process.env.PUBLIC_URL}/img/icon/country/SanMarino(SM).png`},
        {name: "Sao Tome and Principe", krname: "상투메 프린시페", code: "ST", number: "+239", img: `${process.env.PUBLIC_URL}/img/icon/country/SaoTomeandPrincipe(ST).png`},
        {name: "Saudi Arabia", krname: "사우디아라비아", code: "SA", number: "+966", img: `${process.env.PUBLIC_URL}/img/icon/country/SaudiArabia(SA).png`},
        {name: "Senegal", krname: "세네갈", code: "SN", number: "+221", img: `${process.env.PUBLIC_URL}/img/icon/country/Senegal(SN).png`},
        {name: "Serbia", krname: "세르비아", code: "RS", number: "+381", img: `${process.env.PUBLIC_URL}/img/icon/country/Serbia(RS).png`},
        {name: "Seychelles", krname: "세이셸", code: "SC", number: "+248", img: `${process.env.PUBLIC_URL}/img/icon/country/Seychelles(SC).png`},
        {name: "Sierra Leone", krname: "시에라리온", code: "SL", number: "+232", img: `${process.env.PUBLIC_URL}/img/icon/country/SierraLeone(SL).png`},
        {name: "Singapore", krname: "싱가포르", code: "SG", number: "+65", img: `${process.env.PUBLIC_URL}/img/icon/country/Singapore(SG).png`},
        {name: "Sint Maarten", krname: "신트마르턴", code: "SX", number: "+1721", img: `${process.env.PUBLIC_URL}/img/icon/country/SintMaarten(SX).png`},
        {name: "Slovakia", krname: "슬로바키아", code: "SK", number: "+421", img: `${process.env.PUBLIC_URL}/img/icon/country/Slovakia(SK).png`},
        {name: "Slovenia", krname: "슬로베니아", code: "SI", number: "+386", img: `${process.env.PUBLIC_URL}/img/icon/country/Slovenia(SI).png`},
        {name: "Solomon Islands", krname: "솔로몬 제도", code: "SB", number: "+677", img: `${process.env.PUBLIC_URL}/img/icon/country/SolomonIslands(SB).png`},
        {name: "Somalia", krname: "소말리아", code: "SO", number: "+252", img: `${process.env.PUBLIC_URL}/img/icon/country/Somalia(SO).png`},
        {name: "South Africa", krname: "남아프리카", code: "ZA", number: "+27", img: `${process.env.PUBLIC_URL}/img/icon/country/SouthAfrica(ZA).png`},
        {name: "South Sudan", krname: "남수단", code: "SS", number: "+211", img: `${process.env.PUBLIC_URL}/img/icon/country/SouthSudan(SS).png`},
        {name: "Spain", krname: "스페인", code: "ES", number: "+34", img: `${process.env.PUBLIC_URL}/img/icon/country/Spain(ES).png`},
        {name: "Sri Lanka", krname: "스리랑카", code: "LK", number: "+94", img: `${process.env.PUBLIC_URL}/img/icon/country/SriLanka(LK).png`},
        {name: "Sudan", krname: "수단", code: "SD", number: "+249", img: `${process.env.PUBLIC_URL}/img/icon/country/Sudan(SD).png`},
        {name: "Suriname", krname: "수리남", code: "SR", number: "+597", img: `${process.env.PUBLIC_URL}/img/icon/country/Suriname(SR).png`},
        {name: "Svalbard and Jan Mayen", krname: "스발바르 얀마옌", code: "SJ", number: "+47", img: `${process.env.PUBLIC_URL}/img/icon/country/SvalbardandJanMayen(SJ).png`},
        {name: "Swaziland", krname: "스와질란드", code: "SZ", number: "+268", img: `${process.env.PUBLIC_URL}/img/icon/country/Swaziland(SZ).png`},
        {name: "Sweden", krname: "스웨덴", code: "SE", number: "+46", img: `${process.env.PUBLIC_URL}/img/icon/country/Sweden(SE).png`},
        {name: "Switzerland", krname: "스위스", code: "CH", number: "+41", img: `${process.env.PUBLIC_URL}/img/icon/country/Switzerland(CH).png`},
        {name: "Syria", krname: "시리아", code: "SY", number: "+963", img: `${process.env.PUBLIC_URL}/img/icon/country/SyrianArabRepublic(SY).png`},
        {name: "Taiwan", krname: "대만", code: "TW", number: "+886", img: `${process.env.PUBLIC_URL}/img/icon/country/Taiwan(TW).png`},
        {name: "Tajikistan", krname: "타지키스탄", code: "TJ", number: "+992", img: `${process.env.PUBLIC_URL}/img/icon/country/Tajikistan(TJ).png`},
        {name: "Tanzania", krname: "탄자니아", code: "TZ", number: "+255", img: `${process.env.PUBLIC_URL}/img/icon/country/Tanzania(TZ).png`},
        {name: "Thailand", krname: "태국", code: "TH", number: "+66", img: `${process.env.PUBLIC_URL}/img/icon/country/Thailand(TH).png`},
        {name: "Togo", krname: "토고", code: "TG", number: "+228", img: `${process.env.PUBLIC_URL}/img/icon/country/Togo(TG).png`},
        {name: "Tokelau", krname: "토켈라우", code: "TK", number: "+690", img: `${process.env.PUBLIC_URL}/img/icon/country/Tokelau(TK).png`},
        {name: "Tonga", krname: "통가", code: "TO", number: "+676", img: `${process.env.PUBLIC_URL}/img/icon/country/Tonga(TO).png`},
        {name: "Trinidad and Tobago", krname: "트리니다드 토바고", code: "TT", number: "+1868", img: `${process.env.PUBLIC_URL}/img/icon/country/TrinidadandTobago(TT).png`},
        {name: "Tunisia", krname: "튀니지", code: "TN", number: "+216", img: `${process.env.PUBLIC_URL}/img/icon/country/Tunisia(TN).png`},
        {name: "Turkey", krname: "터키", code: "TR", number: "+90", img: `${process.env.PUBLIC_URL}/img/icon/country/Turkey(TR).png`},
        {name: "Turkmenistan", krname: "투르크메니스탄", code: "TM", number: "+993", img: `${process.env.PUBLIC_URL}/img/icon/country/Turkmenistan(TM).png`},
        {name: "Turks and Caicos Islands", krname: "터크스 케이커스 제도", code: "TC", number: "+1649", img: `${process.env.PUBLIC_URL}/img/icon/country/TurksandCaicosIslands(TC).png`},
        {name: "Tuvalu", krname: "투발루", code: "TV", number: "+688", img: `${process.env.PUBLIC_URL}/img/icon/country/Tuvalu(TV).png`},
        {name: "U.S. Virgin Islands", krname: "미국령 버진아일랜드", code: "VI", number: "+1340", img: `${process.env.PUBLIC_URL}/img/icon/country/VirginIslands(U.S.)(VI).png`},
        {name: "Uganda", krname: "우간다", code: "UG", number: "+256", img: `${process.env.PUBLIC_URL}/img/icon/country/Uganda(UG).png`},
        {name: "Ukraine", krname: "우크라이나", code: "UA", number: "+380", img: `${process.env.PUBLIC_URL}/img/icon/country/Ukraine(UA).png`},
        {name: "United Arab Emirates", krname: "아랍에미리트", code: "AE", number: "+971", img: `${process.env.PUBLIC_URL}/img/icon/country/UnitedArabEmirates(AE).png`},
        {name: "United Kingdom", krname: "영국", code: "GB", number: "+44", img: `${process.env.PUBLIC_URL}/img/icon/country/UnitedKingdom(GB).png`},
        {name: "Uruguay", krname: "우루과이", code: "UY", number: "+598", img: `${process.env.PUBLIC_URL}/img/icon/country/Uruguay(UY).png`},
        {name: "Uzbekistan", krname: "우즈베키스탄", code: "UZ", number: "+998", img: `${process.env.PUBLIC_URL}/img/icon/country/Uzbekistan(UZ).png`},
        {name: "Vanuatu", krname: "바누아투", code: "VU", number: "+678", img: `${process.env.PUBLIC_URL}/img/icon/country/Vanuatu(VU).png`},
        {name: "Vatican", krname: "바티칸", code: "VA", number: "+379", img: `${process.env.PUBLIC_URL}/img/icon/country/Vatican(VA).png`},
        {name: "Venezuela", krname: "베네수엘라", code: "VE", number: "+58", img: `${process.env.PUBLIC_URL}/img/icon/country/Venezuela(BolivarianRepublicof)(VE).png`},
        {name: "Vietnam", krname: "베트남", code: "VN", number: "+84", img: `${process.env.PUBLIC_URL}/img/icon/country/Vietnam(VN).png`},
        {name: "Wallis and Futuna", krname: "왈리스 퓌튀나", code: "WF", number: "+681", img: `${process.env.PUBLIC_URL}/img/icon/country/WallisandFutuna(WF).png`},
        {name: "Western Sahara", krname: "서사하라", code: "EH", number: "+212", img: `${process.env.PUBLIC_URL}/img/icon/country/WesternSahara(EH).png`},
        {name: "Yemen", krname: "예멘", code: "YE", number: "+967", img: `${process.env.PUBLIC_URL}/img/icon/country/Yemen(YE).png`},
        {name: "Zambia", krname: "잠비아", code: "ZM", number: "+260", img: `${process.env.PUBLIC_URL}/img/icon/country/Zambia(ZM).png`},
        {name: "Zimbabwe", krname: "짐바브웨", code: "ZW", number: "+263", img: `${process.env.PUBLIC_URL}/img/icon/country/Zimbabwe(ZW).png`}

    ];
    /* -------------------------------------------------------- */

    // 페이지 이동
    const navigate = useNavigate()


    /* 
        💍✅ 로그인 체크
        - 쿠키에 jwt 가 있는지 확인
        - jwt 로 사용자 정보를 요청
    */
    const loginCheck = async () => {

        // 🍪 ➡ 💍 쿠키에서 jwt 토큰 가져오기
        const accessToken = Cookies.get("accessToken")
        // console.log(`accessToekn : ${accessToken}`);

        
        // accessToken (jwt) 이 없음
        if( !accessToken ) {
            // console.log(`쿠키에 accessToken(jwt) 이 없음`);
            // 로그아웃 세팅
            logoutSetting()
            return
        }
        
        // accessToken (jwt) 이 있음
        // ➡ 💌 header 에 💍 jwt 담기
        api.defaults.headers.common.Authorization = `Bearer ${accessToken}`

        
        try {
            // 👩‍💼 사용자 정보 요청
            const response = await auth.info();
            const data = response.data;
            // console.log(`data : ${data}`);
    
            // ❌ 인증 실패
            if( data == 'UNAUTHRIZED' || response.status == 401 ) {
                // console.error(`accessToken (jwt) 이 만료되었거나 인증에 실패하였습니다.`);
                logoutSetting();
                return
            }
            // ✅ 인증 성공
            // console.log(`accessToek (jwt) 로큰으로 사용자 인증정보 요청 성공!`);
            // 로그인 세팅
            loginSetting(data, accessToken);

        } catch (error) {
            if (error.response) {
                console.log(`status : ${error.response.status}`);
            } else {
                console.log('No response or network error occurred');
            }
            logoutSetting();
        } finally {
            setIsUserInfoLoaded(true);
        }
    }

    // 🔐 로그인 
    const login = async (username, password) => {

        // console.log(`username : ${username}`);
        // console.log(`password : ${password}`);

        try {
            const response = await auth.login(username, password)
            const data = response.data
            const status = response.status
            const headers = response.headers
            const authroization = headers.authorization
            const accessToken = authroization.replace("Bearer ", "")  // 💍 JWT

            // console.log(`data : ${data}`);
            // console.log(`status : ${status}`);
            // console.log(`headers : ${headers}`);
            // console.log(`jwt : ${accessToken}`);

            // ✅ 로그인 성공
            if( status === 200 ) {
                // 💍 ➡ 🍪 쿠키에 accessToken(jwt) 저장
                Cookies.set("accessToken", accessToken)
                
                localStorage.setItem("accessToken", accessToken);

                // 로그인 체크 ( /users/{userId}   <---  userData )
                loginCheck()
                
                
                // 여기
                // alert(`로그인 성공`)
                // Swal.alert(`로그인 성공`, `메인 화면으로 갑니다.`, "success", () => { navigate("/main")})

                // 메인 페이지로 이동
                // navigate("/")
                // 로그인 성공
                toast.success('로그인 성공, 메인 화면으로 이동합니다.', {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });

                // 메인 페이지로 이동
                navigate("/market");
            }
        } catch (error) {
            // ✅ 만료된 토큰 처리
            if (error.response?.status === 401) {
                await handleRefreshToken(); // 리프레시 토큰 처리
            } else {
                // 기타 오류 처리
                toast.error("아이디 또는 비밀번호가 일치하지 않습니다.", {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    const handleRefreshToken = async () => {
        try {
            const response = await fetch("/api/users/refresh-token", { method: "POST", credentials: "include" });
            if (response.ok) {
                const { accessToken } = await response.json();
                Cookies.set("accessToken", accessToken, { secure: true });
                localStorage.setItem("accessToken", accessToken);
            } else {
                toast.error("세션이 만료되었습니다. 다시 로그인 해주세요.");
                navigate("/");
            }
        } catch (error) {
            console.error("리프레시 토큰 처리 실패:", error);
            toast.error("세션이 만료되었습니다. 다시 로그인 해주세요.");
            navigate("/");
        }
    };
    
    // 🔐 Seller 로그인 
    const sellerLogin = async (username, password) => {

        // console.log(`username : ${username}`);
        // console.log(`password : ${password}`);

        try {
            const response = await auth.login(username, password)
            const data = response.data
            const status = response.status
            const headers = response.headers
            const authroization = headers.authorization
            const accessToken = authroization.replace("Bearer ", "")  // 💍 JWT

            // console.log(response.data);
            // console.log(`status : ${status}`);
            // console.log(`headers : ${headers}`);
            // console.log(`jwt : ${accessToken}`);

            // ✅ 로그인 성공
            if( status === 200 ) {
                // 💍 ➡ 🍪 쿠키에 accessToken(jwt) 저장
                Cookies.set("accessToken", accessToken)

                // 로그인 체크 ( /users/{userId}   <---  userData )
                loginCheck()
                
                const response2 = await auth.info()
                const data2 = response2.data;

                const response3 = await auth.userselect(data2.userId);
                const data3 = response3.data;

                // console.log(data3.userClassification);

                const userClassification = data3.userClassification;

                
                toast.success('로그인 성공, 메인 화면으로 이동합니다.', {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });

                if(userClassification === 'supportseller') {
                    // 메인 페이지로 이동
                    navigate("/seller/supportdashboard");
                }
                
                if(userClassification === 'storeseller') {
                    navigate("/admin/dashboard");
                }
            }

        } catch (error) {
            // 로그인 실패
            // - 아이디 또는 비밀번호가 일치하지 않습니다.
            // Swal.alert("로그인 실패", "아이디 또는 비밀번호가 일치하지 않습니다.", "error" )
            // 로그인 실패
            toast.error('아이디 또는 비밀번호가 일치하지 않습니다.', {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        

    }
    // 🔐 admin 로그인 
    const adminLogin = async (username, password) => {

        // console.log(`username : ${username}`);
        // console.log(`password : ${password}`);

        try {
            const response = await auth.login(username, password)
            const data = response.data
            const status = response.status
            const headers = response.headers
            const authroization = headers.authorization
            const accessToken = authroization.replace("Bearer ", "")  // 💍 JWT

            // console.log(response.data);
            // console.log(`status : ${status}`);
            // console.log(`headers : ${headers}`);
            // console.log(`jwt : ${accessToken}`);

            // ✅ 로그인 성공
            if( status === 200 ) {
                // 💍 ➡ 🍪 쿠키에 accessToken(jwt) 저장
                Cookies.set("accessToken", accessToken)

                // 로그인 체크 ( /users/{userId}   <---  userData )
                loginCheck()
                
                const response2 = await auth.info()
                const data2 = response2.data;

                const response3 = await auth.userselect(data2.userId);
                const data3 = response3.data;

                
                toast.success('로그인 성공, 메인 화면으로 이동합니다.', {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });

                    // 메인 페이지로 이동
                    navigate("/admin/dashboard");
            } 
            } catch (error) {
                // 로그인 실패
                // - 아이디 또는 비밀번호가 일치하지 않습니다.
                // Swal.alert("로그인 실패", "아이디 또는 비밀번호가 일치하지 않습니다.", "error" )
                // 로그인 실패
                toast.error('가입 승인 전 이거나, 아이디 또는 비밀번호가 일치하지 않습니다. (24시간 이후에도 로그인이 되지 않으면 관리자에게 문의해주세요.)', {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
        }
        

    }

    // 🔓 Admin 로그아웃
    const adminlogout = (force=false) => {

        if( force ) {
            // 로그아웃 세팅
            logoutSetting()
        
            // 페이지 이동 ➡ "/" (메인)
            navigate("/admin")
            return
        }        

        Swal.confirm("로그아웃", "로그아웃 하시겠습니까?", "warning",
                (result) => {
                    if( result.isConfirmed ) {
                        // 로그아웃 세팅
                        logoutSetting()

                        // 메인 페이지로 이동
                        navigate("/admin")
                    }
                }
            )

    }
    // 🔓 Seller 로그아웃
    const sellerlogout = (force=false) => {

        if( force ) {
            // 로그아웃 세팅
            logoutSetting()
        
            // 페이지 이동 ➡ "/" (메인)
            navigate("/seller")
            return
        }        

        Swal.confirm("로그아웃", "로그아웃 하시겠습니까?", "warning",
                (result) => {
                    if( result.isConfirmed ) {
                        // 로그아웃 세팅
                        logoutSetting()

                        // 메인 페이지로 이동
                        navigate("/seller")
                    }
                }
            )

    }

    // 🔓 로그아웃
    const logout = (force=false) => {

        if( force ) {
            // 로그아웃 세팅
            logoutSetting();
            
            setIsUserInfoLoaded(false);
            refreshDelete();
            // 페이지 이동 ➡ "/" (메인)
            // navigate("/")
            return
        }       

        // const check = window.confirm(`로그아웃하시겠습니까?`)

        Swal.confirm("로그아웃", "로그아웃 하시겠습니까?", "warning",
                (result) => {
                    if( result.isConfirmed ) {
                        // 로그아웃 세팅
                        logoutSetting();
                        
                        setIsUserInfoLoaded(false);

                        // 메인 페이지로 이동
                        navigate("/")
                    }
                }
            )

        // if( check ) {
        //     // 로그아웃 세팅
        //     logoutSetting()

        //     // 메인 페이지로 이동
        //     navigate("/")
        // }

    }

    const refreshDelete = async () => {
        try {
            await fetch("/api/users/logout", {
                method: "POST",
                credentials: "include", // 쿠키 전송 포함
            });
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            Cookies.remove("accessToken");
            Cookies.remove("refreshToken", { path: "/" }); // 로컬 쿠키 삭제

            navigate("/");
        } catch (error) {
            console.error("로그아웃 실패:", error);
        }
    }

    // 🔓 회원탈퇴
    const remove =  () => {
        Swal.removeConfirm("탈퇴하기", "탈퇴 시 본인 계정의 모든 기록이 삭제됩니다. 탈퇴하시겠습니까?", "warning",
            async (result) => {
                if( result.isConfirmed ) {
                    try {
                        const response = await auth.remove(userInfo.userId);
                        const data = await response.data;
                        
                        if(data == 'SUCCESS') {
                            // 로그아웃 세팅
                            logoutSetting();

                            localStorage.removeItem("accessToken");
                            Cookies.remove("accessToken");

                            setIsUserInfoLoaded(false);
    
                            // 메인 페이지로 이동
                            navigate("/");
                        }
                        
                        } catch (error) {
                        console.error('회원탈퇴 실패');
                        }
                }
            }
        )
    }

    // 🔐 로그인 세팅
    // 👩‍💼 userData,  💍 accessToken (jwt)
    const loginSetting = (userData, accessToken) => {

        const { userNo, userId, authList } = userData
        const roleList = authList.map((auth) => auth.auth)
        setroleListCheck(roleList);

        // console.log(`userNo : ${userNo}`);
        // console.log(`userId : ${userId}`);
        // console.log(`authList : ${authList}`);
        // console.log(`roleList : ${roleList}`);

        if(userData !== null) {
            userVoteCreate(userData);
        }

        // 🚀 axios 객체의 header(Authorization : `Bearer ${accessToken}`)
        api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        // 👩‍💼🔐 로그인 여부 : true
        setLogin(true)
        
        // 👩‍💼✅ 유저정보 세팅
        const updatedUserInfo = {userNo, userId, roleList}
        setUserInfo(updatedUserInfo)

        // 👮‍♀️✅ 권한정보 세팅
        const updatedRoles = { isUser : false, isStore : false, isSupport: false, isAdmin : false}

        roleList.forEach( (role) => {
            if( role == 'ROLE_USER' ) updatedRoles.isUser = true
            if( role == 'ROLE_ADMIN' ) updatedRoles.isAdmin = true
            if( role == 'ROLE_STORE' ) updatedRoles.isStore = true
            if( role == 'ROLE_SUPPORT' ) updatedRoles.isSupport = true
        })
        setRoles(updatedRoles)

        setUserClassification()
    }

    // 로그아웃 세팅
    const logoutSetting = () => {
        // 🚀❌ axios 헤더 초기화
        api.defaults.headers.common.Authorization = undefined;

        // 🍪❌ 쿠키 초기화
        Cookies.remove("accessToken");

        localStorage.removeItem("accessToken"); 

        window.location.href = "logout://user_logout";

        // 🔐❌ 로그인 여부 : false
        setLogin(false)

        // 👩‍💼❌ 유저 정보 초기화
        setUserInfo(null)

        // 👮‍♀️❌ 권한 정보 초기화
        setRoles(null)
    }

    

    useEffect( () => {
      
        // 로그인 체크
        loginCheck()
      
    }, []);
    

    const userVoteCreate = async (userData) => {
        const response = await auth.votePaid(userData);
        const data = response.data;

        if(data === 'COUPON SUCCESS') {
            toast.success('투표권이 지급되었습니다.', {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }



    return ( 
        <LoginContext.Provider value={ {isLogin, setLogin, userInfo, roles, roleListCheck, isUserInfoLoaded, login, loginCheck, logout, sellerLogin, adminLogin, sellerlogout, adminlogout, remove, showAgree, setShowAgree, countries, logoutSetting} }>
            {children}
        </LoginContext.Provider>
    )
}

export default LoginContextProvider