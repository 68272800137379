import { Select } from 'antd'
import { Option } from 'antd/es/mentions'
import React from 'react'

const OverSeasTempPage = ( {
        admin, setShowOverSeasTempAdd, overseasShippingCosts, addOverseasShippingField, handleOverseasShippingCostChange, removeOverseasShippingField, countries,
        saveOverSeasTemplate, setCurrentTemplate, currentTemplate
    } ) => {
    
  return (
    <div className={`${admin.admin_overseas_tempalate_container}`}>
        <div className={`${admin.admin_overseas_tempalate_box}`}>
            <div className={`${admin.admin_overseas_tempalate_header}`}>
                <div><span>해외 배송 정보 등록</span></div>
                <div><button><span className="material-symbols-outlined" onClick={() => setShowOverSeasTempAdd(false)}>close</span></button></div>
            </div>
            <div className={`${admin.admin_overseas_tempalate_content}`}>
                {/* 배송국가 선택 */}
                <div className={`${admin.admin_overseas_tempalate_content_delivery_country}`}>
                    <div><span>배송국가선택</span></div>
                    <div>
                        <Select
                            mode="multiple"
                            className={`${admin.admin_overseas_tempalate_content_delivery_country_select}`}
                            placeholder="국가를 선택하세요"
                            onChange={(selectedValues) => {
                                // 선택된 국가명(krname)과 코드(code)를 배열로 추출
                                const selectedCountries = selectedValues.map((value) => {
                                    return countries.find((item) => item.krname === value);
                                });

                                // krname과 code 배열 생성
                                const countryNames = selectedCountries.map((item) => item.krname).join(';');
                                const countryCodes = selectedCountries.map((item) => item.code).join(';');

                                // 상태에 두 값 저장
                                setCurrentTemplate({
                                    ...currentTemplate,
                                    overseasDeliveryCountry: countryNames,
                                    overseasDeliveryCode: countryCodes,
                                });
                            }}
                        >
                            {countries.map((item, index) => (
                                <Option value={item.krname} key={index}>
                                    {item.krname} ({item.code})
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                {/* 템플릿 이름 */}
                <div className={`${admin.admin_overseas_tempalate_content_delivery_name}`}>
                    <div><span>해외 배송지 이름</span></div>
                    <div>
                        <input
                            type="text"
                            value={currentTemplate.overseasDeliveryName}
                            placeholder="ex) 아시아, 유럽 등"
                            onChange={(e) =>
                                setCurrentTemplate({ ...currentTemplate, overseasDeliveryName: e.target.value })
                            }
                        />
                    </div>
                </div>

                {/* 반품 배송비 */}
                <div className={`${admin.admin_overseas_tempalate_content_delivery_return}`}>
                    <div><span>해외 배송 반품 배송비(편도)</span></div>
                    <div>
                        <input
                            type="number"
                            value={currentTemplate.overseasReturnCost}
                            onChange={(e) =>
                                setCurrentTemplate({ ...currentTemplate, overseasReturnCost: e.target.value })
                            }
                        />
                        <span>원</span>
                    </div>
                </div>

                {/* 교환 배송비 */}
                <div className={`${admin.admin_overseas_tempalate_content_delivery_exchange}`}>
                    <div><span>해외 배송 교환 배송비(왕복)</span></div>
                    <div>
                        <input
                            type="number"
                            value={currentTemplate.overseasExchangeCost}
                            onChange={(e) =>
                                setCurrentTemplate({ ...currentTemplate, overseasExchangeCost: e.target.value })
                            }
                        />
                        <span>원</span>
                    </div>
                </div>
                <div className={`${admin.admin_overseas_tempalate_content_delivery_kgcost}`}>
                    <div className={`${admin.admin_overseas_tempalate_content_delivery_kgcost_text}`}><span>무게별 배송비 등록</span></div>
                    {
                        overseasShippingCosts.map((cost, index) => (
                            <div
                                className={`${admin.admin_shop_delivery_oversease_add_input}`}
                                key={index}
                            >
                                <div>
                                    <input
                                        type="number"
                                        placeholder="Kg"
                                        value={cost.kg}
                                        onChange={(e) =>
                                            handleOverseasShippingCostChange(
                                                index,
                                                'kg',
                                                e.target.value
                                            )
                                        }
                                    />
                                    <span>kg 이상</span>
                                </div>
                                <div>
                                    <input
                                        type="number"
                                        placeholder="금액"
                                        value={cost.price}
                                        onChange={(e) =>
                                            handleOverseasShippingCostChange(
                                                index,
                                                'price',
                                                e.target.value
                                            )
                                        }
                                    />
                                    <span>&#8361;</span>
                                </div>
                                <button
                                    onClick={() => removeOverseasShippingField(index)}
                                    className={`${admin.admin_shop_delivery_oveseaes_remove_btn}`}
                                >
                                    <span className="material-symbols-outlined">remove</span>
                                </button>
                            </div>
                    ))}
                    <div className={`${admin.admin_shop_delivery_oversease_add_btn}`}>
                        <button 
                            onClick={addOverseasShippingField}
                        >
                            <span className="material-symbols-outlined">add</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className={`${admin.admin_overseas_tempalate_footer}`}>
                <div><button>취소</button></div>
                <div><button onClick={() => saveOverSeasTemplate()}>저장</button></div>
            </div>
        </div>
    </div>
  )
}

export default OverSeasTempPage