import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Attendance from './Attendance.tsx';
import moment from 'moment';
import * as admin from '../../apis/admin.js';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import * as support from '../../apis/support.js';
import { LoginContext } from '../../contexts/LoginContextProvider.jsx';
import * as auth from '../../apis/auth.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';

const EventSection = ({ event }) => {
    const { userInfo, isLogin } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const [activeButton, setActiveButton] = useState('proceeding');
    const [noticeAnnounceActive, setNoticeAnnoimceActive] = useState('all');
    const [middleTabButton, setMiddleTabButton] = useState('total');
    const [attendDay, setAttendDay] = useState([]); // 출석한 날짜 예시
    const navigate = useNavigate();
    const [eventList, setEventList] = useState([]);
    const [showStampAdd, setShowStampAdd] = useState(false);
    const [userStampImg, setUserStampImg] = useState('');
    const [stampNullCheck, setStampNullCheck] = useState(false);
    const [randomNumber, setRandomNumber] = useState(null);
    const [rolling, setRolling] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [attendCheck, setAttendCheck] = useState(false);
    const [pointCheck, setPointCheck] = useState(0);
    const [todayChecked, setTodayChecked] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [announcementList, setAnnouncementList] = useState([]);
    const [filteredAnnouncementList, setFilteredAnnouncementList] = useState([]);
    const [ativeAnnouncement, setActiveAnnouncement] = useState('lucked');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // console.log('isLoading : ', isLoading);
    }, [isLoading])

    useEffect(() => {
        eventSelect();
        userStampSelect();
        userAttendSelect();
        userNotificationSelect();
        userAnnouncementSelect();
        // console.log('todayChecked : ', todayChecked);
    }, [userInfo]);

    useEffect(() => {
        // console.log('pointCheck : ', pointCheck);
        if (pointCheck !== 0) {
            const pointUpdate = async () => {
                const response = await auth.userPointUpdate(userInfo && userInfo?.userId, pointCheck);
                const data = response.data;

                // console.log(data);
            };
            pointUpdate();
        }
    }, [pointCheck]);

    useEffect(() => {
        const today = moment().format('YYYY-MM-DD');
        const hasCheckedToday = attendDay.some((day) => day.attendDay === today);
        setTodayChecked(hasCheckedToday);
    }, [attendDay]);

    useEffect(() => {
        // console.log('attendDay : ', attendDay);
        // console.log('userStampImg : ', userStampImg);
    }, [attendDay, userStampImg]);

    useEffect(() => {
        // console.log('eventList : ', eventList);
    }, [eventList]);

    const eventSelect = async () => {
        const response = await admin.eventSelect();
        const data = response.data;
        setEventList(data);
    };

    const userStampSelect = async () => {
        const response = await auth.userStampSelect(userInfo && userInfo?.userId);
        const data = response.data;

        // console.log('userStampSelect : ', data);

        if (data.stampNo !== 0) {
            setUserStampImg(data.stampImg);
            setStampNullCheck(false);
        } else {
            setStampNullCheck(true);
        }
    };

    const userAttendSelect = async () => {
        const today = new Date();
        const formattedToday = moment(today).format('YYYY-MM');

        const response = await auth.userAttendSelect(userInfo && userInfo?.userId, formattedToday);
        const data = response.data;

        // console.log('출석체크 일 : ', data);

        if(data !== null) {
            setAttendDay(data);
            setIsLoading(false); // 로딩이 끝나면 상태를 false로 설정
        }
    };

    const userNotificationSelect = async () => {
        const response = await auth.userNotificationSelect();
        const data = response.data;

        // console.log('notification : ', data);
        setNotificationList(data);
    }

    const userAnnouncementSelect = async () => {
        const response = await auth.userAnnouncementSelect();
        const data = response.data;

        // console.log('announcement : ', data);
        setAnnouncementList(data);

    }

    const generateRandomNumber = async () => {
        // const rand = Math.random();
        let number;

        
        const response = await auth.pointCal();
        const data = response.data;

        number = data;

        // console.log('data : ', data);

        // if (rand < 0.95) {
        //     number = Math.floor(Math.random() * 10) + 1;
        // } else if (rand < 0.98) {
        //     number = Math.floor(Math.random() * 20) + 11;
        // } else if (rand < 0.995) {
        //     number = Math.floor(Math.random() * 40) + 31;
        // } else if (rand < 0.998) {
        //     number = Math.floor(Math.random() * 20) + 71;
        // } else {
        //     number = Math.floor(Math.random() * 10) + 91;
        // }
        return number;
    };

    const startRolling = async () => {
        setRolling(true);
        setAttendCheck(true);
        const interval = setInterval(() => {
            setRandomNumber(Math.floor(Math.random() * 1000) + 1);
        }, 50);
    
        setTimeout(async () => {  // 이 부분을 async로 변경
            clearInterval(interval);
            const result = await generateRandomNumber();  // 여기서 await를 사용하여 Promise를 기다림
            // console.log('result : ', result);
            setRandomNumber(result);
            setRolling(false);
            setResultMessage(`${result} 덕포인트 당첨!`);
            setPointCheck(result);
        }, 2000);
    };
    

    const onAttendance = async () => {
        const today = new Date();
        const formattedToday = moment(today).format('YYYY-MM-DD');
        // console.log(formattedToday); // 오늘 날짜를 'YYYY-MM-DD' 형태로 콘솔에 출력

        const response = await auth.attendanceCheck(userInfo && userInfo?.userId, userStampImg ? userStampImg : `${process.env.PUBLIC_URL}/img/user/default_img.png`, formattedToday);
        const data = response.data;

        // console.log(data);

        if (data === 'SUCCESS') {
            // attendDay 배열에 formattedToday 값을 추가
            setAttendDay([...attendDay, { userStampImg: userStampImg ? userStampImg : `${process.env.PUBLIC_URL}/img/user/default_img.png`, attend: formattedToday }]);
            startRolling();
            userAttendSelect();
        }
    };

    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 9);

    const startDate = 1;
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

    const formatDate = (dateString) => {
        return dateString.replace('T', ' ').substring(0, 19);
    };
    const KSTformatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    const convertToLocalTime = (dateString) => {
        const eventDate = new Date(dateString);
        return eventDate;
    };

    const onItemLinked = (item) => {
        const eventStartDate = convertToLocalTime(item.eventStartDate);
        if (eventStartDate > currentDate) {
            alert('오픈 대기중인 이벤트입니다.');
            return;
        }
        navigate(`/event/${item.eventType}/${item.eventNumber}`, { state: { eventInfo: item } });
    };

    const handleClick = () => {
        document.getElementById('hiddenShopProfileImg').click();
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;

        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        // console.log('imageUrl : ', imageUrl);

        setUserStampImg(imageUrl);
    };

    const handleDeleteClick = async () => {
        try {
            const queryParams = userStampImg.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }

            const fileName = filePath.split('/').pop();
            // console.log('fileName : ' + fileName);
            // await fetch(`/api/file/delete/${fileName}`, {
            //     method: 'DELETE',
            // });

            setUserStampImg('');

        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    const stampAdd = async () => {
        const response = await auth.stampAdd(userInfo && userInfo?.userId, userStampImg);
        const data = response.data;

        // console.log(data);

        if (data === 'SUCCESS') {
            alert('스탬프 등록이 완료되었습니다.');
            setShowStampAdd(false);
            userStampSelect();
        } else {
            alert('스탬프 등록에 실패했습니다.');
        }
    };

    const stampUpdate = async () => {
        const response = await auth.stampUpdate(userInfo && userInfo?.userId, userStampImg);
        const data = response.data;

        // console.log(data);

        if (data === 'SUCCESS') {
            alert('스탬프 수정이 완료되었습니다.');
            setShowStampAdd(false);
            userStampSelect();
        } else {
            alert('스탬프 수정에 실패했습니다.');
        }
    };

    const filterAnnouncementsByType = (type) => {
        setFilteredAnnouncementList(announcementList.filter(item => item.announcementEventType === type));
    };

    useEffect(() => {
        if (noticeAnnounceActive === 'all') {
            setFilteredAnnouncementList(announcementList);
        } else if (noticeAnnounceActive === 'notification') {
            // 추가적인 필터링이 필요한 경우 여기에서 처리할 수 있습니다.
        } else if (noticeAnnounceActive === 'announcement') {
            setFilteredAnnouncementList(announcementList);
        }
    }, [noticeAnnounceActive, announcementList]);

    const onNotificationClick = (notificationNo) => {
        // console.log(item);

        navigate(`/event/notification/${notificationNo}`);
    }

    const onAnnouncementClick = (item) => {
        // console.log(item);

        navigate('/event/announcement', {state : {announcement : item}});
    }

    const onAttendanceCheck = () => {
        if(isLogin) {
            setMiddleTabButton('attendance');
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${event.event_attendency_popup}`
                },
                html: `
                        <div>
                            <div class='${event.event_attendency_title}'><span>로그인 후 이용가능합니다.</span></div>
                            <div class='${event.event_attendency_sub_title}'><span>포인트혜택 놓치지 마세요!</span></div>
                            <div class='${event.event_attendency_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='event_attendency_cancel'>취소</button>
                                <button class='mx-1' id='event_attendency_success'>로그인</button>
                            </div>
                        </div>
                    `,
                showConfirmButton: false,
                showCancelButton: false,
                });

                document.addEventListener('click', eventAttendencySuccess);
                document.addEventListener('click', eventAttendencyCancel);
            
                function eventAttendencySuccess(e) {
                    if (e.target && e.target.id === 'event_attendency_success') {
                        navigate('/');
                        document.removeEventListener('click', eventAttendencySuccess);
                        document.removeEventListener('click', eventAttendencyCancel);
                        MySwal.close();
                    }
                }
            
                function eventAttendencyCancel(e) {
                    if (e.target && e.target.id === 'event_attendency_cancel') {
                        MySwal.close();
                        document.removeEventListener('click', eventAttendencySuccess);
                        document.removeEventListener('click', eventAttendencyCancel);
                    }
                }
        }

    }

    return (
        <div className='px-3'>
            <div className={`${event.event_top_box}`}>
                <div><button className={`${activeButton === 'proceeding' ? event.top_btn_active : ''}`} onClick={() => setActiveButton('proceeding')}>진행중</button></div>
                <div>&nbsp;|&nbsp;</div>
                <div><button className={`${activeButton === 'notification' ? event.top_btn_active : ''}`} onClick={() => setActiveButton('notification')}>공지·발표</button></div>
            </div>

            {activeButton === 'proceeding' &&
                <div>
                    <div className={`${event.event_middle_tab_btn_box} py-3`}>
                    <Swiper
                        slidesPerView="auto"
                        freeMode={true}
                        spaceBetween={10}
                        className={`${event.event_sub_category_swiper}`}
                    >
                        <SwiperSlide >
                            <div><button className={`${middleTabButton === 'total' ? event.middle_tab_active : ''}`} onClick={() => setMiddleTabButton('total')}>전체</button></div>
                        </SwiperSlide>
                        <SwiperSlide >
                            <div><button className={`${middleTabButton === 'vote' ? event.middle_tab_active : ''}`} onClick={() => setMiddleTabButton('vote')}>투표</button></div>
                        </SwiperSlide>
                        <SwiperSlide >
                            <div><button className={`${middleTabButton === 'lucked' ? event.middle_tab_active : ''}`} onClick={() => setMiddleTabButton('lucked')}>럭드</button></div>
                        </SwiperSlide>
                        <SwiperSlide >
                            <div><button className={`${middleTabButton === 'plan' ? event.middle_tab_active : ''}`} onClick={() => setMiddleTabButton('plan')}>기획전</button></div>
                        </SwiperSlide>
                        <SwiperSlide >
                            <div><button className={`${middleTabButton === 'comment' ? event.middle_tab_active : ''}`} onClick={() => setMiddleTabButton('comment')}>댓글</button></div>
                        </SwiperSlide>
                        {/* <SwiperSlide >
                            <div><button className={`${middleTabButton === 'input' ? event.middle_tab_active : ''}`} onClick={() => setMiddleTabButton('input')}>입력폼</button></div>
                        </SwiperSlide> */}
                        {/* <SwiperSlide >
                            <div><button className={`${middleTabButton === 'attendance' ? event.middle_tab_active : ''}`} onClick={() => onAttendanceCheck()}>출석체크</button></div>
                        </SwiperSlide> */}
                    </Swiper>
                    </div>
                    <div>
                        {
                            middleTabButton === 'attendance' ? null :
                                <span>전체 {
                                    middleTabButton === 'total'
                                        ? eventList.filter(item => new Date(item.eventEndDate) > currentDate).length
                                        : eventList.filter(item => item.eventType === middleTabButton && new Date(item.eventEndDate) > currentDate).length
                                }</span>
                        }
                    </div>
                    {
                        middleTabButton === 'attendance' && isLogin ?
                            <div className={`${event.event_container}`}>
                                <Attendance event={event} attendDay={attendDay} userStampImg={userStampImg} />
                                <div>
                                    <div className='text-center py-3 border-bottom'><span>{startDate}일부터 {lastDayOfMonth}일까지 출석체크를 하면 쿠폰을 드려요!</span></div>
                                    <div className='d-flex justify-content-between pt-3 align-items-center'>
                                        <div className='d-flex align-items-center'>
                                            <div className={`${event.event_stamp_img_box}`}><img src={userStampImg ? userStampImg : `${process.env.PUBLIC_URL}/img/user/default_img.png`} alt="스탬프 이미지" /></div>
                                            <div className='ps-2'><span>스탬프 이미지</span></div>
                                        </div>
                                        <div>
                                            {
                                                stampNullCheck ?
                                                    <button className={`${event.event_stamp_add_box}`} onClick={() => setShowStampAdd(true)}>등록하기</button>
                                                    :
                                                    <button className={`${event.event_stamp_add_box}`} onClick={() => setShowStampAdd(true)}>수정하기</button>

                                            }
                                        </div>
                                    </div>
                                    <div className={`${event.event_stamp_footer_ment} py-3`}><span>출석체크 시 n포인트 지급!</span></div>
                                    <div>
                                    <button
                                        className={todayChecked ? event.event_attendance_btn_check : event.event_attendance_btn}
                                        onClick={() => onAttendance()}
                                        disabled={rolling || todayChecked || isLoading} // 로딩 중일 때 버튼 비활성화
                                    >
                                        {todayChecked ? '출석 완료' : '출석하기'}
                                    </button>
                                    </div>

                                </div>
                            </div>
                            :
                            (
                                <div className='row pb-5 mb-5'>
                                  {Array.isArray(eventList) && eventList.length > 0 ? (
                                    eventList
                                      .filter(item =>
                                        (middleTabButton === 'total' || item.eventType === middleTabButton) &&
                                        new Date(item.eventEndDate) > currentDate
                                      ).length > 0 ? (
                                        eventList
                                          .filter(item =>
                                            (middleTabButton === 'total' || item.eventType === middleTabButton) &&
                                            new Date(item.eventEndDate) > currentDate
                                          )
                                          .map((item, index) => {
                                            const eventStartDate = convertToLocalTime(item.eventStartDate);
                                            return (
                                              <div className='border-bottom py-1 position-relative' key={item.eventNo}>
                                                <button onClick={() => onItemLinked(item)} className={`${event.event_centent_box}`}>
                                                  <div className={`${event.event_content_img_box}`}>
                                                    <div><img src={item.eventImage1} alt={item.eventTitle} /></div>
                                                  </div>
                                                  <div className={`${event.event_content_info_box}`}>
                                                    <div className={`${event.event_content_title_box}`}><span>{item.eventTitle}</span></div>
                                                    <div className={`${event.event_content_sub_title_box}`}><span>{item.eventSubTitle}</span></div>
                                                    <div className={`${event.event_content_date_box}`}><span>{formatDate(item.eventStartDate)} ~ {formatDate(item.eventEndDate)}</span></div>
                                                  </div>
                                                </button>
                                                {eventStartDate > currentDate && (
                                                  <div className={`${event.event_content_open_wait}`}>
                                                    <span>오픈 대기중</span>
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          })
                                      ) : (
                                        <div className={`${event.event_content_no_event}`}>
                                          <span>이벤트 준비 중! 곧 만나요🥳</span>
                                        </div>
                                      )
                                  ) : (
                                    <div className={`${event.event_content_no_event}`}>
                                      <span>이벤트 준비 중! 곧 만나요🥳</span>
                                    </div>
                                  )}
                                </div>
                            )
                    }
                </div>
            }
            {
                isLoading ? (
                    <div className='loading_page_container'>
                        <div className='loading_page_box'>
                            <img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" />
                        </div>
                    </div>
                )
                : (
                    <>
                        {/* {todayChecked === false && (
                            <div className={`${event.event_attend_check_link_box}`}>
                                {
                                    middleTabButton !== 'attendance' && isLogin === true &&
                                    <button className={event.event_attendance_link_btn} onClick={() => {setActiveButton('proceeding'); setMiddleTabButton('attendance')}} disabled={todayChecked || isLoading}>
                                        오늘 출석체크를 안하셨네요!! 출석 체크하러 갈까요?
                                    </button>
                                }
                                {
                                    middleTabButton !== 'attendance' && isLogin === false &&
                                    <button className={event.event_attendance_link_btn} onClick={() => {navigate('/')}} disabled={todayChecked || isLoading}>
                                        로그인하고 포인트 혜택 놓치지마세요!
                                    </button>
                                }
                            </div>
                        )} */}
                    </>
                )
            }
            {
                showStampAdd &&
                <div className={`${event.event_middle_stamp_add_modal_container}`}>
                    <div className={`${event.event_middle_stamp_add_modal_box}`}>
                        <div className={`${event.event_middle_stamp_add_modal_header}`}>
                            <div><span>스탬프 등록하기</span></div>
                            <div><button onClick={() => setShowStampAdd(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${event.event_middle_stamp_add_modal_content}`}>
                            <div>
                                <div><img src={userStampImg ? userStampImg : `${process.env.PUBLIC_URL}/img/user/default_img.png`} alt="" /></div>
                            </div>
                            <input type="file" onChange={(e) => handleImageChange(e)} id='hiddenShopProfileImg' style={{ display: 'none' }} />
                            <div>
                                <div><button onClick={() => handleClick()}>추가</button></div>
                                <div><button onClick={() => handleDeleteClick()}>삭제</button></div>
                            </div>
                        </div>
                        <div className={`${event.event_middle_stamp_add_modal_info}`}>
                            <div><span>스탬프 이미지 등록 후 출석체크 시점부터 스탬프 이미지가 적용됩니다.</span></div>
                        </div>
                        <div className={`${event.event_middle_stamp_add_modal_footer}`}>
                            {
                                stampNullCheck ?
                                    <button onClick={stampAdd}>등록하기</button>
                                    :
                                    <button onClick={stampUpdate}>수정하기</button>
                            }
                        </div>
                    </div>
                </div>
            }
            {
                attendCheck &&
                <div className={`${event.event_middle_attend_check_modal_container}`}>
                    <div className={`${event.event_middle_attend_check_modal_box}`}>
                        <div className={`${event.event_middle_attend_check_number_box}`}>
                            {rolling ? (
                                <div className={`${event.rolling_numbers}`}>
                                    <div className={event.number}>{randomNumber}</div>
                                </div>
                            ) : (
                                <div>
                                    <span>{resultMessage}</span>
                                </div>
                            )}
                        </div>
                        <div className={`${event.event_middle_attend_check_modal_footer}`}><button className={`${!rolling ? event.event_middle_attend_check_modal_footer_active : ''}`} onClick={() => setAttendCheck(false)} disabled={rolling}>확인</button></div>
                    </div>
                </div>
            }

            {
                activeButton === 'notification' &&
                <div className={`${event.event_notification_container}`}>
                    <div className={`${event.event_notification_box}`}>
                        <div className={`${event.event_notification_tab}`}>
                            <div className={noticeAnnounceActive === 'all' ? event.event_notice_announce_active : ''}><button onClick={() => setNoticeAnnoimceActive('all')}>전체</button></div>
                            <div className={noticeAnnounceActive === 'notification' ? event.event_notice_announce_active : ''}><button onClick={() => setNoticeAnnoimceActive('notification')}>공지</button></div>
                            <div className={noticeAnnounceActive === 'announcement' ? event.event_notice_announce_active : ''}><button onClick={() => setNoticeAnnoimceActive('announcement')}>발표</button></div>
                        </div>
                        {
                            noticeAnnounceActive === 'announcement' &&
                            <div className={`${event.event_announcement_tab}`}>
                                <div><button className={ativeAnnouncement === 'lucked' ? event.event_announcement_tab_active : ''} onClick={() => {filterAnnouncementsByType('lucked'); setActiveAnnouncement('lucked')}}>럭드</button></div>
                                <div><button className={ativeAnnouncement === 'vote' ? event.event_announcement_tab_active : ''} onClick={() => {filterAnnouncementsByType('vote'); setActiveAnnouncement('vote')}}>투표</button></div>
                                <div><button className={ativeAnnouncement === 'form' ? event.event_announcement_tab_active : ''} onClick={() => {filterAnnouncementsByType('form'); setActiveAnnouncement('form')}}>입력폼</button></div>
                            </div>   
                        }
                        <div className={`${event.event_notification_content}`}>

                            {/* 전체 */}
                            {
                                noticeAnnounceActive === 'all' &&
                                <div className={`${event.event_notice_announce_all_box}`}>
                                    <div><span>전체 </span><span>{notificationList.length + announcementList.length}</span></div>
                                    <div className={`${event.event_notice_announce_notification_box}`}>
                                        <div><span>공지</span></div>
                                        {
                                            notificationList.length !== 0 ? 
                                            <div className={`${event.event_notification_item_box}`}>
                                                {
                                                    notificationList.map((item, index) => (
                                                        <div className={`${event.event_notification_item}`} key={index} onClick={() => onNotificationClick(item.notificationNo)}>
                                                            <div><span>{item.notificationTitle}</span></div>
                                                            <div><span>{KSTformatDate(item.notificationRegDate)}</span></div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            <div className={`${event.event_notice_announce_no_item_box}`}><span>아아📢 등록된 공지사항이 없습니다.</span></div>
                                        }
                                        <div className={`${event.event_notification_more_btn}`}><button onClick={() => setNoticeAnnoimceActive('notification')}>공지사항 더보기</button></div>
                                    </div>
                                    <div className={`${event.event_notice_announce_announcement_box}`}>
                                        <div><span>당첨자발표</span></div>
                                        {
                                            announcementList.length !== 0 ?
                                            <div className={`${event.event_announcement_item_box}`}>
                                                {
                                                    announcementList.map((item, index) => (
                                                        <div className={`${event.event_announcement_item}`} key={index} onClick={() => onAnnouncementClick(item)}>
                                                            <div><span>{item.announcementTitle}</span></div>
                                                            <div><span>{KSTformatDate(item.announcementRegDate)}</span></div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            <div className={`${event.event_notice_announce_no_item_box}`}><span>두구두구두구! 당첨자 발표를 기다려 주세요🏆</span></div>
                                        }
                                        <div className={`${event.event_announcement_more_btn}`}><button onClick={() => setNoticeAnnoimceActive('announcement')}>당첨자발표 더보기</button></div>
                                    </div>
                                </div>

                            }

                            {/* 공지 */}
                            {
                                noticeAnnounceActive === 'notification' &&
                                <div>
                                    <div className='pt-2'><span>전체 </span><span>{notificationList.length}</span></div>
                                {
                                    notificationList.length !== 0 ?
                                    notificationList.map((item, index) => (
                                        <div className={`${event.event_notification_item}`} key={index} onClick={() => onNotificationClick(item)}>
                                            <div><span>{item.notificationTitle}</span></div>
                                            <div><span>{KSTformatDate(item.notificationRegDate)}</span></div>
                                        </div>
                                    ))
                                    :
                                    <div className={`${event.event_notice_announce_no_item_box}`}><span>아아📢 등록된 공지사항이 없습니다.</span></div>
                                }
                                </div>
                            }

                            {/* 발표 */}
                            {
                                noticeAnnounceActive === 'announcement' &&
                                <div>
                                    {
                                        filteredAnnouncementList.length !== 0 ?
                                        filteredAnnouncementList.map((item, index) => (
                                            <div className={`${event.event_announcement_item}`} key={index} onClick={() => setNoticeAnnoimceActive('notification')}>
                                                <div><span>{item.announcementTitle}</span></div>
                                                <div><span>{KSTformatDate(item.announcementRegDate)}</span></div>
                                            </div>
                                        ))
                                        :
                                        <div className={`${event.event_notice_announce_no_item_box}`}><span>두구두구두구! 당첨자 발표를 기다려 주세요🏆</span></div>
                                    }
                                </div>
                            }

                        </div>
                    </div>
                </div>
            }
            
        </div>
    );
};

export default EventSection;
