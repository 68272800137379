import React from 'react';
import pp from './popup.module.css';
import { useSpring, useTransition } from '@react-spring/core';
import { animated } from 'react-spring';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BottomPopup = ({ showBottomPopup, setShowBottomPopUp, popupData, onPopupLink }) => {
    const popupTransitions = useTransition(showBottomPopup, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translateY(100%)" },
        config: {
            duration: 200,
        },
    });

    const handleClose = () => {
        setShowBottomPopUp(false);
    };

    const handleTodayClose = () => {
        localStorage.setItem('bottomPopupClosed', new Date().getTime()); // 24시간 유효하게 설정
        setShowBottomPopUp(false);
    };

    return (
        <>
            {showBottomPopup && (
                <div className={pp.bottom_popup_container}>
                    {popupTransitions((style, item) =>
                        item && popupData ? (
                            <animated.div className={pp.bottom_popup_box} style={style}>
                                <div>
                                    <div className={pp.bottom_popup_header}>
                                        <div>
                                            <div>
                                                <button onClick={handleTodayClose}>오늘 하루 보지 않기</button>
                                            </div>
                                        </div>
                                        <div>
                                            <button onClick={handleClose}>
                                                <span className="material-symbols-outlined">close</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className={pp.bottom_popup_content} onClick={() => onPopupLink(popupData)}>
                                        <LazyLoadImage
                                            effect="blur"
                                            src={popupData.popupImg} 
                                            alt={popupData.popupTitle} 
                                        />
                                    </div>
                                </div>
                            </animated.div>
                        ) : null
                    )}
                </div>
            )}
        </>
    );
};

export default BottomPopup;
