import React, { useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import event from '../../../../containers/event/event.module.css';
import { Pagination } from 'antd';
import * as adminApi from '../../../../apis/admin';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination as SwiperPagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { CSSTransition } from 'react-transition-group';

const AdminParticipant = () => {
    const [eventList, setEventList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [voteTotalItems, setVoteTotalItems] = useState(0);
    const [commentTotalItems, setCommentTotalItems] = useState(0);
    const [eventInfo, setEventInfo] = useState({});
    const [ showParticipantModal, setShowParticipantModal ] = useState(false);
    const [ showVoteParticipantModal, setShowVoteParticipantModal ] = useState(false);
    const [ showCommentParticipantModal, setShowCommentParticipantModal ] = useState(false);
    const [ participantList, setParticipantList ] = useState([]);
    const [ voteParticipantList, setVoteParticipantList ] = useState([]);
    const [ commentParticipantList, setCommentParticipantList ] = useState([]);
    const [ listImage, setListImage ] = useState([]);
    const [ activePaticipants, setActiveParicipants ] = useState('');
    const [ voteList, setVoteList ] = useState([]);
    const [ commentList, setCommentList ] = useState([]);
    const [bestComment, setBestComment] = useState({});
    const [ showVoteParticipateToggle, setShowVotParticipateToggle ] = useState(false);
    const [replyToCommentId, setReplyToCommentId] = useState(null);
    const [showBestReplyComment, setShowBestReplyComment] = useState(false);

    useEffect(() => {
        eventSelect();
    }, []);

    const eventSelect = async () => {
        const response = await adminApi.eventSelect();
        const data = response.data;

        // console.log('eventData : ', data);

        const luckedList = data.filter(event => event.eventType === 'lucked');
        const voteList = data.filter(event => event.eventType === 'vote');
        const commentList = data.filter(event => event.eventType === 'comment');

        // console.log('lucked : ', luckedList);

        let newData = luckedList.map(item => ({
            eventNumber: item.eventNumber,
            eventTitle: item.eventTitle,
            eventSubTitle: item.eventSubTitle,
            eventStartDate: item.eventStartDate,
            eventEndDate: item.eventEndDate,
            eventContent: item.eventContent,
            eventImage1: item.eventImage1,
            eventImage2: item.eventImage2,
            eventImage3: item.eventImage3,
            eventImage4: item.eventImage4,
            eventImage5: item.eventImage5,
            eventNo: item.eventNo,
            eventPrice: item.eventPrice,
            eventRegDate: item.eventRegDate,
            eventSubTitle: item.eventSubTitle,
            eventType: item.eventType,
            eventUpdDate: item.eventUpdDate,
            eventWinnerDate: item.eventWinnerDate,
            planProductRate: item.planProductRate,
            planProductSelect: item.planProductSelect,
            planType: item.planType,
            selectProduct: item.selectProduct,
            userId: item.userId,
            codeType : item.codeType,
            commentSecretState : item.commentSecretState,
            voteCnt: item.voteCnt,
            voteItems : item.voteItems,
            voteType : item.voteType,
            eventParticipant: 0 // 초기값 설정
        }));
        let voteData = voteList.map(item => ({
            eventNumber: item.eventNumber,
            eventTitle: item.eventTitle,
            eventSubTitle: item.eventSubTitle,
            eventStartDate: item.eventStartDate,
            eventEndDate: item.eventEndDate,
            eventContent: item.eventContent,
            eventImage1: item.eventImage1,
            eventImage2: item.eventImage2,
            eventImage3: item.eventImage3,
            eventImage4: item.eventImage4,
            eventImage5: item.eventImage5,
            eventNo: item.eventNo,
            eventPrice: item.eventPrice,
            eventRegDate: item.eventRegDate,
            eventSubTitle: item.eventSubTitle,
            eventType: item.eventType,
            eventUpdDate: item.eventUpdDate,
            eventWinnerDate: item.eventWinnerDate,
            planProductRate: item.planProductRate,
            planProductSelect: item.planProductSelect,
            planType: item.planType,
            selectProduct: item.selectProduct,
            userId: item.userId,
            codeType : item.codeType,
            commentSecretState : item.commentSecretState,
            voteCnt: item.voteCnt,
            voteItems : item.voteItems,
            voteType : item.voteType,
            eventParticipant: 0 // 초기값 설정
        }));
        let commentData = commentList.map(item => ({
            eventNumber: item.eventNumber,
            eventTitle: item.eventTitle,
            eventSubTitle: item.eventSubTitle,
            eventStartDate: item.eventStartDate,
            eventEndDate: item.eventEndDate,
            eventContent: item.eventContent,
            eventImage1: item.eventImage1,
            eventImage2: item.eventImage2,
            eventImage3: item.eventImage3,
            eventImage4: item.eventImage4,
            eventImage5: item.eventImage5,
            eventNo: item.eventNo,
            eventPrice: item.eventPrice,
            eventRegDate: item.eventRegDate,
            eventSubTitle: item.eventSubTitle,
            eventType: item.eventType,
            eventUpdDate: item.eventUpdDate,
            eventWinnerDate: item.eventWinnerDate,
            planProductRate: item.planProductRate,
            planProductSelect: item.planProductSelect,
            planType: item.planType,
            selectProduct: item.selectProduct,
            userId: item.userId,
            codeType : item.codeType,
            commentSecretState : item.commentSecretState,
            voteCnt: item.voteCnt,
            voteItems : item.voteItems,
            voteType : item.voteType,
            eventParticipant: 0 // 초기값 설정
        }));

        const eventNumbers = newData.map(item => item.eventNumber);
        const voteEventNumbers = voteData.map(item => item.eventNumber);
        const commentEventNumbers = commentData.map(item => item.eventNumber);

        const response2 = await adminApi.participantSelect(eventNumbers);
        const data2 = response2.data;

        const voteResponse = await adminApi.voteParticipantSelect(voteEventNumbers);
        const voteDatas = voteResponse.data;

        const commentResponse = await adminApi.commentParticipantSelect(commentEventNumbers);
        const commnetDatas = commentResponse.data;

        // console.log('lucked participant : ', data2);
        // console.log('vote participant : ', voteDatas);
        // console.log('comment participant : ', commnetDatas);

        newData = newData.map((item, index) => ({
            ...item,
            eventParticipant: data2[index]
        }));
        voteData = voteData.map((item, index) => ({
            ...item,
            eventParticipant: voteDatas[index]
        }));
        commentData = commentData.map((item, index) => ({
            ...item,
            eventParticipant: commnetDatas[index]
        }));

        // console.log('newData : ', newData);
        // console.log('voteData : ', voteData);
        // console.log('commentData : ', commentData);

        setEventList(newData);
        setVoteList(voteData);
        setCommentList(commentData);

        setTotalItems(newData.length);
        setVoteTotalItems(voteData.length);
        setCommentTotalItems(commentData.length);
    };

    const formatDate = (dateString) => {
        return dateString.replace('T', ' ').substring(0, 19);
    };

    const handleVoteParticipateToggle = () => {
        setShowVotParticipateToggle(!showVoteParticipateToggle);
    }

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const displayedEvents = eventList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const voteEvents = voteList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const commnetEvents = commentList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const onEventSelect = async (item) => {
        setEventInfo(item);
        setShowParticipantModal(true);
        
        const response = await adminApi.eventParticipantSelect(item.eventNumber);
        const data = response.data;

        // console.log('list : ', data);
        setParticipantList(data);
    }
    const onVoteEventSelect = async (item) => {
        setEventInfo(item);
        setShowVoteParticipantModal(true);
        
        const response = await adminApi.eventVoteParticipantSelect(item.eventNumber);
        const data = response.data;

        // console.log('vote list : ', data);

        // 중복 제거 및 투표 수 계산
        const processedVoteList = data.reduce((acc, current) => {
            const existingIndex = acc.findIndex(
                (item) => item.userId === current.userId && item.voteText === current.voteText
            );

            if (existingIndex >= 0) {
                // 이미 존재하는 항목일 경우 투표수를 증가시킴
                acc[existingIndex].voteCount += 1;
            } else {
                // 새로운 항목일 경우 추가하고 투표수는 1로 초기화
                acc.push({
                    ...current,
                    voteCount: 1,
                });
            }

            return acc;
        }, []);

        // console.log('processedVoteList : ', processedVoteList);

        setVoteParticipantList(processedVoteList);
    }
    const onCommentEventSelect = async (item) => {
        setEventInfo(item);
        setShowCommentParticipantModal(true);
        
        const response = await adminApi.eventCommentParticipantSelect(item.eventNumber);
        const data = response.data;

        // console.log('comment list : ', data);
        setCommentParticipantList(data);
        
    
        // likeView가 가장 높은 값을 찾고, 동일하면 regDate가 빠른 순으로 정렬
        const best = data
            .filter(comment => comment.likeView > 0) // likeView가 0보다 큰 것만 필터링
            .sort((a, b) => {
                if (b.likeView !== a.likeView) {
                    return b.likeView - a.likeView; // likeView가 큰 순서대로 정렬
                }
                return new Date(a.regDate) - new Date(b.regDate); // 같은 경우 regDate가 빠른 순
            })[0]; // 가장 첫 번째 값 선택
    
        if (best) {
            // bestComment와 관련된 대댓글 가져오기
            const bestCommentReplies = data.filter(
                comment => comment.parentNo === best.commentNo
            );
    
            setBestComment({
                ...best,
                replies: bestCommentReplies, // 대댓글 추가
            });
        } else {
            setBestComment(null); // likeView가 모두 0이면 null로 설정
        }

    }

    useEffect(() => {
        // console.log('eventInfo : ', eventInfo);
        if (eventInfo) {
            const images = [
                eventInfo.eventImage1,
                eventInfo.eventImage2,
                eventInfo.eventImage3,
                eventInfo.eventImage4,
                eventInfo.eventImage5,
            ].filter(image => image !== null && image !== '');

            setListImage(images);
        }
    }, [eventInfo]);

    const onPaticipantsTap = (active) => {
        setActiveParicipants(active);
    }
    
    const toggleReplyInput = (commentId) => {
        setReplyToCommentId((prevId) => (prevId === commentId ? null : commentId));
    };

    const handleBestReplyToggle = () => {
        setShowBestReplyComment(!showBestReplyComment);
    }

    return (
        <div className={`${admin.admin_event_winner_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_event_participants_title}`}><span>참여자 목록</span></div>
                <div className={`${admin.admin_event_participants_tab}`}>
                    <div><button className={`${activePaticipants === 'vote' ? admin.admin_event_participants_active : ''}`} onClick={() => onPaticipantsTap('vote')}>투표({voteList.length || 0})</button></div>
                    <div><button className={`${activePaticipants === 'lucked' ? admin.admin_event_participants_active : ''}`} onClick={() => onPaticipantsTap('lucked')}>럭드({eventList.length || 0})</button></div>
                    <div><button className={`${activePaticipants === 'comment' ? admin.admin_event_participants_active : ''}`} onClick={() => onPaticipantsTap('comment')}>댓글({commentList.length || 0})</button></div>
                </div>
                <div className={`py-3 ${admin.admin_event_winner_add_btn}`}>
                    {/* <div><button>삭제</button></div> */}
                </div>
                {
                    activePaticipants !== '' &&
                    <div className={`${admin.admin_event_participants_container}`}>
                        <div>
                            <div className={`${admin.admin_event_participants_header}`}>
                                <div><input type="checkbox" name="" id="" /></div>
                                <div><span>번호</span></div>
                                <div><span>이미지</span></div>
                                <div><span>이벤트 제목</span></div>
                                <div><span>이벤트 기간</span></div>
                                <div><span>당첨자 발표일</span></div>
                                <div><span>참여자 수</span></div>
                            </div>
                            {
                                activePaticipants === 'vote' &&
                                voteEvents.map((item, index) => (
                                    <div className={`${admin.admin_event_participants_content}`} key={index} onClick={() => onVoteEventSelect(item)}>
                                        <div><input type="checkbox" name="" id="" /></div>
                                        <div><span>{(currentPage - 1) * itemsPerPage + index + 1}</span></div>
                                        <div><img src={item.eventImage1} alt="" /></div>
                                        <div><span>{item.eventTitle}</span></div>
                                        <div>
                                            <div><span>{formatDate(item.eventStartDate)}</span></div>
                                            <div><span>~</span></div>
                                            <div><span>{formatDate(item.eventEndDate)}</span></div>
                                        </div>
                                        <div><span>{formatDate(item.eventWinnerDate)}</span></div>
                                        <div><span>{item.eventParticipant}</span></div>
                                    </div>
                                ))
                            }
                            {
                                activePaticipants === 'lucked' &&
                                displayedEvents.map((item, index) => (
                                    <div className={`${admin.admin_event_participants_content}`} key={index} onClick={() => onEventSelect(item)}>
                                        <div><input type="checkbox" name="" id="" /></div>
                                        <div><span>{(currentPage - 1) * itemsPerPage + index + 1}</span></div>
                                        <div><img src={item.eventImage1} alt="" /></div>
                                        <div><span>{item.eventTitle}</span></div>
                                        <div>
                                            <div><span>{formatDate(item.eventStartDate)}</span></div>
                                            <div><span>~</span></div>
                                            <div><span>{formatDate(item.eventEndDate)}</span></div>
                                        </div>
                                        <div><span>{formatDate(item.eventWinnerDate)}</span></div>
                                        <div><span>{item.eventParticipant}</span></div>
                                    </div>
                                ))
                            }
                            {
                                activePaticipants === 'comment' &&
                                commnetEvents.map((item, index) => (
                                    <div className={`${admin.admin_event_participants_content}`} key={index} onClick={() => onCommentEventSelect(item)}>
                                        <div><input type="checkbox" name="" id="" /></div>
                                        <div><span>{(currentPage - 1) * itemsPerPage + index + 1}</span></div>
                                        <div><img src={item.eventImage1} alt="" /></div>
                                        <div><span>{item.eventTitle}</span></div>
                                        <div>
                                            <div><span>{formatDate(item.eventStartDate)}</span></div>
                                            <div><span>~</span></div>
                                            <div><span>{formatDate(item.eventEndDate)}</span></div>
                                        </div>
                                        <div><span>{formatDate(item.eventWinnerDate)}</span></div>
                                        <div><span>{item.eventParticipant}</span></div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
                <Pagination
                    current={currentPage}
                    total={totalItems}
                    onChange={paginate}
                    pageSize={itemsPerPage}
                    showSizeChanger={false}
                />
            </div>

            {
                showParticipantModal &&
                <div className={`${admin.admin_participants_modal_container}`}>
                    <div className={`${admin.admin_participants_modal_box}`}>
                        <div className={`${admin.admin_participants_modal_header}`}>
                            <div><span>럭드 이벤트 참여자 목록</span></div>
                            <div><button onClick={() => setShowParticipantModal(false)}><span class="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_participants_modal_event_box}`}>
                            <div className={`${admin.admin_participants_modal_event_info}`}>
                                <div>
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={30}
                                        loop={true} // 루프 활성화
                                        autoplay={{
                                            delay: 2000, // 2초마다 자동 재생
                                            disableOnInteraction: false, // 사용자의 스와이프 후에도 자동 재생을 계속 작동하도록 설정
                                        }}
                                        modules={[SwiperPagination, Navigation, Autoplay]} // Autoplay 모듈 추가
                                        className={`${admin.admin_participants_swiper}`}
                                        >
                                        {
                                            listImage.map((item, index) => (
                                            <SwiperSlide key={index}><img src={item} alt="" /></SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                                <div className='px-2'>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>이벤트 제목</span></div>
                                        <div><span>{eventInfo.eventTitle}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>이벤트 금액</span></div>
                                        <div><span>{eventInfo.eventPrice}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>이벤트 기간</span></div>
                                        <div><span>{formatDate(eventInfo.eventStartDate)}</span><span>&nbsp;~&nbsp;</span><span>{formatDate(eventInfo.eventEndDate)}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>당첨자 발표일</span></div>
                                        <div><span>{formatDate(eventInfo.eventWinnerDate)}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail_content}`}>
                                        <div><span>이벤트 내용</span></div>
                                        <div dangerouslySetInnerHTML={{ __html: eventInfo.eventContent}}></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail_btn}`}><button>상세보기</button></div>
                                </div>
                            </div>
                            <div className={`${admin.admin_participant_list}`}>
                                <div><span>참여인원 </span><span>{participantList.length}명</span></div>
                                {
                                    participantList.length > 0 ?
                                    <div>
                                        <div>
                                            <div className={`${admin.admin_participant_list_header}`}>
                                                <div><span>참여순서</span></div>
                                                <div><span>아이디</span></div>
                                                <div><span>참여이벤트</span></div>
                                                <div><span>결제금액</span></div>
                                                <div><span>결제방식</span></div>
                                                <div><span>배송정보</span></div>
                                                <div><span>참여시간</span></div>
                                            </div>
                                            {
                                                participantList.map((item, index) => (
                                                    <div className={`${admin.admin_participant_list_content}`}>
                                                        <div><span>{index+1}</span></div>
                                                        <div><span>{item.userId}</span></div>
                                                        <div><span>{item.paymentProductName}</span></div>
                                                        <div><span>{item.amount}</span></div>
                                                        <div><span>{item.paymentType}</span></div>
                                                        <div>
                                                            <div><span>{item.deliveryUser}</span></div>
                                                            <div><span>{item.deliveryPhone}</span></div>
                                                            <div><span>{item.deliveryAddress}</span></div>
                                                        </div>
                                                        <div><span>{formatDate(item.epRegDate)}</span></div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={`${admin.admin_participant_list_no_data}`}><span>참여자가 없습니다.</span></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                showVoteParticipantModal &&
                <div className={`${admin.admin_participants_modal_container}`}>
                    <div className={`${admin.admin_participants_modal_box}`}>
                        <div className={`${admin.admin_participants_modal_header}`}>
                            <div><span>투표 이벤트 참여자 목록</span></div>
                            <div><button onClick={() => setShowVoteParticipantModal(false)}><span class="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_participants_modal_event_box}`}>
                            <div className={`${admin.admin_participants_modal_event_info}`}>
                                <div>
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={30}
                                        loop={true} // 루프 활성화
                                        autoplay={{
                                            delay: 2000, // 2초마다 자동 재생
                                            disableOnInteraction: false, // 사용자의 스와이프 후에도 자동 재생을 계속 작동하도록 설정
                                        }}
                                        modules={[SwiperPagination, Navigation, Autoplay]} // Autoplay 모듈 추가
                                        className={`${admin.admin_participants_swiper}`}
                                        >
                                        {
                                            listImage.map((item, index) => (
                                            <SwiperSlide key={index}><img src={item} alt="" /></SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                                <div className='px-2'>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>이벤트 제목</span></div>
                                        <div><span>{eventInfo.eventTitle}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>이벤트 금액</span></div>
                                        <div><span>{eventInfo.eventPrice}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>이벤트 기간</span></div>
                                        <div><span>{formatDate(eventInfo.eventStartDate)}</span><span>&nbsp;~&nbsp;</span><span>{formatDate(eventInfo.eventEndDate)}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>당첨자 발표일</span></div>
                                        <div><span>{formatDate(eventInfo.eventWinnerDate)}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail_content}`}>
                                        <div><span>이벤트 내용</span></div>
                                        <div dangerouslySetInnerHTML={{ __html: eventInfo.eventContent}}></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail_btn}`}><button>상세보기</button></div>
                                </div>
                            </div>
                            <div className={`${event.event_vote_participate_box} border-top mt-3 border-bottom`}>
                                <button className={`${event.event_vote_participate_box_drop_btn}`} onClick={() => handleVoteParticipateToggle()}>
                                    <div><span>투표결과</span></div>
                                    <div><span className="material-symbols-outlined">{showVoteParticipateToggle ? `arrow_drop_up` : `arrow_drop_down`}</span></div>
                                </button>
                                    <CSSTransition in={showVoteParticipateToggle} timeout={300} classNames="review" unmountOnExit>
                                        <div>
                                        {
                                            showVoteParticipateToggle &&
                                            eventInfo &&
                                            eventInfo.voteItems &&
                                            eventInfo.voteItems.map((item, index) => {
                                                // 해당 항목에 대한 투표 수 계산
                                                const voteCount = voteParticipantList ? voteParticipantList.filter(
                                                    (vote) => vote.voteText === item.voteText
                                                ).length : 0;

                                                // 총 투표 수 계산
                                                const totalVotes = voteParticipantList.length || 0;

                                                // 비율 계산 (소수점 2자리)
                                                const percentage = totalVotes > 0 ? ((voteCount / totalVotes) * 100).toFixed(2) : 0;

                                                return (
                                                    <div key={index} className={`${event.event_vote_participate_box_item}`}>
                                                        <div>
                                                            <img src={`${item.voteImage}`} alt={item.voteText} />
                                                            <div>
                                                                <div>
                                                                    <span>{item.voteText}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={`${event.vote_bar_container}`}>
                                                                <div 
                                                                    className={`${event.vote_bar}`} 
                                                                    style={{ width: `${percentage}%` }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div><span>{percentage}%</span></div>
                                                            <div><span>({voteCount}표)</span></div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                        </div>
                                    </CSSTransition>
                            </div>
                            <div className={`${admin.admin_participant_list}`}>
                                <div><span>참여인원 </span><span>{voteParticipantList.length}명</span></div>
                                {
                                    voteParticipantList.length > 0 ?
                                    <div>
                                        <div>
                                            <div className={`${admin.admin_vote_participant_list_header}`}>
                                                <div><span>참여순서</span></div>
                                                <div><span>아이디</span></div>
                                                <div><span>투표이미지</span></div>
                                                <div><span>투표</span></div>
                                                <div><span>투표수</span></div>
                                                <div><span>참여시간</span></div>
                                            </div>
                                            {
                                                voteParticipantList.map((item, index) => {
                                                    return (
                                                        <div className={`${admin.admin_vote_participant_list_content}`}>
                                                            <div><span>{index+1}</span></div>
                                                            <div><span>{item.userId}</span></div>
                                                            <div><img src={item.voteImage} alt="이미지" /></div>
                                                            <div>
                                                                <div><span>{item.voteText}</span></div>
                                                                <div><span>({item.voteSubText})</span></div>
                                                            </div>
                                                            <div><span>{item.voteCount}</span></div>
                                                            <div><span>{formatDate(item.regDate)}</span></div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={`${admin.admin_participant_list_no_data}`}><span>참여자가 없습니다.</span></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                showCommentParticipantModal &&
                <div className={`${admin.admin_participants_modal_container}`}>
                    <div className={`${admin.admin_participants_modal_box}`}>
                        <div className={`${admin.admin_participants_modal_header}`}>
                            <div><span>댓글 이벤트 참여자 목록</span></div>
                            <div><button onClick={() => setShowCommentParticipantModal(false)}><span class="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_participants_modal_event_box}`}>
                            <div className={`${admin.admin_participants_modal_event_info}`}>
                                <div>
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={30}
                                        loop={true} // 루프 활성화
                                        autoplay={{
                                            delay: 2000, // 2초마다 자동 재생
                                            disableOnInteraction: false, // 사용자의 스와이프 후에도 자동 재생을 계속 작동하도록 설정
                                        }}
                                        modules={[SwiperPagination, Navigation, Autoplay]} // Autoplay 모듈 추가
                                        className={`${admin.admin_participants_swiper}`}
                                        >
                                        {
                                            listImage.map((item, index) => (
                                            <SwiperSlide key={index}><img src={item} alt="" /></SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                                <div className='px-2'>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>이벤트 제목</span></div>
                                        <div><span>{eventInfo.eventTitle}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>이벤트 금액</span></div>
                                        <div><span>{eventInfo.eventPrice}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>이벤트 기간</span></div>
                                        <div><span>{formatDate(eventInfo.eventStartDate)}</span><span>&nbsp;~&nbsp;</span><span>{formatDate(eventInfo.eventEndDate)}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>당첨자 발표일</span></div>
                                        <div><span>{formatDate(eventInfo.eventWinnerDate)}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail_content}`}>
                                        <div><span>이벤트 내용</span></div>
                                        <div dangerouslySetInnerHTML={{ __html: eventInfo.eventContent}}></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail_btn}`}><button>상세보기</button></div>
                                </div>
                            </div>
                            <div className={`${admin.admin_participant_list}`}>
                                <div><span>참여인원 </span><span>{commentParticipantList.length}명</span></div>
                                {
                                    commentParticipantList.length > 0 ?
                                    <div>
                                        <div>
                                            {/* <div className={`${admin.admin_participant_list_header}`}>
                                                <div><span>참여순서</span></div>
                                                <div><span>아이디</span></div>
                                                <div><span>참여이벤트</span></div>
                                                <div><span>결제금액</span></div>
                                                <div><span>결제방식</span></div>
                                                <div><span>배송정보</span></div>
                                                <div><span>참여시간</span></div>
                                            </div> */}
                                            {/* {
                                                commentParticipantList.map((item, index) => (
                                                    <div className={`${admin.admin_participant_list_content}`}>
                                                        <div><span>{index+1}</span></div>
                                                        <div><span>{item.userId}</span></div>
                                                        <div><span>{item.paymentProductName}</span></div>
                                                        <div><span>{item.amount}</span></div>
                                                        <div><span>{item.paymentType}</span></div>
                                                        <div>
                                                            <div><span>{item.deliveryUser}</span></div>
                                                            <div><span>{item.deliveryPhone}</span></div>
                                                            <div><span>{item.deliveryAddress}</span></div>
                                                        </div>
                                                        <div><span>{formatDate(item.regDate)}</span></div>
                                                    </div>
                                                ))
                                            } */}
                                            {
                                                <div className={`${admin.admin_comment_modal_container}`}>
                                                    <div className={`${event.event_comment_modal_box}`}>
                                                        {/* <div className={`${event.event_comment_modal_header}`}>
                                                            <div><span>댓글</span><span>{commentList.length}</span></div>
                                                        </div> */}
                                                        <div className={`${event.event_comment_modal_content}`}>
                                                            {bestComment && (
                                                                <div className={`${event.event_comment_modal_best_content_info} ${event.best_comment}`}>
                                                                    <div className={`${event.event_comment_modal_best_content_ribbon}`}><span>BEST</span></div>
                                                                    <div className={`${event.event_comment_modal_best_content_top}`}>
                                                                        <div className={`${event.event_comment_modal_content_info_img}`}>
                                                                            <div>
                                                                                <img
                                                                                    src={
                                                                                        bestComment.user.userProfile
                                                                                            ? `${process.env.REACT_APP_API_SERVER}/img?file=${bestComment.user.userProfile}`
                                                                                            : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className={`${event.event_comment_modal_content_info_data}`}>
                                                                            <div>
                                                                                <span>{bestComment.user.userNick}</span>
                                                                                <span>{formatDate(bestComment.regDate)}</span>
                                                                            </div>
                                                                            <div>
                                                                                {bestComment.secretState ? (
                                                                                    <span>🤫비밀 댓글 입니다.</span>
                                                                                ) : (
                                                                                    <span>{bestComment.commentText}</span>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                
                                                                                <div>
                                                                                    <button>
                                                                                        <span className="material-symbols-outlined">thumb_up</span>
                                                                                    </button>
                                                                                    <span>{bestComment.likeView}</span>
                                                                                </div>
                                                                                <div>
                                                                                    <button onClick={() => handleBestReplyToggle()}>
                                                                                        <span className="material-symbols-outlined">comment</span>
                                                                                    </button>
                                                                                    <span>
                                                                                        {bestComment.replies.length}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {showBestReplyComment && bestComment.replies && bestComment.replies.length > 0 && (
                                                                        <div className={`${event.event_comment_modal_reply_box}`}>
                                                                            {bestComment.replies.map(reply => (
                                                                                <div
                                                                                    key={reply.commentNo}
                                                                                    className={`${event.event_comment_modal_reply_info} ${event.reply}`}
                                                                                >
                                                                                    <div className={`${event.event_comment_modal_reply_info_img}`}>
                                                                                        <div>
                                                                                            <img
                                                                                                src={
                                                                                                    reply.user.userProfile
                                                                                                        ? `${process.env.REACT_APP_API_SERVER}/img?file=${reply.user.userProfile}`
                                                                                                        : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`
                                                                                                }
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={`${event.event_comment_modal_reply_info_data}`}>
                                                                                        <div>
                                                                                            <span>{reply.user.userNick}</span>
                                                                                            <span>{formatDate(reply.regDate)}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            {reply.secretState ? (
                                                                                                <span>🤫비밀 댓글 입니다.</span>
                                                                                            ) : (
                                                                                                <span>{reply.commentText}</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {commentParticipantList.length > 0 ? (
                                                                commentParticipantList
                                                                    .filter((comment) => comment.parentNo === null) // 일반 댓글만 필터링
                                                                    .map((item) => {
                                                                        const userProfile = item.user.userProfile
                                                                            ? `${process.env.REACT_APP_API_SERVER}/img?file=${item.user.userProfile}`
                                                                            : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`;

                                                                        return (
                                                                            <>
                                                                                <div key={item.commentNo} className={`${event.event_comment_modal_content_info}`}>
                                                                                    <div className={`${event.event_comment_modal_content_info_img}`}>
                                                                                        <div><img src={userProfile} alt="" /></div>
                                                                                    </div>
                                                                                    <div className={`${event.event_comment_modal_content_info_data}`}>
                                                                                        <div>
                                                                                            <span>{item.user.userNick}</span>
                                                                                            <span>{formatDate(item.regDate)}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                item.secretState ?
                                                                                                <span>🤫비밀 댓글 입니다.</span>
                                                                                                :
                                                                                                <span>{item.commentText}</span>
                                                                                            }
                                                                                        </div>
                                                                                        <div>
                                                                                            <div>
                                                                                                <button>
                                                                                                    <span className="material-symbols-outlined">thumb_up</span>
                                                                                                </button>
                                                                                                <span>{item.likeView}</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <button>
                                                                                                    <span className="material-symbols-outlined">thumb_down</span>
                                                                                                </button>
                                                                                            </div>
                                                                                            <div>
                                                                                                <button onClick={() => toggleReplyInput(item.commentNo)}>
                                                                                                    <span className="material-symbols-outlined">comment</span>
                                                                                                </button>
                                                                                                <span>
                                                                                                    {commentParticipantList.filter((reply) => reply.parentNo === item.commentNo).length}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={`${event.event_comment_modal_content_info_more}`}>
                                                                                        <button>
                                                                                            <span className="material-symbols-outlined">more_vert</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                                {/* 대댓글 리스트 */}
                                                                                {replyToCommentId === item.commentNo && commentParticipantList
                                                                                    .filter((reply) => reply.parentNo === item.commentNo)
                                                                                    .map((reply) => (
                                                                                        <div
                                                                                            key={reply.commentNo}
                                                                                            className={`${event.event_comment_modal_reply_info} ${event.reply}`}
                                                                                        >
                                                                                            <div className={`${event.event_comment_modal_reply_info_img}`}>
                                                                                                <div>
                                                                                                    <img
                                                                                                        src={
                                                                                                            reply.user.userProfile
                                                                                                                ? `${process.env.REACT_APP_API_SERVER}/img?file=${reply.user.userProfile}`
                                                                                                                : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`
                                                                                                        }
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className={`${event.event_comment_modal_reply_info_data}`}>
                                                                                                <div>
                                                                                                    <span>{reply.user.userNick}</span>
                                                                                                    <span>{formatDate(reply.regDate)}</span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    {
                                                                                                        reply.secretState ?
                                                                                                        <span>🤫비밀 댓글 입니다.</span>
                                                                                                        :
                                                                                                        <span>{reply.commentText}</span>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                            </>
                                                                        );
                                                                    })
                                                            ) : (
                                                                <div>
                                                                    <div>
                                                                        <span>등록된 이벤트 댓글이 없습니다😓</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={`${admin.admin_participant_list_no_data}`}><span>참여자가 없습니다.</span></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AdminParticipant;
