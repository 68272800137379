import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ko';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';
import ss from '../../../../containers/market/support/venue/support_shop.module.css';
import * as admin from '../../../../apis/admin';
import market from '../../../../containers/market/market.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import VenueProductMap from './VenueProductMap';

moment.locale('ko');
const localizer = momentLocalizer(moment);

const CustomToolbar = (toolbar) => {
    const goToBack = () => {
        toolbar.onNavigate('PREV');
    };

    const goToNext = () => {
        toolbar.onNavigate('NEXT');
    };

    return (
        <div className={`custom-toolbar ${ss.calendar_header}`}>
            <div>
                <span className="material-symbols-outlined" onClick={goToBack}>
                    arrow_back_ios
                </span>
            </div>
            <div>
                <span className="date-label">
                    {moment(toolbar.date).format('YYYY년 MMMM')}
                </span>
            </div>
            <div>
                <span className="material-symbols-outlined" onClick={goToNext}>
                    arrow_forward_ios
                </span>
            </div>
        </div>
    );
};

const VenueProductExp = ({ highestCount, Wrap2, Stars, ARRAY, clicked, ratings, exchangeReturn, sellerUser, 
    scrollLeft, sliderRef, scrollRight, content, operatingList = [], holidays = [], temporarys = [], averageRatingDecimal, venuInfo }) => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [showDateCalendar, setShowDateCalendar] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTemporary, setSelectedTemporary] = useState(null);
    const [recomandItem, setRecomandItem] = useState([]);
    const containerRef = useRef(null);
    const scrollPosition = useRef(0);
    const isInitialRender = useRef(true);
    // 로드 상태 관리
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        recomandSelect();
    }, []);


    const recomandSelect = async () => {
        try {
            const response = await admin.recomandProductSelect();
            const data = response.data;

            if (response.status === 200) {
                if (Array.isArray(data)) {
                    const sortedData = data.sort((a, b) => {
                        return a.recomandExposureOrder - b.recomandExposureOrder;
                    });

                    const limitedData = sortedData.slice(0, 10);

                    setRecomandItem(limitedData);
                } else {
                    setRecomandItem([]);
                    console.warn('Data is not an array:', data);
                }
            }
        } catch (error) {
            console.error('Error in recomandSelect:', error);
            setRecomandItem([]);
        }
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleDateToggle = () => {
        setShowDateCalendar(!showDateCalendar);
    }

    const handleOpenModal = (temporary) => {
        setSelectedTemporary(temporary);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedTemporary(null);
    };

    const moreBtn = () => {
        setShowMore(!showMore);
    };

    // 정기휴무일 이벤트 생성
    const holidayEvents = holidays.map(holiday => ({
        title: '정기휴무',
        start: new Date(holiday.holidayDate),
        end: new Date(holiday.holidayDate),
        allDay: true,
        type: 'holiday'
    }));

    // 임시 이벤트 생성
    const temporaryEvents = [
        ...temporarys.map(temp => ({
            title: temp.temporaryTitle,
            start: new Date(temp.temporaryDate),
            end: new Date(temp.temporaryEndDate),
            allDay: true,
            type: 'birthday',
            resource: temp
        }))
    ];

    const events = [...holidayEvents, ...temporaryEvents];

    const eventStyleGetter = (event) => {
        let className = '';

        if (event.type === 'holiday') {
            className = ss.holidayEvent;
        } else if (event.type === 'birthday') {
            className = ss.birthdayEvent;
        }

        return { className };
    };

    const formats = {
        dateFormat: 'D',
        dayFormat: (date, culture, localizer) =>
            localizer.format(date, 'ddd', culture),
        weekdayFormat: (date, culture, localizer) =>
            localizer.format(date, 'dddd', culture),
        monthHeaderFormat: (date, culture, localizer) =>
            localizer.format(date, 'YYYY년 MMMM', culture),
    };

    const messages = {
        allDay: '종일',
        previous: '이전',
        next: '다음',
        today: '오늘',
        month: '월',
        week: '주',
        day: '일',
        agenda: '일정',
        date: '날짜',
        time: '시간',
        event: '이벤트',
        noEventsInRange: '이 범위에 이벤트가 없습니다.',
        showMore: total => `+ ${total} 더 보기`,
    };

    const onReviewStore = () => {
        navigate(`/market/suggest/product`);
    }

    const handleProductClick = (path) => {
        // containerRef.current.scrollTop을 사용하여 현재 스크롤 위치를 sessionStorage에 저장
        if (containerRef.current) {
            const scrollPosition = containerRef.current.scrollTop;
            // console.log('containerRef scrollPosition : ', scrollPosition);
            sessionStorage.setItem('scrollPosition', scrollPosition);
            navigate(path);
        }
    };

    return (
        <div className={ss.venue_product_exp}>
            {
                venuInfo && venuInfo?.infoTapState === false &&
                <>
                    <div className="px-4">
                        <div className={`${ss.venue_schedule_box}`} onClick={handleToggle}>
                            <div>
                                <span className="material-symbols-outlined">schedule</span>
                            </div>
                            <div className={`time_text_box content ${isOpen ? "open" : ""}`}>
                                {operatingList.map((item, index) => (
                                    <div className='pb-3 pt-1' key={index}>
                                        <span className='pe-3'>{item.dayName}</span>
                                        {item.holidayState ? (
                                            <span className='system_error'>정기 휴무</span>
                                        ) : (
                                            <>
                                                <span>{item.openTime}</span>
                                                <span> ~ </span>
                                                <span>{item.closeTime}</span>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div>
                                <span className="material-symbols-outlined">{isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>
                            </div>
                        </div>
                        <div className={`${ss.venue_address_box}`}>
                            <div>
                                <span className="material-symbols-outlined">location_on</span>
                            </div>
                            <div>{sellerUser && sellerUser.businessLocation ? sellerUser.businessLocation : '주소값 미지정'}</div>
                            <div></div>
                        </div>
                        {/* 일정 달력 */}
                        <div>
                            <div className={`${ss.venue_date_calendar_box}`} onClick={handleDateToggle}>
                                <div><span className="material-symbols-outlined">calendar_month</span></div>
                                <div><span>달력</span></div>
                                <div>
                                    <span className="material-symbols-outlined">{showDateCalendar ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>
                                </div>
                            </div>
                            <CSSTransition in={showDateCalendar} timeout={300} classNames="review" unmountOnExit>
                                <div className='py-3'>
                                    <BigCalendar
                                        localizer={localizer}
                                        events={events}
                                        startAccessor="start"
                                        endAccessor="end"
                                        style={{ height: 500 }}
                                        eventPropGetter={eventStyleGetter}
                                        onSelectEvent={event => handleOpenModal(event.resource)}
                                        formats={formats}
                                        messages={messages}
                                        views={['month']} // Only show the month view
                                        components={{ toolbar: CustomToolbar }}
                                    />
                                </div>
                            </CSSTransition>
                        </div>
                    </div>
                    <div className="bottom_line pt-3"></div>
                </>
            }
            <div className="bottom_line pt-3"></div>
            <div className='px-4'>
                <div className={`venus_detail_box pt-4 ${showMore ? 'venus_detail_more_active' : ''}`}>
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    <div className={`${ss.venue_exchange_return_content}`} dangerouslySetInnerHTML={{ __html: exchangeReturn }}></div>
                    <VenueProductMap venueDetail={venuInfo} ss={ss}/>
                </div>
                <div>
                    <button className='venus_detail_more_btn' onClick={() => moreBtn()}>{showMore ? '접기' : '더보기'}<span className="material-symbols-outlined">{showMore ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span></button>
                </div>
                <div className='ratio_box d-flex justify-content-between mt-3'>
                    <div className='venue_ratio_box text-center'>
                        <div className='fs-3'>{averageRatingDecimal !== 'NaN' ? averageRatingDecimal : 0.0}</div>
                        <div className='magin-0a'>
                            <Wrap2 className='ps-2'>
                                <Stars>
                                    {ARRAY.map((el, idx) => {
                                        return (
                                            <FaStar
                                                key={idx}
                                                size="20"
                                                className={clicked[el] && 'yellowStar'}
                                            />
                                        );
                                    })}
                                </Stars>
                            </Wrap2>
                        </div>
                    </div>
                    <div className="vertical_line my-3"></div>
                    <div className='venue_ratio_graph'>
                        <table>
                            {Object.entries(ratings).sort((a, b) => b[0] - a[0]).map(([star, count]) => (
                                <tr key={star}>
                                <td width={'10%'} style={{ fontWeight: count === highestCount ? 'bold' : 'normal', color: count === highestCount ? 'black' : '#aaa' }}>{star}</td>
                                <td width={'80%'}>
                                    <div className="barBackground mt-2">
                                    <div className="bar" style={{ width: `${count * 10}%` }}></div>
                                    </div>
                                </td>
                                <td width={'10%'} style={{ fontWeight: count === highestCount ? 'bold' : 'normal', color: count === highestCount ? 'black' : '#aaa' }}>{count}</td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
            </div>
            <div className="bottom_line py-3"></div>
            <div className={`${market.market_main_popularity_product} mb-4`}>
                <div>
                    <button onClick={onReviewStore}>
                        <div><span>이런 상품 어때요?</span></div>
                        <div><span className="material-symbols-outlined">keyboard_arrow_right</span></div>
                    </button>
                </div>
                <div>
                    <Swiper
                        slidesPerView={2.5}
                        spaceBetween={0}
                        modules={[Pagination, Navigation]} // Autoplay 모듈 추가
                        className={`${market.market_main_popularity_swiper}`}
                    >
                        {recomandItem.map((item, index) => (
                            <SwiperSlide className={`${market.market_main_popularity_swiper_box}`} key={index}>
                                <div
                                    onClick={() => handleProductClick(`/store/product/${item.productNo}`)}
                                    className={`${market.market_main_popularity_box}`}
                                >
                                    <div><img src={item.productMainImage} alt="" /></div>
                                    <div>
                                        <div><span>{item.shopName}</span></div>
                                        <div><span>{item.productName}</span></div>
                                        <div>
                                            <div><span>&#8361;{item.productsalePrice.toLocaleString()}</span></div>
                                            {item.productsalePrice !== item.productPrice && (
                                                <div><span>&#8361;{item.productPrice.toLocaleString()}</span></div>
                                            )}
                                        </div>
                                        <div>
                                            {item.productBadge === 'freeDelivery' && <span className={market.freeDelivery}>무료배송</span>}
                                            {item.productBadge === 'md' && <span className={market.md}>MD추천</span>}
                                            {item.productBadge === 'best' && <span className={market.best}>BEST</span>}
                                            {item.productBadge === 'hot' && <span className={market.hot}>HOT</span>}
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Temporary Event Details"
                className={ss.modal}
                overlayClassName={ss.overlay}
            >
                {selectedTemporary && (
                    <div className={`${ss.temporary_modal_popup}`}>
                        <div className={`${ss.temporary_modal_close}`}><button onClick={handleCloseModal}><span className="material-symbols-outlined">close</span></button></div>
                        <div className={`${ss.temporary_modal_title}`}>
                            <span>{selectedTemporary.temporaryTitle}</span>
                        </div>
                        <div className={`${ss.temporary_modal_date}`}>
                            <div><span>{selectedTemporary.temporaryDate}</span></div>
                            <div>&nbsp;~&nbsp;</div>
                            <div><span>{selectedTemporary.temporaryEndDate}</span></div>
                        </div>
                        <div className={`${ss.temporary_modal_content}`}>
                            <div><span>내용</span></div>
                            <div>
                                {selectedTemporary.temporaryContent}
                            </div>
                        </div>
                        <div className={`${ss.temporary_modal_img}`}>
                            {
                                selectedTemporary.temporaryProfileImg === null ? null :
                                <div><img src={`http://localhost:8080/img?file=${selectedTemporary.temporaryProfileImg}`} alt="Event" /></div>
                            }
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
}

export default VenueProductExp;