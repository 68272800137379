import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as storeApi from '../../../apis/store';
import * as adminApi from '../../../apis/admin';
import store from '../../../containers/market/store/store.module.css';
import support from '../../../containers/market/support/venue/support_shop.module.css';

const StorePaymentPending = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [verificationResult, setVerificationResult] = useState(null);
    const [storePaymentInfo, setStorePaymentInfo] = useState({});
    const [kakaoBizTemplate, setKakaoBizTemplate] = useState(null);

    const MAX_RETRIES = 5; // 재시도 최대 횟수

    useEffect(() => {
        console.log('storePaymentInfo : ', storePaymentInfo);
    }, [storePaymentInfo]);

    // useEffect(() => {
    //     console.log('kakaoBizTemplate : ', kakaoBizTemplate);
    // }, [kakaoBizTemplate])

    // useEffect(() => {
    //     kakaoBizSelect();
    // }, []);

    // const kakaoBizSelect = async () => {
    //     const response = await adminApi.kakaoBizTemplateSelect();
    //     const data = response.data;

    //     console.log('kakaoBizSelect : ', data);

    //     const filterBizData = data.list.filter(item => item.templtCode === "TW_3324");

    //     setKakaoBizTemplate(filterBizData);

    // }

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const paymentKey = query.get('paymentKey');
        const orderId = query.get('orderId');
        const amount = query.get('amount');
        const storePayment = JSON.parse(localStorage.getItem('storePayment'));

        // console.log('storePayment : ', storePayment);



        // console.log('paymentKey : ', paymentKey);
        // console.log('orderId : ', orderId);
        // console.log('amount : ', amount);

        // const confirmPayment = async () => {
        //     try {
        //         // 결제 승인 요청
        //         const confirmResponse = await axios.post('/api/payment/confirm', {
        //             paymentKey: paymentKey,
        //             orderId: orderId,
        //             amount: amount,
        //         });

        //         console.log('결제 승인 성공:', confirmResponse.data);

        //         // 결제 승인 이후 검증 요청
        //         verifyPayment();
        //     } catch (error) {
        //         console.error('결제 승인 실패:', error);
        //         setVerificationResult({ error: '결제 승인이 실패했습니다.' });
        //         setLoading(false);
        //     }
        // };

        const verifyPayment = async (retryCount = 0) => {
            try {
                // console.log(`재시도 횟수: ${retryCount}`);
                const response = await axios.post('/api/payment/verify', {
                    paymentKey: paymentKey,
                    orderId: orderId,
                    amount: amount
                });
                setVerificationResult(response.data);
                // console.log('response.data : ', response.data);

                let updatedStorePaymentInfo = {};

                if(response.data.method === '가상계좌') {
                    updatedStorePaymentInfo = {
                        ...storePayment,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: amount,
                        paymentType : response.data.method,
                        paymentBankName : response.data.virtualAccount.bankCode,
                        paymentAccountNumber : response.data.virtualAccount.accountNumber,
                        paymentDepositDeadline : response.data.virtualAccount.dueDate
                    };
                } else if(response.data.method === '카드') {
                    updatedStorePaymentInfo = {
                        ...storePayment,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: amount,
                        currency : response.data.currency,
                        paymentType : response.data.method,
                        issuerCode : response.data.card.issuerCode
                    };
                } else if(response.data.method === '간편결제') {
                    updatedStorePaymentInfo = {
                        ...storePayment,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: amount,
                        currency : response.data.currency,
                        paymentType : response.data.method,
                        issuerCode: response.data.card && response.data.card.issuerCode ? response.data.card.issuerCode : '',
                        easyPay : response.data.easyPay.provider
                    };
                    
                } else if(response.data.method === '계좌이체') {
                    updatedStorePaymentInfo = {
                        ...storePayment,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        currency : response.data.currency,
                        amount: amount,
                        paymentType : response.data.method,
                        transferBankCode : response.data.transfer.bankCode
                    };
                    
                } else if(response.data.method === '해외간편결제') {
                    updatedStorePaymentInfo = {
                        ...storePayment,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: amount,
                        paymentType : response.data.method,
                        currency : response.data.currency,
                        easyPay : response.data.easyPay
                    };
                }

                // console.log('updatedStorePaymentInfo : ', updatedStorePaymentInfo);

        
                setStorePaymentInfo(updatedStorePaymentInfo);

                if (response.data.status === "DONE") {
                    try {
                        const response = await fetch('/api/payment', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                paymentKey: paymentKey,
                                amount: storePayment.paymentPrice
                            }),
                        });

                        const result = await response.json();
                        // console.log('result : ', result);

                        if (result.success) {
                            setStorePaymentInfo((prevState) => {
                                const newState = { ...prevState, ...result };
                                // console.log('newState : ', newState);
                                storePaymentAdd(newState);
                                return newState;
                            });
                        } else {
                            // 서버 검증 실패 시 로직
                            alert(`결제 검증에 실패하였습니다: ${result.error_msg}`);
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                } else if(response.data.status === "WAITING_FOR_DEPOSIT") {
                    try {
                        const response = await fetch('/api/payment', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                paymentKey: paymentKey,
                                amount: storePayment.paymentPrice
                            }),
                        });

                        const result = await response.json();
                        // console.log('result : ', result);

                        if (result.success) {
                            setStorePaymentInfo((prevState) => {
                                const newState = { ...prevState, ...result };
                                storePaymentAdd(newState);
                                return newState;
                            });
                        } else {
                            // 서버 검증 실패 시 로직
                            alert(`결제 검증에 실패하였습니다: ${result.error_msg}`);
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                } else {
                    // 결제 실패 처리
                    navigate(`/store/payment/fail`);
                }
            } catch (error) {
                if (error.response && error.response.status === 404 && retryCount < MAX_RETRIES) {
                    console.warn("결제 세션을 찾을 수 없습니다. 재시도 중...");
                    setTimeout(() => verifyPayment(retryCount + 1), 2000); // 2초 후 재시도
                } else if(error.response.status === 400 && retryCount < MAX_RETRIES) {
                    setTimeout(() => verifyPayment(retryCount + 1), 2000); // 2초 후 재시도
                } else {
                    console.error('결제 검증 오류:', error);
                    setVerificationResult({ error: '결제 검증에 실패했습니다.' });
                }
            } finally {
                setLoading(false);
            }
        };

        verifyPayment();
        // confirmPayment();
    }, [location, navigate]);

    const storePaymentAdd = async (paymentInfo) => {
        // console.log('storePaymentInfo : ', paymentInfo);

        const response = await adminApi.kakaoBizTemplateSelect();
        const data = response.data;

        // console.log('kakaoBizSelect : ', data);

        const kakaoBizTemplate = data.list.find(item => item.templtCode === "TW_3324");

        // console.log('kakaoBizTemplate : ', kakaoBizTemplate);
        
        const payload = {
            paymentInfo,
            kakaoBizTemplate
        }

        // 서버 검증 성공 시 로직
        try {
            const response = await storeApi.storePayment(payload);
            const data = response.data;
            // console.log(data);

            const paymentNumber = paymentInfo.paymentNumber;
            // console.log('paymentNumber = ', paymentNumber);

            navigate(`/store/payment/success/${paymentNumber}`);
        } catch (error) {
            console.error('Error storing payment info:', error);
        }
    };

    if (loading) {
        return <div className={`${store.store_product_detail_loading}`}>
                    <img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" />
               </div>;
    }

    return (
        <div>
            {verificationResult ? (
                <div className={`${support.support_payment_suceess_box}`}>
                    <div><img src={`${process.env.PUBLIC_URL}/img/payment_success.png`} alt="결제성공" /></div>
                </div>
            ) : (
                <div className={`${support.support_payment_fail_box}`}>
                    <div><span>결제 검증에 실패하였습니다.</span></div>
                </div>
            )}
        </div>
    );
};

export default StorePaymentPending;
