import React, { useContext, useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import { CSSTransition } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import * as adminApi from '../../../../apis/admin';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import * as delivery from '../../../../apis/delivery';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminDeliveryUpdate from './AdminDeliveryUpdate';

const AdminDeliverySetting = () => {
    const { userInfo, roles } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const [expandedIndex, setExpandedIndex] = useState(null); // 현재 확장된 템플릿의 인덱스 저장
    const navigate = useNavigate();

    const [deliveryList, setDeliveryList] = useState([]);
    const [updateDelivery, setUpdateDelivery] = useState({});
    const [showUpdateDelivery, setShowUpdateDelivery] = useState(false);

    useEffect(() => {
        if(userInfo) {
            if(roles.isAdmin) {
                adminDeliveryTemplate();
            } else {
                deliveryTemplate();
            }
        }
    }, [userInfo]);

    const deliveryTemplate = async () => {
        const response = await adminApi.deliveryTemplate(userInfo?.userId);
        const data = response.data;

        // console.log('deliveryTemplate : ', data);
        setDeliveryList(data);
    }

    const adminDeliveryTemplate = async () => {
        const response = await adminApi.adminDeliveryTemplate();
        const data = response.data;

        // console.log('adminDeliveryTemplate : ', data);
        setDeliveryList(data);
    }

    const onDeliveryAdd = () => {
        navigate('/admin/shop/delivery/add');
    }

    const toggleDelivery = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index); // 클릭된 항목을 토글
    }
    const renderOverseasShipping = (kgString, costString, state) => {
        // 상태가 false거나 데이터가 없을 경우 기본값 출력
        if (!state || !kgString || !costString) {
            return (
                <div>
                    <span>0kg 이상</span>
                    <span> | KRW 0</span>
                </div>
            );
        }
    
        const kgArray = kgString.split(';');
        const costArray = costString.split(';');
    
        // 배열 길이가 맞지 않는 경우에도 기본값 출력
        if (kgArray.length !== costArray.length) {
            return (
                <div>
                    <span>0kg 이상</span>
                    <span> | KRW 0</span>
                </div>
            );
        }
    
        return kgArray.map((kg, index) => (
            <div key={index}>
                <span>{`${kg}kg 이상`}</span>
                <span>{` | KRW ${parseInt(costArray[index], 10).toLocaleString()}`}</span>
            </div>
        ));
    };

    const onDeliveryTempUpdate = (item) => {
        // console.log('item : ', item);
        setUpdateDelivery(item);
        setShowUpdateDelivery(true);
    }

    const onDeliveryTempDelete = (item) => {
        // console.log('item : ', item);
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_inspection_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_inspection_title}'><span>${item.dtName} 템플릿을 삭제하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_inspection_sub_title}'><span>삭제 후 복구할 수 없습니다.</span></div>
                    <div class='${admin.admin_seller_inspection_modal_btn_box} py-2 d-flex w-100 justify_content_between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>삭제</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerSingleApprovalSuccess);
        document.addEventListener('click', sellerSingleApprovalCancel);

        async function sellerSingleApprovalSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {

                const response = await delivery.deliveryTempDelete(item.dtNo);
                const data = response.data;

                if (data === 'SUCCESS') {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html: `
                        <div>
                            <div class='${admin.admin_seller_add_title}'><span>${item.dtName} 템플릿이 삭제되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerSingleApprovalSuccess);
                    document.removeEventListener('click', sellerSingleApprovalCancel);

                    function sellerAddConfirm(e) {
                        if (e.target && e.target.id === 'seller_add_confirm') {
                            if(roles.isAdmin) {
                                adminDeliveryTemplate();
                            } else {
                                deliveryTemplate();
                            }
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                        }
                    }
                } else {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html: `
                        <div>
                            <div class='${admin.admin_seller_add_title}'><span>${item.dtName} 템플릿 삭제에 실패하였습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerSingleApprovalSuccess);
                    document.removeEventListener('click', sellerSingleApprovalCancel);

                    function sellerAddConfirm(e) {
                        if (e.target && e.target.id === 'seller_add_confirm') {
                            if(roles.isAdmin) {
                                adminDeliveryTemplate();
                            } else {
                                deliveryTemplate();
                            }
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                        }
                    }
                }
            }
        }
        function sellerSingleApprovalCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerSingleApprovalSuccess);
                document.removeEventListener('click', sellerSingleApprovalCancel);
            }
        }
    }

    return (
        <div className={`${admin.admin_shop_delivery_setting_box} px-3 py-3 ${showUpdateDelivery ? admin.admin_shop_delivery_update_active : ''}`}>
            <div>
                <div className={`${admin.admin_shop_delivery_setting_header}`}><span>배송템플릿</span></div>
                <div className={`${admin.admin_shop_delivery_setting_btn}`}><button onClick={() => onDeliveryAdd()}>배송템플릿 추가</button></div>
                <div>
                    <div>
                        <div className={`${admin.admin_shop_delivery_setting_content_header}`}>
                            <div><span>템플릿번호</span></div>
                            <div><span>템플릿설정</span></div>
                            <div><span>템플릿이름</span></div>
                            <div><span></span></div>
                        </div>
                        <div>
                            {
                                Array.isArray(deliveryList) && deliveryList.map((item, index) => (
                                    <div key={index}>
                                        <div 
                                            className={`${admin.admin_shop_delivery_setting_content_content}`} 
                                            onClick={() => toggleDelivery(index)} // 클릭된 인덱스를 전달
                                        >
                                            <div><span>{index + 1}</span></div>
                                            <div><span>{item.dtState}</span></div>
                                            <div><span>{item.dtName}</span></div>
                                            <div className='pe-2'><span className="material-symbols-outlined">{expandedIndex === index ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span></div>
                                            <div className={`${admin.admin_shop_delivery_setting_content_btn}`}>
                                                <div><button onClick={() => onDeliveryTempUpdate(item)}>수정</button></div>
                                                <div><button onClick={() => onDeliveryTempDelete(item)}>삭제</button></div>
                                            </div>
                                        </div>

                                        <CSSTransition in={expandedIndex === index} timeout={300} classNames="review" unmountOnExit>
                                            <div className={`${admin.admin_shop_delivery_setting_content_detail_content}`}>
                                                <div>
                                                    <div><span>배송 및 결제방법</span></div>
                                                    <div><span>{item.dtDeliveryType} / {item.dtDeliveryPm}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>기본 택배사</span></div>
                                                    <div><span>{item.dtDeliveryCompany}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>기본 배송비</span></div>
                                                    <div><span>KRW {item.dtDeliveryPrice.toLocaleString()}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>무료배송 조건</span></div>
                                                    <div><span>KRW {item.dtDeliveryFree.toLocaleString()} 이상 구매시</span></div>
                                                </div>
                                                <div>
                                                    <div><span>지역별배송비</span></div>
                                                    <div>
                                                        <div><span>제주도 추가 배송비 | KRW {item.dtJejuAddPrice.toLocaleString()}</span></div>
                                                        <div><span>도서산간 추가 배송비 | KRW {item.dtOtherAddPrice.toLocaleString()}</span></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div><span>해외 배송비</span></div>
                                                    <div>
                                                        {
                                                            item.overseasShippingState &&
                                                            item.deliveryOverseasAdds.map((ovs, idx) => {
                                                                const shippingCost = ovs.overseasShippingCost ? ovs.overseasShippingCost.split(";") : [];
                                                                const shippingKg = ovs.overseasShippingKg ? ovs.overseasShippingKg.split(';') : [];

                                                                
                                                                const numberic = (shippingCost) => {
                                                                    let cost = parseInt(shippingCost);
                                                                    return cost.toLocaleString()
                                                                }

                                                                return(
                                                                    <div key={idx} className={`${admin.admin_delivery_setting_oveseas_list}`}>
                                                                        <div className={`${admin.admin_delivery_setting_oveseas_header}`}>
                                                                            <div><span>{ovs.overseasDeliveryName}</span></div>
                                                                        </div>
                                                                        <div className={`${admin.admin_delivery_setting_oveseas_item}`}>
                                                                            <div><span>해외 배송 국가</span></div>
                                                                            <div><span>{ovs.overseasDeliveryCountry}</span></div>
                                                                        </div>
                                                                        <div className={`${admin.admin_delivery_setting_oveseas_item}`}>
                                                                            <div><span>해외 배송 반품 배송비(편도)</span></div>
                                                                            <div><span>{ovs.overseasReturnCost ? ovs.overseasReturnCost.toLocaleString() : 0}원</span></div>
                                                                        </div>
                                                                        <div className={`${admin.admin_delivery_setting_oveseas_item}`}>
                                                                            <div><span>해외 배송 교환 배송비(왕복)</span></div>
                                                                            <div><span>{ovs.overseasExchangeCost ? ovs.overseasExchangeCost.toLocaleString() : 0}원</span></div>
                                                                        </div>
                                                                        <div className={`${admin.admin_delivery_setting_oveseas_weight_item}`}>
                                                                            <div className={`${admin.admin_delivery_setting_oveseas_weight_item_text}`}><span>무게별 배송 비용</span></div>
                                                                            <div className={`${admin.admin_delivery_setting_oveseas_weight_item_content}`}>
                                                                                <div className={`${admin.admin_delivery_setting_oveseas_weight_item_content_header}`}>
                                                                                    <div><span>kg</span></div>
                                                                                    <div><span>금액</span></div>
                                                                                </div>
                                                                                {
                                                                                    shippingKg.map((kg, idx) =>{
                                                                                        return (
                                                                                            <div className={`${admin.admin_delivery_setting_oveseas_weight_item_content_box}`}>
                                                                                                <div><span>{kg}kg 이상</span></div>
                                                                                                <div><span>{numberic(shippingCost[idx]).toLocaleString()}원</span></div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )   
                                                            })
                                                        }
                                                        {/* {renderOverseasShipping(item.overseasShippingKg, item.overseasShippingCost, item.overseasShippingState)} */}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div><span>반품 배송비</span></div>
                                                    <div><span>KRW {item.dtReturnDeliveryPrice.toLocaleString()}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>교환 배송비</span></div>
                                                    <div><span>KRW {item.dtExchangeDeliveryPrice.toLocaleString()}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>출고지</span></div>
                                                    <div>
                                                        <div><span>{item.dtShippingAddress}</span></div>
                                                        <div><span>{item.dtShippingAddressDetail}</span></div>
                                                        <div><span>{item.dtShippingAddressZipcode}</span></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div><span>반품/교환지</span></div>
                                                    <div>
                                                        <div><span>{item.dtReturnLocation}</span></div>
                                                        <div><span>{item.dtReturnLocationDetail}</span></div>
                                                        <div><span>{item.dtReturnLocationZipcode}</span></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div><span>대표연락처</span></div>
                                                    <div><span>{item.dtCompanyTel}</span></div>
                                                </div>
                                            </div>
                                        </CSSTransition>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                showUpdateDelivery &&
                <AdminDeliveryUpdate updateDelivery={updateDelivery} setUpdateDelivery={setUpdateDelivery} setShowUpdateDelivery={setShowUpdateDelivery} deliveryTemplate={deliveryTemplate} />
            }
        </div>
    )
}

export default AdminDeliverySetting;
