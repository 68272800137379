import api from "./api";

export const venueRentalLength = (category, activeSubCategory) => api.get('/api/support/venueRentalLength', {
  params: { category : category, activeSubCategory : activeSubCategory }
});

export const venueRental = (category, activeSubCategory, page) => api.get('/api/support/venue', {
  params: { category : category, activeSubCategory : activeSubCategory, page : page }
});

export const imgDetail = (supportNo) => api.get(`/api/support/venue/${supportNo}`);

export const venueDetail = (supportNo) => api.get(`/api/support/venuedetail/${supportNo}`);

export const reservation = (reservation) => api.post('/api/reservation', reservation);

export const paymentNumber = (reservationId) => api.get(`/api/reservation/paymentsuccess/${reservationId}`);

// 에디터 이미지 업로드
export const uploadImageToServer = (formData, config) => api.post('/api/file/upload', formData, config);

// 데이터 이미지 업로드(여러 파일 한번에)
export const uploadImagesToServer = (formData, config) => api.post('/api/file/uploads', formData, config);

// 예약완료 정보 조회
export const reservationSelect = (reservationNo) => api.get(`/api/reservation/paySuccess/${reservationNo}`);

// 토스 accessToken 발급
export const accessToken = () => api.get('/api/payment/accessToken');

// 메세지 결제 요청
export const messagePayment = (msg, orderId, paymentKey, amount, paymentNumber, point, coupon, paymentType, bankName, accountNumber, depositDeadline, paymentStatus) => api.post('/api/reservation/message/payment', {msg, orderId, paymentKey, amount, paymentNumber, point, coupon, paymentType, bankName, accountNumber, depositDeadline, paymentStatus});

// 메세지 결제 완료 조회
export const messagePaymentSelect = (paymentNumber) => api.get(`/api/reservation/messagePaymentSelect/${paymentNumber}`);

// 서포트 리뷰 등록
export const supportReviewAdd = (reservationNo, reviewText, fileList, userId, sellerId, rating, productNumber) => api.post('/api/reservation/reviewAdd', { reservationNo, reviewText, fileList, userId, sellerId, rating, productNumber });

// 셀러 리뷰 조회
export const supportSellerReviewSelect = (userId, currentPage, itemsPerPage) => api.get(`/api/support/supportSellerReviewSelect/${userId}?page=${currentPage}&size=${itemsPerPage}`);

// 셀러 답변 등록 및 수정
export const reviewUpdate = (supportReviewNo, supportReceiveReview) => api.post(`/api/support/reviewUpdate`, {supportReviewNo: supportReviewNo, supportReceiveReview: supportReceiveReview});

// 1:1 문의 등록
export const inquiryAdd = (inquiryContent) => api.post(`/api/support/inquiryAdd`, inquiryContent);

export const inquirySelect = (userId) => api.get(`/api/support/inquirySelect/${userId}`);

// 서포트 장바구니 등록
export const supportCartAdd = (newItem) => api.post(`/api/support/supportCartAdd`, newItem);
// 서포트 장바구니 조회
export const supportCartSelect = (userId) => api.get(`/api/support/supportCartSelect/${userId}`);
// 서포트 장바구니 삭제
export const supportCartDelete = (cartNo, userId) => api.delete(`/api/support/supportCartDelete/${userId}`, { params : { cartNo }});
// 장바구니 예약기간 수정
export const reservationDateUpdate = (cartNo, startDate, endDate) => api.put(`/api/support/reservationDateUpdate`, {cartNo: cartNo, startDate: startDate, endDate: endDate});
// 전체 예약일 조회
export const holidaySelect = () => api.get(`/api/support/holidaySelect`);
export const temporarySelect = () => api.get(`/api/support/temporarySelect`);

// 리뷰 조회
export const supportSelectReview = (supportNo) => api.get(`/api/support/supportSelectReview/${supportNo}`);

export const supportFavorityCheck = (productNo, userId) => api.get(`/api/support/supportFavorityCheck/${productNo}`, {params: {userId : userId}});
export const supportWishlistRemove = (productNo, userId) => api.delete(`/api/support/supportWishlistRemove/${productNo}`, {params: {userId : userId}});
export const supportWishlistAdd = (productNo, userId) => api.post(`/api/support/supportWishlistAdd`, {productNo : productNo, userId : userId});

export const supportUserViews = (userId, productNumber, sellerId, mainCategory) => api.post(`/api/support/supportUserViews`, {userId: userId, productNumber: productNumber, sellerId: sellerId, mainCategory: mainCategory});

export const dashboardNewReservationSelect = (userId) => api.get(`/api/support/dashboardNewReservationSelect/${userId}`);
export const reservationConfirmed = (userId) => api.get(`/api/support/reservationConfirmed/${userId}`);
export const reservationProvided = (userId) => api.get(`/api/support/reservationProvided/${userId}`);
export const reservationReviewCount = (userId) => api.get(`/api/support/reservationReviewCount/${userId}`);
export const supportPageViewSelect = (userId) => api.get(`/api/support/supportPageViewSelect/${userId}`);
export const dashboardReservationSelect = (userId) => api.get(`/api/support/dashboardReservationSelect/${userId}`);

export const supportProductAwaitingApproval = () => api.get(`/api/support/supportProductAwaitingApproval`);
export const supportProductEnabledStateUpdate = (productNo) => api.put(`/api/support/supportProductEnabledStateUpdate/${productNo}`);
export const supportProductInsepectionWaiting = (productNumbers) => api.put(`/api/support/supportProductInsepectionWaiting`, productNumbers);
export const supportApprovalDenied = (no, rejectionReason) => api.put(`/api/support/supportApprovalDeniedUpdate`, {productNo: no, rejection : rejectionReason});
export const supportEnabledStateListUpdate = (selectedProducts) => api.put(`/api/support/supportEnabledStateListUpdate`, { productNo : selectedProducts });

export const supportCartProductDecrement = (item) => api.put(`/api/support/supportCartProductDecrement`, item);
export const supportCartProductIncrement = (item) => api.put(`/api/support/supportCartProductIncrement`, item);

export const supportProductDelete = (selectedProducts) => api.post(`/api/support/supportProductDelete`, {productNo : selectedProducts});
export const supportProductHide = (selectedProducts) => api.post(`/api/support/supportProductHide`, {productNo : selectedProducts});

export const supportItemSelect = () => api.get(`/api/support/supportItemSelect`);