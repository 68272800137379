import React, { useState, useEffect, useContext } from 'react';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { Link, useNavigate } from 'react-router-dom';
import EmailJoin from '../components/join/EmailJoin';
import '../components/join/Join.css';
import EmailLogin from '../components/login/EmailLogin';
import { LoginContext } from '../contexts/LoginContextProvider';
import * as auth from '../apis/auth';
import Cookies from 'js-cookie';

const MainContainer = () => {
  const { showAgree, setShowAgree, isLogin, setLogin, loginCheck } = useContext(LoginContext);
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [props, set] = useSpring(() => ({ transform: 'translateX(100%)' }));
  const [loginUrl, setLoginUrl] = useState('');
  const [twitterLoginUrl, setTwitterLoginUrl] = useState('');

  const navigateLogin = useNavigate();

  // useEffect(() => {
  //   refreshOnce();
  // }, []);

  // const refreshOnce = () => {
  //     const isRefreshed = sessionStorage.getItem("isPageRefreshed");
  //     // console.log("isPageRefreshed:", isRefreshed);

  //     if (!isRefreshed || isRefreshed !== "true") {
  //         // console.log("로그인 화면에서 강제로 새로고침 실행");
  //         sessionStorage.setItem("isPageRefreshed", "true");
  //         window.location.reload(true); // 강제로 새로고침
  //     } else {
  //         // 새로고침 후 초기화
  //         sessionStorage.removeItem("isPageRefreshed");
  //         // console.log("isPageRefreshed 값 초기화");
  //     }
  // };  

  useEffect(() => {
    // console.log("Access token 이벤트 리스너 설정 중.");

    // 기존 로그인 사용자의 토큰 확인
    const storedToken = localStorage.getItem('accessToken');

    // console.log('storedToken : ', storedToken);
    if (storedToken) {
      setAccessToken(storedToken);
      checkTokenAndFetchData(storedToken); // 기존 사용자 로그인 확인
    }
  
    // Flutter로부터 accessToken을 받는 이벤트 리스너 추가
    const handleAccessToken = (event) => {
      const newToken = event.detail.accessToken;
      
      // console.log('handleAccessToken : ', newToken);

      // console.log("이벤트를 통해 받은 access token:", newToken);
      // console.log("Received access token via event:", newToken);
      localStorage.setItem('accessToken', newToken);
      setAccessToken(newToken);
      checkTokenAndFetchData(newToken); // 새로운 토큰으로 데이터 요청
    };
  
    document.addEventListener("accessTokenReceived", handleAccessToken);
  
    return () => {
      // console.log("Access token 이벤트 리스너 제거 중.");
      document.removeEventListener("accessTokenReceived", handleAccessToken);
    };
  }, []);
  

  useEffect(() => {
    if (isLogin) {
      navigateLogin('/market');
    }
  }, [isLogin, navigateLogin]);


  // 비동기 함수로 분리
  const checkTokenAndFetchData = async (token) => {
    // console.log('토큰으로 로그인 : ', token);

    if (token) {
      try {
        const response = await fetch('/api/users/tokenLogin', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include', // 쿠키를 포함하여 요청
        });

        if (response.status === 401) {
          // console.warn('Access token expired, attempting to refresh...');
          const newToken = await refreshAccessToken(); // 리프레시 토큰으로 새 토큰 요청
          if (newToken) {
            // console.log('New access token received:', newToken);
            Cookies.set("accessToken", newToken);
    
            // 새 토큰으로 재요청
            await checkTokenAndFetchData(newToken);
          } else {
            console.error('Failed to refresh access token.');
          }
          return;
        }

        const data = await response.json();
        console.log(data);

        if(data && data.userId !== null) {
          Cookies.set("accessToken", token);

          loginCheck();
        }

      } catch (error) {
        console.error('Error fetching protected data:', error);
      }
    }
  };
  
  const handleSessionExpiration = () => {
    alert('세션이 만료되었습니다. 다시 로그인해 주세요.');
    Cookies.remove("accessToken");
    localStorage.removeItem("accessToken");
    window.location.href = "/"; // 로그인 페이지로 이동
  };
  
  const refreshAccessToken = async () => {
    try {
      // console.log('리프레시 토큰 요청');
      // 리프레시 토큰 요청
      const response = await fetch('/api/users/refresh-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // HTTP-only 쿠키로 리프레시 토큰 전달
      });
  
      if (!response.ok) {
        console.error('Failed to refresh access token:', response.statusText);
        handleSessionExpiration(); // 세션 만료 처리
        return null;
      }
  
      const data = await response.json();
      if (data?.accessToken) {
        Cookies.set("accessToken", data.accessToken);
        return data.accessToken;
      } else {
        console.error('No access token received from server.');
        handleSessionExpiration();
        return null;
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
      handleSessionExpiration();
      return null;
    }
  };
  

  const handleAgree = () => {
    setShowAgree(true);
    set({ transform: 'translateX(0)' });
  };

  const handleEmailLogin = () => {
    setShowEmailLogin(true);
    set({ transform: 'translateX(0)' });
  };

  const handleBack = () => {
    setShowAgree(false);
  };

  const transitions = useTransition(showAgree, {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0)' },
    leave: { transform: 'translateX(100%)' },
    config: { duration: 300 },
  });

  const emailLoginTransitions = useTransition(showEmailLogin, {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0)' },
    leave: { transform: 'translateX(100%)' },
    config: { duration: 300 },
  });

  useEffect(() => {
    const fetchLoginUrl = async () => {
      try {
        const response = await auth.kakaoAuth();
        setLoginUrl(response.data);
      } catch (error) {
        console.error('Failed to fetch Kakao login URL', error);
      }
    };
    fetchLoginUrl();
  }, []);

  const onKakaoLogin = () => {
    if (loginUrl) {
      window.location.href = loginUrl;
    } else {
      console.error('Kakao login URL is not set');
    }
  };

  const onTwitterLogin = () => {
    window.location.href = `https://dailyduck.com/api/twitter/login`;
  };

  const onLineLogin = () => {
    window.location.href = 'https://dailyduck.com/api/oauth2/authorization/line';
  };

  const handleGoogleLogin = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = `https://dailyduck.com/auth/google/callback`; // Your redirect URI
    const responseType = 'code';
    const scope = 'openid email profile';
    const state = 'random_state_string'; // You can use a more secure state value

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&state=${state}`;

    window.location.href = authUrl;
  };

  const handleAppleLogin = () => {
    const clientId = process.env.REACT_APP_APPLE_CLIENT_ID; // Apple에서 발급받은 Client ID
    const redirectUri = 'https://dailyduck.com/api/apple/redirect'; // 스프링 부트와 동일한 Redirect URI
    const responseType = 'code id_token'; // 받을 데이터 타입 설정
    const scope = 'name email'; // 요청할 사용자 정보
    const state = 'random_state_string'; // 보안 강화용 State 값 설정
    const responseMode = 'form_post';
  
    const authUrl = `https://appleid.apple.com/auth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&state=${state}&response_mode=${responseMode}`;
  
    window.location.href = authUrl;
  };

  return (
    <div className='main position-relative'>
      <div className='logo_box'>
        <img src={`${process.env.PUBLIC_URL}/img/logo_pink2.png`} alt='데이리덕 로고' />
      </div>
      <div className='btn_box'>
        <button
          className='btn btn-lg btn-success twitter d-flex justify-content-between'
          onClick={onTwitterLogin}
        >
          <img src={`${process.env.PUBLIC_URL}/img/snsicon/twitter.png`} alt='트위터아이콘' />
          <span>Twitter로 로그인</span>
          <span>&nbsp;</span>
        </button>
      </div>
      <div className='btn_box'>
        <button
          className='btn btn-lg btn-success kakao d-flex justify-content-between'
          onClick={onKakaoLogin}
        >
          <img src={`${process.env.PUBLIC_URL}/img/snsicon/kakao.png`} alt='카카오아이콘' />
          <span>Kakao로 로그인</span>
          <span>&nbsp;</span>
        </button>
      </div>
      <div className='btn_box'>
        <button
          className='btn btn-lg btn-success line d-flex justify-content-between'
          onClick={onLineLogin}
        >
          <img src={`${process.env.PUBLIC_URL}/img/snsicon/line.png`} alt='라인아이콘' />
          <span>Line으로 로그인</span>
          <span>&nbsp;</span>
        </button>
      </div>
      <div className='btn_box'>
        <button
          className='btn btn-lg btn-success google d-flex justify-content-between google_btn'
          onClick={handleGoogleLogin}
        >
          <img src={`${process.env.PUBLIC_URL}/img/snsicon/Google.png`} alt='구글아이콘' />
          <span>Google 로그인</span>
          <span>&nbsp;</span>
        </button>
        <div id="g_id_onload"
          data-client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          data-callback="handleGoogleLoginSuccess"
          data-auto_prompt="false"
          style={{ display: "none" }}>
        </div>
        <button className='btn btn-lg btn-success apple d-flex justify-content-between align-items-center apple_btn' onClick={handleAppleLogin}>
          <img src={`${process.env.PUBLIC_URL}/img/snsicon/Apple_logo_white.png`} alt='애플아이콘' />
          <span>Apple 로그인</span>
          <span>&nbsp;</span>
        </button>
      </div>
      <div className='btn_box'>
        <button className='btn btn-lg btn-success email' onClick={handleEmailLogin}>
          로그인
        </button>
      </div>
      <div className='join_box'>
        <button className='email_join' onClick={handleAgree}>
          회원가입
        </button>
      </div>
      <div className='link_box'>
        <Link to='/market' className='main_link'>
          둘러보기
        </Link>
      </div>
      {showAgree && (
        <div className='email_container'>
          <EmailJoin
            handleBack={handleBack}
            showAgree={showAgree}
            animated={animated}
            transitions={transitions}
            setShowAgree={setShowAgree}
          />
        </div>
      )}
      {showEmailLogin && (
        <EmailLogin
          showEmailLogin={showEmailLogin}
          animated={animated}
          emailLoginTransitions={emailLoginTransitions}
          setShowEmailLogin={setShowEmailLogin}
        />
      )}
    </div>
  );
};

export default MainContainer;
