import React, { useEffect, useRef, useState } from "react";

const VenueProductMap = ({ venueDetail, ss }) => {
    const [coordinates, setCoordinates] = useState({ lat: venueDetail?.lat ? parseFloat(venueDetail?.lat ): 37.5665, lng: venueDetail?.lng ? parseFloat(venueDetail?.lng) : 126.9780 }); // 기본 좌표 (서울)
    const mapRef = useRef(null); // 지도 DOM 참조
    const googleMapRef = useRef(null); // Google Map 객체 참조
    const markerRef = useRef(null); // 마커 참조

    useEffect(() => {
        if (venueDetail && venueDetail.length > 0) {
            // venueDetail에서 좌표 가져오기
            const venue = venueDetail[0]; // 첫 번째 항목 사용
            setCoordinates({
                lat: parseFloat(venue.lat),
                lng: parseFloat(venue.lng),
            });
        }
    }, [venueDetail]);

    useEffect(() => {
        // Google Maps API 스크립트 로드 및 지도 초기화
        loadGoogleMapsScript(() => {
            if (mapRef.current) {
                initializeMap();
            }
        });
    }, [coordinates]);

    const loadGoogleMapsScript = (callback) => {
        const existingScript = document.getElementById("googleMapsScript");
        if (!existingScript) {
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
            script.id = "googleMapsScript";
            script.async = true;
            script.defer = true;
            script.onload = callback;
            document.body.appendChild(script);
        } else {
            if (callback) callback();
        }
    };

    const initializeMap = () => {
        const map = new window.google.maps.Map(mapRef.current, {
            center: coordinates,
            zoom: 15,
        });
        googleMapRef.current = map;
    
        const venue = venueDetail;
        console.log("venue 값: ", venue);
    
        // OverlayView 생성
        class CustomMarker extends window.google.maps.OverlayView {
            constructor(position, map, content) {
                super();
                this.position = position;
                this.map = map;
                this.content = content;
                this.setMap(map); // 마커를 지도에 추가
            }
    
            onAdd() {
                const div = document.createElement("div");
                div.innerHTML = this.content;
                div.style.position = "absolute";
                div.style.cursor = "pointer";
                div.style.zIndex = "1000"; // 항상 최상위

                this.div = div;
    
                // Google Maps의 pane에 마커 추가
                const panes = this.getPanes();
                panes.overlayMouseTarget.appendChild(div);
    
                // 표준 DOM 이벤트로 클릭 및 터치 이벤트 추가
                div.addEventListener("click", (e) => {
                    e.stopPropagation(); // 이벤트 전파 방지
                    showInfoWindow();
                });

                div.addEventListener("touchstart", (e) => {
                    e.stopPropagation(); // 이벤트 전파 방지
                    showInfoWindow();
                });
            }
    
            draw() {
                const overlayProjection = this.getProjection();
                const position = overlayProjection.fromLatLngToDivPixel(this.position);
    
                const div = this.div;
                div.style.left = `${position.x - 25}px`; // 가로 위치 조정
                div.style.top = `${position.y - 70}px`; // 세로 위치 조정
            }
    
            onRemove() {
                if (this.div) {
                    this.div.parentNode.removeChild(this.div);
                    this.div = null;
                }
            }
        }
    
        const labelContent = `
            <div style="
                width: 50px;
                height: 50px;
                background-color: rgba(255, 3, 102, 1);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
                cursor: pointer;
            ">
                <img
                    src="${venue.representativeImage}"
                    alt="${venue.productName}"
                    style="width: 40px; height: 40px; border-radius: 50%;"
                />
                <div style="
                    position: absolute;
                    bottom: -8.5px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 10px solid rgba(255, 3, 102, 1);
                "></div>
            </div>
        `;
    
        const customMarker = new CustomMarker(
            new window.google.maps.LatLng(parseFloat(venue.lat), parseFloat(venue.lng)),
            map,
            labelContent
        );
    
        // InfoWindow 생성
        const infoWindow = new window.google.maps.InfoWindow();
    
        const showInfoWindow = () => {
            const content = `
                <div>
                    <h3>${venue.productName}</h3>
                    <p>${venue.address} ${venue.detailAddress}</p>
                </div>
            `;
            infoWindow.setContent(content);
            infoWindow.setPosition(new window.google.maps.LatLng(parseFloat(venue.lat), parseFloat(venue.lng)));
            infoWindow.open(map);
        };
    
        map.addListener("click", () => {
            infoWindow.close();
        });
        
    };
    
    

    return (
        <div className={`${ss.support_location_container}`}>
        {
            venueDetail && venueDetail?.address ?
            <div
                id="map"
                ref={mapRef}
                style={{ width: "100%", height: "500px" }}
            />
            :
            <div className={`${ss.support_no_location}`}><span>등록된 주소가 없습니다.</span></div>
        }
        </div>
    );
};

export default VenueProductMap;
