import api from "./api";
import { paymentNumber } from "./support";

export const cartAdd = (newItem) => api.post('/api/store/cartAdd', newItem);
export const cartSelect = (userId) => api.get(`/api/store/cartSelect/${userId}`);
export const cartDelete = (cartNo) => api.delete(`/api/store/cartDelete/${cartNo}`);

export const productAdd = (productInfo, userId) => api.post(`/api/store/productAdd/${userId}`, productInfo);
export const productUpdate = (productInfo, userId) => api.put(`/api/store/productUpdate/${userId}`, productInfo);
export const productSelect = (userId, roles, currentPage, itemsPerPage, activeButton) => api.post('/api/store/productSelect', {userId : userId, roles: roles, currentPage: currentPage, itemsPerPage: itemsPerPage, activeButton : activeButton});
export const productRead = (productNumber) => api.get(`/api/store/product/${productNumber}`);
export const productAllSelect = (userId, roles, activeButton) => api.post('/api/store/productAllSelect', {userId : userId, roles: roles, activeButton: activeButton});
export const productStateSelect = (userId, roles) => api.post('/api/store/productStateSelect', {userId : userId, roles: roles});
export const productSearchData = (searchTerm) => api.get(`/api/store/productSearchData/${searchTerm}`);

export const productDelete = (selectedProducts) => api.delete(`/api/store/product/delete`, {data: { productNumbers : selectedProducts}});
export const productSaleStateChange = (selectedProducts, state) => api.put(`/api/store/productState/${state}`, {productNumbers : selectedProducts});

export const productCopy = (selectedProducts, userId) => api.post(`/api/store/productCopy/${userId}`, {productNumbers : selectedProducts});

export const userProductSelect = (category, activeSubCategory, page) => api.get(`/api/store/categoryProduct/${category}`, {params : {page : page, activeSubCategory : activeSubCategory}});
export const userProductLengthSelect = (category, activeSubCategory) => api.get(`/api/store/userProductLengthSelect/${category}`, {params : {activeSubCategory : activeSubCategory}});

export const productDetaile = (productNo) => api.get(`/api/store/productDetaileSelect/${productNo}`);

export const productAwaitingApproval = () => api.get(`/api/store/productAwaitingApproval`);

// 등록 상태 업데이트
export const productEnabledStateUpdate = (productNo) => api.put(`/api/store/productEnabledStateUpdate/${productNo}`);

// 리스트 상태 업데이트
export const productEnabledStateListUpdate = (selectedProducts) => api.put(`/api/store/productEnabledStateListUpdate`, { productNo : selectedProducts });

// 거절 사유 업데이트
export const approvalDenied = (no, rejectionReason) => api.put(`/api/store/productApprovalDeniedUpdate`, {productNo: no, rejection : rejectionReason});

// 셀러 조회
export const sellerSelect = (currentPage, itemsPerPage, selectedFilter) => api.get(`/api/store/sellerSelect/${selectedFilter}?page=${currentPage}&size=${itemsPerPage}`);
// 셀러 카운트
export const sellerCount = () => api.get(`/api/store/sellerCount`);

// 메세지 체크
export const messageCheck = (productNumber, userId, sellerId) => api.post(`/api/chat`, {productNumber : productNumber, userId : userId, sellerId: sellerId});

// 배송템플릿 조회
export const deliverySelect = (deliveryNo, userId) => api.get(`/api/store/deliverySelect`, { params: { deliveryNo: deliveryNo, userId: userId } });

// 결제내역 저장
export const storePayment = (payload) => api.post(`/api/store/payment`, payload);

// 이벤트 결제내역 저장
export const planPayment = (newItem) => api.post(`/api/store/planPayment`, newItem);

// 결제내역 조회
export const storePaymentSelect = (paymentNumber) => api.get(`/api/store/storePaymentSelect/${paymentNumber}`);

// 스토어 상점 조회
export const sellerShopSelect = (sellerId) => api.get(`/api/store/sellerShopSelect/${sellerId}`);

// 이벤트 결제 
export const eventPayment = (paymentInfo) => api.post(`/api/event/eventPayment`, paymentInfo);
// 이벤트 결제 후 확인
export const eventPaymentSelect = (paymentNumber) => api.get(`/api/event/eventPaymentSelect/${paymentNumber}`);

// 이벤트 참여 체크
export const eventConfirm = (eventNumber, userId) => api.get(`/api/event/eventConfirm`, { params: { eventNumber: eventNumber, userId: userId } })

// 기획전 이벤트 번호 조회
export const planRead = (eventNumber) => api.get(`/api/event/planRead/${eventNumber}`);

// 장바구니 아이템 개수 증가
export const quantityUpdate = (cartNo, quantity, value, userId, totalQuantity, totalPrice) => api.post(`/api/store/quantityUpdate`, {cartNo: cartNo, quantity: quantity, value: value, userId: userId, totalQuantity: totalQuantity, totalPrice: totalPrice});
export const nonOptionIncrementQuantityUpdate = (cartNo, totalQuantity, totalPrice, productSalePrice) => api.put(`/api/store/nonOptionIncrementQuantityUpdate`, {cartNo: cartNo, totalQuantity: totalQuantity, totalPrice: totalPrice, productSalePrice: productSalePrice})
export const nonOptionDecrementQuantityUpdate = (cartNo, totalQuantity, totalPrice, productSalePrice) => api.put(`/api/store/nonOptionDecrementQuantityUpdate`, {cartNo: cartNo, totalQuantity: totalQuantity, totalPrice: totalPrice, productSalePrice: productSalePrice})
export const nonOptionQuantityUpdateDirect = (cartNo, totalQuantity, totalPrice, productSalePrice) => api.put(`/api/store/nonOptionQuantityUpdateDirect`, {cartNo: cartNo, totalQuantity: totalQuantity, totalPrice: totalPrice, productSalePrice: productSalePrice})

// 장바구니 제거
export const storeCartDelete = (cartNo, userId) => api.delete(`/api/store/storeCartDelete/${userId}`, {params : { cartNo }})

export const storeReviewAdd = (paymentNo, reviewText, fileList, userId, sellerId, rating, productNumber) => api.post('/api/store/storeReviewAdd', { paymentNo, reviewText, fileList, userId, sellerId, rating, productNumber });

// 유저 스토어 뷰
export const userViews = (userId, productNumber, sellerId, mainCategory) => api.post(`/api/store/userViews`, {userId: userId, productNumber: productNumber, sellerId: sellerId, mainCategory: mainCategory});

// 상품 디테일 리뷰 조회
export const selectReview = (productNo) => api.get(`/api/store/selectReview/${productNo}`);
export const planSelectReview = (productNumber) => api.get(`/api/store/planSelectReview/${productNumber}`);

// 상품 디테일 찜 여부 조회
export const favorityCheck = (productNo, userId) => api.get(`/api/store/favorityCheck/${productNo}`, {params: {userId : userId}});
export const storeWishlistRemove = (productNo, userId) => api.delete(`/api/store/storeWishlistRemove/${productNo}`, {params: {userId : userId}});
export const storeWishlistAdd = (productNo, userId) => api.post(`/api/store/storeWishlistAdd`, {productNo : productNo, userId : userId});

export const sellerProductInsepectionWaiting = (productNumbers) => api.put(`/api/store/sellerProductInsepectionWaiting`, productNumbers);

export const searchSellerSelect = (currentPage, itemsPerPage, searchData) => api.post(`/api/store/searchSellerSelect?page=${currentPage}&size=${itemsPerPage}`, searchData);

export const eventCommentInsert = (commentInfo) => api.post(`/api/store/eventCommentInsert`, commentInfo);
export const eventCommentSelect = (eventNumber) => api.get(`/api/store/eventCommentSelect/${eventNumber}`)
export const eventCommentLike = (commentNo, userId) => api.put(`/api/store/eventCommentLike`, { commentNo : commentNo, userId : userId});
export const eventCommentLikeSelect = (userId) => api.get(`/api/store/eventCommentLikeSelect/${userId}`);
export const eventCommentNoLike = (commentNo, userId) => api.put(`/api/store/eventCommentNoLike`, { commentNo : commentNo, userId : userId});

export const eventVoteConfirm = (eventNumber, userId) => api.get(`/api/store/eventVoteConfirm`, {params: {eventNumber : eventNumber, userId : userId}})
export const voteSuccess = (selectedItems) => api.post(`/api/store/voteSuccess`, selectedItems);
export const voteParticipateResult = (eventNumber) => api.get(`/api/store/voteParticipateResult/${eventNumber}`);
export const eventVoteCheck = (userId) => api.get(`/api/store/eventVoteCheck/${userId}`);

export const couponNameDuplicateCheck = (eventNumber, couponName) => api.post(`/api/store/couponNameDuplicateCheck`, {eventNumber : eventNumber, couponName, couponName});
export const couponCodeAdd = (eventNumber, userId, couponName) => api.post(`/api/store/couponCodeAdd`, {eventNumber : eventNumber, userId : userId, couponName: couponName});
export const couponCodeUse = (eventNumber, userId, sharedCouponName) => api.post(`/api/store/couponCodeUse`, {eventNumber : eventNumber, userId : userId, sharedCouponName: sharedCouponName});
