import React, { useEffect, useState } from 'react';
import admin from '../../../admin/admin.module.css';
import { checkEmail, checkUserId, emailCheck, emailAuthNumberCheck, phoneAuth, phoneAuthCheck } from '../../../apis/auth';
import * as auth from '../../../apis/auth';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as adminApi from '../../../apis/admin';

const LoginPwChangeSection = ( {seller, showPwChange, setShowPwChange} ) => {

    const MySwal = withReactContent(Swal);

    const [idMessage, setIdMessage] = useState('');
    const [idColor, setIdColor] = useState('black');
    const [idBorderColor, setIdBorderColor] = useState('');
    const [ password, setPassword ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ newPasswordConfirm, setNewPasswordConfirm ] = useState('');
    const [ statePwCheck, setStatePwCheck ] = useState(false);
    const [ userInfo, setUserInfo] = useState({});
    const [ infoState, setInfoState ] = useState(false);

    const [idState, setIdState] = useState(false);
    const [ btnState, setBtnState ] = useState(true);

    const [userInput, setUserInput] = useState({
        sellerId: '',
        sellerEmail: '',
        sellerPhone: '',
        password: '',
        businessNumber: '',
        representative: '',
        businessName: '',
        businessType: '',
        businessSector: '',
        postCode: '',
        address: '',
        extraAddress: '',
        detailAddr: '',
        businessState : '',
        residentRegistrationNumber : ''
    });
    const [ shopInfo, setShopInfo ] = useState({
        userId : '',
        shopName : '',
        shopDetailContent : '',
        shopProfileImg : '',
        shopBackImg : '',
        shopBankImg : '',
        shopAccountNumber : '',
        shopDepositorName : ''
    });

    const [userId, setUserId] = useState('');
    const [phone, setPhone] = useState('');
    const [showPhoneAuth, setShowPhoneAuth] = useState(false);
    const [phoneAuthNumber, setPhoneAuthNumber] = useState('');
    const [phoneCertification, setPhoneCertification] = useState(false);

    const onPhone = (e) => {
        setPhone(e.target.value);
        setUserInput({...userInput, sellerPhone: e.target.value});
    };

    const onIdCheck = async () => {
        const idRegex = /^[A-Za-z0-9]+$/;
        if (idRegex.test(userId)) {
            const timer = setTimeout(() => {
                checkUserId(userId)
                    .then((res) => {
                        if (res.data.userId) {
                            setIdMessage('존재하는 아이디입니다.');
                            setIdColor('green');
                            setIdBorderColor('rgba(255, 92, 92, 1)');
                            setIdState(true);
                        } else {
                            setIdMessage('존재하지 않는 아이디입니다.');
                            setIdColor('rgba(255, 92, 92, 1)');
                            setIdBorderColor('green');
                            setIdState(false)
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            if (userId !== '') {
                setIdMessage('아이디는 영어와 숫자만 포함해야 합니다.');
                setIdColor('rgba(255, 92, 92, 1)');
                setIdBorderColor('rgba(255, 92, 92, 1)');
                setIdState(false);
            } else {
                setIdMessage('');
            }
        }
    };

    const onPhoneCertification = async () => {
        const response = await auth.phoneAuth(userInput.sellerPhone);
        const data = response.data;
        if (data === 'SUCCESS') {
            MySwal.fire({
                customClass: {
                  popup: `${admin.admin_seller_join_popup}`
                },
                html: `
                <div>
                  <div class='${admin.admin_seller_join_title}'><span>인증문자가 발송되었습니다.</span></div>
                  <div class='${admin.admin_seller_join_sub_title}'><span>인증문자가 도착하지 않을 경우 휴대폰번호를 확인해주세요.</span></div>
                  <div class='${admin.admin_seller_join_confirm_btn_box}'>
                    <button id='address_confirm'>확인</button>
                  </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
              });
      
              document.addEventListener('click', addressConfirm);
      
              function addressConfirm(e) {
                if (e.target && e.target.id === 'address_confirm') {
                  MySwal.close();
                  document.removeEventListener('click', addressConfirm);
                  setShowPhoneAuth(true);
                }
              }
        }
    };

    const onPhoneAuthNumber = (e) => {
        setPhoneAuthNumber(e.target.value);
    };

    const onPhoneAuthCheck = async () => {
        const response = await auth.phoneAuthCheck(userInput.sellerPhone, phoneAuthNumber);
        const data = response.data;
        if (data === 'SUCCESS') {
            setShowPhoneAuth(false);
            setPhoneCertification(true);
        }
    };

    const idCheck = (e) => {
        setUserId(e.target.value);
        setUserInput({
            ...userInput,
            sellerId: e.target.value,
        });
        setShopInfo({
            ...shopInfo, userId : e.target.value
        })
    };

    useEffect(() => {
        stateCheck();
    }, [idState, phoneCertification])

    const stateCheck = async () => {
        if(idState) {
            if(phoneCertification) {

                const response = await auth.userselect(userId);
                const data = response.data;

                if(response.status === 200) {
                    setUserInfo(data);
                    setInfoState(true);
                }
            } else {
                setInfoState(false);
            }
        } else {
            setInfoState(false);
        }
    }

    const onPw = (e) => {
        setPassword(e.target.value);
    }

    const adminPwChangeBack = () => {
        Swal.fire({
          customClass: {
              popup: `${admin.admin_popup}`,
              confirmButton: `${admin.admin_btn_confirm}`,
              cancelButton: `${admin.admin_btn_cancel}`,
              title: `${admin.admin_swal_title}`, 
              htmlContainer: `${admin.admin_swal_text}`,
    
          },
          title: '페이지를 이동 하시겠습니까?',
          html: '입력한 정보는 저장 되지 않습니다.',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '예',
          cancelButtonText: '아니오',
          reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
              setShowPwChange(false);
            }
        });
      }
      const onNewPw = (e) => {
        setNewPassword(e.target.value);
      }
      const onNewPwCheck = (e) => {
        setNewPasswordConfirm(e.target.value);
      }
    
      useEffect(() => {
        if(newPassword != '' && newPasswordConfirm != '') {
          if(newPassword != newPasswordConfirm) {
            setStatePwCheck(true);
            setBtnState(true);
          } else {
            setStatePwCheck(false);
            setBtnState(false);
          }
        } else {
          setBtnState(true);
        }
    
      }, [newPassword, newPasswordConfirm]);
    
      const adminPwChangeSuccess = () => {
        if(!infoState) {
            alert('아이디와 휴대폰번호 인증을 먼저 진행해주세요.');
            
            return;
        }

        if(statePwCheck) {
            alert('비밀번호를 확인해주세요.');

            return;
        }

        let newInfo = {
            userId : userId,
            prevPw : password,
            newPw : newPassword,
            newPwConfirm : newPasswordConfirm
        }

        // console.log('newInfo : ', newInfo);

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>비밀번호를 변경 하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>변경</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.sellerPwChange(newInfo);
                    const data = response.data;

                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>비밀번호가 변경되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                setShowPwChange(false);
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>비밀번호 변경에 실패하였습니다.</span></div>
                                <div class='${admin.admin_seller_add_sub_title}'><span>입력하신 현재 비밀번호가 일치하지 않습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
      }
      
  return (
    <div>
        <div className={`${seller.seller_login_box}`}>
            <div className={`${seller.seller_login_box_header}`}>
                <div><span>비밀번호 변경</span></div>
                <div><button onClick={() => adminPwChangeBack()}><span className="material-symbols-outlined">close</span></button></div>
            </div>
            <div className={`${seller.seller_login_box_content}`}>
            {
                        !infoState &&
                        <div className=''>
                            <div className={`${admin.support_admin_join_section_content_id_box}`}>
                                <div>
                                    <div><span>아이디</span></div>
                                    <div>
                                        <div><input type="text" placeholder='가입하신 아이디를 입력해주세요.' onChange={idCheck} disabled={idState}/></div>
                                        <div><button className={userId === '' ? '' : admin.admin_join_section_content_id_active} onClick={() => onIdCheck()} disabled={userId === '' ? true : false}>아이디체크</button></div>
                                    </div>
                                </div>
                                {
                                    idMessage !== '' &&
                                    <div><span style={{ color: idColor }}>{idMessage}</span></div>
                                }
                            </div>
                            <div className={`${admin.support_admin_join_section_content_phone_box} ${admin.admin_password_change_phone_box}`}>
                                <div>
                                    <div><span>휴대폰번호</span></div>
                                    <div>
                                        <div><input type="number" placeholder='휴대폰 번호를 입력해주세요.' onChange={(e) => onPhone(e)} disabled={showPhoneAuth || phoneCertification}/></div>
                                        <div>
                                            {phoneCertification ? (
                                                <button className={`${admin.admin_join_section_content_phone_certification_success}`} disabled={phoneCertification}>인증완료</button>
                                            ) : (
                                                <button className={phone === '' ? '' : admin.admin_join_section_content_phone_active} disabled={phone === '' ? true : false} onClick={() => onPhoneCertification()}>인증번호발송</button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {showPhoneAuth && (
                                    <div className={`${admin.admin_join_section_content_phone_confirm_box}`}>
                                        <div><span>인증번호</span></div>
                                        <div>
                                            <div><input type="number" onChange={(e) => onPhoneAuthNumber(e)} /></div>
                                            <div><button className={phoneAuthNumber === '' ? '' : admin.admin_join_section_content_phone_confirm_active} onClick={() => onPhoneAuthCheck()} disabled={phoneAuthNumber === '' ? true : false}>인증확인</button></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    {
                        infoState &&
                        <div>
                            {/* <div className='px-5 pt-5'>
                                <div><span>현재 비밀번호</span></div>
                                <div className={`${admin.admin_login_input_box} pt-3`}><input type="password" placeholder='현재 비밀번호를 입력해주세요' onChange={(e) => onPw(e)} /></div>
                            </div> */}
                            <div className={`${admin.admin_new_password_info_txt}`}>
                                <div>
                                    <span>{userInfo && userInfo.userName ? userInfo?.userName : userInfo?.userId}</span>
                                    <span> 셀러님 반갑습니다.</span>
                                </div>
                                <div><span>변경하실 비밀번호를 입력해주세요 😊</span></div>
                            </div>
                            <div className='px-5 pt-5'>
                                <div><span>새 비밀번호</span></div>
                                <div className={`${admin.admin_login_input_box} pt-3`}><input type="password" placeholder='새 비밀번호를 입력해주세요' onChange={(e) => onNewPw(e)} /></div>
                            </div>
                            <div className='px-5 pt-5 position-relative'>
                                <div><span>새 비밀번호 확인</span></div>
                                <div className={`${admin.admin_login_input_box} pt-3`}>
                                    <input type="password" placeholder='새 비밀번호를 한 번 더 입력해주세요' onChange={(e) => onNewPwCheck(e)} />
                                </div>
                            {
                                statePwCheck &&
                                <div className={`${admin.admin_login_error_box}`}><span className={`${admin.admin_login_error}`}>입력하신 새로운 비밀번호 일치하지 않습니다. 다시 입력해주세요.</span></div>
                            }
                            </div>
                        </div>
                    }
            </div>
            <div className={`${seller.seller_login_box_footer}`}>
                <div className={`${seller.seller_login_btn}`}><button onClick={() => adminPwChangeSuccess()}>완료</button></div>
            </div>
        </div>
    </div>
  )
}

export default LoginPwChangeSection