import React, { useState, useEffect } from 'react'
import EmailJoin2 from './EmailJoin2';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import TermsOfUse from './TermsOfUse';
import EmailJoin3 from './EmailJoin3';
import JoinFavority from './JoinFavority';
import FavorityDday from './FavorityDday';
import RecomMender from './RecomMender';
import FavorityAdd from './FavorityAdd';
import * as adminApi from '../../apis/admin';

const EmailJoin = ( {handleBack, showAgree, animated, transitions, setShowAgree} ) => {

    const [showJoin, setShowJoin] = useState(false);
    const [ showJoinInfo, setShowJoinInfo ] = useState(false);  
    const [ showJoinFavority, setShowJoinFavority ] = useState(false);  
    const [showFavorityDday, setShowFavorityDday] = useState(false);
    const [showRecommend, setShowRecommend] = useState(false);
    const [showFavorityAdd, setShowFavorityAdd] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    
    // 입력 값 리스트로 관리
    const [userList, setUserList] = useState({});
    // 선택된 객체의 정보를 저장하는 state
    const [selectedItems, setSelectedItems] = useState([]); 
    
    const [userInput, setUserInput] = useState({
        username: '',
        id: '',
        email: '',
        password: '',
        countries: '',
        phone : '',
        nick: '',
        year: '',
        month: '',
        day: '',
        gender: '',
      });

    const [isChecked, setIsChecked] = useState({
        total: false,
        service: false,
        privacy: false,
        age: false,
        marketing: false,
    });

    const [labelText, setLabelText] = useState('');
    const [showTermsOfUse, setShowTermsOfUse] = useState(false);
    const [basicTermsList, setBasicTermsList] = useState([]);
    const [termsInfo, setTermsInfo] = useState({});

    useEffect(() => {
        basicTermsSelect();
    }, []);

    useEffect(() => {
        setUserList({
            userName : userInput.username,
            userId : userInput.id,
            userEmail : userInput.email, 
            userPw : userInput.password, 
            userRepublic : userInput.countries, 
            userTel : userInput.phone, 
            userNick : userInput.nick, 
            userYear : userInput.year, 
            userMonth : userInput.month, 
            userDay : userInput.day,
            userGender : userInput.gender
        });
    }, [userInput])

    const basicTermsSelect = async () => {
        setIsLoading(true);

        const response = await adminApi.basicTermsSelect();
        const data = response.data;

        if(response.status === 200) {
            // console.log('basicTermsSelect : ', data);
            setBasicTermsList(data);
            setIsLoading(true);
        }


        // setCheckedListBasic(new Array(data.length).fill(false));
    }

    const handleShowJoin = () => {
        setShowJoin(true);
    }

    const handleJoinBack = () => {
        setShowJoin(false);
    }
    
    const handleJoinClose = () => {
        setShowJoin(false);
        setShowAgree(false);

    }

    const handleJoinInfo = () => {
        setShowJoinInfo(true);
    }
    const handleJoinInfoBack = () => {
        setShowJoinInfo(false);
    }
    const handleJoinInfoClose = () => {
        setShowAgree(false);
        setShowJoin(false);
        setShowJoinInfo(false);
    }

    const handleJoinFavority = () => {
        setShowJoinFavority(true);
    }
    const handleJoinFavBack = () => {
        setShowJoinFavority(false);
    }
    const handleJoinFavClose = () => {
        setShowAgree(false);
        setShowJoin(false);
        setShowJoinInfo(false);
        setShowJoinFavority(false);
    }
    const handleFavorityDdayClick = () => {
        setShowFavorityDday(true);
    }
    const handleSkipClick = () => {
        setShowRecommend(true);
    }
    const handleFavorityAddClick = () => {
        setShowFavorityAdd(true);
    }
    
    const handleFavDdayBack = () => {
        setShowFavorityDday(false);
    }
    
    const handleFavDdayClose = () => {
        setShowAgree(false);
        setShowJoin(false);
        setShowJoinInfo(false);
        setShowJoinFavority(false);
        setShowFavorityDday(false);
    }    
    
    const favoritydDayNext = () => {
        setShowRecommend(true);
    }

    const joinTransitions = useTransition(showJoin, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });
    const joinTransitions2 = useTransition(showJoinInfo, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });
    const joinTransitions3 = useTransition(showJoinFavority, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });
    const recommendTransition = useTransition(showRecommend, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });
    
    const favorityDdayTransition = useTransition(showFavorityDday, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });
    const favorityAddTransition = useTransition(showFavorityAdd, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });
    const termsOfUseTransitions = useTransition(showTermsOfUse, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });

    const handleButtonClick = (e) => {
        // 가장 가까운 버튼 요소를 찾음
        const button = e.target.closest('button');
        // 버튼의 data-label 속성 값을 읽어옴
        const label = button.getAttribute('data-label');
        // // console.log(label); // 콘솔에 label 값 출력
        setLabelText(label);
    };

    const handleTermsOfUseClick = (termsInfo) => {
        setTermsInfo(termsInfo);
        setShowTermsOfUse(true);
    }
    
    const handleCheck = (e) => {
        const { id, checked } = e.target;
      
        setIsChecked(prev => {
          const updated = { ...prev, [id]: checked };
          const allChecked = ['service', 'privacy', 'age', 'marketing'].every(key => updated[key]);
      
          return {
            ...updated,
            total: id !== 'total' ? allChecked : checked,
          };
        });
      
        // // console.log(id + " : " + checked);
      };
    
    
    const handleTotalCheck = (e) => {
        const { checked } = e.target;
        setIsChecked(prev => {
            const updated = { ...prev, total: checked };
            if (checked) {
                for (let key in updated) {
                    updated[key] = true;
                }
            } else {
                for (let key in updated) {
                    updated[key] = false;
                }
            }
            return updated;
        });
        // // console.log(checked);
    }
    
    const allRequiredChecked = isChecked.service && isChecked.privacy && isChecked.age;

    useEffect(() => {
    }, [isChecked]);

    // dday 업데이트 함수
    const updateDday = (no, newDday) => {
        setSelectedItems(selectedItems.map(item => 
        item.artistNo === no ? { ...item, dday: newDday } : item
        ));
    };
    
    const termsStateCheck = (termsState) => {
        // console.log('termsState : ', termsState);
        if(termsState) {
            return '필수';
        } else {
            return '선택';
        }
    }
    

    return (
        <>
            {showAgree &&
                <div className="agree_background">
                    {transitions((style, item) =>
                        item ? 
                        <animated.div className="agree_box" style={style}>
                            <div className='agree_header'>
                                <div>
                                    <button onClick={() => handleBack()}>
                                        <span className="material-symbols-outlined">arrow_back_ios</span>
                                    </button>
                                </div>
                                <div>
                                     <span>약관동의</span>
                                </div>
                            </div>
                            <div>
                                <div className='agree mx-3 pt-4 border-bottom'>
                                    <input type="checkbox" name="" id="total" className='total_agree' onChange={handleTotalCheck} checked={isChecked.total} />
                                    <label className='ps-2' htmlFor="total">전체 동의</label>
                                </div>
                                <div className='mx-3 each_agree'>
                                    <div className='agree service_agree justify-content-between'>
                                        <div className='item_center'>
                                            <input type="checkbox" name="" id="service" className='service_agree' onChange={handleCheck} checked={isChecked.service} />
                                            <label className='ps-2' htmlFor="service">[{termsStateCheck(basicTermsList[0]?.termsState)}] {basicTermsList[0]?.termsTitle}</label>
                                        </div>
                                        <div>
                                            <button onClick={ (e) =>{handleButtonClick(e); handleTermsOfUseClick(basicTermsList[0]);} } data-label={`[${termsStateCheck(basicTermsList[0]?.termsState)}] ${basicTermsList[0]?.termsTitle}`}>
                                                <span className="material-symbols-outlined">chevron_right</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='agree justify-content-between'>
                                        <div className='item_center'>
                                            <input type="checkbox" name="" id="privacy" className='privacy_agree' onChange={handleCheck} checked={isChecked.privacy} />
                                            <label className='ps-2' htmlFor="privacy">[{termsStateCheck(basicTermsList[1]?.termsState)}] {basicTermsList[1]?.termsTitle}</label>
                                        </div>
                                        <div>
                                            <button onClick={ (e) =>{handleButtonClick(e); handleTermsOfUseClick(basicTermsList[1]);} } data-label={`[${termsStateCheck(basicTermsList[1]?.termsState)}] ${basicTermsList[1]?.termsTitle}`}>
                                                <span className="material-symbols-outlined">chevron_right</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='agree justify-content-between'>
                                        <div className='item_center'>
                                            <input type="checkbox" name="" id="age" className='age_agree' onChange={handleCheck} checked={isChecked.age}  />
                                            <label className='ps-2' htmlFor="age">[{termsStateCheck(basicTermsList[6]?.termsState)}] {basicTermsList[6]?.termsTitle}</label>
                                        </div>
                                        <div className='item_center'>
                                            <button onClick={ (e) =>{handleButtonClick(e); handleTermsOfUseClick(basicTermsList[6]);} } data-label={`[${termsStateCheck(basicTermsList[6]?.termsState)}] ${basicTermsList[6]?.termsTitle}`}>
                                                <span className="material-symbols-outlined">chevron_right</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='agree justify-content-between'>
                                        <div className='item_center'>
                                            <input type="checkbox" name="" id="marketing" className='marketing_agree' onChange={handleCheck} checked={isChecked.marketing} />
                                            <label className='ps-2' htmlFor="marketing">
                                                {basicTermsList.length > 4
                                                    ? `[${termsStateCheck(basicTermsList[4]?.termsState)}] ${basicTermsList[4]?.termsTitle}`
                                                    : 'Loading...'}
                                            </label>
                                        </div>
                                        <div>
                                        {basicTermsList.length > 4 && (
                                            <button 
                                                onClick={(e) => { 
                                                    handleButtonClick(e); 
                                                    handleTermsOfUseClick(basicTermsList[4]); 
                                                }} 
                                                data-label={`[${termsStateCheck(basicTermsList[4]?.termsState)}] ${basicTermsList[4]?.termsTitle}`}
                                            >
                                                <span className="material-symbols-outlined">chevron_right</span>
                                            </button>
                                        )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='agree_btn_box px-3 py-3'>
                                <div><button className={`${allRequiredChecked ? 'agree_active' : ''}`} disabled={!allRequiredChecked} onClick={handleShowJoin}>다음</button></div>
                            </div>
                        </animated.div>
                        :null
                    )}
                </div>
            }
            {
                showJoin && 
                <EmailJoin2 handleJoinBack={handleJoinBack} handleJoinClose={handleJoinClose} animated={animated} 
                            joinTransitions={joinTransitions} showJoin={showJoin} useTransition={useTransition} setShowAgree={setShowAgree} setShowJoin={setShowJoin}
                            handleJoinInfo={handleJoinInfo} userList={userList} setUserList={setUserList} userInput={userInput} setUserInput={setUserInput} showJoinInfo={showJoinInfo}
                />
            }
            {
                showTermsOfUse &&
                <TermsOfUse showTermsOfUse={showTermsOfUse} termsOfUseTransitions={termsOfUseTransitions} animated={animated}
                            setShowTermsOfUse={setShowTermsOfUse} labelText={labelText} termsInfo={termsInfo} termsStateCheck={termsStateCheck}
                />
            }
            {
                showJoinInfo &&
                <EmailJoin3 showJoinInfo={showJoinInfo} animated={animated} joinTransitions2={joinTransitions2} 
                            handleJoinInfoBack={handleJoinInfoBack} handleJoinInfoClose={handleJoinInfoClose}
                            setShowAgree={setShowAgree} setShowJoin={setShowJoin} setShowJoinInfo={setShowJoinInfo} 
                            useTransition={useTransition} userList={userList} setUserInput={setUserInput}
                            userInput={userInput}showJoinFavority={showJoinFavority} setShowJoinFavority={setShowJoinFavority}
                            handleJoinFavority={handleJoinFavority}
                />
            }
            {
                showJoinFavority && 
                <JoinFavority handleJoinFavBack={handleJoinFavBack} handleJoinFavClose={handleJoinFavClose} 
                joinTransitions3={joinTransitions3} animated={animated} showJoinFavority={showJoinFavority}
                useTransition={useTransition} setShowAgree={setShowAgree} setShowJoin={setShowJoin}
                setShowJoinInfo={setShowJoinInfo} setShowJoinFavority={setShowJoinFavority}
                userList={userList} handleFavorityDdayClick={handleFavorityDdayClick} handleSkipClick={handleSkipClick} handleFavorityAddClick={handleFavorityAddClick} handleFavDdayBack={handleFavDdayBack}
                selectedItems={selectedItems} setSelectedItems={setSelectedItems}
                />
            }
            {
                showFavorityDday &&
                <FavorityDday showFavorityDday={showFavorityDday} animated={animated} favorityDdayTransition={favorityDdayTransition}
                setShowAgree={setShowAgree} setShowJoin={setShowJoin} setShowJoinInfo={setShowJoinInfo} setShowJoinFavority={setShowJoinFavority}
                handleFavDdayBack={handleFavDdayBack} handleFavDdayClose={handleFavDdayClose} selectedItems={selectedItems}
                useTransition={useTransition} userList={userList} setSelectedItems={setSelectedItems} updateDday={updateDday}
                recommendTransition={recommendTransition} showRecommend={showRecommend} setShowRecommend={setShowRecommend}
                favoritydDayNext={favoritydDayNext}
                />
            }
            {
                showRecommend &&
                <RecomMender setShowAgree={setShowAgree} setShowJoin={setShowJoin} setShowJoinInfo={setShowJoinInfo} 
                setShowJoinFavority={setShowJoinFavority} showRecommend={showRecommend} recommendTransition={recommendTransition}
                animated={animated} useTransition={useTransition} setShowRecommend={setShowRecommend}
                selectedItems={selectedItems} userList={userList} />
            }
            {
                showFavorityAdd &&
                <FavorityAdd showFavorityAdd={showFavorityAdd} favorityAddTransition={favorityAddTransition} animated={animated} setShowFavorityAdd={setShowFavorityAdd}/>
            }
        </>
    )
}

export default EmailJoin