import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import event from '../../containers/event/event.module.css'
import store from '../../containers/market/store/store.module.css'
import admin from '../../admin/admin.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { LoginContext } from '../../contexts/LoginContextProvider';
import * as storeApi from '../../apis/store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const EventComment = () => {
  
    const {eventNumber} = useParams();
    const {userInfo, isLogin} = useContext(LoginContext);
    const navigate = useNavigate();
    const location = useLocation(); 
    const MySwal = withReactContent(Swal);

    const { eventInfo } = location.state || {};

    const [ listImage, setListImage ] = useState([]);

    const [ participation, setParticipation ] = useState('');

    const [showCommentPopup, setShowCommentPopup] = useState(false);
    const [modalHeight, setModalHeight] = useState(window.innerHeight * 0.6);
    const [isDragging, setIsDragging] = useState(false);
    const isDraggingRef = useRef(false);
    const startYRef = useRef(0);
    const initialHeightRef = useRef(modalHeight);

    const [commentInfo, setCommentInfo] = useState({
        commentNo : '', parentNo : null, eventNumber : '', userId : '', secretState : false, 
        commentText : '', likeView : 0, deleteState :false
    })

    const [commentList, setCommentList] = useState([]);
    const [bestComment, setBestComment] = useState({});

    const [expandedCommentId, setExpandedCommentId] = useState(null);
    const [replyToCommentId, setReplyToCommentId] = useState(null);
    const [replyText, setReplyText] = useState("");
    const [replyState, setReplyState] = useState(false);

    const [commentLikeState, setCommentLikeState] = useState([]);
    const [likedComments, setLikedComments] = useState([]);
    
    const [ loading, setLoading ] = useState(false);

    const [ secretBtn, setSecretBtn ] = useState(false);
    const [ replySecret, setReplySecret] = useState(false);
    const [ replySecretState, setReplySecretState] = useState(false);

    const [showBestReplyComment, setShowBestReplyComment] = useState(false);

    const [ bestPopupOpen, setBestPopupOpen ] = useState(false);
    
    const [ clipboardMessage, setClipboardMessage ] = useState('');

    const [ shared, setShared ] = useState(false);

    const handleBack = () => {
        navigate(-1);
    }
    
    const toggleReplies = (commentId) => {
        setExpandedCommentId((prevId) => (prevId === commentId ? null : commentId));
    };

    useEffect(() => {
        // console.log('commentInfo : ', commentInfo);
    }, [commentInfo]);

    useEffect(() => {
        // console.log('bestComment : ', bestComment);
    }, [bestComment]);

    useEffect(() => {
        // console.log('eventInfo : ', eventInfo);

        if (eventInfo) {
            const images = [
                eventInfo.eventImage1,
                eventInfo.eventImage2,
                eventInfo.eventImage3,
                eventInfo.eventImage4,
                eventInfo.eventImage5,
            ].filter(image => image !== null && image !== '');

            setListImage(images);
        }

        
        eventConfirm();
        
    }, [eventInfo]);

    const eventConfirm = async () => {
        try {
            const response = await storeApi.eventConfirm(eventInfo?.eventNumber, userInfo?.userId);
            const data = response.data;
            // console.log('eventCheck : ', data);

            setParticipation(data);

        } catch (error) {
            console.error('Error fetching event confirmation:', error);
        }
    }

    useEffect(() => {
        eventCommentSelect();
    }, [eventNumber]);

    useEffect(() => {
        fetchCommentLikeState();
    }, [userInfo]);

    // 좋아요 상태 데이터 가져오기
    const fetchCommentLikeState = async () => {
        if (userInfo) {
            const response = await storeApi.eventCommentLikeSelect(userInfo?.userId);
            setCommentLikeState(response.data || []);
        }
    };
    
    useEffect(() => {
        // 좋아요 상태를 반영한 새로운 리스트 생성
        if (Array.isArray(commentList) && Array.isArray(commentLikeState)) {
            const updatedLikedComments = commentList.map((comment) => {
                const liked = commentLikeState.some(
                    (likeState) => likeState.commentNo === comment.commentNo
                );
                return { ...comment, liked };
            });
            setLikedComments(updatedLikedComments); // likedComments로 상태 관리
        } else {
            console.error('Invalid commentList or commentLikeState: Expected arrays.');
            setLikedComments([]);
        }
    }, [commentLikeState, commentList]);

    useEffect(() => {
        // console.log('listImage : ', listImage);
    }, [listImage]);

    useEffect(() => {
        if (!window.Kakao.isInitialized()) {
            window.Kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
        }
    }, []);

    const eventCommentSelect = async () => {
        try {
            const response = await storeApi.eventCommentSelect(eventNumber);
            if (response && response.data && Array.isArray(response.data)) {
                const data = response.data;
    
                // console.log('eventCommentSelect : ', data);
                setCommentList(data);
    
                // likeView가 가장 높은 값을 찾고, 동일하면 regDate가 빠른 순으로 정렬
                const best = data
                    .filter((comment) => {
                        // 방어 코드: 필터링 전 데이터 검증
                        if (typeof comment.likeView !== 'number' || !comment.regDate) {
                            console.warn('Invalid comment data:', comment);
                            return false;
                        }
                        return comment.likeView > 0;
                    })
                    .sort((a, b) => {
                        if (b.likeView !== a.likeView) {
                            return b.likeView - a.likeView; // likeView가 큰 순서대로 정렬
                        }
                        return new Date(a.regDate) - new Date(b.regDate); // 같은 경우 regDate가 빠른 순
                    })[0]; // 가장 첫 번째 값 선택
    
                if (best) {
                    // bestComment와 관련된 대댓글 가져오기
                    const bestCommentReplies = data.filter(
                        (comment) =>
                            comment.parentNo === best.commentNo && comment.parentNo != null
                    );
    
                    setBestComment({
                        ...best,
                        replies: bestCommentReplies, // 대댓글 추가
                    });
                } else {
                    setBestComment(null); // likeView가 모두 0이면 null로 설정
                }
            } else {
                console.error('Invalid response data: Expected an array.');
                setCommentList([]);
                setBestComment(null);
            }
        } catch (error) {
            console.error('Error in eventCommentSelect:', error);
            setCommentList([]);
            setBestComment(null);
        }
    };

    const transitions = useTransition(shared, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translateY(100%)" },
        config: {
            duration: 300,
        },
    });

    const handleShare = () => {
        setShared(true);
    }
    const handleShardBack = () => {
        setShared(false);
    }
    const handleShareKakaoClick = () => {
        if (window.Kakao) {
            const kakao = window.Kakao;
        
            kakao.Share.sendDefault({
                objectType: 'feed',
                content: {
                title: 'title',
                description: '데일리덕에서 구매하고 포인트 받자!',
                imageUrl: 'https://dailyduck.com/img/kakaolink40_original.png',
                link: {
                    mobileWebUrl: 'https://dailyduck.com/event/comment/' + eventNumber,
                    webUrl: 'https://dailyduck.com/event/comment/' + eventNumber,
                },
                },
            });
        }
    }
    const handleTwitterShare = () => {
        const url = `https://dailyduck.com/event/comment/${eventNumber}`;
        const text = `데일리덕에서 구매하고 포인트 받자!`;
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            text
        )}&url=${encodeURIComponent(url)}`;
        window.open(twitterUrl);
    };
    const handleNaverLineShare = () => {
        const url = `https://social-plugins.line.me/lineit/share?url=https://dailyduck.com/event/comment/${eventNumber}`
        window.open(url);
    }  

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const handleSmsShare = () => {
        const productLink = `https://dailyduck.com/event/comment/${eventNumber}`;
        const productName = `test`;
        const productDescription = "데일리덕에서 구매하고 포인트 받자!";

        if (!isMobile) {
            alert("SMS 공유는 모바일 기기에서만 가능합니다.");
            return;
        }

        const message = encodeURIComponent(`${productName} - ${productDescription}\n링크: ${productLink}`);
        window.location.href = `sms:&body=${message}`;
    }
    const handleClipboardShare = async () => {
        const text = `https://dailyduck.com/event/comment/${eventNumber}`
        if ('clipboard' in navigator) {
            try {
                await navigator.clipboard.writeText(text);
                setClipboardMessage('클립보드에 복사되었습니다.');
                setTimeout(() => setClipboardMessage(''), 2000);
            } catch (err) {
                console.error('클립보드 복사에 실패했습니다: ', err);
            }
        } else {
            // Fallback for older browsers
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            setClipboardMessage('클립보드에 복사되었습니다.');
            setTimeout(() => setClipboardMessage(''), 2000);
            }
    }
    const handleMoreShare = () => {
        const productLink = `https://dailyduck.com/event/comment/${eventNumber}`;
        const productName = `test`;
        const productDescription = "데일리덕에서 구매하고 포인트 받자!";

        if (navigator.share) {
            navigator.share({
            title: `${productName}`,
            text: `${productDescription}`,
            url: `${productLink}`,
            })
            .then(() => console.log('공유 성공!'))
            .catch((error) => console.log('공유 실패:', error));
        } else {
            // Web Share API를 지원하지 않는 경우의 대체 처리
            alert('Web Share API를 지원하지 않는 브라우저입니다.');
        }
    }

    const onSubScribe = () => {
        // navigate(`/lucked/${eventNumber}`);
        navigate(`/lucked/payment/${eventNumber}`, {state : {paymentInfo : eventInfo }});
    }

    const formatDate = (date) => {
        const days = ['일', '월', '화', '수', '목', '금', '토']; // 요일 배열
        const dateObj = new Date(date); // Date 객체 생성
        const dayOfWeek = days[dateObj.getUTCDay()]; // 요일 추출 (UTC 기준)
        const formattedDate = date.replace('T', ' ').substring(0, 19); // 기존 형식 유지
        return `${formattedDate} (${dayOfWeek})`; // 요일 추가
    };
    

    const onLogin = () => {
        navigate('/');
    };

    const handleCommentFocus = () => {
        setShowCommentPopup(true);
        setModalHeight(window.innerHeight * 0.6);
        
        setCommentInfo({
            ...commentInfo, userId : userInfo?.userId, eventNumber : eventNumber
        });

        setSecretBtn(true);
    }
    
    const handleCommentBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setSecretBtn(false);
        }
    }

    const textRef = useRef();
    const handleResizeHeight = useCallback(() => {
        if (textRef.current) {
            const textarea = textRef.current;
    
            // 내용이 비어 있는 경우 최소 높이로 설정
            if (textarea.value === "") {
                textarea.style.height = "auto"; // 초기화
                textarea.style.height = "2.75em"; // 기본 높이 설정 (rows={1}와 일치)
            } else {
                textarea.style.height = "auto"; // 높이 초기화
                textarea.style.height = `${textarea.scrollHeight}px`; // 내용에 맞게 높이 조정
            }
        }
    }, []);

    const handleMouseDown = (e) => {
        isDraggingRef.current = true;
        setIsDragging(true);
        startYRef.current = e.clientY;
        initialHeightRef.current = modalHeight;
    };

    const handleMouseMove = (e) => {
        if (isDraggingRef.current) {
            const newHeight = modalHeight + (startYRef.current - e.clientY);
            startYRef.current = e.clientY;

            if (newHeight > 150) {
                setModalHeight(newHeight);
            } else {
                setModalHeight(0);
                setTimeout(() => setShowCommentPopup(false), 300);
            }
        }
    };

    const handleMouseUp = () => {
        isDraggingRef.current = false;
        if (modalHeight > 50) {
            setModalHeight(initialHeightRef.current);
        }
        setIsDragging(false);
    };

    const handleTouchStart = (e) => {
        isDraggingRef.current = true;
        setIsDragging(true);
        startYRef.current = e.touches[0].clientY;
        initialHeightRef.current = modalHeight;
    };

    const handleTouchMove = (e) => {
        if (isDraggingRef.current) {
            const newHeight = modalHeight + (startYRef.current - e.touches[0].clientY);
            startYRef.current = e.touches[0].clientY;

            if (newHeight > 150) {
                setModalHeight(newHeight);
            } else {
                setModalHeight(0);
                setTimeout(() => setShowCommentPopup(false), 300);
            }
        }
    };

    const handleTouchEnd = () => {
        isDraggingRef.current = false;
        if (modalHeight > 50) {
            setModalHeight(initialHeightRef.current);
        }
        setIsDragging(false);
    };

    const onCommentInput = (e) => {
        setCommentInfo({
            ...commentInfo, commentText : e.target.value
        });
    }

    const handleCommentSend = () => {
        // console.log('commentInfo', commentInfo);

        if(commentInfo.commentText === '') {
           alert('댓글 내용을 입력해주세요.');
           return;
        }

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>댓글을 등록 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>등록</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await storeApi.eventCommentInsert(commentInfo);
                    const data = response.data;
                    // const data = 'SUCCESS';
        
                    if (data === 'SUCCESS') {

                        eventCommentSelect();

                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>댓글 등록이 완료 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>댓글 등록에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
        
    }

    const onSecretCheck = (e) => {
        
        let checked = e.target.checked;
        // console.log(checked);

        setCommentInfo({
            ...commentInfo, secretState : checked
        });
        
    }

    const commentFormatDate = (dateString) => {
        // 방어 코드: 입력 값이 문자열인지 확인
        if (typeof dateString !== 'string') {
            console.error('Invalid input: dateString must be a string');
            return ''; // 빈 문자열 반환
        }
    
        // 방어 코드: 유효한 날짜 형식인지 확인 (예: ISO 8601)
        const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?\+\d{2}:\d{2}$/;
        if (!iso8601Regex.test(dateString)) {
            console.error('Invalid input: dateString is not a valid ISO 8601 format');
            return ''; // 빈 문자열 반환
        }
    
        // 유효한 경우 포맷팅 수행
        return dateString.replace('T', ' ').replace(/(\.\d+)?\+\d{2}:\d{2}$/, '');
    };

    const handleReplyInputChange = (e) => {
        setReplyText(e.target.value);
    };
    
    const handleReplySend = async () => {
        if (!replyText.trim()) {
            alert("댓글 내용을 입력해주세요.");
            return;
        }
    
        try {
            const replyData = {
                parentNo: replyToCommentId,
                commentText: replyText,
                userId: userInfo?.userId,
                eventNumber: eventNumber,
                secretState: replySecret
            };
    
            const response = await storeApi.eventCommentInsert(replyData);
    
            if (response.data === "SUCCESS") {
                setReplyToCommentId(null);
                setReplyText("");
                eventCommentSelect(); // 댓글 목록 다시 불러오기
            } else {
                alert("댓글 등록에 실패했습니다.");
            }
        } catch (error) {
            console.error("대댓글 등록 에러:", error);
            alert("댓글 등록 중 문제가 발생했습니다.");
        }
    };
    
    const toggleReplyInput = (commentId) => {
        setReplyToCommentId((prevId) => (prevId === commentId ? null : commentId));
    };

    const handleReplyState = () => {
        setReplyState(true);
        setReplySecretState(true);
    }
    const handleNoReplyState = (e) => {
        setReplyState(false);    
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setReplySecretState(false);
        }
    }

    const handleLikeBtn = async (item) => {
        // console.log('item : ', item);
        setLoading(true);
        const response = await storeApi.eventCommentLike(item.commentNo, userInfo?.userId);
        const data = response.data;

        // console.log('상태 : ', data);

        if(data === 'SUCCESS') {
            eventCommentSelect();
            fetchCommentLikeState();

            // 1초 뒤 로딩 상태를 false로 변경
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleANoLikeBtn = async (item) => {
        // console.log('item : ', item);
        setLoading(true);
        const response = await storeApi.eventCommentNoLike(item.commentNo, userInfo?.userId);
        const data = response.data;

        // console.log('상태 : ', data);

        if(data === 'SUCCESS') {
            eventCommentSelect();
            fetchCommentLikeState();
            
            // 1초 뒤 로딩 상태를 false로 변경
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const onReplySecretCheck = (e) => {
        setReplySecret(e.target.checked);
    }

    const handleBestReplyToggle = () => {
        setShowBestReplyComment(!showBestReplyComment);
    }

    const bestLikedState = (item) => {

        return commentLikeState.some(
            (likeState) => likeState.commentNo === item.commentNo
        );
    }

    const handlePopupOpen = () => {
        setShowCommentPopup(true);
        setBestPopupOpen(true);
    }

  return (
    <>
      <div className={`${event.event_comment_container} `}>
        <div className={`${event.event_product_detail_header}`}>
            <div className={`${event.event_product_detail_header_left}`}><button onClick={() => handleBack()}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
            <div className={`${event.event_product_detail_header_right}`}><button><span className="material-symbols-outlined" onClick={() => handleShare()}>share</span></button></div>
        </div>
        <div className={`${event.event_comment_img_box}`}>
          <div>
              <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                  clickable: true,
                  }}
                  // navigation={true}
                  modules={[Pagination, Navigation]}
                  className={`${event.event_comment_swiper}`}
              >
                {
                    listImage.map((item, index) => (
                        <SwiperSlide key={index}><img src={item} alt="이미지" /></SwiperSlide>
                    ))
                }
              </Swiper>
          </div>
        </div>
        <div className='px-3 py-3'>
          <div className='border-bottom'>
            <div className={`${event.event_comment_info_title_box}`}><span>{eventInfo.eventTitle}</span></div>
            <div className={`${event.event_comment_info_sub_title_box}`}><span>{eventInfo.eventSubTitle}</span></div>
            <div className={`${event.event_comment_info_people_box}`}><span>총 참여 인원은 1,164명 입니다.</span></div>
            <div className='py-3'>
              <div className={`${event.event_comment_info_date_box}`}>
                <div><span>이벤트 기간</span></div>
                <div><span>{formatDate(eventInfo.eventStartDate)} ~ {formatDate(eventInfo.eventEndDate)}</span></div>
              </div>
              <div className={`${event.event_comment_info_date_box}`}>
                <div><span>당첨자 발표일</span></div>
                <div><span>{formatDate(eventInfo.eventWinnerDate)}</span></div>
              </div>
              {/* <div className={`${event.event_raffle_info_date_box}`}>
                <div><span>당첨자 구매기간</span></div>
                <div><span>5.4 (목) 12:00 ~ 5.4 (목) 14:00</span></div>
              </div> */}
              {/* <div className={`${event.event_raffle_info_date_box}`}>
                <div><span>안내사항</span></div>
                <div><span>안내사항 내용입니다. 안내사항 내용입니다. 안내사항 내용입니다.</span></div>
              </div> */}
            </div>
          </div>
        </div>
        <div className={`${event.event_comment_content_box}`}>
            <div dangerouslySetInnerHTML={{ __html: eventInfo.eventContent}}></div>
        </div>
        {
            isLogin ?
            <div className={`${event.event_comment_footer_box} border-top`}>
                {
                    bestComment ? !bestPopupOpen ? (
                        <div className={`${event.event_comment_modal_best_content_info} ${event.best_comment}`} style={{cursor : 'pointer'}} onClick={() => handlePopupOpen()}>
                            <div className={`${event.event_comment_modal_best_content_ribbon}`}><span>BEST</span></div>
                            <div className={`${event.event_comment_modal_best_content_top}`}>
                                <div className={`${event.event_comment_modal_content_info_img}`}>
                                    <div>
                                        <img
                                            src={
                                                bestComment && bestComment.user?.userProfile
                                                    ? `${process.env.REACT_APP_API_SERVER}/img?file=${bestComment.user?.userProfile}`
                                                    : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`
                                            }
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className={`${event.event_comment_modal_content_info_data}`}>
                                    <div>
                                        <span>{bestComment && bestComment.user?.userNick}</span>
                                        <span>{commentFormatDate(bestComment.regDate)}</span>
                                    </div>
                                    <div>
                                        {bestComment.secretState ? (
                                            <span>🤫비밀 댓글 입니다.</span>
                                        ) : (
                                            <span>{bestComment.commentText}</span>
                                        )}
                                    </div>
                                    <div>
                                        <div>
                                            {
                                                bestLikedState(bestComment) ?
                                                <button onClick={() => handleANoLikeBtn(bestComment)} className={`${event.event_like_active}`} disabled={true}>
                                                    <span className="material-symbols-outlined">thumb_up</span>
                                                </button>
                                                :
                                                <button onClick={() => handleLikeBtn(bestComment)} disabled={true}>
                                                    <span className="material-symbols-outlined">thumb_up</span>
                                                </button>
                                            }
                                            <span>{bestComment && bestComment.likeView}</span>
                                        </div>
                                        
                                        <div>
                                            <button onClick={() => handleBestReplyToggle()} disabled={true}>
                                                <span className="material-symbols-outlined">comment</span>
                                            </button>
                                            <span>
                                                {bestComment && bestComment.replies ? bestComment.replies.length : 0}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) :
                        <div 
                            className={`${event.event_comment_input_box}`}
                            tabIndex={-1} // tabIndex를 추가하여 focus 가능한 영역으로 설정
                            onBlur={handleCommentBlur} // 블러 이벤트 처리
                        >
                            <textarea 
                                type="text" 
                                rows={1} 
                                onFocus={() => handleCommentFocus()} 
                                // onBlur={() => handleCommentBlur()} 
                                ref={textRef} 
                                onInput={handleResizeHeight} 
                                onChange={(e) => onCommentInput(e)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleCommentSend();
                                    }
                                }}
                                disabled={replyState}
                            />
                            <button onClick={() => handleCommentSend()}><span className="material-symbols-outlined">send</span></button>
                            {
                                secretBtn &&
                                <div className={`${event.event_comment_input_secret}`}>
                                    <input type="checkbox" name="secret" id="secret_comment" onChange={(e) => onSecretCheck(e)}/>
                                    <label htmlFor="secret_comment">비밀댓글</label>
                                </div>
                            }
                        </div>
                    :
                    (
                        <div 
                            className={`${event.event_comment_input_box}`}
                            tabIndex={-1} // tabIndex를 추가하여 focus 가능한 영역으로 설정
                            onBlur={handleCommentBlur} // 블러 이벤트 처리
                        >
                            <textarea 
                                type="text" 
                                rows={1} 
                                onFocus={() => handleCommentFocus()} 
                                // onBlur={() => handleCommentBlur()} 
                                ref={textRef} 
                                onInput={handleResizeHeight} 
                                onChange={(e) => onCommentInput(e)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleCommentSend();
                                    }
                                }}
                                disabled={replyState}
                            />
                            <button onClick={() => handleCommentSend()}><span className="material-symbols-outlined">send</span></button>
                            {
                                secretBtn &&
                                <div className={`${event.event_comment_input_secret}`}>
                                    <input type="checkbox" name="secret" id="secret_comment" onChange={(e) => onSecretCheck(e)}/>
                                    <label htmlFor="secret_comment">비밀댓글</label>
                                </div>
                            }
                        </div>
                    )
                }
            </div>
            :
            <div className={`${event.event_comment_footer_box} border-top`}>
                <div className={`${event.event_comment_no_login}`}><button onClick={onLogin}>로그인 후 이벤트 참여하기</button></div>
            </div>
        }
        {
          shared &&
          <div className={`${store.store_shared_background}`} >
              {transitions((style, item) =>
                  item ? 
                  <animated.div className={`${store.store_shard_box}`} style={style}>
                      <div className='pt-5 px-3 d-flex justify-content-between'>
                          <div><h5>공유하기</h5></div>
                          <div><button className={`${store.store_shared_close}`} onClick={handleShardBack}><span className="material-symbols-outlined">close</span></button></div>
                      </div>
                      <div className='d-flex justify-content-center py-3'>
                          <div className={`${store.store_shard_btn_box}`}>
                              <button onClick={handleShareKakaoClick}>
                                  <div>
                                      <div><img src="../../img/icon/share/kakaotalk.png" alt="카카오톡" /></div>
                                      <div className='py-1'><span>카카오톡</span></div>
                                  </div>
                              </button>
                          </div>
                          <div className={`${store.store_shard_btn_box}`}>
                              <button onClick={handleTwitterShare}>
                                  <div>
                                      <div><img src="../../img/icon/share/twiter.png" alt="트위터" /></div>
                                      <div className='py-1'><span>트위터</span></div>
                                  </div>
                              </button>
                          </div>
                          <div className={`${store.store_shard_btn_box}`}>
                              <button onClick={handleNaverLineShare}>
                                  <div>
                                      <div><img src="../../img/icon/share/line.png" alt="라인" /></div>
                                      <div className='py-1'><span>라인</span></div>
                                  </div>
                              </button>
                          </div>
                          <div className={`${store.store_shard_btn_box}`}>
                              <button onClick={handleSmsShare}>
                                  <div>
                                      <div><img src="../../img/icon/share/message.png" alt="SMS" /></div>
                                      <div className='py-1'><span>SMS</span></div>
                                  </div>
                              </button>
                          </div>
                      </div>
                      <div className='d-flex'>
                          <div className={`${store.store_shard_btn_bottom_box}`}>
                              <button onClick={() => handleClipboardShare()}>
                                  <div>
                                      <div><img src="../../img/icon/share/url.png" alt="" /></div>
                                      <div><span>URL</span></div>
                                  </div>
                              </button>
                          </div>
                          <div className={`${store.store_shard_btn_bottom_box}`}>
                              <button onClick={handleMoreShare}>
                                  <div>
                                      <div><img src="../../img/icon/share/more.png" alt="" /></div>
                                      <div><span>더보기</span></div>
                                  </div>
                              </button>
                          </div>
                      </div>
                      {
                          clipboardMessage !== '' ? 
                          <div className={`${store.store_clipboard_box}`}><span>{clipboardMessage}</span></div>
                          :
                          null
                      }
                  </animated.div>
                  : null
              )}
          </div>
      }
      </div>

      {
        showCommentPopup &&
        <div
            className={`${event.event_comment_modal_container}`}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div
                className={`${event.event_comment_modal_box}`}
                style={{
                  height: `${modalHeight}px`,
                  transition: isDragging ? 'none' : 'height 0.3s ease', // 드래그 중에는 애니메이션 제거
                }}
            >
                <div
                    className={`${event.event_comment_modal_header}`}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleTouchStart}
                    style={{ cursor: 'grab' }}
                >
                    <div><span>댓글</span><span>{commentList.length}</span></div>
                    <div>
                        <button onClick={() => {setShowCommentPopup(false); setBestPopupOpen(false);}}>
                            <span className="material-symbols-outlined">close</span>
                        </button>
                    </div>
                </div>
                <div className={`${event.event_comment_modal_content}`}>
                    {bestComment && (
                        <div className={`${event.event_comment_modal_best_content_info} ${event.best_comment}`}>
                            <div className={`${event.event_comment_modal_best_content_ribbon}`}><span>BEST</span></div>
                            <div className={`${event.event_comment_modal_best_content_top}`}>
                                <div className={`${event.event_comment_modal_content_info_img}`}>
                                    <div>
                                        <img
                                            src={
                                                bestComment.user.userProfile
                                                    ? `${process.env.REACT_APP_API_SERVER}/img?file=${bestComment.user.userProfile}`
                                                    : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`
                                            }
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className={`${event.event_comment_modal_content_info_data}`}>
                                    <div>
                                        <span>{bestComment.user.userNick}</span>
                                        <span>{commentFormatDate(bestComment.regDate)}</span>
                                    </div>
                                    <div>
                                        {bestComment.secretState ? (
                                            <span>🤫비밀 댓글 입니다.</span>
                                        ) : (
                                            <span>{bestComment.commentText}</span>
                                        )}
                                    </div>
                                    <div>
                                        <div>
                                            {
                                                bestLikedState(bestComment) ?
                                                <button onClick={() => handleANoLikeBtn(bestComment)} className={`${event.event_like_active}`}>
                                                    <span className="material-symbols-outlined">thumb_up</span>
                                                </button>
                                                :
                                                <button onClick={() => handleLikeBtn(bestComment)}>
                                                    <span className="material-symbols-outlined">thumb_up</span>
                                                </button>
                                            }
                                            <span>{bestComment.likeView}</span>
                                        </div>
                                        
                                        <div>
                                            <button onClick={() => handleBestReplyToggle()}>
                                                <span className="material-symbols-outlined">comment</span>
                                            </button>
                                            <span>
                                                {bestComment.replies.length}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showBestReplyComment && bestComment.replies && bestComment.replies.length > 0 && (
                                <div className={`${event.event_comment_modal_reply_box}`}>
                                    {bestComment.replies.map(reply => (
                                        <div
                                            key={reply.commentNo}
                                            className={`${event.event_comment_modal_reply_info} ${event.reply}`}
                                        >
                                            <div className={`${event.event_comment_modal_reply_info_img}`}>
                                                <div>
                                                    <img
                                                        src={
                                                            reply.user.userProfile
                                                                ? `${process.env.REACT_APP_API_SERVER}/img?file=${reply.user.userProfile}`
                                                                : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className={`${event.event_comment_modal_reply_info_data}`}>
                                                <div>
                                                    <span>{reply.user.userNick}</span>
                                                    <span>{commentFormatDate(reply.regDate)}</span>
                                                </div>
                                                <div>
                                                    {reply.secretState ? (
                                                        <span>🤫비밀 댓글 입니다.</span>
                                                    ) : (
                                                        <span>{reply.commentText}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {commentList.length > 0 ? (
                        commentList
                            .filter((comment) => comment.parentNo === null) // 일반 댓글만 필터링
                            .map((item) => {
                                const userProfile = item.user.userProfile
                                    ? `${process.env.REACT_APP_API_SERVER}/img?file=${item.user.userProfile}`
                                    : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`;

                                const isLiked = commentLikeState.some(
                                    (likeState) => likeState.commentNo === item.commentNo
                                );

                                return (
                                    <>
                                        <div key={item.commentNo} className={`${event.event_comment_modal_content_info}`}>
                                            <div className={`${event.event_comment_modal_content_info_img}`}>
                                                <div><img src={userProfile} alt="" /></div>
                                            </div>
                                            <div className={`${event.event_comment_modal_content_info_data}`}>
                                                <div>
                                                    <span>{item.user.userNick}</span>
                                                    <span>{commentFormatDate(item.regDate)}</span>
                                                </div>
                                                <div>
                                                    {
                                                        item.secretState ?
                                                        <span>🤫비밀 댓글 입니다.</span>
                                                        :
                                                        <span>{item.commentText}</span>
                                                    }
                                                </div>
                                                <div>
                                                    <div>
                                                        {isLiked ? (
                                                            <button onClick={() => handleANoLikeBtn(item)} className={`${event.event_like_active}`}>
                                                                <span className="material-symbols-outlined">thumb_up</span>
                                                            </button>
                                                        ) : (
                                                            <button onClick={() => handleLikeBtn(item)}>
                                                                <span className="material-symbols-outlined">thumb_up</span>
                                                            </button>
                                                        )}
                                                        <span>{item.likeView}</span>
                                                    </div>
                                                    <div>
                                                        <button>
                                                            <span className="material-symbols-outlined">thumb_down</span>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button onClick={() => toggleReplyInput(item.commentNo)}>
                                                            <span className="material-symbols-outlined">comment</span>
                                                        </button>
                                                        <span>
                                                            {commentList.filter((reply) => reply.parentNo === item.commentNo).length}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${event.event_comment_modal_content_info_more}`}>
                                                <button>
                                                    <span className="material-symbols-outlined">more_vert</span>
                                                </button>
                                            </div>
                                        </div>

                                            {/* 대댓글 리스트 */}
                                            {replyToCommentId === item.commentNo && commentList
                                                .filter((reply) => reply.parentNo === item.commentNo)
                                                .map((reply) => (
                                                    <div
                                                        key={reply.commentNo}
                                                        className={`${event.event_comment_modal_reply_info} ${event.reply}`}
                                                    >
                                                        <div className={`${event.event_comment_modal_reply_info_img}`}>
                                                            <div>
                                                                <img
                                                                    src={
                                                                        reply.user.userProfile
                                                                            ? `${process.env.REACT_APP_API_SERVER}/img?file=${reply.user.userProfile}`
                                                                            : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={`${event.event_comment_modal_reply_info_data}`}>
                                                            <div>
                                                                <span>{reply.user.userNick}</span>
                                                                <span>{commentFormatDate(reply.regDate)}</span>
                                                            </div>
                                                            <div>
                                                                {
                                                                    reply.secretState ?
                                                                    <span>🤫비밀 댓글 입니다.</span>
                                                                    :
                                                                    <span>{reply.commentText}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                
                                        

                                            {/* 대댓글 입력 필드 */}
                                            {replyToCommentId === item.commentNo && (
                                                <div 
                                                    className={`${event.event_reply_input_box}`}
                                                    tabIndex={-1} // tabIndex를 추가하여 focus 가능한 영역으로 설정
                                                    onBlur={handleNoReplyState} // 블러 이벤트 처리
                                                >
                                                    <textarea
                                                        placeholder="답글을 입력하세요."
                                                        value={replyText}
                                                        onChange={handleReplyInputChange}
                                                        onFocus={handleReplyState}
                                                        // onBlur={handleNoReplyState}
                                                        row={1}
                                                    />
                                                    <button onClick={handleReplySend}>
                                                        <span className="material-symbols-outlined">send</span>
                                                    </button>
                                                    {
                                                        replySecretState &&
                                                        <div className={`${event.event_comment_input_secret}`}>
                                                            <input type="checkbox" name="secret" id="secret_comment" onChange={(e) => onReplySecretCheck(e)}/>
                                                            <label htmlFor="secret_comment">비밀댓글</label>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                    </>
                                );
                            })
                    ) : (
                        <div>
                            <div>
                                <span>등록된 이벤트 댓글이 없습니다😓</span>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>
      }
      {
        loading &&
        <div className={`${store.store_product_detail_loading}`}>
                <img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" />
        </div>
      }
    </>
  )
}

export default EventComment