import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ss from '../../../../containers/market/support/venue/support_shop.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const VenueRental = ({ category, onHeartClick, supportData, date, userInfo, activeSubCategory, rentalProductListRef, supportDataLength }) => {
  const safeSupportData = Array.isArray(supportData) ? supportData : [];
  const [userId, setUserId] = useState('');
  const [heartClicked, setHeartClicked] = useState({});
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const [startX, setStartX] = useState(null); // 드래그 및 터치 시작 위치

  useEffect(() => {
    if (userInfo?.userId) {
      setUserId(userInfo.userId);
    }
  }, [userInfo]);

  const filteredSupportData = activeSubCategory === 'all'
    ? safeSupportData.filter(
        (support) =>
          support.supportSaleState === '판매중' && 
          support.supportState === '승인완료'
      )
    : safeSupportData.filter(
        (support) => 
          support.supportSaleState === '판매중' && 
          support.supportState === '승인완료' &&
          support.subCategory === activeSubCategory
  );

  const getMinPrice = (items) => {
    if (!Array.isArray(items) || items.length === 0) return null;
    return Math.min(...items.map((item) => item.mainItemPrice));
  };

  const initialHeartState = filteredSupportData.reduce((acc, support) => {
    acc[support.productNo] = false;
    return acc;
  }, {});

  const fetchWishlist = useCallback(async () => {
    if (!userId) return [];

    try {
      const response = await fetch(`/api/support/wishlist/${userId}`);
      const data = await response.json();
      return Array.isArray(data) ? data : [];
    } catch (error) {
      console.error('Failed to fetch wishlist:', error);
      return [];
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchWishlist().then((wishlist) => {
        const newHeartState = { ...initialHeartState };
        wishlist.forEach((item) => {
          newHeartState[item.productNo] = true;
        });
        setHeartClicked(newHeartState);
      });
    }
  }, [fetchWishlist, userId]);

  const handleHeartClick = async (productNo) => {
    if (!userId) {
      toast.error('로그인 후 사용해 주세요');
      return;
    }

    setHeartClicked((prevState) => {
      const newState = {
        ...prevState,
        [productNo]: !prevState[productNo],
      };
      return newState;
    });

    const isFavorite = !heartClicked[productNo];

    const url = isFavorite ? '/api/support/addWishlist' : '/api/support/removeWishlist';
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, productNo }),
    });

    onHeartClick(productNo);
  };

  const formatRating = (rating) => {
    return rating.toFixed(1);
  };

  const handleImageClick = (images, index) => {
    setPreviewImages(images);
    setCurrentImageIndex(index);
    setIsPreviewOpen(true);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % previewImages.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + previewImages.length) % previewImages.length);
  };

  // 마우스 및 터치 이벤트 핸들러
  const handleStart = (e) => {
    e.preventDefault(); // 기본 드래그 동작 방지
    const startPosition = e.touches ? e.touches[0].clientX : e.clientX;
    setStartX(startPosition);
  };

  const handleMove = (e) => {
    e.preventDefault(); // 기본 드래그 동작 방지
    if (startX === null) return;

    const currentX = e.touches ? e.touches[0].clientX : e.clientX;
    const distance = currentX - startX;

    if (distance > 50) {
      // 오른쪽으로 드래그 또는 스와이프
      handlePrevImage();
      setStartX(null);
    } else if (distance < -50) {
      // 왼쪽으로 드래그 또는 스와이프
      handleNextImage();
      setStartX(null);
    }
  };

  const handleEnd = () => {
    setStartX(null);
  };

  return (
    <div className="venue">
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="d-flex justify-content-between venue_total py-1 px-3">
        <span>
          전체<span className="product_count ps-1">{supportDataLength}</span>
        </span>
        <span>
          <button className="recent">
            기본순<span className="material-symbols-outlined">expand_more</span>
          </button>
        </span>
      </div>
      <div className="venue_product_box pt-3 px-2" ref={rentalProductListRef}>
        {filteredSupportData.map((support) => {

          const minPrice = getMinPrice(support.mainItem);
          const hasOptions = Array.isArray(support.mainItem) && support.mainItem.length > 0;
          const isHeartClicked = heartClicked[support.productNo];

          const supportImages = [
            support.representativeImage,
            support.listImage1,
            support.listImage2,
            support.listImage3,
            support.listImage4,
          ].filter(Boolean);

          return (
            <div className="position-relative px-2" key={support.productNo}>
              <Link
                className="venue_product"
                to={`/venue/${support.productNumber}`}
                state={{ category: category, support: support, date: date }}
              >
                <div className={`search_content_support_info ${ss.support_content_info}`}>
                  <div>
                    <div>
                      <img src={support.shopProfile} alt="" />
                    </div>
                    <div>
                      <span>{support.productName}</span>
                    </div>
                  </div>
                  <div>
                    <span>{support.productInfo}</span>
                  </div>
                  <div className={`${ss.support_content_price_rate_star_box}`}>
                    <div>
                      <div>
                        <img src="../img/icon/star_fill.png" alt="별점" />
                      </div>
                      <div className="ps-1">
                        <span>{support.reviewRating.toFixed(1)}</span>
                      </div>
                    </div>
                    <div>
                      <span>리뷰</span>
                      <span>{support.reviewCount}</span>
                    </div>
                    <div>
                      {minPrice !== null && (
                        <span>
                          &#8361;{minPrice.toLocaleString()}{hasOptions ? ' ~' : ''}
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <Swiper 
                      slidesPerView={"auto"} 
                      spaceBetween={10} 
                      modules={[Pagination, Navigation]}
                      className="search_content_support_item"
                    >
                      {supportImages.map((image, index) => (
                        <SwiperSlide key={index}>
                          <div className="search_content_support_item_img">
                            <LazyLoadImage
                              src={image}
                              effect="blur"
                              alt={`이미지 ${index + 1}`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleImageClick(supportImages, index);
                              }}
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </Link>
              <div className="position-absolute pe-2 pb-2 z-3 mt-2 heart_box">
                <button
                  className="heart_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleHeartClick(support.productNo);
                  }}
                >
                  {isHeartClicked ? (
                    <img className="icon_size" src="../img/icon/venue/heart_fill.png" alt="찜O" />
                  ) : (
                    <img className="icon_size" src="../img/icon/venue/white_heart.png" alt="찜X" />
                  )}
                </button>
              </div>
            </div>
          );
        })}
      </div>

      {isPreviewOpen && (
        <div
          className={`${ss.support_content_image_preview_content}`}
          onMouseDown={handleStart}
          onMouseMove={handleMove}
          onMouseUp={handleEnd}
          onTouchStart={handleStart}
          onTouchMove={handleMove}
          onTouchEnd={handleEnd}
        >
          <div className={`${ss.support_content_image_preview_close}`}><button onClick={() => setIsPreviewOpen(false)}><span className="material-symbols-outlined">close</span></button></div>
          <img
            src={previewImages[currentImageIndex]}
            alt="프리뷰 이미지"
          />
        </div>
      )}
    </div>
  );
};

export default VenueRental;
