import { Pagination } from 'antd';
import admin from '../../../admin.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import * as product from '../../../../apis/store';
import * as adminApi from '../../../../apis/admin';
import * as seller from '../../../../apis/seller';
import { LoginContext } from '../../../../contexts/LoginContextProvider';

import { formatDistanceToNow } from 'date-fns';
import { formatDistanceStrict } from 'date-fns';
import { ko } from 'date-fns/locale';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

const AdminProduct = () => {
    const { isLogin, adminlogout, userInfo, loginCheck, roles, roleListCheck } = useContext(LoginContext);
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(7);
    const [totalItems, setTotalItems] = useState(0);
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const [activeButton, setActiveButton] = useState('all');

    const [productList, setProductList] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [rejectionReasons, setRejectionReasons] = useState({});
    const [shopInfo, setShopInfo] = useState(null);
    const [originalProductList, setOriginalProductList] = useState([]); // 원본 리스트 상태 추가


    const [searchTerm, setSearchTerm] = useState(''); // 검색어 상태 추가

    const [showMobileProductMangementState, setShowMobileProductMangementState] = useState(false);

    const [productLength, setProductLength] = useState(0);
    const [productStateList, setProductStateList] = useState([]);


    const [filters, setFilters] = useState({
        dailyduck: false,
        consignment: false,
        seller: false,
    });

    useEffect(() => {
        if (userInfo) {
            productSelect();
            productAllSelectLength();
            productStateSelect();
            shopSelect();
        }
    }, [userInfo, roleListCheck, currentPage, activeButton]);
    
    useEffect(() => {
        console.log('shopInfo : ', shopInfo);
    }, [shopInfo]);
    

    useEffect(() => {
        console.log(productList);
    }, [productList])

    // 검색 핸들러 함수
    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            performSearch();
        }
    };

    const performSearch = async () => {
        if (searchTerm.trim() === '') {
            // 검색어가 비어 있으면 원본 리스트를 보여줌
            // setProductList(originalProductList);
            setCurrentPage(1);
            setItemPerPage(7);
            searchReturnProductSelect(1, 7);
            productAllSelectLength(userInfo?.userId, roleListCheck);
            return;
        }
    
        const lowercasedFilter = searchTerm.toLowerCase();

        const response = await product.productSearchData(lowercasedFilter);
        const filteredData = response.data;

        // console.log('filteredData : ', filteredData);

        // const filteredData = originalProductList.filter((item) => {
        //     return (
        //         (item.enabledState && item.enabledState.toLowerCase().includes(lowercasedFilter)) ||
        //         (item.productBadge && item.productBadge.toLowerCase().includes(lowercasedFilter)) ||
        //         (item.productName && item.productName.toLowerCase().includes(lowercasedFilter)) ||
        //         (item.productDetail && item.productDetail.toLowerCase().includes(lowercasedFilter)) ||
        //         (item.productNumber && item.productNumber.toString().includes(lowercasedFilter)) ||
        //         (item.shopName && item.shopName.toLowerCase().includes(lowercasedFilter)) ||
        //         (item.userId && item.userId.toLowerCase().includes(lowercasedFilter)) ||
        //         (item.saleState && item.saleState.toLowerCase().includes(lowercasedFilter)) ||
        //         (item.productPrice && item.productPrice.toString().includes(lowercasedFilter)) ||
        //         (item.productsalePrice && item.productsalePrice.toString().includes(lowercasedFilter))
        //     );
        // });
    
        setProductList(filteredData);
        setProductLength(filteredData.length);
        setCurrentPage(1);
        setItemPerPage(filteredData.length);
    };
    
    

    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);
        setSelectedProducts(isChecked ? productList.map((item) => item.productNumber) : []);
    };

    const handleSelectProduct = (productNumber) => {
        setSelectedProducts((prevSelected) =>
            prevSelected.includes(productNumber)
                ? prevSelected.filter((num) => num !== productNumber)
                : [...prevSelected, productNumber]
        );
    };

    const handleDeleteSelected = () => {
        console.log('selectedProducts : ', selectedProducts);
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>선택된 상품을 삭제 하시겠습니까?</span></div>
                    <div class='${admin.admin_product_popup_subtitle}'><span>삭제된 상품은 되돌릴 수 없습니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>삭제</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                productDelete();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
                MySwal.close();
            }
        }

        async function productDelete() {
            try {
                const response = await product.productDelete(selectedProducts);

                if (response.status === 200) {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html: `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>상품이 삭제 되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                }
            } catch (error) {
                console.error('Error deleting products:', error);
                MySwal.fire({
                    icon: 'error',
                    title: '삭제 실패',
                    text: '상품 삭제 중 오류가 발생했습니다.',
                });
            }
        }

        function sellerAddConfirm(e) {
            if (e.target && e.target.id === 'seller_add_confirm') {
                setProductList((prevList) =>
                    prevList.filter((item) => !selectedProducts.includes(item.productNumber))
                );
                setSelectedProducts([]);
                setSelectAll(false);
                MySwal.close();
                document.removeEventListener('click', sellerAddConfirm);
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddCancel);
                document.removeEventListener('click', sellerAddSuccess);
            }
        }
    };


    const productSelect = async () => {
        try {
            const response = await product.productSelect(userInfo && userInfo.userId, roleListCheck && roleListCheck, currentPage, itemsPerPage, activeButton);
            const data = response.data;
    
            console.log(data);
    
            if (Array.isArray(data)) {
                const filteredData = userInfo && userInfo.userId === 'admin'
                    ? data.filter((item) => item.enabledState !== '승인대기' && item.enabledState !== '승인거절')
                    : data;
    
                setProductList(filteredData); // 필터된 리스트 설정
                setOriginalProductList(filteredData); // 원본 리스트 설정
            } else {
                console.error('Fetched data is not an array:', data);
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    const searchReturnProductSelect = async (currentPage, itemsPerPage) => {
        try {
            const response = await product.productSelect(userInfo && userInfo.userId, roleListCheck && roleListCheck, currentPage, itemsPerPage);
            const data = response.data;
    
            console.log('productSelect : ', data);
    
            if (Array.isArray(data)) {
                const filteredData = userInfo && userInfo.userId === 'admin'
                    ? data.filter((item) => item.enabledState !== '승인대기' && item.enabledState !== '승인거절')
                    : data;
    
                console.log('filteredData : ', filteredData);
                setProductList(filteredData); // 필터된 리스트 설정
                setOriginalProductList(filteredData); // 원본 리스트 설정
            } else {
                console.error('Fetched data is not an array:', data);
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    const productAllSelectLength = async () => {
        const response = await product.productAllSelect(userInfo && userInfo.userId, roleListCheck && roleListCheck, activeButton);
        const data = response.data;
        
        console.log('productAllSelect : ', data);

        setProductLength(data);
    }

    const productStateSelect = async () => {
        const response = await product.productStateSelect(userInfo?.userId, roleListCheck);
        const data = response.data;

        setProductStateList(data);
    }

    const shopSelect = async () => {
        const response = await seller.shopSelect(userInfo&&userInfo?.userId);
        const data = response.data;

        console.log('shop : ', data);

        if(data !== null || data !== "") {
            setShopInfo(data);
        } 
    }

    const filteredProductList = productList.filter((item) => {
        const { dailyduck, consignment, seller } = filters;
    
        if (dailyduck && consignment && seller) {
            return true; // Show all products when all filters are selected
        } else if (dailyduck && consignment) {
            return item.userId === 'admin' || item.consignmentStatus === true;
        } else if (dailyduck && seller) {
            return item.userId === 'admin' || (item.userId !== 'admin' && item.consignmentStatus === false);
        } else if (consignment && seller) {
            return item.userId !== 'admin' && item.consignmentStatus === true;
        } else if (dailyduck) {
            return item.userId === 'admin';
        } else if (consignment) {
            return item.consignmentStatus === true;
        } else if (seller) {
            return item.userId !== 'admin' && item.consignmentStatus === false;
        } else {
            return true; // Show all products if no filters are selected
        }
    });

    const getFilteredAndPaginatedProducts = () => {
        return filteredProductList
            .filter((item) => {
                if (activeButton === 'all') return true;
                if (activeButton === 'sale' && item.saleState === '판매중') return true;
                if (activeButton === 'soldout' && item.saleState === '품절') return true;
                if (activeButton === 'hide' && item.saleState === '숨김') return true;
                return false;
            })
            // .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    };

    const totalItemsCount = filteredProductList.filter((item) => {
        if (activeButton === 'all') return true;
        if (activeButton === 'sale' && item.saleState === '판매중') return true;
        if (activeButton === 'soldout' && item.saleState === '품절') return true;
        if (activeButton === 'hide' && item.saleState === '숨김') return true;
        return false;
    }).length;

    const allCount = productStateList.length;
    const saleCount = productStateList.filter(item => item.saleState === '판매중').length;
    const soldOutCount = productStateList.filter(item => item.saleState === '품절').length;
    const hiddenCount = productStateList.filter(item => item.saleState === '숨김').length;

    const onProductAdd = () => {
        if(shopInfo !== null) {
            navigate('/admin/shop/product/add');
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>등록된 상점 정보가 없습니다.</span></div>
                        <div class='${admin.admin_seller_add_sub_title}'><span>상점 정보를 등록하러 갈까요??</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>아니오</button>
                          <button class='mx-1' id='seller_add_success'>예</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
    
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
    
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    navigate('/admin/shopsetting');
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }

    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const fixedPoint = 100;
        setIsFixed(window.scrollY > fixedPoint);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    
    const [mainCategory, setMainCategory] = useState([
        {mainCategoryNo : 1, mainCategoryName: '스토어'},
        {mainCategoryNo : 2, mainCategoryName: '프로미스나인'}
    ]);
    const [middleCategory, setMiddleCategory] = useState([
        {middleCategoryNo: 1, middleCategoryName: '크리에이터', mainCategory: '스토어'},
        {middleCategoryNo: 2, middleCategoryName: '팬시', mainCategory: '스토어'},
        {middleCategoryNo: 3, middleCategoryName: '인형', mainCategory: '스토어'},
        {middleCategoryNo: 4, middleCategoryName: '앨범', mainCategory: '스토어'},
        {middleCategoryNo: 5, middleCategoryName: '생카템', mainCategory: '스토어'},
        {middleCategoryNo: 6, middleCategoryName: '팬싸템', mainCategory: '스토어'},
        {middleCategoryNo: 7, middleCategoryName: '콘서트템', mainCategory: '스토어'},
        {middleCategoryNo: 8, middleCategoryName: '손민수템', mainCategory: '스토어'}
    ]);
    const [openStates, setOpenStates] = useState(mainCategory.map(() => false));

    const toggleOpen = (index) => {
        const newOpenStates = [...openStates];
        newOpenStates[index] = !newOpenStates[index];
        setOpenStates(newOpenStates);
    };
    const [showCategory, setShowCategory] = useState(window.innerWidth >= 991);
    const onCategoryOpen = () => {
        setShowCategory(!showCategory);
    }

        useEffect(() => {
            const handleResize = () => {
                if (window.innerWidth >= 991) {
                    setShowCategory(true);
                } else {
                    setShowCategory(false);
                }
            };

            window.addEventListener('resize', handleResize);

            return () => {
            window.removeEventListener('resize', handleResize);
            };
        }, []);

        
    // 서버에서 받은 시간을 시간대 변환 없이 비교하는 함수
    const formatDate = (dateString) => {
        try {
        // 서버에서 받은 시간을 '년-월-일 시:분:초'로 취급
        if (!dateString || typeof dateString !== 'string') {
            throw new Error('Invalid date string');
        }
    
        const [datePart, timePart] = dateString.split('T');
        if (!datePart || !timePart) {
            throw new Error('Invalid date format');
        }
    
        const [hours, minutes, seconds] = timePart.split('.')[0].split(':');
        if (!hours || !minutes || !seconds) {
            throw new Error('Invalid time format');
        }
    
        // 로컬 시간으로 새로운 날짜 객체 생성 (시간대 무시)
        const serverDate = new Date(`${datePart}T${hours}:${minutes}:${seconds}`);
        const now = new Date(); // 현재 시간을 로컬 시간 그대로 사용
    
        // 두 시간을 비교하여 상대적인 시간 출력
        return formatDistanceStrict(serverDate, now, { addSuffix: true, locale: ko });
    
        } catch (error) {
        console.error('Error formatting date:', error.message);
        return 'Invalid date'; // 에러 발생 시 기본 값 반환
        }
  };

  const onProductRead = (productNumber) => {
    navigate(`/admin/shop/product/${productNumber}`);
  }

    const onProductStateSales = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>선택된 상품을 판매중 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_product_popup_subtitle}'><span>승인 완료 된 상품을 판매중 처리시 고객에게 바로 노출됩니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>판매중처리</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                stateChange('판매중');
                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                    <div>
                    <div class='${admin.admin_seller_add_title}'><span>상품이 판매중 처리 되었습니다.</span></div>
                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                    </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
                document.addEventListener('click', sellerAddConfirm);
            }
        }

        function sellerAddConfirm(e) {
            if (e.target && e.target.id === 'seller_add_confirm') {
                MySwal.close();
                document.removeEventListener('click', sellerAddConfirm);
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddConfirm);
                document.removeEventListener('click', sellerAddCancel);
                document.removeEventListener('click', sellerAddSuccess);
            }
        }
    }
    const onProductStateSoldOut = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>선택된 상품을 품절 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_product_popup_subtitle}'><span>품절 된 상품은 노출은 되나 고객 주문이 불가합니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>품절처리</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                
                stateChange('품절');

                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                    <div>
                    <div class='${admin.admin_seller_add_title}'><span>상품이 품절 처리 되었습니다.</span></div>
                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                    </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
                document.addEventListener('click', sellerAddConfirm);
            }
        }

        function sellerAddConfirm(e) {
            if (e.target && e.target.id === 'seller_add_confirm') {
                MySwal.close();
                document.removeEventListener('click', sellerAddConfirm);
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddConfirm);
                document.removeEventListener('click', sellerAddCancel);
                document.removeEventListener('click', sellerAddSuccess);
            }
        }
    }

    const onProductStateHide = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>선택된 상품을 숨김 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_product_popup_subtitle}'><span>숨김 처리 된 상품은 고객에게 노출되지 않습니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>숨김처리</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                stateChange('숨김');
                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                    <div>
                    <div class='${admin.admin_seller_add_title}'><span>상품이 숨김 처리 되었습니다.</span></div>
                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                    </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
                document.addEventListener('click', sellerAddConfirm);
            }
        }

        function sellerAddConfirm(e) {
            if (e.target && e.target.id === 'seller_add_confirm') {
                MySwal.close();
                document.removeEventListener('click', sellerAddConfirm);
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddConfirm);
                document.removeEventListener('click', sellerAddCancel);
                document.removeEventListener('click', sellerAddSuccess);
            }
        }
    }

    const stateChange = async (state) => {
        const pendingProducts = selectedProducts.filter((productNumber) => {
            const product = productList.find((item) => item.productNumber === productNumber);
            return product && product.enabledState === '승인대기';
        });

        if (pendingProducts.length > 0) {
            MySwal.fire({
                icon: 'warning',
                title: '승인대기',
                text: '상품이 승인대기 중 입니다.',
            });
            return;
        }

        const response = await product.productSaleStateChange(selectedProducts, state);
        const data = response.data;

        console.log(data);

        if (response.status == 200) {
            productSelect();
        }
    }

    const onProductCopy = async () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>선택된 상품을 복제 하시겠습니까?</span></div>
                    <div class='${admin.admin_product_popup_subtitle}'><span>복제된 상품은 관리자의 재승인이 필요합니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>복제</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {

                const response = await product.productCopy(selectedProducts, userInfo && userInfo.userId);
                const data = response.data;
                
                console.log(data);
                
                if (response.status === 200) {
                    productSelect();

                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html: `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>상품이 복제 되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);

                    function sellerAddConfirm(e) {
                        if (e.target && e.target.id === 'seller_add_confirm') {
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                            document.removeEventListener('click', sellerAddSuccess);
                            document.removeEventListener('click', sellerAddCancel);
                        }
                    }
                }
                
            }
        }
        

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddCancel);
                document.removeEventListener('click', sellerAddSuccess);
            }
        }
    }

    const handleFilterChange = (e) => {
        const { id, checked } = e.target;
        setFilters((prevFilters) => ({ ...prevFilters, [id]: checked }));
    };

    const toggleRejectionReason = (productNumber) => {
        setRejectionReasons((prevReasons) => ({
            ...prevReasons,
            [productNumber]: !prevReasons[productNumber],
        }));
    };

    const handleManagementState = () => {
        setShowMobileProductMangementState(!showMobileProductMangementState);
    }

    return (
        <div className={`${admin.admin_shop_product_management_box} px-3 py-3`}>
            <div className={`${admin.admin_shop_catogory_open_box} ${showCategory ? '' : admin.admin_shop_category_active}`} onClick={() => onCategoryOpen()}><span className="material-symbols-outlined ps-1">{showCategory ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left'}</span></div>
            <CSSTransition in={showCategory} timeout={300} classNames="adminCategory" unmountOnExit>
                <div className={`${admin.admin_shop_side_bar}`}>
                    {
                        roles.isAdmin &&
                        <div className={`${admin.admin_shop_side_check_rander}`}>
                            <div>
                                <div><input type="checkbox" id="dailyduck" checked={filters.dailyduck} onChange={handleFilterChange} /></div>
                                <div><label className='ps-1' htmlFor="dailyduck">데일리덕 상품만</label></div>
                            </div>
                            <div>
                                <div><input type="checkbox" id="consignment" checked={filters.consignment} onChange={handleFilterChange} /></div>
                                <div><label className='ps-1' htmlFor="consignment">파트너스 상품(위탁)</label></div>
                            </div>
                            <div>
                                <div><input type="checkbox" id="seller" checked={filters.seller} onChange={handleFilterChange} /></div>
                                <div><label className='ps-1' htmlFor="seller">셀러 상품만</label></div>
                            </div>
                        </div>
                    }
                    <div className={`${admin.admin_shop_category_setting}`}>
                        <div><button className={`${admin.admin_shop_category_setting_btn}`}><span>카테고리 관리&nbsp;</span><span className={`material-symbols-outlined ${admin.admin_gray_font_1}`}>settings</span></button></div>
                        <div className={`pt-2 pb-1 ${admin.admin_shop_total_category}`}>
                            <button className={`${admin.admin_shop_total_category_btn}`}><span>전체 카테고리</span></button>
                        </div>
                        {
                            mainCategory.map((mainCat, mainIndex) => (
                                <div className='pt-2' key={mainCat.mainCategoryNo}>
                                    <button className={`${admin.admin_shop_main_category}`} onClick={() => toggleOpen(mainIndex)}>
                                        <div><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>{openStates[mainIndex] ? 'arrow_drop_up' : 'arrow_drop_down'}</span></div>
                                        <div><span>{mainCat.mainCategoryName}</span></div>
                                    </button>
                                    <CSSTransition in={openStates[mainIndex]} timeout={300} classNames="review" unmountOnExit>
                                        <div>
                                            {
                                                middleCategory.filter(middleCat => middleCat.mainCategory === mainCat.mainCategoryName).map((filteredMiddleCat) => (
                                                    <div className={`ps-4 ${admin.admin_shop_middle_category}`} key={filteredMiddleCat.middleCategoryNo}>
                                                        <button className={`${admin.admin_shop_middle_category_btn}`}>
                                                            <div><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>arrow_drop_down</span></div>
                                                            <div><span>{filteredMiddleCat.middleCategoryName}</span></div>
                                                        </button>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </CSSTransition>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </CSSTransition>
            <div className={`${admin.admin_shop_product_management_content}`}>
                <div className={`${admin.admin_shop_product_management_header} py-3`}>
                    <div><span>상품 관리</span></div>
                    <div><button onClick={() => onProductAdd()}>상품 추가</button></div>
                </div>
                <div className={`${admin.admin_shop_product_tap}`}>
                    <div className={`${activeButton === 'all' ? admin.admin_shop_product_tap_active : ''}`}><button onClick={() => setActiveButton('all')}>전체({allCount})</button></div>
                    <div className={`${activeButton === 'sale' ? admin.admin_shop_product_tap_active : ''}`}><button onClick={() => setActiveButton('sale')}>판매중({saleCount})</button></div>
                    <div className={`${activeButton === 'soldout' ? admin.admin_shop_product_tap_active : ''}`}><button onClick={() => setActiveButton('soldout')}>품절({soldOutCount})</button></div>
                    <div className={`${activeButton === 'hide' ? admin.admin_shop_product_tap_active : ''}`}><button onClick={() => setActiveButton('hide')}>숨김({hiddenCount})</button></div>
                </div>
                <div className={`${admin.admin_shop_product_mobile_active_tap}`}>
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={10}
                        freeMode={true}
                        className={`${admin.admin_shop_product_tap_swiper}`}
                    >
                        <SwiperSlide>
                            <div className={`${activeButton === 'all' ? admin.admin_shop_product_tap_active : ''}`}><button onClick={() => setActiveButton('all')}>전체({allCount})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'sale' ? admin.admin_shop_product_tap_active : ''}`}><button onClick={() => setActiveButton('sale')}>판매중({saleCount})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'soldout' ? admin.admin_shop_product_tap_active : ''}`}><button onClick={() => setActiveButton('soldout')}>품절({soldOutCount})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'hide' ? admin.admin_shop_product_tap_active : ''}`}><button onClick={() => setActiveButton('hide')}>숨김({hiddenCount})</button></div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div>
                    <div className={`${admin.admin_seller_product_management_top} py-2 ${isFixed ? admin.fixedElement : ''}`}>
                        <div className={`${admin.admin_seller_product_management_state_btn}`}>
                            {
                                selectedProducts.length > 0 && (
                                    <>
                                        <button onClick={() => onProductStateSales()}>판매중 처리</button>
                                        <button onClick={() => onProductStateSoldOut()}>품절 처리</button>
                                        <button onClick={() => onProductStateHide()}>숨김 처리</button>
                                        <button onClick={() => onProductCopy()}>복제</button>
                                        <button onClick={handleDeleteSelected}>삭제</button>
                                    </>
                                )
                            }
                        </div>
                        
                        <div className={`${admin.admin_seller_product_management_state_mobile_btn}`}>
                            <div><button onClick={handleManagementState}>상태 버튼</button></div>
                            {
                                showMobileProductMangementState &&
                                <div className={`${admin.admin_seller_product_management_state_mobile_btn_content}`}>
                                    {
                                        selectedProducts.length > 0 && (
                                            <>
                                                <button onClick={() => onProductStateSales()}>판매중 처리</button>
                                                <button onClick={() => onProductStateSoldOut()}>품절 처리</button>
                                                <button onClick={() => onProductStateHide()}>숨김 처리</button>
                                                <button onClick={() => onProductCopy()}>복제</button>
                                                <button onClick={handleDeleteSelected}>삭제</button>
                                            </>
                                        )
                                    }
                                </div>
                            }
                        </div>
                        <div className={`${admin.admin_order_inspection_search_box}`}>
                            <button className={`${admin.admin_seller_inspection_download} me-1`}>
                                <div><span>내보내기</span></div>
                                <div><span className="material-symbols-outlined">download</span></div>
                            </button>
                            <div className={`${admin.admin_seller_inspection_input}`}>
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleSearchInputChange} // 입력 값이 변경될 때 상태 업데이트
                                    onKeyDown={handleSearchKeyDown} // Enter 키가 눌릴 때 검색 실행
                                />
                                <button className={`${admin.admin_seller_inspection_search_btn}`} onClick={performSearch}>
                                    <span className="material-symbols-outlined">search</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_product_content}`}>
                        <div>
                            <div className={`${admin.admin_seller_inspection_product_header} pt-3 pb-2`}>
                                <div><input type="checkbox" checked={selectAll} onChange={handleSelectAll} /></div>
                                <div><span>번호</span></div>
                                <div><span>등록 상품 정보</span></div>
                                <div><span>판매가</span></div>
                                <div><span>카테고리</span></div>
                                <div><span>재고</span></div>
                                <div><span>등록일</span></div>
                                <div><span>수정일</span></div>
                                <div><span>판매 상태</span></div>
                            </div>
                            {
                                Array.isArray(getFilteredAndPaginatedProducts()) && getFilteredAndPaginatedProducts()
                                    .map((item, index) => (
                                        <div
                                            key={item.productNumber}
                                            className={`${admin.admin_seller_inspection_product_content}`}
                                            onClick={() => onProductRead(item.productNumber)}
                                        >
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedProducts.includes(item.productNumber)}
                                                    onChange={() => handleSelectProduct(item.productNumber)}
                                                    onClick={(e) => e.stopPropagation()}
                                                />
                                            </div>
                                            <div><span>{productLength - ((currentPage - 1) * itemsPerPage + index)}</span></div>
                                            <div className={`${admin.admin_seller_inspection_product_info}`}>
                                                <div><img src={item.productMainImage} alt="상품이미지" /></div>
                                                <div>
                                                    <div><span>{item.productName}</span></div>
                                                    <div><span>옵션명 {item.optionValueList.length}종</span></div>
                                                </div>
                                            </div>
                                            <div>
                                                <span>
                                                    {item.productsalePrice !== null && item.productsalePrice !== 0 
                                                    ? (typeof item.productsalePrice === 'number' 
                                                        ? item.productsalePrice.toLocaleString() 
                                                        : 'N/A') 
                                                    : (typeof item.productPrice === 'number' 
                                                        ? item.productPrice.toLocaleString() 
                                                        : 'N/A')}&#8361;
                                                </span>
                                            </div>
                                            <div className='text-start'><span>{item.mainCategory}&gt;{item.middleCategory}&gt;{item.subCategory}</span></div>
                                            <div><span>{item.inventoryCnt}</span></div>
                                            <div><span>{formatDate(item.regDate)}</span></div>
                                            <div><span>{formatDate(item.updDate)}</span></div>
                                            <div className={`${admin.admin_seller_inspection_btn_box}`}>
                                                <div><span>{item.saleState}</span></div>
                                                {
                                                    item.enabledState === null ?
                                                    null
                                                    :
                                                    <div className={`${admin.admin_font_date_font}`}><span>({item.enabledState})</span></div>
                                                }
                                                {
                                                    item.enabledState === '승인거절' &&
                                                    <div>
                                                        <button onClick={(e) => { e.stopPropagation(); toggleRejectionReason(item.productNumber); }}>승인거절사유</button>
                                                        {rejectionReasons[item.productNumber] && (
                                                            <div className={`${admin.admin_seller_rejection_reason}`}>
                                                                {item.approvalDenied}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                            {
                                                item.consignmentStatus === true &&
                                                <div className={`${admin.admin_seller_consignment_product_label}`}>
                                                    <span>데일리덕위탁상품</span>
                                                </div>
                                            }
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                </div>
                <Pagination 
                    current={currentPage} 
                    total={productLength} 
                    onChange={paginate} 
                    pageSize={itemsPerPage} 
                />
            </div>
        </div>
    )
}

export default AdminProduct;
