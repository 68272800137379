import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as support from '../../apis/support';
import * as auth from '../../apis/auth';
import { v4 as uuidv4 } from 'uuid';

const CartPaymentSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [verificationResult, setVerificationResult] = useState(null);
    const [supportPaymentInfo, setSupportPaymentInfo] = useState({});
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [exchangeRate, setExchangeRate] = useState(null);

    useEffect(() => {
        fetchExchangeRate();
    }, []);

    // useEffect(() => {
    //     console.log('exchangeRate : ', exchangeRate);
    // }, [exchangeRate])

    const generateUniqueId = () => {
        return Date.now() + Math.floor(Math.random() * 1000); // 현재 시간에 1000 이하의 랜덤 값을 추가
    };

    const fetchExchangeRate = async () => {
        try {
            const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
            setExchangeRate(response.data.rates.KRW);
        } catch (error) {
            console.error("환율 정보를 가져오는 중 오류 발생", error);
        }
    };

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const paymentKey = query.get('paymentKey');
        const orderId = query.get('orderId');
        const amount = query.get('amount');
        const storePayment = JSON.parse(localStorage.getItem('storePayment'));
        const supportPayment = JSON.parse(localStorage.getItem('supportPayment'));
        const point = JSON.parse(localStorage.getItem('point'));
        const couponDiscount = JSON.parse(localStorage.getItem('couponDiscount'));
        const totalPrice = JSON.parse(localStorage.getItem('totalPrice'));
        const couponNo = JSON.parse(localStorage.getItem('couponNo'));
        const couponName = JSON.parse(localStorage.getItem('couponName'));
        const deliveryAddress = JSON.parse(localStorage.getItem('deliveryAddress'));
        const orderZipCode = JSON.parse(localStorage.getItem('orderZipCode'));
        const selectDelivery = JSON.parse(localStorage.getItem('selectDelivery'));

        

        // console.log('paymentKey : ', paymentKey);
        // console.log('orderId : ', orderId);
        // console.log('amount : ', amount);
        // console.log('storePayment : ', storePayment);
        // console.log('supportPayment : ', supportPayment);
        // console.log('point : ', point);
        // console.log('couponDiscount : ', couponDiscount);
        // console.log('totalPrice : ', totalPrice);
        // console.log('couponNo : ', couponNo);
        // console.log('couponName : ', couponName);
        // console.log('deliveryAddress : ', deliveryAddress);

        setPaymentAmount(amount);

        
        const confirmPayment = async () => {
            try {
                // 결제 승인 요청
                const confirmResponse = await axios.post('/api/payment/confirm', {
                    paymentKey: paymentKey,
                    orderId: orderId,
                    amount: amount,
                });

                // console.log('결제 승인 성공:', confirmResponse.data);

                // 결제 승인 이후 검증 요청
                verifyPayment();
            } catch (error) {
                console.error('결제 승인 실패:', error);
                setVerificationResult({ error: '결제 승인이 실패했습니다.' });
                setLoading(false);
            }
        };

        const verifyPayment = async () => {

            if (exchangeRate === null) {
                // console.log('환율 정보가 아직 로드되지 않았습니다.');
                return; // 환율 정보가 없으면 실행하지 않음
            }

            try {
                const response = await axios.post('/api/payment/verify', {
                    paymentKey: paymentKey,
                    orderId: orderId,
                    amount: amount
                });
                setVerificationResult(response.data);
                // console.log('response.data : ', response.data);

                let paymentMethod = "";
                let currency = "";
                let paymentBankNames = ""
                let paymentAccountNumber = ""
                let paymentDepositDeadline = ""
                let issuerCode = "";
                let easyPay = "";
                let transferBankCode = "";
                
                if(response.data.method === '가상계좌') {
                    paymentMethod = response.data.method;
                    currency = response.data.currency;
                    paymentBankNames = response.data.virtualAccount.bankCode;
                    paymentAccountNumber = response.data.virtualAccount.accountNumber;
                    paymentDepositDeadline = response.data.virtualAccount.dueDate;
                } else if(response.data.method === '카드') {
                    paymentMethod = response.data.method;
                    currency = response.data.currency;
                    issuerCode = response.data.card.issuerCode;
                } else if(response.data.method === '간편결제') {
                    paymentMethod = response.data.method;
                    currency = response.data.currency;
                    issuerCode = response.data.card && response.data.card.issuerCode ? response.data.card.issuerCode : '';
                    easyPay = response.data.easyPay.provider;
                } else if(response.data.method === '계좌이체') {
                    paymentMethod = response.data.method;
                    currency = response.data.currency;
                    transferBankCode = response.data.transfer.bankCode;
                } else if(response.data.method === '해외간편결제') {
                    paymentMethod = response.data.method;
                    currency = response.data.currency;
                    easyPay = response.data.easyPay.provider;
                }

                if (response.data.status === "DONE") {
                    try {
                        const response = await fetch('/api/payment', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                paymentKey: paymentKey,
                                amount: totalPrice
                            }),
                        });

                        const result = await response.json();
                        // console.log('result : ', result);

                        if (result.success) {
                            const storeItems = storePayment.map(item => {
                                // console.log('(item.totalPrice - point - couponDiscount) / exchangeRate : ', (item.totalPrice - point - couponDiscount) / exchangeRate);

                                const totalWeight = item.productWeight !== null ? item.productWeight * item.totalQuantity : 0;
                                // console.log('현재 totalWeight:', totalWeight);
                            
                                const calculatedShippingCost = calculateOverseasShippingCost(
                                    totalWeight,
                                    item.deliveryInfo.overseasShippingKg,
                                    item.deliveryInfo.overseasShippingCost
                                );

                                return {
                                    cartNo: item.cartNo,
                                    // paymentNumber: uuidv4(),
                                    paymentNumber: generateUniqueId(),
                                    shopName: item.shopName,
                                    userId: item.userId,
                                    sellerId: item.sellerId,
                                    paymentKey: paymentKey,
                                    paymentImg: item.productImg,
                                    paymentProductName: item.productName,
                                    paymentProductNumber: item.productNumber,
                                    principalAmount: item.productSalePrice, // 상품 원 금액
                                    deliveryType: item.deliveryType, // 배송 유형 (필요에 따라 수정)
                                    deliveryPrice: selectDelivery.deliveryState === '국내' ? item.deliveryPrice : calculatedShippingCost,
                                    deliveryCompany: "", // 배송 회사 (필요에 따라 수정)
                                    waybillNumber: "", // 운송장 번호 (필요에 따라 수정)
                                    deliveryUser: '',
                                    deliveryAddress: deliveryAddress,
                                    zipcode: orderZipCode, // 우편번호 (필요에 따라 수정)
                                    deliveryPhone: item.deliveryPhone, // 배송 전화번호 (필요에 따라 수정)
                                    point: point,
                                    coupon: couponDiscount,
                                    paymentPrice: currency === 'KRW' ? item.totalPrice - point - couponDiscount : ((item.totalPrice - point - couponDiscount) / exchangeRate).toFixed(2), // 실제 결제 금액
                                    paymentType: paymentMethod, // 결제 방식 (필요에 따라 수정)
                                    currency : currency,
                                    easyPay : paymentMethod === '간편결제' || paymentMethod === '해외간편결제' ? easyPay : "",
                                    paymentBankName: paymentMethod === '가상계좌' ? paymentBankNames : "", // 은행 이름 (필요에 따라 수정)
                                    paymentAccountNumber: paymentMethod === '가상계좌' ? paymentAccountNumber : "", // 계좌 번호 (필요에 따라 수정)
                                    paymentDepositDeadline: paymentMethod === '가상계좌' ? paymentDepositDeadline : "", // 입금 기한 (필요에 따라 수정)
                                    paymentState: "", // 결제 상태
                                    couponNo: couponNo,
                                    couponName: couponName,
                                    eventType: item.eventType ? item.eventType : '', // 이벤트 유형 (필요에 따라 수정)
                                    eventNumber: item.eventNumber ? item.eventNumber : '', // 이벤트 번호 (필요에 따라 수정)
                                    planType: item.planType ? item.planType : '', // 계획 유형 (필요에 따라 수정)
                                    planProductRate: item.planProductRate ? item.planProductRate : '', // 계획 제품 비율 (필요에 따라 수정)
                                    commission : item.commission,
                                    totalQuantity : item.totalQuantity,
                                    orderId: orderId,
                                    consignmentStatus : item.consignmentStatus,
                                    paymentExchangeRate : exchangeRate,
                                    combinationState : item.combinationState,
                                    optionPrice : item.optionPrice,
                                    options: item.selectOption.map(option => ({
                                        optionId: option.id,
                                        selectionOptionName: option.optionName,
                                        selectionOptionValue: option.optionValue,
                                        quantity: option.quantity,
                                        addPrice: option.addPrice,
                                        cartNo: item.cartNo
                                    })),
                                    contentForm: item.contentForm ? item.contentForm : []
                                };
                            });



                            const supportItems = supportPayment.map(item => {
                                return {
                                    cartNo : item.cartNo,
                                    orderId: orderId,
                                    // reservationNo: uuidv4(),
                                    reservationNo: generateUniqueId(),
                                    mainItemImg: item.productImg,
                                    mainItemNo: item.productNo,
                                    mainItemPrice: item.productMinPrice, // 총 금액
                                    mainOptionName: item.mainOptionName,
                                    productNumber: item.productNumber,
                                    sellerId: item.sellerId,
                                    userId: item.userId,
                                    paymentKey: paymentKey,
                                    amount: item.totalPrice - point - couponDiscount, // 실제 결제 금액
                                    point: point,
                                    couponDiscount: couponDiscount,
                                    reservationState: '', // 예약 상태
                                    reviewState: false, // 리뷰 상태 (필요에 따라 수정)
                                    reservationPaymentType: paymentMethod, // 결제 유형 (필요에 따라 수정)
                                    currency : currency,
                                    shopName: item.shopName,
                                    couponNo: couponNo,
                                    couponName: couponName,
                                    dateRange: [
                                        new Date(item.reservationStartDate),
                                        new Date(item.reservationEndDate)
                                    ],
                                    options: item.optionItem ? item.optionItem.map(option => ({
                                        name: option.name,
                                        value: option.value,
                                        price: option.price,
                                        type: option.type,
                                        quantity: option.quantity,
                                        originalFileName: option.type === 'fileUpload' && option.originalFileName,
                                        serverFileName : option.type === 'fileUpload' && option.serverFileName
                                    })) : []
                                };
                            });

                            // console.log('storeItemsList : ', storeItems);
                            // console.log('supportItemsList : ', supportItems);

                            cartPaymentAdd(storeItems, supportItems, currency);
                        } else {
                            // 서버 검증 실패 시 로직
                        alert(`결제 검증에 실패하였습니다: ${result.error_msg}`);
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                } else {
                    // 결제 실패 처리
                    navigate(`/cart/payment/fail`);
                }
            } catch (error) {
                console.error('결제 검증 오류:', error);
                navigate(`/cart/payment/fail`);
            } finally {
                setLoading(false);
            }
        };

        if(exchangeRate != null) {
            // verifyPayment();
            confirmPayment();
        }
        
    }, [location, navigate, exchangeRate]);

    const calculateOverseasShippingCost = (totalWeight, kgString, costString) => {
        if (!kgString || !costString) return 0; // 데이터가 없으면 0 반환
    
        const kgArray = kgString.split(';').map(parseFloat); // 무게 배열
        const costArray = costString.split(';').map(parseFloat); // 비용 배열
    
        let shippingCost = 0;
    
        // 정확한 구간 비교
        for (let i = 1; i < kgArray.length; i++) {
            if (totalWeight > kgArray[i - 1] && totalWeight <= kgArray[i]) {
                shippingCost = costArray[i - 1]; // 하한 값의 비용 반환
                break;
            }
        }
    
        // 첫 번째 구간에 포함되는 경우
        if (totalWeight <= kgArray[0]) {
            shippingCost = costArray[0];
        }
    
        // 모든 구간 초과 시, 마지막 배송비 반환
        if (totalWeight > kgArray[kgArray.length - 1]) {
            shippingCost = costArray[costArray.length - 1];
        }
    
        return shippingCost;
    };

    const cartPaymentAdd = async (storeItems, supportItems, currency) => {
        // console.log('storeItems : ', storeItems);
        // console.log('supportItems : ', supportItems);
        
        const requestBody = {
            storeItems: storeItems,
            supportItems: supportItems,
        };

        // 서버 검증 성공 시 로직
        try {
            const response = await auth.cartPaymentAdd(requestBody);
            const data = response.data;

            // console.log(data);

            // const reservationNo = paymentInfo.reservationNo;

            // console.log('reservationNo = ', reservationNo);

            if(data === 'SUCCESS') {
                const keysToRemove = [
                    'storePayment',
                    'supportPayment',
                    'point',
                    'couponDiscount',
                    'totalPrice',
                    'couponNo',
                    'couponName',
                    'deliveryAddress',
                    'orderZipCode',
                    'selectDelivery'
                ];
            
                keysToRemove.forEach(key => localStorage.removeItem(key));

                // console.log('paymentAmount : ', paymentAmount);

                navigate(`/cart/payment/success`, {state : {totalPrice : paymentAmount, storeItem : storeItems, supportItem : supportItems, currency : currency }});
            }

        } catch (error) {
            console.error('Error storing payment info:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {verificationResult ? (
                <div>결제가 성공적으로 완료되었습니다!</div>
            ) : (
                <div>결제 검증에 실패하였습니다.</div>
            )}
        </div>
    );
};

export default CartPaymentSuccess;
