import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import VenueHeader from '../../../../components/market/support/venue/VenueHeader.jsx';
import VenueProduct from '../../../../components/market/support/venue/VenueProduct.jsx';
import * as supportDetail from '../../../../apis/support.js';
import { LoginContext } from '../../../../contexts/LoginContextProvider.jsx';
import ss from '../../../../containers/market/support/venue/support_shop.module.css';
import store from '../../store/store.module.css';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import * as supportApi from '../../../../apis/support.js';

const VenueDetail = () => {
  const { isLogin, roles, logout, userInfo } = useContext(LoginContext);

  const location = useLocation();
  const { no: supportNo } = useParams();
  const [ imgSlide, setImgSlide ] = useState([]);
  const [ venueDetail, setVenueDetail ] = useState([]);
  const { support } = location.state || {};
  const { date } = location.state || {};

  const [ operatingList, setOperatingList ] = useState([]);
  const [ shopInfo, setShopInfo ] = useState();
  const [ holidays, setHolidays ] = useState([]);
  const [ temporarys, setTemporarys ] = useState([]);
  const [ sellerUser, setSellerUser ] = useState();
  const [shared, setShared] = useState(false);
  const [clipboardMessage, setClipboardMessage] = useState('');
  const [isHeartClicked, setIsHeartClicked] = useState(false);

  useEffect(() => {
    // console.log('venueDetail : ', venueDetail);
    if(venueDetail.length !== 0 && userInfo && supportNo) {
        favorityCheck();
    }
  },[venueDetail, userInfo, supportNo]);

  useEffect(() => {
    if (!window.Kakao.isInitialized()) {
        window.Kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
    }
  }, []);

  const transitions = useTransition(shared, {
    from: { transform: "translateY(100%)" },
    enter: { transform: "translateY(0)" },
    leave: { transform: "translateY(100%)" },
    config: {
        duration: 300,
    },
  });

  const [ reservation, setReservation ] = useState({
    reservationName : userInfo ? userInfo.userId : '',
    reservationPlaceName : '',
    supportNo : supportNo,
    reservationDate : '',
    essentialOption : '',
    essentialOptionPrice : '',
  });
  const [ reservationSelectOption, setReservationSelectOption ] = useState([
    {
      SelectOptionNo : '',
      supportNo : supportNo,
      reservationSelectOptionName : '',
      reservationSelectOptionPrice : '',
    }
  ]);
  const [questions, setQuestions] = useState([]);
  const [ reservationSelectQuestion, setReservationSelectQuestion ] = useState([
    {
      SelectQuestionNo : '',
      supportNo : supportNo,
      reservationSelectQuestionContent : '',
    }
  ]);
  const [essentialQuestions, setEssentialQuestions] = useState([]);
  const [ reservationEssentialQuestion, setReservationEssentialQuestion ] = useState([
    {
    essentialQuestionNo : '',
    supportNo : supportNo,
    reservationEssentialQuestionContent : '',
    }
  ]);
  const [ reservationRequest, setReservationRequest ] = useState({
    supportNo : supportNo,
    reservationRequestContent : '',
    reservationRequestImg1 : '',
    reservationRequestImg2 : '',
    reservationRequestImg3 : '',
    reservationRequestImg4 : '',
  });

  const [category, setCategory] = useState(localStorage.getItem('category') || (location.state ? location.state.category : ''));
  
  const [showDate, setShowDate] = useState(false);
  const [showCombinationDate, setShowCombinationDate] = useState(false);

  useEffect(() => {
    // console.log('date : ', date);
  }, [date])

  const getImageDetail = async () => {
    try {
      const venueResponse = await supportDetail.venueDetail(supportNo);
      const data = await venueResponse.data;

      // console.log('data : ', data);
      if(venueResponse.status === 200) {
          const venueData = data.supportDetailList;
          const operatingTime = data.operatingTimes;
          const shopInfo  = data.shopInfo;
          const holidayList = data.holidayList;
          const temporaryList = data.temporaryList;
          const sellerUser = data.sellerUser;
    
          // console.log('venueData : ', venueData)
          // console.log('temporaryList : ', temporaryList)
    
          setVenueDetail([venueData]);
          setOperatingList(operatingTime);
          setShopInfo(shopInfo);
          setHolidays(holidayList);
          setTemporarys(temporaryList);
          setSellerUser(sellerUser);
    
          const images = [
            `${venueData.representativeImage}`,
            `${venueData.listImage1}`,
            `${venueData.listImage2}`,
            `${venueData.listImage3}`,
            `${venueData.listImage4}`
          ];
    
          setImgSlide(images);

          suportUserViews(venueData.productNumber, venueData.userId, venueData.mainCategory);
      }
    } catch (error) {
      console.error('Error fetching venue detail:', error);
    }
  }

  useEffect(() => {
    if (location.state?.category) {
      // console.log('접근');
      localStorage.setItem('category', location.state.category);
      setCategory(location.state.category);
      getImageDetail();
    }
  }, [location.state?.category]);

  useEffect(() => {
    if (!location.state?.category) {
      getImageDetail();
    }
  }, []);

  const suportUserViews = async (productNumber, sellerId, mainCategory) => {
    const response = await supportApi.supportUserViews(userInfo&&userInfo?.userId, productNumber, sellerId, mainCategory);
    const data = response.data;
    
    // console.log('userViews : ', data);
  }

  const handleShare = () => {
    setShared(true);
  }
  const handleShardBack = () => {
    setShared(false);
  }

  const handleShareKakaoClick = () => {
      if (window.Kakao) {
          const kakao = window.Kakao;

          kakao.Share.sendDefault({
              objectType: 'feed',
              content: {
                  title: venueDetail.length > 0 ? venueDetail[0].productName : 'title',
                  description: '데일리덕에서 구매하고 포인트 받자!',
                  imageUrl: venueDetail.length > 0 ? venueDetail[0].representativeImage : 'https://dailyduck.com/img/kakaolink40_original.png',
                  link: {
                      mobileWebUrl: 'https://dailyduck.com/venue/' + venueDetail[0].productNumber,
                      webUrl: 'https://dailyduck.com/venue/' + venueDetail[0].productNumber,
                  },
              },
          });
      }
  }

  const handleTwitterShare = () => {
      const url = `https://dailyduck.com/venue/${venueDetail[0].productNumber}`;
      const text = `데일리덕에서 구매하고 포인트 받자!`;
      const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
      window.open(twitterUrl);
  };

  const handleNaverLineShare = () => {
      const url = `https://social-plugins.line.me/lineit/share?url=https://dailyduck.com/venue/${venueDetail[0].productNumber}`;
      window.open(url);
  }

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const handleSmsShare = () => {
      const productLink = `https://dailyduck.com/venue/${venueDetail[0].productNumber}`;
      const productName = `${venueDetail.length > 0 ? venueDetail[0].productName : '데일리덕상품'}`;
      const productDescription = "데일리덕에서 구매하고 포인트 받자!";

      if (!isMobile) {
          alert("SMS 공유는 모바일 기기에서만 가능합니다.");
          return;
      }

      const message = encodeURIComponent(`${productName} - ${productDescription}\n링크: ${productLink}`);
      window.location.href = `sms:&body=${message}`;
  }

  const handleClipboardShare = async () => {
      const text = `https://dailyduck.com/venue/${venueDetail[0].productNumber}`;
      if ('clipboard' in navigator) {
          try {
              await navigator.clipboard.writeText(text);
              setClipboardMessage('클립보드에 복사되었습니다.');
              setTimeout(() => setClipboardMessage(''), 2000);
          } catch (err) {
              console.error('클립보드 복사에 실패했습니다: ', err);
          }
      } else {
          const textarea = document.createElement('textarea');
          textarea.value = text;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
          setClipboardMessage('클립보드에 복사되었습니다.');
          setTimeout(() => setClipboardMessage(''), 2000);
      }
  }

  const handleMoreShare = () => {
      const productLink = `https://dailyduck.com/venue/${venueDetail[0].productNumber}`;
      const productName = `${venueDetail.length > 0 ? venueDetail[0].productName : '데일리덕상품'}`;
      const productDescription = "데일리덕에서 구매하고 포인트 받자!";

      if (navigator.share) {
          navigator.share({
              title: `${productName}`,
              text: `${productDescription}`,
              url: `${productLink}`,
          })
              .then(() => alert('공유 성공!'))
              .catch((error) => alert('공유 실패:', error));
      } else {
          alert('Web Share API를 지원하지 않는 브라우저입니다.');
      }
  }

  const favorityCheck = async () => {
    const response = await supportApi.supportFavorityCheck(venueDetail && venueDetail[0]?.productNo, userInfo?.userId);
    const data = response.data;

    // console.log('favorityCheck : ', data);

    if(data !== null) {
        setIsHeartClicked(true);
    } 
    if(data === 'noData') {
        setIsHeartClicked(false);
    }
 }

  return (
    <>
        <VenueHeader category={category} ss={ss} showDate={showDate} handleShare={handleShare}/>
        <VenueProduct imgSlide={imgSlide} venueDetail={venueDetail} support={support} 
                      reservation={reservation} setReservation={setReservation}
                      reservationSelectOption={reservationSelectOption} setReservationSelectOption={setReservationSelectOption}
                      reservationSelectQuestion={reservationSelectQuestion} setReservationSelectQuestion={setReservationSelectQuestion}
                      reservationEssentialQuestion={reservationEssentialQuestion} setReservationEssentialQuestion={setReservationEssentialQuestion}
                      reservationRequest={reservationRequest} setReservationRequest={setReservationRequest} isLogin={isLogin} date={date} 
                      questions={questions} setQuestions={setQuestions} supportNo={supportNo} ss={ss} 
                      essentialQuestions={essentialQuestions} setEssentialQuestions={setEssentialQuestions} operatingList={operatingList} shopInfo={shopInfo}
                      temporarys={temporarys} holidays={holidays} userInfo={userInfo} sellerUser={sellerUser} showDate={showDate} setShowDate={setShowDate}
                      supportDetail={supportDetail} isHeartClicked={isHeartClicked} setIsHeartClicked={setIsHeartClicked} favorityCheck={favorityCheck} supportApi={supportApi}
                      showCombinationDate={showCombinationDate} setShowCombinationDate={setShowCombinationDate}
        />

        {
            shared &&
            <div className={`${store.store_shared_background}`} >
                {transitions((style, item) =>
                    item ? 
                    <animated.div className={`${store.store_shard_box}`} style={style}>
                        <div className='pt-5 px-3 d-flex justify-content-between'>
                            <div><h5>공유하기</h5></div>
                            <div><button className={`${store.store_shared_close}`} onClick={handleShardBack}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className='d-flex justify-content-center py-3'>
                            <div className={`${store.store_shard_btn_box}`}>
                                <button onClick={handleShareKakaoClick}>
                                    <div>
                                        <div><img src="../../img/icon/share/kakaotalk.png" alt="카카오톡" /></div>
                                        <div className='py-1'><span>카카오톡</span></div>
                                    </div>
                                </button>
                            </div>
                            <div className={`${store.store_shard_btn_box}`}>
                                <button onClick={handleTwitterShare}>
                                    <div>
                                        <div><img src="../../img/icon/share/twiter.png" alt="트위터" /></div>
                                        <div className='py-1'><span>트위터</span></div>
                                    </div>
                                </button>
                            </div>
                            <div className={`${store.store_shard_btn_box}`}>
                                <button onClick={handleNaverLineShare}>
                                    <div>
                                        <div><img src="../../img/icon/share/line.png" alt="라인" /></div>
                                        <div className='py-1'><span>라인</span></div>
                                    </div>
                                </button>
                            </div>
                            <div className={`${store.store_shard_btn_box}`}>
                                <button onClick={handleSmsShare}>
                                    <div>
                                        <div><img src="../../img/icon/share/message.png" alt="SMS" /></div>
                                        <div className='py-1'><span>SMS</span></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className={`${store.store_shard_btn_bottom_box}`}>
                                <button onClick={() => handleClipboardShare()}>
                                    <div>
                                        <div><img src="../../img/icon/share/url.png" alt="" /></div>
                                        <div><span>URL</span></div>
                                    </div>
                                </button>
                            </div>
                            <div className={`${store.store_shard_btn_bottom_box}`}>
                                <button onClick={handleMoreShare}>
                                    <div>
                                        <div><img src="../../img/icon/share/more.png" alt="" /></div>
                                        <div><span>더보기</span></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {
                            clipboardMessage !== '' ? 
                            <div className={`${store.store_clipboard_box}`}><span>{clipboardMessage}</span></div>
                            :
                            null
                        }
                    </animated.div>
                    : null
                )}
            </div>
        }
    </>
  )
}

export default VenueDetail;
