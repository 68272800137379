import React, { useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Pagination } from 'swiper/modules';
import Lightbox from 'react-image-lightbox-rotate-latest';
import 'react-image-lightbox-rotate-latest/style.css'; 
import { CSSTransition } from 'react-transition-group';
import { formatDistanceToNow } from 'date-fns';
import { ko } from 'date-fns/locale';

const ARRAY = [0, 1, 2, 3, 4];

function ReviewComment({
  reviewList,
  averageRatingDecimal,
  clicked,
  ratings,
  highestCount,
  store,
  ss,
  handleFilterModal,
  reviewFilterActive
}) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isPhotoReviewOnly, setIsPhotoReviewOnly] = useState(false); // 사진 리뷰 필터링 상태 관리

  useEffect(() => {
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  useEffect(() => {
    if (Array.isArray(reviewList) && reviewList.length !== 0) {
      setImageList(
        reviewList.flatMap((item) => [item.photo1].filter(Boolean))
      );
    }
  }, [reviewList]);

  const handleImageClick = (i) => {
    setPhotoIndex(i);
    setIsOpen(true);
  };

  const togglePhotoReviewFilter = () => {
    setIsPhotoReviewOnly(!isPhotoReviewOnly); // 사진 리뷰 필터링 상태 토글
  };

  const filteredReviewList = isPhotoReviewOnly
    ? reviewList.filter(review => review.photo1 || review.photo2 || review.photo3 || review.photo4)
    : reviewList;

  // UTC 시간을 로컬 시간으로 변환하는 함수
  const convertUTCtoLocal = (dateString) => {
    const date = new Date(dateString); // UTC 시간으로 받아온 값을
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000); // 로컬 시간으로 변환
  };

  // 리뷰 날짜를 포맷하는 함수
  const formatDate = (dateString) => {
    const localDate = convertUTCtoLocal(dateString);
    return formatDistanceToNow(localDate, { addSuffix: true, locale: ko });
  };

  // 리뷰 목록을 reviewFilterActive 값에 따라 정렬하는 함수
  const sortedReviewList = () => {
    let sortedList = [...filteredReviewList];

    if (reviewFilterActive === 'popular') {
      // 인기순 정렬 (예: 좋아요가 많은 순으로 정렬)
      sortedList.sort((a, b) => (b.reviewLike || 0) - (a.reviewLike || 0));
    } else if (reviewFilterActive === 'rateHigh') {
      // 별점 높은 순으로 정렬
      sortedList.sort((a, b) => b.rating - a.rating);
    } else if (reviewFilterActive === 'rateRow') {
      // 별점 낮은 순으로 정렬
      sortedList.sort((a, b) => a.rating - b.rating);
    }

    return sortedList;
  };

  const filterActiveFormat = (text) => {
    if(text === 'basic') {
      return '기본순';
    }
    if(text === 'popular') {
      return '인기순';
    }
    if(text === 'rateHigh') {
      return '별점 높은 순';
    }
    if(text === 'rateRow') {
      return '별점 낮은 순';
    }
  }
        
  return (
    <div className='px-4 review_box'>
      <div className='venus_review_box pt-4'>
        <span>리뷰 </span><span>{filteredReviewList.length}</span>
      </div>
      <div className='ratio_box d-flex justify-content-between mt-3'>
        <div className='venue_ratio_box text-center'>
          <div className='fs-3'>{averageRatingDecimal !== 'NaN' ? averageRatingDecimal : 0.0}</div>
          <div className='magin-0a'>
            <Wrap2 className='ps-2'>
              <Stars>
                {ARRAY.map((el, idx) => (
                  <FaStar key={idx} size="20" className={clicked[el] && 'yellowStar'} />
                ))}
              </Stars>
            </Wrap2>
          </div>
        </div>
        <div className="vertical_line my-3"></div>
        <div className='venue_ratio_graph'>
          <table>
            {Object.entries(ratings).sort((a, b) => b[0] - a[0]).map(([star, count]) => (
              <tr key={star}>
                <td width={'10%'} style={{fontWeight: count === highestCount ? 'bold' : 'normal', color: count === highestCount ? 'black' : '#aaa'}}>{star}</td>
                <td width={'80%'}>
                  <div className="barBackground mt-2">
                    <div className="bar" style={{width: `${count * 10}%`}}></div>
                  </div>
                </td>
                <td width={'10%'} style={{fontWeight: count === highestCount ? 'bold' : 'normal', color: count === highestCount ? 'black' : '#aaa'}}>{count}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
      <div className='mt-4'>
        <div className='review_img_box'>
          <Swiper
            slidesPerView={windowWidth < 500 ? 3.5 : 4.45}
            spaceBetween={30}
            freeMode={true}
            className={`${ss.review_my_slide}`}
          >
            {imageList.map((src, i) => (
              <SwiperSlide key={i} onClick={() => handleImageClick(i)}>
                <img src={src} alt="리뷰이미지" />
              </SwiperSlide>
            ))}
          </Swiper>
          {isOpen && (
            <Lightbox
              mainSrc={imageList[photoIndex]}
              nextSrc={imageList[(photoIndex + 1) % imageList.length]}
              prevSrc={imageList[(photoIndex + imageList.length - 1) % imageList.length]}
              onCloseRequest={() => setIsOpen(false)}
            />
          )}
        </div>
      </div>
      <div className={`${store.store_review_btn_box}`}>
        <div>
          <input type="checkbox" name="사진리뷰" id='photoReview' className='photo_review' checked={isPhotoReviewOnly} onChange={togglePhotoReviewFilter} />
          <label htmlFor="photoReview" className='ps-1'>사진 리뷰만</label>
        </div>
        <div className='list_select_box'>
          <button className='list_select_btn' onClick={handleFilterModal}>
            <span>{filterActiveFormat(reviewFilterActive)}</span>
            <span className="material-symbols-outlined">{"expand_more"}</span>
          </button>
        </div>
      </div>
      {sortedReviewList().length > 0 ? (
        sortedReviewList().map((review, index) => {
          const images = [review.photo1, review.photo2, review.photo3, review.photo4].filter(Boolean);
          return (
            <div className='pt-3 personal_review border-bottom pb-3' key={review.id}>
              <div className={`${store.store_user_review_box}`}>
                <div className={`${store.store_user_review_profile}`}>
                  <div>
                    {
                      review?.userProfile ?
                      <img src={`${process.env.REACT_APP_API_SERVER}/img?file=${review.userProfile}`} alt="" />
                      :
                      <img src={`${process.env.PUBLIC_URL}/img/shop/default_profile.png`} alt="" />
                    }
                  </div>
                  <div><span>{review.userNick}</span></div>
                </div>
                <div className={`${store.store_user_review_date}`}>
                  <span>{formatDate(review.reviewRegDate)}</span>
                </div>
              </div>
              {images.length > 0 && (
                <div className='personal_review_img mt-2'>
                  <Swiper
                    slidesPerView={5}
                    spaceBetween={10}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {images.map((image, idx) => (
                      <SwiperSlide key={idx}>
                        <img src={image} alt="개인리뷰이미지" />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
              <div className='personal_review_ratio'>
                <Wrap3 className='py-2'>
                  <Stars>
                    {ARRAY.map((star, idx) => (
                      <FaStar key={idx} size="20" className={idx < review.rating ? 'yellowStar' : ''} />
                    ))}
                  </Stars>
                </Wrap3>
              </div>
              <div className='personal_review_content'>
                <span className='shorten_words'>{review.reviewText}</span>
                <Link>더보기</Link>
              </div>
            </div>
          );
        })
      ) : (
        <div className={`${store.store_no_review_box}`}><span>등록된 리뷰가 없습니다.</span></div>
      )}
    </div>
  );
}

export default ReviewComment;

const Wrap = styled.div`
  display: flex;
  // flex-direction: column;
  padding-top: 15px;
`;
const Wrap2 = styled.div`
  display: flex;
  justify-content: center;
//   padding-top: 15px;/
`;
const Wrap3 = styled.div`
  display: flex;
  // justify-content: center;
//   padding-top: 15px;/
`;

const RatingText = styled.div`
  color: #787878;
  font-size: 1em;
  font-weight: 400;
  border-bottom: 1px solid #787878;
  padding-top: 2px;
  margin-left: 10px;
`;

const Stars = styled.div`
  display: flex;
  padding-top: 5px;

  & svg {
    color: #e4e4e4;
    // cursor: pointer;
  }

  :hover svg {
    // color: #fcc419;
  }

  & svg:hover ~ svg {
    // color: gray;
  }

  .yellowStar {
    color: #fcc419;
  }
`;