import React, { useEffect, useState } from 'react';
import event from '../../containers/event/event.module.css';
import store from '../../containers/market/store/store.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import * as storeApi from '../../apis/store';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const EventPlan = () => {
  const { eventNumber } = useParams();
  const navigate = useNavigate();
  const [eventInfo, setEventInfo] = useState({});
  const [planProduct, setPlanProduct] = useState([]);
  const [banner, setBanner] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [columnClass, setColumnClass] = useState('col-6'); // 초기값 설정
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const shuffleArray = (array) => {
      const shuffled = [...array];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    };
  
    const planRead = async () => {
      try {
        setIsLoading(true);
        const response = await storeApi.planRead(eventNumber);
        const data = response.data;

        if(response.status === 200) {
          setIsLoading(false);
          setEventInfo(data.eventDTO);
    
          // Shuffle the planProductList before setting it to state
          const shuffledPlanProduct = shuffleArray(data.planProductList);
          setPlanProduct(shuffledPlanProduct);
        }
      } catch (error) {
        console.error('Error fetching plan data:', error);
      }
    };
  
    planRead();
  }, [eventNumber]);

  useEffect(() => {
    const updateColumnClass = () => {
      if (window.innerWidth > 500) {
        setColumnClass('col-4'); // 화면이 500px 이상일 때
      } else {
        setColumnClass('col-6'); // 화면이 500px 이하일 때
      }
    };

    updateColumnClass();
    window.addEventListener('resize', updateColumnClass);

    return () => {
      window.removeEventListener('resize', updateColumnClass);
    };
  }, []);

  useEffect(() => {
    if(eventInfo) {
      setBanner(
        [
          eventInfo.eventImage1,
          eventInfo.eventImage2,
          eventInfo.eventImage3,
          eventInfo.eventImage4,
          eventInfo.eventImage5,
        ].filter((image) => image !== null)
      );
    }
  }, [eventInfo])

  // const planRead = async () => {
  //   const response = await storeApi.planRead(eventNumber);
  //   const data = response.data;

  //   // console.log(data);
  //   setEventInfo(data.eventDTO);
  //   setPlanProduct(data.planProductList);
  // }

  const handleBack = () => {
    navigate(-1);
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const onDetaile = (item) => {
    // console.log('item : ', item);
    navigate(`/plan/product/${item.productNumber}`, { state : {product : item, rate : eventInfo.planProductRate}});
  }

  const calculateSalePrice = (item) => {
    // return item.productPrice - (item.productPrice * (item.eventRate * 0.01));
    return item.eventRatePrice ? item.eventRatePrice : item.productsalePrice ? item.productsalePrice : item.productPrice;
  }

  const isSoldOut = (item) => {
    // 옵션이 없는 경우 inventoryCnt 값이 0인지 확인
    if (!item.optionValueList?.length && !item.combinationOptions?.length) {
      return item.inventoryCnt === 0;
    }
  
    // 단일 옵션이 있을 때, optionInventory가 모두 0 또는 null인지 확인
    if (Array.isArray(item.optionValueList) && item.optionValueList.length > 0) {
      return item.optionValueList.every(
        (option) => option.optionInventory === 0 || option.optionInventory === null
      );
    }
  
    // 조합 옵션이 있을 때, stock이 모두 0인지 확인
    if (Array.isArray(item.combinationOptions) && item.combinationOptions.length > 0) {
      return item.combinationOptions.every(
        (combination) => combination.stock === 0
      );
    }
  
    return false; // 기본값은 품절 아님
  };

  return (
    <>
        <div>
          <div>
            <div className={`${event.event_plan_haeader}`}>
              <div>
                <button onClick={handleBack}><span className="material-symbols-outlined">arrow_back_ios</span></button>
              </div>
              <div><span>기획전</span></div>
            </div>
            {
              isLoading ?
              <div className={`${store.store_product_detail_loading}`}>
                  <img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" />
              </div>
              :
              <div className={`${event.event_plan_box}`}>
                <div>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    effect="fade"
                    fadeEffect={{ crossFade: true }}
                    speed={2000} // 여기에서 페이드인 아웃 효과의 속도를 설정합니다.
                    modules={[Pagination, Navigation, Autoplay, EffectFade]}
                    className={`${event.main_banner_swiper}`}
                  >
                    {banner.map((item, index) => (
                      <SwiperSlide key={index}>
                        <div className={`${event.event_main_banner}`}>
                          <LazyLoadImage
                            effect='blur' 
                            src={item} 
                            alt="banner" 
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <div className={`${event.event_plan_content}`} style={{ height: isExpanded ? 'auto' : '350px', overflow: 'hidden' }}>
                  <div dangerouslySetInnerHTML={{ __html: eventInfo.eventContent }}></div>
                </div>
                <div className={`${event.event_plan_content_btn_box}`}>
                  <button onClick={toggleExpand}>{isExpanded ? '접기' : '자세히 보기'}</button>
                </div>
                <div>
                  <div className={`${event.event_plan_title}`}><span>{eventInfo.eventTitle}</span></div>
                  {/* {
                    eventInfo.planType === 'priceDiscount' &&
                    <div className={`${event.event_plan_event_type}`}>
                      <div><span>전 품목 {eventInfo.planProductRate}% 할인</span></div>
                    </div>
                  }
                  {
                    eventInfo.planType === 'deliveryFree' &&
                    <div className={`${event.event_plan_event_type}`}>
                      <div><span>전 품목 배송비 할인</span></div>
                    </div>
                  } */}
                  <div className='row px-2 pb-3'>
                      {
                        planProduct.map((item, index) => (
                          <div className={`${columnClass} ${event.event_plan_product_item}`} key={index} onClick={() => onDetaile(item)}>
                            <div>
                            {isSoldOut(item) && (
                                <div className={`${store.store_stockCnt_zero_box}`}>
                                  <div
                                    className={`${store.store_stockCnt_zero}`}
                                    onClick={() => onDetaile(item)}
                                  >
                                    <span>품절</span>
                                  </div>
                                </div>
                                )}
                              <LazyLoadImage 
                                effect='blur'
                                src={item.productMainImage} 
                                alt="product" 
                              />
                            </div>
                            <div><span>상점명</span></div>
                            <div><span>{item.productName}</span></div>
                            <div>
                              {
                                item.eventRate !== 0 &&
                                <div className={`${event.event_plan_product_item_event_rate}`}><span>{item.eventRate}%</span></div>
                              }
                              {
                                item.planType === 'deliveryFree' && item.discountRate !== 0 &&
                                <div className={`${event.event_plan_product_item_discount_rate}`}><span>{item.discountRate}%</span></div>
                              }
                              <div className={`${event.event_plan_product_item_event_price}`}><span>{calculateSalePrice(item).toLocaleString()}원</span></div>
                              {
                                item.productsalePrice !== item.productPrice &&
                                <div className={`${event.event_plan_product_item_event_principal}`}><span>{item.productPrice.toLocaleString()}원</span></div>
                              }
                              </div>
                          </div>
                        ))
                      }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
    </>
  );
}

export default EventPlan;
