import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import seller from '../../../../seller/seller.module.css';
import admin from '../../../admin.module.css';
import * as sellerapi from '../../../../apis/seller';
import { Pagination } from 'antd';
import { LoginContext } from '../../../../contexts/LoginContextProvider';

const AdminSupportOrder = () => {
    const { isLogin, sellerlogout, userInfo, loginCheck, roles } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);

    const [activeButton, setActiveButton] = useState('completePayment');
    const [reservationList, setReservationList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [filteredReservationList, setFilteredReservationList] = useState([]);
    const [selectedReservations, setSelectedReservations] = useState([]);
    
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // 검색어 상태 추가

    const [orderUserInfo, setOrderUserInfo] = useState({});
    const [singleOrderInfo, setSingleOrderInfo] = useState({});
    const [showOrderUser, setShowOrderUser] = useState(false);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        reservationSelect();
    }, []);

    // useEffect(() => {
    //     console.log('filteredReservationList:', filteredReservationList);
    // }, [filteredReservationList]);

    useEffect(() => {
        if (reservationList.length > 0) {
            filterReservationsBySearchTerm();
        }
    }, [reservationList, searchTerm, activeButton]);

    const reservationSelect = async () => {
        const response = await sellerapi.reservationAdminSelect();
        const data = response.data.reservationList;
        const product = response.data.sellerProduct;

        const convertedData = data.map(reservation => {
        if (reservation.dateRangeStrings && reservation.dateRangeStrings.length === 2) {
            reservation.dateRange = reservation.dateRangeStrings.map(dateStr => new Date(dateStr).toLocaleString());
        }
        return reservation;
        });

        // console.log('product : ', product);
        // console.log('convertedData : ', convertedData);

        setReservationList(convertedData);
        setFilteredReservationList(convertedData);
        setTotalItems(convertedData.length);
        setProductList(product);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-CA', { hour12: false }).replace(',', '');
    };

    const filterReservationsBySearchTerm = () => {
        const filtered = reservationList.filter((reservation) => {
            const searchLower = searchTerm.toLowerCase();
            return (
                String(reservation?.reservationNo || '').toLowerCase().includes(searchLower) ||
                String(reservation?.userId || '').toLowerCase().includes(searchLower) ||
                String(reservation?.mainOptionName || '').toLowerCase().includes(searchLower)
            );
        });
    
        const stateMap = {
            completePayment: '예약대기',
            reservationConfirmed: '예약확정',
            successOffer: '제공완료',
            purchaseConfirmation: '구매확정',
            canceled: '취소완료',
        };
    
        const filteredByState = filtered.filter(
            (reservation) => reservation.reservationState === stateMap[activeButton]
        );
    
        // console.log('Filtered Data:', filteredByState); // 디버깅용
        setFilteredReservationList(filteredByState);
        setTotalItems(filteredByState.length);
        setCurrentPage(1); // 검색 시 페이지를 첫 페이지로 설정
    };
    

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredReservationList.slice(startIndex, endIndex);
    };

    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
    };

    const filterReservations = (state) => {
        const stateMap = {
        'completePayment': '예약대기',
        'reservationConfirmed': '예약확정',
        'successOffer': '제공완료',
        'purchaseConfirmation': '구매확정',
        'canceled': '취소완료'
        };
        return reservationList.filter(reservation => reservation.reservationState === stateMap[state]);
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
        setSelectedReservations(filterReservations(activeButton).map(reservation => reservation.rsNo));
        } else {
        setSelectedReservations([]);
        }
    };

    const handleSelectReservation = (e, rsNo) => {
        if (e.target.checked) {
        setSelectedReservations([...selectedReservations, rsNo]);
        } else {
        setSelectedReservations(selectedReservations.filter(id => id !== rsNo));
        }
    };

    const handleViewOptions = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const onReservationConfirmation = () => {
        MySwal.fire({
        customClass: {
            popup: `${seller.seller_product_popup}`,
            htmlContainer: `${seller.seller_date_html_container}`
        },
        html: `
            <div>
            <div class='d-flex justify-content-center align-items-center position-relative pb-5 pt-2'>
                <div>예약확정 처리</div>
                <div class=${seller.seller_date_close}><button id="swal-close-button"><span class="material-symbols-outlined">close</span></button></div>
            </div>
            <div class='${seller.seller_date_title} pb-3'>
                <div><span>*선택하신 ${selectedReservations.length}개의 주문 중 처리 가능한 주문은 ${selectedReservations.length}건입니다.</span></div>
                <div><span>${selectedReservations.length}건에 대해 처리를 하시겠습니까?</span></div>
            </div>
            <div class='d-flex justify-content-center pt-3'>
                <div><button class='${seller.seller_date_cancel}' id="custom-cancel-button">취소</button></div>
                <div><button class='${seller.seller_date_confirm}' id="custom-confirm-button">확인</button></div>
            </div>
            </div>
        `,
        showConfirmButton: false,
        showCancelButton: false,
        cancelButtonText: '취소'
        });

        document.addEventListener('click', sellerReservationConfirm);
        document.addEventListener('click', sellerReservationCancel);
        
        

        function sellerReservationConfirm(e) {
        if (e.target && e.target.id === 'custom-confirm-button') {
            updateReservationComplete();
        }
        }

        function sellerReservationCancel(e) {
        if (e.target && e.target.id === 'custom-cancel-button') {
            document.removeEventListener('click', sellerReservationConfirm);
            document.removeEventListener('click', sellerReservationCancel);
            MySwal.close();
        }
        }

        const updateReservationComplete = async () => {
        const response = await sellerapi.updateCompleteReservationStatus(selectedReservations);
        const data = response.data;

        if(data === 'SUCCESS') {
            try {
            MySwal.fire({
                customClass: {
                popup: `${seller.seller_date_success_popup}`
                },
                html: `
                <div>
                    <div class='${seller.seller_date_success_header}'>
                    <div class='${seller.seller_date_success_header_title}'><span>예약확정 처리</span></div>
                    <div class='${seller.seller_date_success_header_btn}'><button><span class="material-symbols-outlined" id='seller_swal_success_close'>close</span></button></div>
                    </div>
                    <div class='${seller.seller_date_success_title}'>
                    <div><span>*선택하신 ${selectedReservations.length}개의 주문 중 총 ${selectedReservations.length}건에 대해서 처리가 완료되었습니다.</span></div>
                    </div>
                    <div class='${seller.seller_date_success_btn}'>
                    <button id='seller_swal_success'>확인</button>
                    </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });

            document.addEventListener('click', sellerSwalSuccess);
            document.addEventListener('click', sellerSwalSuccessClose);

            function sellerSwalSuccess(e) {
                if (e.target && e.target.id === 'seller_swal_success') {
                reservationSelect();
                document.removeEventListener('click', sellerReservationConfirm);
                document.removeEventListener('click', sellerReservationCancel);
                document.removeEventListener('click', sellerSwalSuccess);
                document.removeEventListener('click', sellerSwalSuccessClose);
                MySwal.close();
                }
            }
            function sellerSwalSuccessClose(e) {
                if (e.target && e.target.id === 'seller_swal_success_close') {
                reservationSelect();
                document.removeEventListener('click', sellerReservationConfirm);
                document.removeEventListener('click', sellerReservationCancel);
                document.removeEventListener('click', sellerSwalSuccess);
                document.removeEventListener('click', sellerSwalSuccessClose);
                MySwal.close();
                }
            }

            } catch (error) {
            console.error('Error updating reservation status:', error);
            // MySwal.fire('Error', '예약확정 처리 중 오류가 발생했습니다.', 'error');
            }
        }
        }
    };

    const onCancelProcessing = async () => {
        const selectedReservation = reservationList.find(reservation => selectedReservations.includes(reservation.rsNo));
        
        // 예약 상태 체크
        const invalidStates = ['예약확정', '제공완료', '구매확정'];
        const canCancel = selectedReservations.every(rsNo => {
        const reservation = reservationList.find(res => res.rsNo === rsNo);
        return !invalidStates.includes(reservation.reservationState);
        });
    
        if (!canCancel) {
        MySwal.fire({
            title: '취소 불가',
            text: '선택하신 예약 중 취소가 불가능한 상태인 예약이 포함되어 있습니다.',
            icon: 'error',
            confirmButtonText: '확인'
        });
        return;
        }
    
        MySwal.fire({
        customClass: {
            popup: `${seller.seller_product_popup}`,
            htmlContainer: `${seller.seller_date_html_container}`
        },
        html: `
            <div>
            <div class='d-flex justify-content-center align-items-center position-relative pb-5 pt-2'>
                <div>취소 처리</div>
                <div class=${seller.seller_date_close}><button id="swal-close-button"><span class="material-symbols-outlined">close</span></button></div>
            </div>
            <div class='${seller.seller_date_title} pb-3'>
                <div><span>*선택하신 ${selectedReservations.length}개의 주문 중 처리 가능한 주문은 ${selectedReservations.length}건입니다.</span></div>
            </div>
            <div class='text-start'>
                <div class='d-flex py-3'>
                <div class='${seller.seller_reservation_popup_left}'><span>주문번호</span></div>
                <div class='${seller.seller_reservation_popup_right}'><span>${selectedReservation?.reservationNo}</span></div>
                </div>
                <div class='d-flex py-3'>
                <div class='${seller.seller_reservation_popup_left}'><span>상품명</span></div>
                <div class='${seller.seller_reservation_popup_right}'><span>${selectedReservation?.mainOptionName}</span></div>
                </div>
                <div class='d-flex py-3'>
                <div class='${seller.seller_reservation_popup_left}'><span>판매취소 사유</span></div>
                <div class='${seller.seller_reservation_popup_right}'><textarea name="" id="" cols="30" rows="10"></textarea></div>
                </div>
            </div>
            <div class='d-flex justify-content-center pt-3'>
                <div><button class='${seller.seller_date_cancel}' id="custom-cancel-button">취소</button></div>
                <div><button class='${seller.seller_date_confirm}' id="custom-confirm-button">확인</button></div>
            </div>
            </div>
        `,
        showConfirmButton: false,
        showCancelButton: false,
        cancelButtonText: '취소'
        });
    
        document.addEventListener('click', async function (e) {
        if (e.target && e.target.id === 'custom-confirm-button') {
            try {
            await sellerapi.updateCancelReservationStatus(selectedReservations); // API 호출
            MySwal.fire({
                customClass: {
                popup: `${seller.seller_reservation_success_popup}`
                },
                html: `
                <div>
                    <div class='${seller.seller_date_success_header}'>
                    <div class='${seller.seller_date_success_header_title}'><span>판매취소</span></div>
                    <div class='${seller.seller_date_success_header_btn}'><button><span class="material-symbols-outlined" id='seller_swal_success_close'>close</span></button></div>
                    </div>
                    <div class='${seller.seller_date_success_title} py-3'>
                    <div><span>*선택하신 ${selectedReservations.length}개의 주문 중 총 ${selectedReservations.length}건에 대해서 처리가 완료되었습니다.</span></div>
                    </div>
                    <div class='d-flex text-start py-3 dd-fs-7 border-bottom'>
                    <div class='${seller.seller_reservation_popup_left}'><span>주문번호</span></div>
                    <div class='${seller.seller_reservation_popup_right}'><span>${selectedReservation?.reservationNo}</span></div>
                    </div>
                    <div class='d-flex text-start py-3 dd-fs-7 border-bottom'>
                    <div class='${seller.seller_reservation_popup_left}'><span>상품명</span></div>
                    <div class='${seller.seller_reservation_popup_right}'><span>${selectedReservation?.mainOptionName}</span></div>
                    </div>
                    <div class='d-flex text-start py-3 dd-fs-7 border-bottom'>
                    <div class='${seller.seller_reservation_popup_left}'><span>결제수단</span></div>
                    <div class='${seller.seller_reservation_popup_right}'><span>무통장입금</span></div>
                    </div>
                    <div class='d-flex text-start py-3 dd-fs-7 border-bottom'>
                    <div class='${seller.seller_reservation_popup_left}'><span>처리결과</span></div>
                    <div class='${seller.seller_reservation_popup_right}'><span>취소처리완료</span></div>
                    </div>
                    <div class='d-flex text-start py-3 dd-fs-7 border-bottom'>
                    <div class='${seller.seller_reservation_popup_left}'><span>판매취소 사유</span></div>
                    <div class='${seller.seller_reservation_popup_right}'><span>안녕하세요. 고객님, 고객님의 요청으로 예약취소 처리해드렸습니다.</span></div>
                    </div>
                    <div class='${seller.seller_date_success_btn}'>
                    <button id='seller_swal_success'>확인</button>
                    </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
    
            document.addEventListener('click', function (e) {
                if (e.target && e.target.id === 'seller_swal_success') {
                MySwal.close();
                }
            });
    
            document.addEventListener('click', function (e) {
                if (e.target && e.target.id === 'seller_swal_success_close') {
                MySwal.close();
                }
            });
            } catch (error) {
            console.error('Error updating reservation status:', error);
            MySwal.fire('Error', '취소 처리 중 오류가 발생했습니다.', 'error');
            }
        }
        });
    
        document.addEventListener('click', function (e) {
        if (e.target && e.target.id === 'custom-cancel-button') {
            MySwal.close();
        }
        });
    };
    

    const calculateDaysDifference = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        const differenceInTime = endDate.getTime() - startDate.getTime();
        return differenceInTime / (1000 * 3600 * 24) + 1;
    };

    const downloadFile = async (serverFileName, originalFileName) => {
        const fileUrl = `${process.env.REACT_APP_API_SERVER}/chat/download?file=${serverFileName}`;
        try {
          const response = await fetch(fileUrl);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = originalFileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('File download error:', error);
        }
    };

    const isImageFile = (fileName) => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'heic'];
        const extension = fileName.split('.').pop().toLowerCase();
        return imageExtensions.includes(extension);
    };

    
    const onOrderUserShow = (item) => {
        setOrderUserInfo(item.user);
        setSingleOrderInfo(item);
        setShowOrderUser(true);
    }

  return (
    <div className={`${admin.admin_support_dashboard_container} py-3 px-3`}>
      <div>
        <div className='pb-3 w-100 border-bottom fs-5'><span>예약 관리</span></div>
      </div>
      <div>
        <div className={`${seller.seller_dashboard_tab_btn} pt-4 pb-3`}>
          <div className={`w-100 ${activeButton === 'completePayment' ? seller.seller_dashboard_tab_active : ''}`}><button onClick={() => setActiveButton('completePayment')}>예약대기 ({filterReservations('completePayment').length})</button></div>
          <div className={`w-100 ${activeButton === 'reservationConfirmed' ? seller.seller_dashboard_tab_active : ''}`}><button onClick={() => setActiveButton('reservationConfirmed')}>예약확정 ({filterReservations('reservationConfirmed').length})</button></div>
          <div className={`w-100 ${activeButton === 'successOffer' ? seller.seller_dashboard_tab_active : ''}`}><button onClick={() => setActiveButton('successOffer')}>제공완료 ({filterReservations('successOffer').length})</button></div>
          <div className={`w-100 ${activeButton === 'purchaseConfirmation' ? seller.seller_dashboard_tab_active : ''}`}><button onClick={() => setActiveButton('purchaseConfirmation')}>구매확정 ({filterReservations('purchaseConfirmation').length})</button></div>
          <div className={`w-100 ${activeButton === 'canceled' ? seller.seller_dashboard_tab_active : ''}`}><button onClick={() => setActiveButton('canceled')}>취소완료 ({filterReservations('canceled').length})</button></div>
        </div>
      </div>

      <div className='d-flex justify-content-between py-3'>
        <div className='d-flex'>
          <div><button className={`${seller.seller_product_state_btn}`} onClick={onReservationConfirmation}>예약확정 처리</button></div>
          <div><button className={`${seller.seller_product_state_btn} ms-2`} onClick={onCancelProcessing}>취소 처리</button></div>
        </div>
        <div className='d-flex align-items-center'>
          <div>
            <button className={`d-flex align-items-center ${seller.seller_product_export_btn} py-1 px-2`}>
              <span>내보내기</span>
              <span className="material-symbols-outlined ps-1">download</span>
            </button>
          </div>
          <div className='position-relative ps-2'>
            <input 
                type="text"
                className={`${seller.seller_product_search_input} py-1`}
                placeholder="주문번호, 주문자, 상품명 검색"
                value={searchTerm}
                onChange={handleSearch}
            />
            <span className={`material-symbols-outlined ${seller.seller_product_search_icon}`}>search</span>
          </div>
        </div>
      </div>

      <div className={`${seller.seller_reservation_item_container}`}>
        <div>
          <div className={`border-bottom py-2 ${seller.seller_reservation_item_header}`}>
            <div className={`${seller.seller_reservation_item_one}`}><input type="checkbox" onChange={handleSelectAll} checked={filterReservations(activeButton).length === selectedReservations.length} /></div>
            <div className={`${seller.seller_reservation_item_two}`}><span>주문번호/시각</span></div>
            <div className={`${seller.seller_reservation_item_three}`}><span>상태</span></div>
            <div className={`${seller.seller_reservation_item_four}`}><span>주문자</span></div>
            <div className={`${seller.seller_reservation_item_five}`}><span>주문 상품</span></div>
            <div className={`${seller.seller_reservation_item_six}`}><span>추가정보</span></div>
            <div className={`${seller.seller_reservation_item_seven}`}><span>예약 일자</span></div>
            <div className={`${seller.seller_reservation_item_eight}`}><span>결제 내역</span></div>
          </div>

          {
            getPaginatedData().map((item, index) => (
              <div key={index} className={`border-bottom py-2 ${seller.seller_reservation_item_content}`}>
                <div className={`${seller.seller_reservation_item_one}`}><input type="checkbox" checked={selectedReservations.includes(item.rsNo)} onChange={(e) => handleSelectReservation(e, item.rsNo)} /></div>
                <div className={`${seller.seller_reservation_item_two}`}><span>{item.reservationNo}<br />/<br />{formatDate(item.reservationRegDate)}</span></div>
                <div className={`${seller.seller_reservation_item_three}`}><span>{item.reservationState}</span></div>
                <div className={`${seller.seller_reservation_item_four}`}><span>{item.userId}</span></div>
                <div className={`${seller.seller_reservation_item_five}`}>
                  <div><img src={item.mainItemImg} alt="상품 이미지" /></div>
                  <div>
                    <div><span>{item.mainOptionName}</span></div>
                  </div>
                </div>
                <div className={`${seller.seller_reservation_item_six} px-2}`}><button className={`${seller.seller_reservation_item_search}`} onClick={(e) => { e.stopPropagation(); handleViewOptions(item); }}>조회</button></div>
                <div className={`${seller.seller_reservation_item_seven}`}>
                  {item.dateRange ? (
                    <div>
                      <div><span>{item.dateRange[0]}</span></div>
                      <div><span>~</span></div>
                      <div><span>{item.dateRange[1]}</span></div>
                      <div><span>({calculateDaysDifference(item.dateRangeStrings[0], item.dateRangeStrings[1])}일)</span></div>
                    </div>
                  ) : ''}
                </div>
                <div className={`${seller.seller_reservation_item_eight}`}>
                  <div className={`${seller.seller_reservation_item_total_price}`}>
                    <div><span>총 결제금액</span></div>
                    <div><span>{(item.amount - item.point).toLocaleString()}{item.reservationPaymentType === '해외간편결제' ? 'USD' : '원'}</span></div>
                  </div>
                  <div className={`${seller.seller_reservation_item_payment_info}`}>
                    <div><span>소계</span></div>
                    <div><span>{item.amount.toLocaleString()}{item.reservationPaymentType === '해외간편결제' ? 'USD' : '원'}</span></div>
                    <div><span>포인트사용</span></div>
                    <div><span>-{item.point.toLocaleString()}원</span></div>
                    <div><span>결제방법</span></div>
                    <div><span>{item.reservationPaymentType}</span></div>
                  </div>
                </div>
                <div className={`${seller.seller_order_content_user_info}`}><button onClick={(e) => {e.stopPropagation(); onOrderUserShow(item);}}><span className="material-symbols-outlined">person</span>{item && item.user && item.user.userName}</button></div>
              </div>
            ))
          }
        </div>
      </div>
      <Pagination
        current={currentPage}
        total={totalItems}
        onChange={paginate}
        pageSize={itemsPerPage}
      />
      {/* modal */}
      {isModalOpen && (
        <div className={seller.reservation_item_modal}>
          <div className={seller.reservation_item_modal_content}>
            <div className={seller.reservation_item_modal_close}><span onClick={() => setIsModalOpen(false)}><span className="material-symbols-outlined">close</span></span></div>
            <div className={seller.reservation_item_modal_title}>
              <span>추가 정보</span>
            </div>
            {selectedItem.options ? (
              selectedItem.options.map((option, index) => (
                <div key={index}>
                  <div className={`${seller.seller_reservation_item_modal_option_img_box}`}>
                  {option.type === 'fileUpload' && option.files && (
                    option.files.map((file, idx) => (
                      <div key={idx}>
                        {isImageFile(file.serverFileName) ? (
                          <img src={`${process.env.REACT_APP_API_SERVER}/img?file=${file.serverFileName}`} alt="업로드된 파일" />
                        ) : (
                          <div>{file.originalFileName}</div>
                        )}
                        <div className={`${seller.seller_reservation_item_modal_option_download_box}`}><button onClick={() => downloadFile(file.serverFileName, file.originalFileName)}><span className="material-symbols-outlined">download</span></button></div>
                      </div>
                    ))
                  )}
                  </div>
                  <div className={`${seller.seller_reservation_item_modal_option_box}`}>
                    <div>
                      <div className={`${seller.seller_reservation_item_modal_option_name}`}>
                        <div><span>옵션이름</span></div>
                        <div><span>{option.name}</span></div>
                      </div>
                      <div className={`${seller.seller_reservation_item_modal_option_value}`}>
                        <div><span>옵션값</span></div>
                        <div><span>{option.value}</span></div>
                      </div>
                    </div>
                    <div className={`${seller.seller_reservation_item_modal_option_price}`}>
                        <div>
                            <div><span>옵션금액</span></div>
                            <div><span>{option.price.toLocaleString()}원</span></div>
                        </div>
                        <div>
                            <div><span>수량</span></div>
                            <div><span>{option.quantity}개</span></div>
                        </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <p>추가 정보가 없습니다.</p>
              </div>
            )}
          </div>
        </div>
      )}

        {
            showOrderUser &&
            <div className={`${admin.admin_order_user_modal_container}`}>
                <div className={`${admin.admin_order_user_modal_box}`}>
                    <div className={`${admin.admin_order_user_modal_header}`}>
                        <div><span>주문자정보</span></div>
                        <div><button onClick={() => setShowOrderUser(false)}><span className="material-symbols-outlined">close</span></button></div>
                    </div>
                    <div className={`${admin.admin_order_user_modal_content}`}>
                        <div className={`${admin.admin_order_user_modal_content_user_profile}`}>
                            <div className={`${admin.admin_order_user_modal_content_user_profile_img}`}>
                                <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${orderUserInfo.userProfile}`} alt="userProfile" /></div>
                                <div><button>문자보내기</button></div>
                            </div>
                            <div className={`${admin.admin_order_user_modal_content_user_profile_info}`}>
                                <div>
                                    <div><span>닉네임</span></div>
                                    <div><span>{orderUserInfo.userNick}</span></div>
                                </div>
                                <div>
                                    <div><span>이름</span></div>
                                    <div><span>{orderUserInfo.userName}</span></div>
                                </div>
                                <div>
                                    <div><span>주문자 이름</span></div>
                                    <div><span>{singleOrderInfo.deliveryUser}</span></div>
                                </div>
                                <div>
                                    <div><span>배송 주소지</span></div>
                                    <div><span>{singleOrderInfo.deliveryAddress}</span></div>
                                </div>
                                <div>
                                    <div><span>이메일</span></div>
                                    <div><span>{orderUserInfo.userEmail}</span></div>
                                </div>
                                <div>
                                    <div><span>전화번호</span></div>
                                    <div><span>{orderUserInfo.userTel}</span></div>
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_order_user_modal_content_user_orders}`}>
                            <div><span>주문 내역</span></div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
  )
}

export default AdminSupportOrder