import React, { useContext, useEffect, useRef, useState } from 'react'
import admin from '../../../admin.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Select } from 'antd';
import TextEditor from './TextEditor';
import TextEditor2 from './TextEditor2';
import * as support from '../../../../apis/support';
import * as category from '../../../../apis/category';
import * as adminApi from '../../../../apis/admin';
import AdminDeliveryAdd from './AdminDeliveryAdd';
import AdminDeliveryAddPopup from './AdminDeliveryAddPopup';
import * as delivery from '../../../../apis/delivery';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import axios from 'axios';

import * as product from '../../../../apis/store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import seller from '../../../../seller/seller.module.css';

import * as sellerapi from '../../../../apis/seller';
import SellerSalesMain from '../../../../seller/components/section/supportdashboard/sales/SellerSalesMain';

const AdminProductRead = () => {
  const { isLogin, adminlogout, userInfo, loginCheck, roles } = useContext(LoginContext);
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const { productNumber } = useParams();



  const [ mainImage, setMainImage ] = useState(null);
  const [ listImages, setListImages ] = useState([]);
  const { Option } = Select;
  const [ mainCategory, setMainCategory ] = useState('store');
  const [ middleCategory, setMiddleCategory ] = useState([]);
  const [ middleCategorySelect, setMiddleCategorySelect ] = useState('');
  const [ loadMiddleCategory, setLoadMiddleCategory ] = useState('');
  const [ subCategory, setSubCategory ] = useState([]);
  const [ selectSubCategory, setSelectSubCategory ] = useState([]);
  const [ subCategorySelect, setSubCategorySelect ] = useState([]);
  const [ couponCheckState, setCouponCheckState ] = useState(false);
  const [ reservesCheckState, setReservesCheckState ] = useState(false);  
  const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
  const [isTemplateLoaded, setIsTemplateLoaded] = useState(false);

  // 배송 템플릿 선택
  const [ template, setTemplate ] = useState([]);
  const [ selectedTemplate, setSelectedTemplate ] = useState(null);

  
  // 에디터 내용 담을 변수
  const [ data, setData ] = useState("");
  const [ fileName, setFileName ] = useState('');
  const [ update, setUpdate ] = useState(false);
  const [ data2, setData2 ] = useState("");
  
  const [combinationOptionState, setCombinationOptionState] = useState(false);
  const [combinationOptions, setCombinationOptions] = useState([]);

  const [ relatedState, setRelatedState ] = useState('');
  const [ optionState, setOptionState ] = useState('option_false');
  const [ options, setOptions ] = useState([]);
  const [ inventoryEnabled, setInventoryEnabled ] = useState(false); // 재고 설정 사용 여부 상태
  const [ regularPrice, setRegularPrice ] = useState('');
  const [ salePrice, setSalePrice ] = useState('');
  const [ discountRate, setDiscountRate ] = useState(0);  
  const [ optionValueSave, setOptionValueSave] = useState([]);
  
  const [textColors, setTextColors] = useState(['#000000']); // Initialize with a default color
  const [backgroundColors, setBackgroundColors] = useState(['#ffffff']); // Initialize with a default color
  const [englishText, setEnglishText] = useState('');
  const [koreanText, setKoreanText] = useState('');

  const initialProductInfo = {
    productName: '',
    productMainImage: '',
    productListImage: [],
    mainCategory: mainCategory,
    middleCategory: '',
    subCategory: '',
    rateCoupon: false,
    rateReserves: false,
    productBadge: [],
    consignmentStatus: '',
    productPrice: '',
    productsalePrice: '',
    discountRate: '',
    relatedProduct: '',
    optionNameList: [],
    optionValueList: [],
    inventoryCnt: '',
    productDetail: '',
    deliveryTemplate: '',
    photoFileForm: '',
    dateFileForm: '',
    contentForm: [],
    productFaq: '',
    returnRule: '',
    productFaqs: [],
    combinationState: false,
    combinationOptions: [],
    productWeight: ''
  };
  
  const [productInfo, setProductInfo] = useState(initialProductInfo);

  const [deliveryData, setDeliveryData] = useState({
    userId: userInfo?.userId, dtName: '', dtDeliveryEngType: '', dtDeliveryType: '', dtDeliveryEngPm: '', dtDeliveryPm: '', dtDeliveryEngCompany: '', dtDeliveryCompany: '', dtDeliveryPrice: '',
    dtDeliveryFree: '', dtJejuAddPrice: '', dtOtherAddPrice: '', dtReturnDeliveryPrice: '', dtExchangeDeliveryPrice: '',
    dtShippingAddress: '', dtShippingAddressDetail: '', dtShippingAddressZipcode: '', dtReturnLocation: '',
    dtReturnLocationDetail: '', dtReturnLocationZipcode: '', dtCompanyTel: '', overseasShippingCost: ''
  });

  const [approvedState, setApprovedState] = useState('');

  const [ showFavoritySelect, setShowFavoritySelect ] = useState(false);
  const [ favorityList, setFavorityList ] = useState([]);
  const [ selectedFavoritis, setSelectedFavoritis ] = useState([]);
  const [ searchValue, setSearchValue ] = useState('');
  const [ selectedArtistNames, setSelectedArtistNames ] = useState('');
  
  const [ showBadgeModal, setShowBadgeModal ] = useState(false);
  const [ badgeList, setBadgeList ] = useState([]);

  const [ showFaqModal, setShowFaqModal ] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [selectedFaqs, setSelectedFaqs] = useState([]); // 체크된 FAQ를 저장할 상태

  const [ showFaqAdd, setShowFaqAdd ] = useState(false);

  const [ productWeight, setProductWeight ] = useState(0);
  const [ showWeightInfo, setShowWeightInfo ] = useState(false);

  useEffect(() => {
    if (combinationOptionState && Array.isArray(combinationOptions)) {
        const updatedCombinationOptions = combinationOptions.map((combination, index) => {
            const optionsArray = combination.options || []; // options가 존재하지 않으면 빈 배열로 설정

            // console.log('combination : ', combination);
            // console.log('updatedCombinationOptions : ', optionsArray);

            return {
                combinationId: index + 1,
                combination: optionsArray.map((opt) => `${opt.optionName}: ${opt.optionValue}`).join(', '),
                options: optionsArray.map((opt) => ({
                    optionName: opt.optionName,
                    optionValue: opt.optionValue,
                })),
                price: combination.price ? combination.price : 0,
                stock: combination.stock ? combination.stock : 0,
                status: combination.status === 'available' ? 'available' : 'unavailable',
            };
        });

        setProductInfo((prevProductInfo) => ({
            ...prevProductInfo,
            combinationOptions: updatedCombinationOptions,
        }));
    }
}, [combinationOptionState, combinationOptions]);

  useEffect(() => {
    // console.log('combinationOptions : ', combinationOptions);
  }, [combinationOptions])

  // useEffect(() => {
  //    // faq 값을 배열로 변환

  //   setProductInfo({
  //     ...productInfo, faq : faqArray
  //   });

  // },[productInfo]);


  const handleApprovedChange = (e) => {
    const value = e.target.id;
    setApprovedState(value);
    
    if (value === 'immediate_exposure') {
      setProductInfo(productInfo => {
        return {...productInfo, saleState: '판매중'};
      });
    } else {
      setProductInfo(productInfo => {
        return {...productInfo, saleState: ''};
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await deliverySelect();
      setIsTemplateLoaded(true);  // 배송 템플릿 로드 완료
    };

    fetchData();
    artistDBSelect();
    faqListSelect();
    storeSubCategorySelect();
  }, [userInfo]);

  useEffect(() => {
    console.log('productInfo : ', productInfo);
  },[productInfo]);

  useEffect(() => {
    if (template.length > 0 && isTemplateLoaded && userInfo) {
      productRead();
    }
  }, [template, isTemplateLoaded, userInfo]);

  
  const faqListSelect = async () => {
    const response = await sellerapi.sellerFaqSelect(userInfo && userInfo.userId);
    const data = response.data;

    // console.log(data);
    setFaqList(data);

  }

  const storeSubCategorySelect = async () => {
    const response = await category.storeSubCategorySelect();
    const data = response.data;

    // console.log('storeSubCategorySelect : ', data);
    setSubCategory(data);
  }

  const sellerFaqAdd = () => {
    setShowFaqAdd(true);
  }

  const handleFaqSelect = () => {
      const selectedFaqNos = selectedFaqs.map(faq => faq.sellerFaqNo);
      setProductInfo((prevProductInfo) => ({
          ...prevProductInfo,
          productFaq: selectedFaqNos // 배열로 저장
      }));
      setShowFaqModal(false);
  };

  const handleRemoveFaq = (faqItem) => {
      // console.log('faqItem : ', faqItem);

      setSelectedFaqs((prevSelected) =>
          prevSelected.filter((item) => item.sellerFaqNo !== faqItem.sellerFaqNo)
      );

      setProductInfo((prevProductInfo) => ({
          ...prevProductInfo,
          productFaq: prevProductInfo.productFaq.filter((faqNo) => faqNo !== faqItem.sellerFaqNo),
      }));
  };

  const handleFaqCheckboxChange = (faqItem) => {
    setSelectedFaqs((prevSelected) => {
      // prevSelected가 null이나 undefined일 경우 빈 배열로 초기화
      const updatedSelected = prevSelected || [];
      if (updatedSelected.includes(faqItem)) {
        return updatedSelected.filter(item => item !== faqItem);
      } else {
        return [...updatedSelected, faqItem];
      }
    });
  };

  const deliverySelect = async () => {
    let response;
    let data;
    if(roles.isAdmin) {
      response = await delivery.adminDeliverySelect();
      data = response.data;
    } else {
      response = await delivery.deliverySelect(userInfo && userInfo.userId);
      data = response.data;
    }
    
    // console.log('setTemplate : ', data);

    setTemplate(data);
  }

  const handleTemplateChange = (selectedOption) => {
    // console.log(selectedOption);
    const selectedTemplate = template.find(item => item.dtNo === selectedOption);
    setSelectedTemplate(selectedTemplate);
    setProductInfo(productInfo => {
      return {...productInfo, deliveryTemplate: selectedTemplate.dtNo};
    });
  };

  const setTemplateByName = (templateName) => {
    // console.log('templateName : ', templateName);
    // console.log('template : ', template);
  
    // template이 배열인지 확인하고, 아닌 경우 빈 배열로 초기화
    if (!Array.isArray(template)) {
      console.error('template is not an array:', template);
      return;
    }
  
    // find 함수로 템플릿을 검색
    const selectedTemplate = template.find(item => item.dtNo === Number(templateName));
    // console.log('find selectedTemplate : ', selectedTemplate);
  
    if (selectedTemplate) {
      setSelectedTemplate(selectedTemplate);
      setProductInfo(productInfo => {
        return { ...productInfo, deliveryTemplate: selectedTemplate.dtNo };
      });
    } else {
      console.warn('Template not found:', templateName);
    }
  };

  const productRead = async () => {
    const response = await product.productRead(productNumber);
    const data = response.data;

    console.log('productRead : ', data);

    setProductInfo(data.productDTO);

    const faqArray = data && data.productDTO && data.productDTO.productFaq
    ? data.productDTO.productFaq.split(',').map(Number)
    : [];

    setProductInfo(productInfo => ({
      ...productInfo,
      productListImage: data.imageList,
      optionNameList: data.optionNameList,
      optionValueList: data.optionValueList,
      productFaq: faqArray,
      combinationOptions: data.combinationOptionList,
    }));

    setCombinationOptionState(data.productDTO.combinationState);
    setCombinationOptions(data.combinationOptionList);
    setSelectedFaqs(data.productDTO.productFaqs);

    setListImages(data.imageList);
    setMainImage(data.productDTO.productMainImage);
    setProductName(data.productDTO.productName);
    setMiddleCategorySelect(data.productDTO.middleCategory || '');

    const filteredSubCategories = subCategory.filter(sub => sub.storeMainCategoryEngName === data.productDTO.middleCategory);
    setSelectSubCategory(filteredSubCategories);
    setSubCategorySelect(data.productDTO.subCategory || '');

    setRegularPrice(data.productDTO.productPrice);
    setSalePrice(data.productDTO.productsalePrice);
    setData(data.productDTO.productDetail);

    setProductWeight(data.productDTO.productWeight);
    setContentQuestions(data.productDTO.contentForm ? data.productDTO.contentForm : []);

    // 옵션 데이터 구성
    if (data.optionValueList && data.optionValueList.length > 0) {
        // console.log('data.optionValueList : ', data.optionValueList);
        setOptionState('option_true');
        
        const formattedOptions = data.optionNameList.map(optionNameItem => {
            const values = data.optionValueList
              .filter(valueItem => valueItem.optionName === optionNameItem.optionName)
              .map(valueItem => ({
                value: valueItem.optionValueName,
                price: valueItem.optionPrice,
                stock: valueItem.optionInventory,
                status: valueItem.optionSaleStatus
              }));
          
            return {
              name: optionNameItem.optionName,
              values,
              required: optionNameItem.optionEssential
            };
        });
        
        setOptions(formattedOptions);

        const hasInventory = data.optionValueList.some(valueItem => valueItem.optionPrice || valueItem.optionInventory);
        setInventoryEnabled(hasInventory ? 'true' : 'false');
    } else if (data.combinationOptionList && data.combinationOptionList.length > 0) {
        // console.log('data.combinationOptionList : ', data.combinationOptionList);
        setOptionState('option_true');
        
        // 중복된 옵션 값을 제거하여 구성
        const uniqueOptions = {};
        
        data.combinationOptionList.forEach(combination => {
            combination.options.forEach(opt => {
                if (!uniqueOptions[opt.optionName]) {
                    uniqueOptions[opt.optionName] = new Set();
                }
                uniqueOptions[opt.optionName].add(opt.optionValue);
            });
        });

        const formattedCombinations = Object.entries(uniqueOptions).map(([optionName, valuesSet]) => {
          const optionEssential = data.optionNameList.find(
              optionItem => optionItem.optionName === optionName
          )?.optionEssential || false; // optionEssential 값이 없으면 기본값으로 false 사용
          
          return {
              name: optionName,
              values: Array.from(valuesSet).map(value => ({
                  value: value,
                  price: 0, // 가격은 기본값으로 설정
                  stock: 0, // 재고는 기본값으로 설정
                  status: 'available' // 기본 판매 상태
              })),
              required: optionEssential // optionEssential 값에 따라 설정
          };
      });
        
        setOptions(formattedCombinations);
        setInventoryEnabled('true');
    } else {
        setOptionState('option_false');
        setInventoryEnabled('false');
    }

    setData2(data.productDTO.returnRule);

    setTemplateByName(data.productDTO.deliveryTemplate);
    setSubCategorySelect(data.productDTO.subCategory);
};



  // useEffect(() => {
  //   console.log(middleCategorySelect);
  // }, [middleCategorySelect]);

  // 배송지 추가
  const [showDelivery, setShowDelivery] = useState(false);
  const onDeliveryAdd = () => {
    setShowDelivery(true);
  }
  // 판매가
  const onSalePrice = (e) => {
    setSalePrice(e.target.value);
    setProductInfo(productInfo => {
      return {...productInfo, productsalePrice: e.target.value};
    });
  }
  // 정가
  const onProductPrice = (e) => {
    setRegularPrice(e.target.value);
    setProductInfo(productInfo => {
      return {...productInfo, productPrice: e.target.value};
    });
  }
  // 위탁 여부
  const handleConsignmentChange = (e) => {
    setProductInfo({
      ...productInfo,
      consignmentStatus: e.target.value === 'true' ? true : false
    });
  };

  // 뱃지 종류
  const handleBadgeChange = (item) => {
    // console.log('badge : ', item);
    const badge = item.korBadgeName;
    
    setProductInfo(productInfo => {
      return {...productInfo, productBadge: badge};
    });
};
  
  // 할인설정
  const onCouponCheck = (e) => {
    // console.log(e.target.id);
    if(e.target.checked) {
      setCouponCheckState(e.target.checked);
      setProductInfo(productInfo => {
        return {...productInfo, rateCoupon: e.target.checked};
      });
    } else {
      setCouponCheckState(e.target.checked);
      setProductInfo(productInfo => {
        return {...productInfo, rateCoupon: e.target.checked};
      });
    }
  }
  const onReservesCheck = (e) => {
    // console.log(e.target.id);
    if(e.target.checked) {
      setReservesCheckState(e.target.checked);
      setProductInfo(productInfo => {
        return {...productInfo, rateReserves: e.target.checked};
      });
    } else {
      setReservesCheckState(e.target.checked);
      setProductInfo(productInfo => {
        return {...productInfo, rateReserves: e.target.checked};
      });
    }
  }

  // 소분류 카테고리 설정
  useEffect(() => {
    const filteredSubCategories = Array.isArray(subCategory) 
    ? subCategory.filter(sub => sub.storeMainCategoryEngName === middleCategorySelect) 
    : [];
    
    if(filteredSubCategories.length === 0) {
      setSelectSubCategory('');  
    } else {
      setSelectSubCategory(filteredSubCategories);
    }
  }, [middleCategorySelect])

  const onMainCategory = (value) => {
    // console.log('선택된 값:', value); // 선택된 옵션의 값 확인
    setMainCategory(value);
    setProductInfo(productInfo => {
      return {...productInfo, mainCategory: value};
    });
  }


  // 중분류 카테고리 설정
  useEffect(() => {
    if(mainCategory === 'store') {
      middleCategorySetup();
    }
  }, [mainCategory]);
  
  useEffect(() => {
    console.log('middleCategory : ', middleCategory);
  }, [middleCategory])
  
  const middleCategorySetup = async () => {
    const response = await category.storeCategory();
    const data = response.data;
    
    console.log(data);
    setMiddleCategory(data);
  }

  const artistDBSelect = async () => {
    const response = await adminApi.artistDBSelect();
    const data = response.data;

    console.log('artistDBSelect : ', data);
    setFavorityList(data);
  }

  const onSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $&는 일치된 전체 문자열을 의미합니다.
  };

  const filteredFavorityList = favorityList.filter(fav => {
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      return (
          searchRegex.test(fav.artistName) || 
          searchRegex.test(fav.artistGroup) ||
          searchRegex.test(fav.fandom) || 
          searchRegex.test(fav.labels) || 
          searchRegex.test(fav.realName) ||
          searchRegex.test(fav.artistAgency)
      );
  });

  const handleFavorityCheckboxChange = (fav) => {
    setSelectedFavoritis(prevSelectedFavoritis => {
        if (prevSelectedFavoritis.some(selectedFavority => selectedFavority.artistNo === fav.artistNo)) {
            return prevSelectedFavoritis.filter(selectedFavority => selectedFavority.artistNo !== fav.artistNo);
        } else {
            return [...prevSelectedFavoritis, fav];
        }
    });
  };

  const handleFavoritySelect = () => {
    const artistNames = selectedFavoritis.map(item => item.artistName).join(',');

    console.log('artistNames : ', artistNames)

    setSelectedArtistNames(artistNames);
    setProductInfo(productInfo => {
      return {...productInfo, favority : artistNames}
    });
    setSelectedFavoritis([]);
    setShowFavoritySelect(false);
  }

  
  const onExchangeReturn = (data) => {
    setProductInfo(productInfo => {
      return {...productInfo, returnRule: data};
    });
  }

  const onDetailContent = (data) => {
    setProductInfo(productInfo => {
      return {...productInfo, productDetail: data};
    });
  }

  useEffect(() => {
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  useEffect(() => {
    console.log(mainImage);
  }, [mainImage]);

  const handleDragOver = (e) => {
    e.preventDefault(); // 기본 이벤트를 방지합니다.
  };

  const handleDropMainImage = async (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const file = files && files[0];

    if (file) {
        // 파일 크기가 5MB를 초과하는지 검사
        if (file.size > 5 * 1024 * 1024) {
            alert("파일 크기는 5MB를 초과할 수 없습니다.");
            return;
        }

        const formData = new FormData();
        formData.append('image', file);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;

        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;

        console.log(imageUrl);
        setMainImage(imageUrl);
        setProductInfo(productInfo => ({
            ...productInfo,
            productMainImage: imageUrl
        }));
    }
  };


  const handleDropListImages = async (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    
    // 현재 이미지와 새로 드래그 앤 드롭한 이미지를 합쳐 총 개수를 계산
    const currentImageCount = listImages.length;
    const newFilesCount = files.length;
    const totalImagesCount = currentImageCount + newFilesCount;

    if (totalImagesCount > 4) {
      alert('4개의 파일만 선택해주세요.');
      return;
    }

    const fileList = [];
    const formData = new FormData();
    for (const file of files) {
      if (file.size > 5 * 1024 * 1024) {
        alert("파일 크기는 5MB를 초과할 수 없습니다.");
        return;
      } else {
        formData.append('images', file);
      }
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    const response = await support.uploadImagesToServer(formData, config);
    const data = response.data;

    const newImageUrls = data.map(fileName => 
      `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(fileName)}`
    );

    setListImages(prevImages => [...prevImages, ...newImageUrls]);
    setProductInfo(productInfo => ({
      ...productInfo,
      productListImage: [...productInfo.productListImage, ...newImageUrls]
    }));
  };
  
  // 파일 인풋에 대한 참조 생성
  const mainImageInputRef = useRef(null);
  const listImagesInputRef = useRef(null);
  
  // 대표 이미지 추가 버튼 클릭 핸들러
  const handleMainImageUploadClick = () => {
    mainImageInputRef.current.click();
  };
  
  // 리스트 이미지 추가 버튼 클릭 핸들러
  const handleListImagesUploadClick = () => {
    listImagesInputRef.current.click();
  };
  
  // 대표 이미지 파일 변경 핸들러
  const handleMainImageChange = async(e) => {
    const file = e.target.files[0];
    if (file) {
      // 파일 크기가 5MB를 초과하는지 검사
      if(file.size > 5 * 1024 * 1024) {
        alert("파일 크기는 5MB를 초과할 수 없습니다.");
        return; // 함수 종료
      }

      const formData = new FormData();
      formData.append('image', file);

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      const response = await support.uploadImageToServer(formData, config);
      const data = response.data;

      const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;

      console.log(imageUrl);
      // setImageListImgPreview4(imageUrl);

      // setMainImage(URL.createObjectURL(file));
      setMainImage(imageUrl);
      setProductInfo(productInfo => {
        return {...productInfo, productMainImage: imageUrl};
      });
    }
  };

  // 리스트 이미지 파일 변경 핸들러
  const handleListImagesChange = async (e) => {
    const files = e.target.files;

    if (files) {
        const currentImageCount = listImages.length;
        const newFilesCount = files.length;
        const totalImagesCount = currentImageCount + newFilesCount;

        if (totalImagesCount > 4) {
            alert('4개의 파일만 선택해주세요.');
            return;
        }

        const fileList = [];
        const formData = new FormData();

        for (const file of files) {
            // 파일 크기가 5MB를 초과하는지 검사
            if (file.size > 5 * 1024 * 1024) {
                alert("파일 크기는 5MB를 초과할 수 없습니다.");
                return; // 함수 종료
            } else {
                formData.append('images', file); // 파일 추가
            }
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        const response = await support.uploadImagesToServer(formData, config);
        const data = response.data;

        const newImageUrls = data.map(fileName =>
            `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(fileName)}`
        );

        setListImages(prevImages => [...prevImages, ...newImageUrls]); // 기존 이미지에 새 이미지 추가
        setProductInfo(productInfo => ({
            ...productInfo,
            productListImage: [...productInfo.productListImage, ...newImageUrls]
        }));
    }
  };


  const onRelated = (e) => {
    console.log(e.target.id);
    setRelatedState(e.target.id);
  }

  const onOptionSelect = (e) => {
    console.log(e.target.id);
    setOptionState(e.target.id);

    if (e.target.id === 'option_true') {
      setInventoryEnabled(true);
      setCombinationOptionState(true);
      setProductInfo((prevInfo) => ({ ...prevInfo, inventoryCnt: 0, combinationState: true})); // Reset inventory count
    }
    
    if(e.target.id === 'option_false') {
      setProductInfo((prevInfo) => ({ ...prevInfo, inventoryCnt: 0, combinationState: false})); // Reset inventory count
      setInventoryEnabled(false);
      setOptions([]);
    }
  }



  const addOption = () => {
    const newOption = { name: '', values: [{ value: '', price: '', stock: '', status: 'available' }], required: false };
    const updatedOptions = [...options, newOption];
    setOptions(updatedOptions);
    
    // 새로운 옵션 추가 후 조합 생성
    const newCombinations = generateOptionCombinations(updatedOptions);
    setCombinationOptions(newCombinations);
  };
  
  // 옵션 초기화
  const resetOptions = () => {
    setOptions([]);
    setCombinationOptions([]);
    
    setProductInfo({
      ...productInfo,
      optionNameList: [],
      optionValueList: []
    });
  };

  const handleOptionChange = (optionIndex, valueIndex, key, value) => {
    const newOptions = [...options];
    
    if (key === 'name' || key === 'required') {
      newOptions[optionIndex][key] = value;
    } else if (valueIndex !== null) {
      if (!newOptions[optionIndex].values) {
        newOptions[optionIndex].values = [];
      }
      if (!newOptions[optionIndex].values[valueIndex]) {
        newOptions[optionIndex].values[valueIndex] = { value: '', price: '', stock: '', status: 'available' };
      }
      newOptions[optionIndex].values[valueIndex][key] = value;
    }
  
    setOptions(newOptions);
    updateProductInfo(newOptions);
  };
  

  const handleInventoryChange = (e) => {
    console.log(e.target.value);
    setInventoryEnabled(e.target.value === 'true');

    if(e.target.value === 'false') {
      setProductInfo({
        ...productInfo,
        inventoryCnt : 9999
      })
    }
  };

  const splitOptionValues = (optionValuesString) => {
    return optionValuesString.split(',').filter(value => value.trim() !== '');
  };

  const updateProductInfo = (newOptions) => {
    setOptionValueSave(newOptions);

    if (combinationOptionState) {
        // 조합형 옵션일 경우 combinationOptions 값을 유지하면서 업데이트
        setProductInfo((prevProductInfo) => ({
            ...prevProductInfo,
            optionNameList: newOptions.map((option) => ({
                optionNameNo: '',
                optionName: option.name,
                optionEssential: option.required,
            })),
            // combinationOptions: combinationOptions.map((combination, index) => {
            //     const optionsArray = combination.options || [];

            //     // 옵션 값의 총 가격을 계산
            //     const totalCombinationPrice = optionsArray.reduce(
            //         (sum, opt) => sum + (opt.price ? parseFloat(opt.price) : 0),
            //         0
            //     );

            //     // 옵션 값의 최소 재고를 계산
            //     const minCombinationStock = optionsArray.reduce(
            //         (minStock, opt) => Math.min(minStock, opt.stock ? parseInt(opt.stock) : Infinity),
            //         Infinity
            //     );

            //     return {
            //         combinationId: index + 1,
            //         combination: optionsArray.map((opt) => `${opt.optionName}: ${opt.optionValue}`).join(', '), // 옵션 이름과 값을 조합하여 표시
            //         options: optionsArray.map((opt) => ({
            //             optionName: opt.optionName,
            //             optionValue: opt.optionValue,
            //         })),
            //         price: totalCombinationPrice,
            //         stock: minCombinationStock === Infinity ? 0 : minCombinationStock,
            //         status: optionsArray.every((opt) => opt.status === 'available') ? 'available' : 'unavailable',
            //     };
            // }),
            optionValueList: [],
        }));
    } else {
        // 조합형 옵션이 아닌 경우
        setProductInfo((prevProductInfo) => ({
            ...prevProductInfo,
            optionNameList: newOptions.map((option) => ({
                optionNameNo: '',
                optionName: option.name,
                optionEssential: option.required,
            })),
            optionValueList: newOptions.flatMap((option) =>
                option.values.map((value) => ({
                    optionValueNo: '',
                    optionValueName: value.value,
                    optionName: option.name,
                    optionPrice: value.price,
                    optionInventory: value.stock,
                    optionSaleStatus: value.status,
                }))
            ),
            combinationOptions: [],
        }));
    }
  };

  

  // 상품명
  const [ productName, setProductName ] = useState('');
  const onProductName = (e) => {
    console.log(e.target.value);
    const pName = e.target.value; 
    setProductName(e.target.value);

    setProductInfo(productInfo => {
      return {...productInfo, productName: pName};
    });
  }
  const onMiddleCategory = (value) => {
    setMiddleCategorySelect(value);
    setProductInfo(productInfo => {
      return {...productInfo, middleCategory: value};
    });
    setSubCategorySelect('');
    setProductInfo(productInfo => {
      return {...productInfo, subCategory: ''};
    });
  }

  const onSubCategory = (value) => {
    setSubCategorySelect(value);
    setProductInfo(productInfo => {
      return {...productInfo, subCategory: value};
    });
  }
  
  useEffect(() => {
    console.log('중분류 : ', middleCategorySelect);
  }, [middleCategorySelect]);


  // 할인율 계산하는 함수
  const calculateDiscountRate = (regularPrice, salePrice) => {
    if (regularPrice && salePrice) {
      return ((regularPrice - salePrice) / regularPrice) * 100;
    }
    return 0;
  };

  // 정가와 판매가가 변경될 때마다 할인율을 자동으로 계산
  useEffect(() => {
    if (regularPrice && salePrice) {
      if (parseFloat(salePrice) > parseFloat(regularPrice)) {
        alert('판매가가 정가보다 높을 수 없습니다.');
        setSalePrice(regularPrice);
      } else {
        const discount = calculateDiscountRate(parseFloat(regularPrice), parseFloat(salePrice));
        setDiscountRate(discount.toFixed(0));
        setProductInfo(productInfo => {
          return {...productInfo, discountRate: discount.toFixed(0)};
        });
      }
    }
  }, [regularPrice, salePrice]);


  useEffect(() => {
    console.log(selectedTemplate);
  },[selectedTemplate]);
  
  const [contentQuestions, setContentQuestions] = useState([]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setProductInfo((prevInfo) => ({
      ...prevInfo,
      [name]: checked
    }));
  };

  const handleAddContentForm = () => {
    setContentQuestions((prevQuestions) => [...prevQuestions, '']);
  };

  const handleContentChange = (index, value) => {
    const updatedQuestions = [...contentQuestions];
    updatedQuestions[index] = value;
    setContentQuestions(updatedQuestions);
    setProductInfo((prevInfo) => ({
      ...prevInfo,
      contentForm: updatedQuestions
    }));
  };

  const handleRemoveContentForm = (index) => {
    const updatedQuestions = contentQuestions.filter((_, i) => i !== index);
    setContentQuestions(updatedQuestions);
    setProductInfo((prevInfo) => ({
      ...prevInfo,
      contentForm: updatedQuestions
    }));
  };

  const confirm = () => {
    return productInfo 
      && productInfo.productName 
      && productInfo.productMainImage 
      && productInfo.productListImage 
      // && productInfo.productListImage.length > 0 
      && productInfo.mainCategory 
      && productInfo.middleCategory 
      && productInfo.productPrice 
      // && productInfo.productsalePrice 
      // && productInfo.discountRate 
      && productInfo.productDetail 
      && productInfo.returnRule;
  };
  
  const onProductAdd = async () => {

    const productData = {
      ...productInfo,
      productFaq: productInfo.productFaq.join(",")
    };

    MySwal.fire({
      customClass: {
          popup: `${admin.admin_seller_add_popup}`
      },
      html : 
      `
          <div>
              <div class='${admin.admin_seller_add_title}'><span>상품을 수정하시겠습니까?</span></div>
              <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
              <button class='mx-1' id='product_add_cancel'>취소</button>
              <button class='mx-1' id='product_add_success'>수정</button>
              </div>
          </div>
      `,
      showConfirmButton: false,
      showCancelButton: false,
    });
      document.addEventListener('click', productAddSuccess);
      document.addEventListener('click', productAddCancel);

      function productAddSuccess(e) {
        if(e.target && e.target.id == 'product_add_success') {
          updateEvent(productData);
          MySwal.close();
          document.removeEventListener('click', productAddSuccess);
        }
      }
      
      function productAddCancel(e) {
        if(e.target && e.target.id == 'product_add_cancel') {
          MySwal.close();
          document.removeEventListener('click', productAddCancel);
        }
      }

      const updateEvent = async (productData) => {
        console.log('productInfo : ', productInfo);

        const response = await product.productUpdate(productData, userInfo && userInfo.userId);
        const data = response.data;
    
        console.log('product added successfully : ', data);
    
        if(data === 'SUCCESS') {
          MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html : 
                    `
                    <div>
                    <div class='${admin.admin_seller_add_title}'><span>상품 수정이 완료 되었습니다.</span></div>
                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                    </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
                document.addEventListener('click', sellerAddConfirm);
                
                function sellerAddConfirm(e) {
                    if(e.target && e.target.id == 'seller_add_confirm') {
                        MySwal.close();
                        document.removeEventListener('click', sellerAddConfirm);
                        navigate('/admin/shop/product');
                    }
                }
        }
      }
    
  }

  const onSave = async () => {
    const response = await delivery.deliveryInsert(deliveryData);
    const data = response.data;

    if(data === 'SUCCESS') {
      MySwal.fire({
        customClass: {
            popup: `${admin.admin_seller_add_popup}`
        },
        html : 
        `
            <div>
                <div class='${admin.admin_seller_add_title}'><span>배송템플릿 등록 완료</span></div>
                <div class='${admin.admin_seller_add_sub_title}'><span>등록이 완료됐습니다. 배송지를 선택해주세요.</span></div>
                <div class='${admin.admin_seller_add_btn_box} py-2'><button id='seller_date_save'>확인</button></div>
            </div>
        `,
        showConfirmButton: false,
        showCancelButton: false,
      });
      document.addEventListener('click', sellerProfileSuccess);

      function sellerProfileSuccess(e) {
        if(e.target && e.target.id == 'seller_date_save') {
          setShowDelivery(false);
          MySwal.close();
          deliverySelect();
          document.removeEventListener('click', sellerProfileSuccess);
        }
      }
    }
  };
    // Update a specific text color
    const updateTextColor = (index, color) => {
      const newColors = [...textColors];
      newColors[index] = color;
      setTextColors(newColors);
    };
  
    // Update a specific background color
    const updateBackgroundColor = (index, color) => {
      const newColors = [...backgroundColors];
      newColors[index] = color;
      setBackgroundColors(newColors);
    };
  
    const onBadgeCancel = () => {
      setTextColors([]);
      setBackgroundColors([]);
      setEnglishText('');
      setKoreanText('');
      setShowBadgeModal(false);
    }
  
    const onBadgeAdd = async () => {
      console.log('textColors : ', textColors[0]);
      console.log('backgroundColors : ', backgroundColors[0]);
      console.log('englishText : ', englishText);
      console.log('koreanText : ', koreanText);
  
      let newItem = {
        engBadgeName : englishText,
        korBadgeName : koreanText,
        badgeBackColor : backgroundColors[0],
        badgeFontColor : textColors[0]
      }
  
      console.log('newItem : ', newItem);
  
      const response = await adminApi.productBadgeAdd(newItem);
      const data = response.data;
  
      if(data === 'SUCCESS') {
        alert('상품 뱃지 등록이 완료되었습니다.');
        setTextColors([]);
        setBackgroundColors([]);
        setEnglishText('');
        setKoreanText('');
        setShowBadgeModal(false);
        badgeSelect();
      }
    }
    useEffect(() => {
      badgeSelect();
    }, []);
  
    const badgeSelect = async () => {
      const response = await adminApi.badgesSelect();
      const data = response.data;
  
      console.log('badgeSelect : ', data);
      setBadgeList(data);
    }

    const handleListImageDelete = async (index) => {
      try {
          // 이미지 URL에서 파일명을 추출
          const imageUrl = listImages[index];
          const queryParams = imageUrl.split('?')[1];
          let filePath = '';
          const params = queryParams.split('&');
          for (let param of params) {
              if (param.startsWith('file=')) {
                  filePath = decodeURIComponent(param.split('=')[1]);
                  break;
              }
          }
          const fileName = filePath.split('/').pop();
  
          console.log('fileName : ', fileName);
  
          // 서버에서 이미지 삭제 요청
          await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
  
          // 클라이언트에서 이미지 목록과 productInfo 업데이트
          setListImages((prevImages) => prevImages.filter((_, i) => i !== index));
          setProductInfo((productInfo) => ({
              ...productInfo,
              productListImage: productInfo.productListImage.filter((_, i) => i !== index),
          }));
      } catch (error) {
          console.error("Failed to delete image from server", error);
      }
    };
  
    const handleMainImageDelete = async () => {
      try {
        const queryParams = mainImage.split('?')[1];
        let filePath = '';
        const params = queryParams.split('&');
        for (let param of params) {
            if (param.startsWith('file=')) {
                filePath = decodeURIComponent(param.split('=')[1]);
                break;
            }
        }
        const fileName = filePath.split('/').pop();
        await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
        setMainImage('');
        setProductInfo((productInfo) => ({
          ...productInfo,
          productMainImage: "",
      }));
      } catch (error) {
          console.error('Failed to delete image from server', error);
      }
    }

  // 옵션값 삭제 핸들러
  const handleDeleteOptionValue = (optionIndex, valueIndex) => {
    const newOptions = [...options];
    const optionNameToDelete = newOptions[optionIndex]?.name;
    const optionValueToDelete = newOptions[optionIndex]?.values?.[valueIndex]?.value;
  
    // 옵션 값 삭제
    if (newOptions[optionIndex]?.values) {
      newOptions[optionIndex].values = newOptions[optionIndex].values.filter((_, index) => index !== valueIndex);
      setOptions(newOptions);
      updateProductInfo(newOptions); // productInfo 업데이트
    }
  
    // combinationOptions에서도 해당 옵션 값 삭제
    const updatedCombinationOptions = combinationOptions?.filter(combination => {
      return !combination.options?.some(option => 
        option.optionName === optionNameToDelete && option.optionValue === optionValueToDelete
      );
    }) || [];
    
    setCombinationOptions(updatedCombinationOptions);
  };

  const onCombinationCheck = (e) => {
    console.log(e.target.checked);
    setCombinationOptionState(!combinationOptionState);
    console.log('combinationOptions : ', combinationOptions);
  
    if (e.target.checked === true) {
      setProductInfo({
        ...productInfo,
        combinationState: e.target.checked,
        optionValueList: [],
        combinationOptions: combinationOptions.map((combination, index) => {
          // combination이 배열인지 확인하고, 배열이 아닐 경우 빈 배열로 설정
          const optionsArray = Array.isArray(combination) ? combination : combination.options || [];
  
          // 각 조합에 대해 가격과 재고를 개별적으로 설정
          const totalCombinationPrice = optionsArray.reduce((sum, opt) => {
            const optionPrice = opt.price && !isNaN(opt.price) ? parseFloat(opt.price) : 0; // price가 정의되지 않은 경우 0으로 설정
            return sum + optionPrice;
          }, 0);
  
          const minCombinationStock = optionsArray.reduce((minStock, opt) => {
            const optionStock = opt.stock && !isNaN(opt.stock) ? parseInt(opt.stock) : Infinity; // stock이 없으면 Infinity로 설정
            return Math.min(minStock, optionStock);
          }, Infinity);
  
          return {
            combinationId: index + 1,
            combination: optionsArray.map(opt => `${opt.name}: ${opt.value}`).join(', '),
            options: optionsArray.map(opt => ({
              optionName: opt.name,
              optionValue: opt.value,
            })),
            price: combination.price || totalCombinationPrice, // 이미 지정된 price가 있으면 사용
            stock: combination.stock || (minCombinationStock === Infinity ? 0 : minCombinationStock), // 이미 지정된 stock이 있으면 사용
            status: optionsArray.every(opt => opt.status === 'available') ? 'available' : 'unavailable',
          };
        }),
      });
    } else {
      setProductInfo({
        ...productInfo,
        combinationState: e.target.checked,
        combinationOptions: [],
        optionValueList: [...optionValueSave],
      });
    }
  };
  

  const handleRemoveOption = (optionIndex) => {
    // 새로운 옵션 목록 생성 (제거된 옵션 제외)
    const newOptions = options.filter((_, index) => index !== optionIndex);
    setOptions(newOptions);
  
    if (combinationOptionState) {
      // 삭제할 옵션 이름 추출
      const optionNameToDelete = options[optionIndex]?.name;

      console.log('optionNameToDelete : ', optionNameToDelete);
  
      // combinationOptions에서 해당 옵션만 제거
      const updatedCombinationOptions = combinationOptions.map((combination) => {
        const filteredOptions = combination.options.filter(
          (option) => option.optionName.trim() !== optionNameToDelete.trim()
        );
      
        // 새로 생성된 옵션 배열을 사용해 `combination` 문자열 재생성
        const updatedCombinationString = filteredOptions
          .map((option) => `${option.optionName}: ${option.optionValue}`)
          .join(', ');
      
        return {
          ...combination,
          options: filteredOptions,
          combination: updatedCombinationString, // 업데이트된 combination 문자열
        };
      }).filter((combination) => combination.options.length > 0); // 빈 옵션 조합 제거
       // 빈 옵션 조합 제거

      console.log('updatedCombinationOptions : ', updatedCombinationOptions);
  
      setCombinationOptions(updatedCombinationOptions);
  
      // productInfo 업데이트
      setProductInfo((prevProductInfo) => ({
        ...prevProductInfo,
        optionNameList: newOptions.map((option) => ({
          optionNameNo: '',
          optionName: option.name,
          optionEssential: option.required,
        })),
        combinationOptions: updatedCombinationOptions,
        optionValueList: [], // 조합형에서는 optionValueList를 비워둠
      }));
    } else {
      // 조합형 옵션이 아닐 때는 optionValueList 업데이트
      setProductInfo((prevProductInfo) => ({
        ...prevProductInfo,
        optionNameList: newOptions.map((option) => ({
          optionNameNo: '',
          optionName: option.name,
          optionEssential: option.required,
        })),
        optionValueList: newOptions.flatMap((option) =>
          option.values.map((value) => ({
            optionValueNo: '',
            optionValueName: value.value,
            optionName: option.name,
            optionPrice: value.price,
            optionInventory: value.stock,
            optionSaleStatus: value.status,
          }))
        ),
        combinationOptions: [], // 조합형 옵션이 아닌 경우 조합 옵션 비워둠
      }));
    }
  };
  
  
  

  const generateOptionCombinations = (options) => {
    if (options.length === 0) return [];
  
    let combinationCounter = 1; // combinationId 생성용
  
    return options.reduce((acc, option) => {
      if (acc.length === 0) {
        return option.values.map(value => ({
          combinationId: combinationCounter++, // 새로운 combinationId
          combination: `${option.name}: ${value.value}`,
          options: [{ ...value, optionName: option.name, optionValue: value.value }],
          price: value.price || 0,
          stock: value.stock || 0,
          status: value.status || 'available',
        }));
      }
  
      const newCombinations = [];
      acc.forEach(combination => {
        option.values.forEach(value => {
          const existingCombination = combinationOptions.find(
            (existing) =>
              existing.combination === `${combination.combination}, ${option.name}: ${value.value}`
          );
          newCombinations.push({
            combinationId: existingCombination?.combinationId || combinationCounter++, // 기존 ID 유지
            combination: `${combination.combination}, ${option.name}: ${value.value}`,
            options: [...combination.options, { ...value, optionName: option.name, optionValue: value.value }],
            price: existingCombination?.price ?? value.price ?? combination.price,
            stock: existingCombination?.stock ?? combination.stock,
            status: existingCombination?.status ?? (value.status === 'available' ? 'available' : 'unavailable'),
          });
        });
      });
  
      return newCombinations;
    }, []);
  };
  
  

  const handleCombinationOptionChange = (combinationIndex, key, value) => {
    // console.log('combinationIndex : ', combinationIndex);
    // console.log('key : ', key);
    // console.log('value : ', value);
    const updatedCombinationOptions = [...combinationOptions];
    updatedCombinationOptions[combinationIndex][key] = value;

    // console.log('updatedCombinationOptions[combinationIndex][key] : ', updatedCombinationOptions[combinationIndex][key]);
    // console.log('updatedCombinationOptions : ', updatedCombinationOptions);
    setCombinationOptions(updatedCombinationOptions);
    
    updateProductInfoWithCombinations(updatedCombinationOptions);
  };

  const updateProductInfoWithCombinations = (newCombinations) => {
    // console.log('상태업데이트')
    setProductInfo((prevProductInfo) => ({
      ...prevProductInfo,
      combinationOptions: newCombinations.map((combination, index) => {
        const optionsArray = Array.isArray(combination.options) ? combination.options : []; // options가 배열인지 확인 후 설정
  
        const totalCombinationPrice = optionsArray.reduce(
          (sum, opt) => sum + (opt.price ? parseFloat(opt.price) : 0),
          0
        );
  
        const minCombinationStock = optionsArray.reduce(
          (minStock, opt) => Math.min(minStock, opt.stock ? parseInt(opt.stock) : Infinity),
          Infinity
        );
  
        return {
          combinationId: index + 1,
          combination: optionsArray.map((opt) => `${opt.optionName}: ${opt.optionValue}`).join(', '),
          options: optionsArray.map((opt) => ({
            optionName: opt.optionName,
            optionValue: opt.optionValue,
          })),
          price: combination.price || totalCombinationPrice,
          stock: combination.stock || (minCombinationStock === Infinity ? 0 : minCombinationStock),
          status: optionsArray.every((opt) => opt.status === 'available') ? 'available' : 'unavailable',
        };
      }),
    }));
  };

  
  const onProductWeight = (e) => {
    console.log(e.target.value);
    const weight = e.target.value; 
    setProductWeight(weight);

    setProductInfo(productInfo => {
      return {...productInfo, productWeight: weight};
    });
  }

  const renderOverseasShipping = (kgString, costString, state) => {
    // 상태가 false거나 데이터가 없을 경우 기본값 출력
    if (!state || !kgString || !costString) {
        return (
            <div>
                <span>0kg 이상</span>
                <span> | KRW 0</span>
            </div>
        );
    }

    const kgArray = kgString.split(';');
    const costArray = costString.split(';');

    // 배열 길이가 맞지 않는 경우에도 기본값 출력
    if (kgArray.length !== costArray.length) {
        return (
            <div>
                <span>0kg 이상</span>
                <span> | KRW 0</span>
            </div>
        );
    }

    return kgArray.map((kg, index) => (
        <div key={index}>
            <span>{`${kg}kg 이상`}</span>
            <span>{` | KRW ${parseInt(costArray[index], 10).toLocaleString()}`}</span>
        </div>
    ));
  };
  

  return (
    <div className={`${admin.admin_shop_product_add_box} px-3 py-3`}>
        <div className='py-3'><h5>상품 수정</h5></div>
        <div>
        <div className={`${admin.admin_shop_side}`}>
              <div className={`${admin.admin_shop_side_box} px-3 py-3`}>
              {
                  roles.isAdmin &&
                  <div>
                    <div className={`${admin.admin_shop_product_badge_title_box}`}>
                      <div><span>상품뱃지</span></div>
                      <div><button onClick={() => setShowBadgeModal(true)}>상품뱃지 추가</button></div>
                    </div>
                    <div className={`pt-2 ${admin.admin_shop_product_badge_content_box} border-bottom pb-2`}>
                      {
                        Array.isArray(badgeList) && badgeList.map((item, index) => {
                          return (
                          <div className='pb-1' key={index}>
                            <input type="radio" id={item.engBadgeName} name="product_badge" onChange={() => handleBadgeChange(item)} checked={productInfo.productBadge === item.korBadgeName} />
                            <label className='ps-2' htmlFor={item.engBadgeName}>{item.korBadgeName}</label>
                          </div>
                          )
                        })
                      }
                    </div>
                  </div>
                }
                <div className='py-2 border-bottom'>
                  <div className={`${admin.admin_shop_product_add_consignment_title} pb-1`}><span>승인 후 노출여부</span><button><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>help</span></button></div>
                  <div>
                    <div className={`${admin.admin_shop_product_add_consignment_content}`}>
                      <div><input type="radio" name="approved" id="immediate_exposure" onChange={handleApprovedChange} checked={productInfo && productInfo.saleState === '판매중'} /></div>
                      <div><label className='ps-1' htmlFor="immediate_exposure">승인 완료 후 즉시노출 설정</label></div>
                    </div>
                    <div className={`${admin.admin_shop_product_add_consignment_content}`}>
                      <div><input type="radio" name="approved" id="direct_exposure" onChange={handleApprovedChange} checked={productInfo.saleState === '' || productInfo.saleState === null || productInfo.saleState === undefined} /></div>
                      <div><label className='ps-1' htmlFor="direct_exposure">승인 완료 후 직접노출 설정</label></div>
                    </div>
                  </div>
                </div>

                <div className='py-2 border-bottom'>
                  <div className={`${admin.admin_shop_product_add_consignment_title} pb-1`}><span>위탁신청</span><button><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>help</span></button></div>
                  <div className={`${admin.admin_shop_product_add_consignment_content}`}>
                    <div>
                      <input
                        type="radio"
                        name="consignment"
                        id="consignment_true"
                        value="true"
                        onChange={handleConsignmentChange}
                        checked={productInfo.consignmentStatus}
                      />
                      <label className='ps-1' htmlFor="consignment_true">신청</label>
                    </div>
                    <div className='ps-4'>
                      <input
                        type="radio"
                        name="consignment"
                        id="consignment_false"
                        value="false"
                        onChange={handleConsignmentChange}
                        checked={!productInfo.consignmentStatus} // Set checked property based on consignmentStatus state
                      />
                      <label className='ps-1' htmlFor="consignment_false">신청안함</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${admin.admin_shop_product_add_product_box}`}>
                <div className={`${admin.admin_shop_product_add_top}`}>
                  <div>
                    <div className={`${admin.admin_shop_product_add_img_box} border-bottom`}>
                      <div>
                        <div><span>대표이미지</span></div>
                        <div><button onClick={handleMainImageUploadClick}>대표이미지 추가</button></div>
                      </div>
                      <input
                        type="file"
                        ref={mainImageInputRef}
                        onChange={handleMainImageChange}
                        style={{ display: 'none' }}
                      />
                      <div onDragOver={handleDragOver} onDrop={handleDropMainImage}>
                        {
                          mainImage ? 
                          <div className={`${admin.admin_shop_product_add_main_select_img}`}>
                            <div>
                              <img src={mainImage} alt="" />
                              <button onClick={() => handleMainImageDelete()}>
                                <span className="material-symbols-outlined">close</span>
                              </button>
                            </div>
                          </div>
                          :
                          <div className={`${admin.admin_shop_product_add_default_img}`}>
                            <div><img src="../../../img/admin/defaultImage.png" alt="이미지" /></div>
                            <div><span>이미지를 여기로 드래그</span></div>
                            <div><span>500px x 500px / JPG, PNG권장 (5MB 미만)</span></div>
                            <div><span>이미지 최대 1개</span></div>
                          </div>
                        }
                      </div>
                    </div>
                    <div className={`${admin.admin_shop_product_add_img_box}`}>
                      <div>
                        <div><span>리스트이미지</span></div>
                        <div><button onClick={handleListImagesUploadClick}>리스트이미지 추가</button></div>
                      </div>
                      <input
                        type="file"
                        ref={listImagesInputRef}
                        onChange={handleListImagesChange}
                        multiple
                        style={{ display: 'none' }}
                      />
                      <div onDragOver={handleDragOver} onDrop={handleDropListImages}>
                        {
                          listImages.length > 0 ? 
                          <div className={`${admin.admin_shop_product_add_select_img}`}>
                            <Swiper
                              // slidesPerView={windowWidth < 1500 ? 3 : 3.5}
                              // spaceBetween={windowWidth < 1500 ? 30 : 0}
                              slidesPerView={"auto"}
                              spaceBetween={10}
                              className={`${admin.admin_shop_product_add_swiper}`}
                            >
                            {
                              listImages.map((image, index) => (
                                <SwiperSlide key={index}>
                                <div className={`${admin.admin_shop_product_add_swiper_item}`}>
                                  <img key={index} src={image} alt={`리스트 이미지 ${index + 1}`} />
                                  <button onClick={() => handleListImageDelete(index)}>
                                    <span className="material-symbols-outlined">close</span>
                                  </button>
                                </div>
                              </SwiperSlide>
                              ))
                            }
                            </Swiper>
                          </div>
                          :
                          <div className={`${admin.admin_shop_product_add_default_img}`}>
                            <div><img src="../../../img/admin/defaultImage.png" alt="이미지" /></div>
                            <div><span>이미지를 여기로 드래그</span></div>
                            <div><span>500px x 500px / JPG, PNG권장 (5MB 미만)</span></div>
                            <div><span>이미지 최대 4개</span></div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='px-3 py-3'>
                      {/* 상품명 */}
                      <div>
                        <div><span>상품명 입력</span></div>
                        <div className={`${admin.admin_shop_product_add_product_name}`}>
                          <div><span>상품명</span></div>
                          <div><input type="text" onChange={(e) => onProductName(e)} value={productName && productName || ''}/></div>
                        </div>
                      </div>
                      {/* 카테고리 */}
                      <div className='pt-4'>
                        <div>
                          <div><span>카테고리 설정</span></div>
                        </div>
                        <div className={`${admin.admin_shop_product_add_select_box}`}>
                          <div><span>대분류</span></div>
                          <div>
                            <Select 
                              className={`${admin.admin_shop_product_add_select}`}
                              onChange={onMainCategory}
                              defaultValue={mainCategory}
                            >
                              <Option value="store">스토어</Option>
                            </Select>
                          </div>
                        </div>
                        <div className={`${admin.admin_shop_product_add_select_box}`}>
                          <div><span>중분류</span></div>
                          <div>
                            <Select 
                              className={`${admin.admin_shop_product_add_select}`}
                              onChange={onMiddleCategory}
                              value={middleCategorySelect}
                            >
                              {
                                middleCategory.map((item, index) => (
                                  <Option value={item.dailyduckStorecategoryEngName} key={index}>{item.dailyduckStorecategoryName}</Option>
                                ))
                              }
                            </Select>
                          </div>
                        </div>
                        <div className={`${admin.admin_shop_product_add_select_box}`}>
                          <div><span>소분류</span></div>
                          <div>
                            <Select className={`${admin.admin_shop_product_add_select}`}
                              onChange={onSubCategory}
                              value={subCategorySelect || ''}
                              disabled={selectSubCategory.length === 0}
                            >
                              {
                                selectSubCategory && selectSubCategory.map((item, index) => (
                                  <Option value={item.storeSubCategoryEngName}>{item.storeSubCategoryKorName}</Option>
                                ))
                              }
                            </Select>
                          </div>
                        </div>
                      </div>
                      {
                        roles.isAdmin &&
                        <div className='pt-4'>
                          <div><span>할인 설정</span></div>
                          <div className={`${admin.admin_shop_product_add_discount_box}`}>
                            <div><span>적용 가능 할인</span></div>
                            <div>
                              <div>
                                <input type="checkbox" name='' id='coupon' onChange={(e) => onCouponCheck(e)}/>
                                <label className='ps-1' htmlFor="coupon">쿠폰</label>
                              </div>
                              <div className='ps-4'>
                                <input type="checkbox" name='' id='reserves' onChange={(e) => onReservesCheck(e)}/>
                                <label className='ps-1' htmlFor="reserves">적립금</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className={`${admin.admin_shop_product_add_weight}`}>
                  <div className='px-3 py-3'>
                    <div>
                      <span>상품무게</span>
                      <button className={`${admin.admin_shop_product_add_weight_info_box}`} onClick={() => setShowWeightInfo(!showWeightInfo)}>
                        <span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>help</span>
                        {
                          showWeightInfo &&
                          <div className={`${admin.admin_shop_product_add_weight_info}`}>
                            <div><span>해외 배송을 위한 상품 무게 정보입니다.</span></div>
                            <div><span>배송템플릿에 무게(kg)당 금액을 설정하셨다면 입력하신 무게를 기준으로 해외 배송비가 책정됩니다.</span></div>
                            <br />
                            <div>
                              <div>
                                <div><span>상품무게 : 1.2kg</span></div>
                                <div><span>상품금액 : 5,000원</span></div>
                              </div>
                              <div>
                                <div><span>배송템플릿 무게 설정 : </span></div>
                                <div><span>1kg ~ - 10,000원</span></div>
                                <div><span>2kg ~ - 20,000원</span></div>
                              </div>
                            </div>
                            <br />
                            <div><span>1.2kg * 1개 = 1.2kg</span><span> =&gt; 1kg 이상 금액 10000원 적용 =&gt; 결제금액 : 15,000원</span></div>
                            <div><span>1.2kg * 2개 = 2.4kg</span><span> =&gt; 2kg 이상 금액 20000원 적용 =&gt; 결제금액 : 25,000원</span></div>

                          </div>
                        }
                      </button>
                    </div>
                    <div>
                      <div><span>무게</span></div>
                      <div>
                        <div><input type="number" value={productWeight ? productWeight : 0} onChange={(e) => onProductWeight(e)}/></div>
                        <div className={`${productWeight !== '' ? admin.adimn_shop_kg_active : ''}`}><span>kg</span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${admin.admin_shop_product_add_middle} ${!roles.isAdmin && admin.admin_seller_auth}`}>
                  <div className='px-3 py-3'>
                    <div className='d-flex align-items-center'>
                      <div><span>가격정보</span></div>
                      <div>
                        <button className={`${admin.admin_btn_style}`}>
                          <span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>help</span>
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className={`${admin.admin_shop_product_add_price_setting_box}`}>
                        <div><span>정가</span></div>
                        <div>
                          <input 
                            type="number" 
                            value={regularPrice || 0} 
                            onChange={(e) => onProductPrice(e)} 
                          />
                        </div>
                      </div>
                      <div className={`${admin.admin_shop_product_add_price_setting_box}`}>
                        <div><span>판매가</span></div>
                        <div>
                          <input 
                            type="number" 
                            value={salePrice || 0} 
                            onChange={(e) => onSalePrice(e)} 
                          />
                        </div>
                      </div>
                      <div className={`${admin.admin_shop_product_add_price_setting_box}`}>
                        <div><span>할인율</span></div>
                        <div className={`${admin.admin_shop_product_add_discount_rate_box}`}>
                          <div>
                            <input 
                              type="number" 
                              value={discountRate || 0} 
                              readOnly 
                            />
                          </div>
                          <div>
                            <span>%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    roles.isAdmin &&
                    <div className='px-3 py-3'>
                      <div className='d-flex align-items-center'>
                        <div><span>연관상품</span></div>
                        <div><button className={`${admin.admin_btn_style}`}><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>help</span></button></div>
                      </div>
                      <div className={`${admin.admin_shop_product_add_related_radio} ${relatedState === 'related_true' ? 'border-bottom' : ''}`}>
                        <div>
                          <input type="radio" name='related' id='related_true' value={true} onChange={(e) => onRelated(e)}/>
                          <label className='ps-1' htmlFor="related_true">사용함</label>
                        </div>
                        <div className='ps-4'>
                          <input type="radio" name='related' id='related_false' value={false} onChange={(e) => onRelated(e)}/>
                          <label className='ps-1' htmlFor="related_false">사용안함</label>
                        </div>
                      </div>
                      {
                        relatedState === 'related_true' &&
                        <div className='py-1'>
                          <div className={`${admin.admin_shop_product_add_related_true_btn}`}>
                            <div><button>상품 추가</button></div>
                            <div className='ps-2'><button>선택 상품 삭제</button></div>
                          </div>
                          <div className='px-3 pt-4 d-flex align-items-center justify-content-center'>
                            <div><span className={`${admin.admin_font_date_font}`}>등록한 연관 상품이 없습니다.</span></div>
                          </div>
                        </div>
                      }
                      {
                        roles.isStore || roles.isAdmin &&
                        <div className={`${admin.admin_shop_product_add_favority_select_box}`}>
                          <div>
                            <div><span>최애선택</span></div>
                            <div><button className={`${admin.admin_btn_style}`}><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>help</span></button></div>
                          </div>
                          <div>
                            <div><span>선택된 최애</span></div>
                            <div><textarea name="" id="" value={selectedArtistNames.length === 0 ? productInfo.favority : selectedArtistNames || ''} readOnly></textarea></div>
                          </div>
                          <div><button onClick={() => setShowFavoritySelect(true)}>선택</button></div>
                        </div>
                      }
                    </div>
                  }
                </div>
                <div className={`${admin.admin_shop_product_add_product_option_box} px-3 py-3`}>
                  <div><span>상품 옵션</span></div>
                  <div className={`py-3 ${optionState === 'option_true' ? 'border-bottom' : ''}`}>
                  <div>
                    <input type="radio" name="option" id="option_true" onChange={onOptionSelect} checked={optionState === 'option_true'} />
                    <label className='ps-1' htmlFor="option_true">옵션 있음</label>
                  </div>
                  <div className='ps-4'>
                    <input type="radio" name="option" id="option_false" onChange={onOptionSelect} checked={optionState === 'option_false'} />
                    <label className='ps-1' htmlFor="option_false">옵션 없음</label>
                  </div>
                  </div>
                  {
                    optionState === 'option_true' &&
                    <div className={`${admin.admin_shop_product_add_option_add} py-3`}>
                      <div><button onClick={addOption}>옵션 추가</button></div>
                      {options.length > 0 && <div className='ps-2'><button onClick={resetOptions}>옵션 초기화</button></div>}
                      <div className={`${admin.admin_shop_product_add_option_add_combination}`}>
                        <input type="checkbox" name="combination" id="combination" checked={combinationOptionState} onChange={(e) => onCombinationCheck(e)}/>
                        <label htmlFor="combination">조합형 옵션</label>
                      </div>
                    </div>
                  }
                  {
                    optionState === 'option_true' &&
                    options.map((option, optionIndex) => (
                      <div className={`${admin.admin_shop_product_add_option_info}`} key={optionIndex}>
                        <div>
                          <div><span>옵션명</span></div>
                          <div><input type="text" value={option.name || ''} onChange={(e) => handleOptionChange(optionIndex, null, 'name', e.target.value)} /></div>
                        </div>
                        <div className={`${admin.admin_shop_product_add_option_value}`}>
                          <div><span>옵션값</span></div>
                          <div>
                            <input
                              type="text"
                              value={(option.values ?? []).map(v => v.value).join(',')}
                              onChange={(e) => {
                                const values = e.target.value.split(',').map((value, idx) => {
                                  return option.values && option.values[idx]
                                    ? { ...option.values[idx], value }
                                    : { value, price: '', stock: '', status: 'available' };
                                });
                                const newOptions = [...options];
                                newOptions[optionIndex].values = values;
                                setOptions(newOptions);
                                updateProductInfo(newOptions);

                                const newCombinations = generateOptionCombinations(newOptions);
                                setCombinationOptions(newCombinations);
                              }}
                            />
                          </div>
                          <div><span>*쉼표(,)를 통해 옵션값을 구분하여 입력하세요.</span></div>
                        </div>
                        <div>
                          <input type="checkbox" checked={option.required} onChange={(e) => handleOptionChange(optionIndex, null, 'required', e.target.checked)} />
                          <label className='ps-1' htmlFor="">필수 옵션</label>
                        </div>
                        <div>
                          <button onClick={() => handleRemoveOption(optionIndex)}><span className="material-symbols-outlined">remove</span></button>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div className={`${admin.admin_shop_product_add_inventory_box} py-3 px-3`}>
                  <div><span>재고 설정</span></div>
                  <div className={`${admin.admin_shop_product_add_inventory_radio}`}>
                    <div>
                      <input
                        type="radio"
                        name="inventory"
                        id="inventory_true"
                        value="true"
                        onChange={handleInventoryChange}
                        checked={inventoryEnabled}
                        disabled={optionState === 'option_true'}
                      />
                      <label className='ps-1' htmlFor="inventory_true">사용</label>
                    </div>
                    <div className='ps-4'>
                      <input
                        type="radio"
                        name="inventory"
                        id="inventory_false"
                        value="false"
                        onChange={handleInventoryChange}
                        checked={!inventoryEnabled}
                        disabled={optionState === 'option_true'} // Disable when option is set
                      />
                      <label className='ps-1' htmlFor="inventory_false">사용 안함</label>
                    </div>
                  </div>
                  {
                  inventoryEnabled && productInfo.combinationState === false ? options.map((option, optionIndex) => (
                    <div key={optionIndex}>
                        {option.values.map((value, valueIndex) => (
                            <div key={valueIndex} className={`${admin.admin_shop_product_add_inventory_option_value}`}>
                                <div>
                                    <div><span>옵션값</span></div>
                                    <div><input type="text" value={value.value} disabled={true} /></div>
                                </div>
                                <div>
                                    <div><span>옵션가격</span></div>
                                    <div className="position-relative">
                                        <div><input type="text" value={value.price ? value.price : 0} onChange={(e) => handleOptionChange(optionIndex, valueIndex, 'price', e.target.value)} placeholder="옵션가격" /></div>
                                        <div className={`${admin.admin_shop_product_add_inventory_option_price_txt}`}><span>원</span></div>
                                    </div>
                                </div>
                                <div>
                                    <div><span>적용가격</span></div>
                                    <div>
                                        <div><span>{salePrice ? parseInt(salePrice).toLocaleString() : parseInt(productInfo.productPrice).toLocaleString()}원</span></div>
                                        <div><span>(+{value.price ? value.price.toLocaleString() : ''}원)</span></div>
                                    </div>
                                </div>
                                <div className={`${admin.admin_shop_product_add_inventory_option_state}`}>
                                    <div>
                                        <div><span>재고수량</span></div>
                                        <div><input type="number" value={value.stock ? value.stock : 0} onChange={(e) => handleOptionChange(optionIndex, valueIndex, 'stock', e.target.value)} placeholder="재고수량" /></div>
                                    </div>
                                    <div>
                                        <div><span>판매상태</span></div>
                                        <div>
                                            <Select className={`${admin.admin_shop_product_add_inventory_option_state_select}`} value={value.status} onChange={(status) => handleOptionChange(optionIndex, valueIndex, 'status', status)}>
                                                <Option value="available">판매중</Option>
                                                <Option value="unavailable">판매중지</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                {/* 삭제 버튼 추가 */}
                                <div>
                                    <button onClick={() => handleDeleteOptionValue(optionIndex, valueIndex)}><span class="material-symbols-outlined">remove</span></button>
                                </div>
                            </div>
                        ))}
                    </div>
                  )) :
                  <div className={`${admin.admin_combination_option_box}`}>
                    {/* 데이터가 존재할 경우에만 렌더링 */}
                    {combinationOptions && combinationOptions.length > 0 && (
                      <>
                        {/* 옵션 이름 출력 (상단에 한 번만) */}
                        <div className={`${admin.admin_combination_option_name_box_header}`}>
                          <div>
                          {
                            combinationOptions &&
                            combinationOptions.length > 0 &&
                            combinationOptions[0]?.options?.length > 0 &&
                            combinationOptions[0].options.map((opt, index) => (
                              <div key={index}><span>{opt.optionName}</span></div>
                            ))
                          }
                          </div>
                          <div>
                            <div><span>옵션 금액</span></div>
                            <div><span>재고 수량</span></div>
                            <div><span>판매 상태</span></div>
                          </div>
                        </div>

                        {/* 각 옵션 값 출력 */}
                        {
                          combinationOptions.map((combination, combinationIndex) => (
                            <div key={combinationIndex} className={`${admin.admin_combination_option_value_box}`}>
                              <div className={`${admin.admin_combination_option_value_box_content}`}>
                                <div>
                                  {(combination.options || []).map((opt, index) => (
                                    <div key={index}><span>{opt.optionValue}</span></div>
                                  ))}
                                </div>
                                <div>
                                  <div>
                                    <input
                                      type="text"
                                      value={combination.price || 0}
                                      onChange={(e) => handleCombinationOptionChange(combinationIndex, 'price', e.target.value)}
                                      placeholder="옵션 가격"
                                    />
                                  </div>
                                  <div>
                                    <input
                                      type="number"
                                      value={combination.stock || 0}
                                      onChange={(e) => handleCombinationOptionChange(combinationIndex, 'stock', e.target.value)}
                                      placeholder="재고 수량"
                                    />
                                  </div>
                                  <div>
                                    <Select
                                      value={combination.status}
                                      onChange={(status) => handleCombinationOptionChange(combinationIndex, 'status', status)}
                                      className={`${admin.admin_combination_option_select_box}`}
                                    >
                                      <Option value="available">판매중</Option>
                                      <Option value="unavailable">판매중지</Option>
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        }

                      </>
                    )}
                  </div>

                }
                  {
                    optionState === 'option_false' && inventoryEnabled &&
                    <div className={`${admin.admin_shop_product_add_inventory_single_count}`}>
                      <div><span>재고 수량</span></div>
                      <div><input type="number" value={productInfo.inventoryCnt || ''} onChange={(e) => setProductInfo({...productInfo, inventoryCnt: e.target.value})} /></div>
                    </div>
                  }
                </div>
                <div className={`${admin.admin_shop_product_add_product_detail} px-3 py-3`}>
                  <div className='pb-3'><span>제품 상세</span></div>
                  <div>
                    <div>
                    <TextEditor setData={setData} admin={admin} fileName={fileName} setFileName={setFileName} onDetailContent={onDetailContent} update={update} data={data}/>
                    </div>
                  </div>
                </div>
                <div className={`${admin.admin_shop_product_add_delivery_form_box}`}>
                  <div className='px-3 py-2'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div><span>배송 템플릿 선택</span></div>
                      <div className={`py-2 ${admin.admin_shop_product_add_delivery_templete_add}`}>
                        <button onClick={onDeliveryAdd}>배송템플릿 추가</button>
                      </div>
                    </div>
                    <div className={`py-2 ${admin.admin_shop_product_add_delivery_box}`}>
                      <div><span>배송 템플릿</span></div>
                      <div>
                        <Select
                          className={`${admin.admin_shop_product_add_delivery_select}`}
                          onChange={handleTemplateChange}
                          options={Array.isArray(template) ? template.map((item) => ({
                            value: item.dtNo,
                            label: `[${item.userId}] - ${item.dtName}`,
                          })) : []} // template이 배열이 아니면 빈 배열로 처리
                          value={selectedTemplate ? { value: selectedTemplate.dtNo, label: `[${selectedTemplate.userId}] - ${selectedTemplate.dtName}` } : null}
                        />
                      </div>
                    </div>
                    {selectedTemplate && (
                      <div className={`${admin.admin_shop_product_add_delevery_templete_info}`}>
                        <div>
                          <div><span>배송 및 결제방법</span></div>
                          <div><span>{selectedTemplate.dtDeliveryPm}</span></div>
                        </div>
                        <div>
                          <div><span>기본택배사</span></div>
                          <div><span>{selectedTemplate.dtDeliveryCompany.toLocaleString()}</span></div>
                        </div>
                        <div>
                          <div><span>기본배송비</span></div>
                          <div><span>{selectedTemplate.dtDeliveryPrice.toLocaleString()}원</span></div>
                        </div>
                        <div>
                          <div><span>무료배송 조건</span></div>
                          <div><span>{selectedTemplate.dtDeliveryFree.toLocaleString()}원</span></div>
                        </div>
                        <div>
                          <div><span>지역별 배송비</span></div>
                          <div>
                            <div><span>제주도 추가 배송비</span><span> | </span><span>{selectedTemplate.dtJejuAddPrice.toLocaleString()}원</span></div>
                            <div><span>도서산간 추가 배송비</span><span> | </span><span>{selectedTemplate.dtOtherAddPrice.toLocaleString()}원</span></div>
                          </div>
                        </div>
                        <div>
                          <div><span>해외 배송비</span></div>
                          <div>
                                {
                                  selectedTemplate.overseasShippingState &&
                                    selectedTemplate.deliveryOverseasAdds.map((ovs, idx) => {
                                      const shippingCost = ovs.overseasShippingCost ? ovs.overseasShippingCost.split(";") : [];
                                      const shippingKg = ovs.overseasShippingKg ? ovs.overseasShippingKg.split(';') : [];

                                      
                                      const numberic = (shippingCost) => {
                                          let cost = parseInt(shippingCost);
                                          return cost.toLocaleString()
                                      }

                                      return(
                                          <div key={idx} className={`${admin.admin_delivery_setting_oveseas_list}`}>
                                              <div className={`${admin.admin_delivery_setting_oveseas_header}`}>
                                                  <div><span>{ovs.overseasDeliveryName}</span></div>
                                              </div>
                                              <div className={`${admin.admin_delivery_setting_oveseas_item}`}>
                                                  <div><span>해외 배송 국가</span></div>
                                                  <div><span>{ovs.overseasDeliveryCountry}</span></div>
                                              </div>
                                              <div className={`${admin.admin_delivery_setting_oveseas_item}`}>
                                                  <div><span>해외 배송 반품 배송비(편도)</span></div>
                                                  <div><span>{ovs.overseasReturnCost ? ovs.overseasReturnCost.toLocaleString() : 0}원</span></div>
                                              </div>
                                              <div className={`${admin.admin_delivery_setting_oveseas_item}`}>
                                                  <div><span>해외 배송 교환 배송비(왕복)</span></div>
                                                  <div><span>{ovs.overseasExchangeCost ? ovs.overseasExchangeCost.toLocaleString() : 0}원</span></div>
                                              </div>
                                              <div className={`${admin.admin_delivery_setting_oveseas_weight_item}`}>
                                                  <div className={`${admin.admin_delivery_setting_oveseas_weight_item_text}`}><span>무게별 배송 비용</span></div>
                                                  <div className={`${admin.admin_delivery_setting_oveseas_weight_item_content}`}>
                                                      <div className={`${admin.admin_delivery_setting_oveseas_weight_item_content_header}`}>
                                                          <div><span>kg</span></div>
                                                          <div><span>금액</span></div>
                                                      </div>
                                                      {
                                                          shippingKg.map((kg, idx) =>{
                                                              return (
                                                                  <div className={`${admin.admin_delivery_setting_oveseas_weight_item_content_box}`}>
                                                                      <div><span>{kg}kg 이상</span></div>
                                                                      <div><span>{numberic(shippingCost[idx]).toLocaleString()}원</span></div>
                                                                  </div>
                                                              )
                                                          })
                                                      }
                                                  </div>
                                              </div>
                                          </div>
                                      )   
                                  })
                                }
                                {/* {renderOverseasShipping(selectedTemplate.overseasShippingKg, selectedTemplate.overseasShippingCost, selectedTemplate.overseasShippingState)} */}
                            </div>
                        </div>
                        <div>
                          <div><span>반품 배송비</span></div>
                          <div><span>{selectedTemplate.dtReturnDeliveryPrice.toLocaleString()}원</span></div>
                        </div>
                        <div>
                          <div><span>교환 배송비</span></div>
                          <div><span>{selectedTemplate.dtExchangeDeliveryPrice.toLocaleString()}원</span></div>
                        </div>
                        <div>
                          <div><span>출고지</span></div>
                          <div>
                            <div><span>{selectedTemplate.dtShippingAddress}</span></div>
                            <div><span>{selectedTemplate.dtShippingAddressDetail}</span></div>
                            <div><span>{selectedTemplate.dtShippingAddressZipcode}</span></div>
                          </div>
                        </div>
                        <div>
                          <div><span>반품/교환지</span></div>
                          <div>
                            <div><span>{selectedTemplate.dtReturnLocation}</span></div>
                            <div><span>{selectedTemplate.dtReturnLocationDetail}</span></div>
                            <div><span>{selectedTemplate.dtReturnLocationZipcode}</span></div>
                          </div>
                        </div>
                        <div>
                          <div><span>대표연락처</span></div>
                          <div><span>{selectedTemplate.dtCompanyTel}</span></div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='px-3 py-3'>
                    <div><span>입력폼 추가(선택)</span></div>
                    <div>
                      <div className={`${admin.admin_shop_product_add_form_box}`}>
                        <div className='d-flex align-items-center'>
                          <div><span>사진 파일 입력폼</span></div>
                          <div><button className={`${admin.admin_btn_style}`}><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>help</span></button></div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <div><input type="checkbox" name="photoFileForm" checked={productInfo.photoFileForm} onChange={handleCheckboxChange} /></div>
                          <div><label className='ps-1' htmlFor="">유저에게 사진·파일 등록 기능 제공</label></div>
                        </div>
                      </div>
                      <div className={`${admin.admin_shop_product_add_form_box}`}>
                        <div className='d-flex align-items-center'>
                          <div><span>날짜 선택 입력폼</span></div>
                          <div><button className={`${admin.admin_btn_style}`}><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>help</span></button></div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <div><input type="checkbox" name="dateFileForm" checked={productInfo.dateFileForm} onChange={handleCheckboxChange} /></div>
                          <div><label className='ps-1' htmlFor="">유저에게 날짜 선택 등록 기능 제공</label></div>
                        </div>
                      </div>
                      <div className={`${admin.admin_shop_product_add_form_box}`}>
                        <div className='d-flex align-items-center'>
                          <div><span>내용 입력 입력폼</span></div>
                          <div><button className={`${admin.admin_btn_style}`}><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>help</span></button></div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <div><button className={`${admin.admin_shop_product_add_form_content_btn}`} onClick={handleAddContentForm}>내용 직접 입력 입력폼 추가</button></div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {contentQuestions && contentQuestions.length != 0 && <div className='py-3'><span className={`${admin.admin_font_date_font}`}>질문 내용을 작성해주세요.</span></div>}
                      {contentQuestions && contentQuestions.map((question, index) => (
                        <div key={index} className={`${admin.admin_shop_product_add_content_input}`}>
                          <input
                            type="text"
                            value={question || ''}
                            onChange={(e) => handleContentChange(index, e.target.value)}
                            placeholder="내용을 입력하세요"
                          />
                          <button onClick={() => handleRemoveContentForm(index)} className={`${admin.admin_shop_product_add_content_input_delete}`}>삭제</button>
                      </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={`${admin.admin_shop_product_add_faq_box} px-3 py-3`}>
                  <div><span>FAQ (선택)</span></div>
                  <div>
                      <div><span>자주쓰는 FAQ</span></div>
                      <div><button onClick={() => setShowFaqModal(true)}>FAQ 선택</button></div>
                  </div>
                  <div className={`${admin.admin_shop_product_add_faq_add_btn}`}>
                      <div><button onClick={() => sellerFaqAdd()}>FAQ 등록</button></div>
                  </div>
                  {
                    selectedFaqs && selectedFaqs.length > 0 &&
                    <div className={`${seller.seller_edit_faq_select_item_box}`}>
                        <div className={`${seller.seller_edit_faq_select_item_title}`}><span>선택된 FAQ</span></div>
                        <div className={`${seller.seller_edit_faq_select_item_content}`}>
                            {
                                selectedFaqs.map((item, index) => {
                                    return (
                                        <div className={`${seller.seller_edit_faq_select_item_content_item}`}>
                                            <div className={`${seller.seller_edit_faq_select_item_content_item_question}`}>
                                                <div><span>질문</span></div>
                                                <div><span>{item.question}</span></div>
                                            </div>
                                            <div className={`${seller.seller_edit_faq_select_item_content_item_answer}`}>
                                                <div><span>답변</span></div>
                                                <div><span>{item.answer}</span></div>
                                            </div>
                                            <div className={`${seller.seller_edit_faq_select_item_content_item_delete}`}><button onClick={() => handleRemoveFaq(item)}><span className="material-symbols-outlined">remove</span></button></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                </div>
                <div className={`${admin.admin_shop_product_add_return_box} px-3 py-3`}>
                  <div><span>교환/반품 규정</span></div>
                  <div>
                    <TextEditor2 setData2={setData2} onExchangeReturn={onExchangeReturn} data2={data2} />
                  </div>
                </div>
            </div>
        </div>
        <div className={`${admin.admin_shop_product_add_btn_box}`}>
          <div><button className={`${confirm() ? admin.admin_product_add_btn_active : ''}`} onClick={() => onProductAdd()} disabled={!confirm()}>상품 수정</button></div>
        </div>
        {
          showDelivery &&
          <div className={`${admin.admin_shop_product_add_delevery_add}`}>
            <div>
              <div className={`${admin.admin_shop_product_add_delivery_add_close}`}><button onClick={() => setShowDelivery(false)}><span className="material-symbols-outlined">close</span></button></div>
              <AdminDeliveryAddPopup 
                showDelivery={showDelivery} setShowDelivery={setShowDelivery} userInfo={userInfo} onSave={onSave}
                deliveryData={deliveryData} setDeliveryData={setDeliveryData}
              />
            </div>
          </div>
        }
        
        {
          showFavoritySelect &&
          <div className={`${admin.admin_product_add_favority_modal_container}`}>
            <div className={`${admin.admin_product_add_favority_modal_box}`}>
              <div className={`${admin.admin_product_add_favority_modal_header}`}>
                <div><span>최애선택</span></div>
                <div><button onClick={() => setShowFavoritySelect(false)}><span className="material-symbols-outlined">close</span></button></div>
              </div>
              <div className={`${admin.admin_product_add_favority_modal_content}`}>
                <div className={admin.admin_event_participants_search}>
                    <div><span>최애 검색</span></div>
                    <div><input type="text" onChange={onSearchValue} /></div>
                </div>
                <div className={`${admin.admin_product_add_favority_modal_content_info}`}>
                    <div>
                        <div><span>최애 리스트</span></div>
                        <div><span>선택 리스트</span></div>
                    </div>
                    <div>
                        <div className={`${admin.admin_product_add_modal_content_total_fav_list}`}>
                            {
                                filteredFavorityList.map((fav, index) => (
                                    <div key={index}>
                                        <div><input 
                                            type="checkbox" 
                                            checked={selectedFavoritis.some(selectedfavority => selectedfavority.artistNo === fav.artistNo)} 
                                            onChange={() => handleFavorityCheckboxChange(fav)} 
                                        /></div>
                                        <div className='ps-1'>
                                          <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${fav.artistImg}`} alt="" /></div>
                                          <div>
                                            <div><span>{fav.artistGroup !== null ? fav.artistGroup : '이름없음'}</span></div>
                                            <div><span>{fav.artistName}</span></div>
                                            <div><span>{fav.fandom}</span></div>
                                          </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={`${admin.admin_product_add_modal_content_select_fav_list}`}>
                            {
                                selectedFavoritis.map((fav, index) => (
                                    <div key={index}>
                                        <div><input type="checkbox" checked={true} onChange={() => handleFavorityCheckboxChange(fav)} /></div>
                                        <div className='ps-1'>
                                          <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${fav.artistImg}`} alt="" /></div>
                                          <div>
                                            <div><span>{fav.artistGroup !== null ? fav.artistGroup : '이름없음'}</span></div>
                                            <div><span>{fav.artistName}</span></div>
                                            <div><span>{fav.fandom}</span></div>
                                          </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
              </div>
              <div className={`${admin.admin_product_add_favority_modal_footer}`}>
                <div><button onClick={() => {setShowFavoritySelect(false); setSelectedFavoritis([]);}}>취소</button></div>
                <div><button onClick={handleFavoritySelect}>선택</button></div>
              </div>
            </div>
          </div>
        }

        {
          showBadgeModal && (
            <div className={`${admin.admin_badge_add_modal_container}`}>
              <div className={`${admin.admin_badge_add_modal_box}`}>
                <div className={`${admin.admin_badge_add_modal_header}`}>
                  <div><span>상품뱃지 추가</span></div>
                  <div>
                    <button onClick={onBadgeCancel}>
                      <span className="material-symbols-outlined">close</span>
                    </button>
                  </div>
                </div>
                <div className={`${admin.admin_badge_add_modal_content}`}>
                  <div className={`${admin.admin_badge_add_modal_content_info}`}>
                    <div>
                      <div>
                        <div><span>글자색</span></div>
                        <div>
                          {textColors.map((color, index) => (
                            <div key={index}>
                              <input 
                                type="color" 
                                value={color || ''} 
                                onChange={(e) => updateTextColor(index, e.target.value)} 
                              />
                            </div>
                          ))}
                          {/* <button onClick={addTextColor}>글자색 추가</button> */}
                        </div>
                      </div>
                      <div>
                        <div><span>배경색</span></div>
                        <div>
                          {backgroundColors.map((color, index) => (
                            <div key={index}>
                              <input 
                                type="color" 
                                value={color || ''} 
                                onChange={(e) => updateBackgroundColor(index, e.target.value)} 
                              />
                            </div>
                          ))}
                          {/* <button onClick={addBackgroundColor}>배경색 추가</button> */}
                        </div>
                      </div>
                      <div>
                        <div><span>영문</span></div>
                        <div><input type="text" value={englishText || ''} onChange={(e) => setEnglishText(e.target.value)} /></div>
                      </div>
                      <div>
                        <div><span>한글</span></div>
                        <div><input type="text" value={koreanText || ''} onChange={(e) => setKoreanText(e.target.value)} /></div>
                      </div>
                    </div>
      
                    {/* 미리보기 */}
                    <div>
                      <div>
                        <span 
                          style={{ 
                            color: textColors[0], // Apply the first text color for preview
                            backgroundColor: backgroundColors[0], // Apply the first background color for preview
                            padding: '5px 10px',
                            borderRadius: '4px',
                            display: 'inline-block',
                            fontWeight: 'bold',
                            fontSize: '0.9em'
                          }}
                        >
                          {koreanText || '미리보기'} {/* Default text if empty */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='pt-2'>등록된 뱃지</div>
                  <div className={`${admin.admin_badge_add_modal_content_list}`}>
                    <div className={`${admin.admin_badge_add_modal_content_list_header}`}>
                      <div><span>영문</span></div>
                      <div><span>한글</span></div>
                      <div><span>배경색상</span></div>
                      <div><span>글자색상</span></div>
                      <div><span>적용</span></div>
                    </div>
                    {
                      Array.isArray(badgeList) && badgeList.map((item, index) => {
                        return (
                          <div className={`${admin.admin_badge_add_modal_content_list_content}`} key={index}>
                            <div><span>{item.engBadgeName}</span></div>
                            <div><span>{item.korBadgeName}</span></div>
                            <div><span>{item.badgeBackColor}</span></div>
                            <div><span>{item.badgeFontColor}</span></div>
                            <div>
                              <span 
                                style={{ 
                                  color: item.badgeFontColor, // Apply the first text color for preview
                                  backgroundColor: item.badgeBackColor, // Apply the first background color for preview
                                  padding: '5px 10px',
                                  borderRadius: '4px',
                                  display: 'inline-block',
                                  fontWeight: 'bold',
                                  fontSize: '0.8em'
                                }}
                              >
                                {item.korBadgeName}
                              </span>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className={`${admin.admin_badge_add_modal_footer}`}>
                  <div><button onClick={onBadgeCancel}>취소</button></div>
                  <div><button onClick={onBadgeAdd}>등록</button></div>
                </div>
              </div>
            </div>
          )
        }
        {
          showFaqAdd && 
          <div className={`${seller.seller_edit_produt_faq_add_modal_container}`}>
              <div className={`${seller.seller_edit_produt_faq_add_modal_box}`}>
                  <div className={`${seller.seller_edit_produt_faq_add_modal_header}`}>
                      <div><button onClick={() => {setShowFaqAdd(false); faqListSelect();}}><span className="material-symbols-outlined">close</span></button></div>
                  </div>
                  <div className={`${seller.seller_edit_produt_faq_add_modal_content}`}>
                      <SellerSalesMain />
                  </div>
              </div>
          </div>
        }
        {
            showFaqModal &&
            <div className={`${seller.seller_edit_produt_faq_select_modal_container}`}>
                <div className={`${seller.seller_edit_produt_faq_select_modal_box}`}>
                    <div className={`${seller.seller_edit_produt_faq_select_modal_header}`}>
                        <div><span>FAQ 선택</span></div>
                        <div><button onClick={() => {setShowFaqModal(false); setSelectedFaqs([]);}}><span className="material-symbols-outlined">close</span></button></div>
                    </div>
                    <div className={`${seller.seller_edit_produt_faq_select_modal_content}`}>
                        {
                            Array.isArray(faqList) && faqList.map((item, index) => {

                              const selectedFaqNos = Array.isArray(selectedFaqs) ? selectedFaqs.map(faq => faq.sellerFaqNo) : [];

                              return (
                                <div key={index} className={`${seller.seller_edit_produt_faq_select_modal_content_item}`}>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={selectedFaqNos.includes(item.sellerFaqNo)}
                                            onChange={() => handleFaqCheckboxChange(item)}
                                        />
                                    </div>
                                    <div>
                                        <div className={`${seller.seller_edit_produt_faq_select_modal_content_question}`}>
                                            <div><span>질문</span></div>
                                            <div><span>{item.question}</span></div>
                                        </div>
                                        <div className={`${seller.seller_edit_produt_faq_select_modal_content_answer}`}>
                                            <div><span>답변</span></div>
                                            <div><span>{item.answer}</span></div>
                                        </div>
                                    </div>
                                </div>
                              )
                            })
                        }
                    </div>
                    <div className={`${seller.seller_edit_produt_faq_select_modal_footer}`}>
                        <div><button onClick={() => {setShowFaqModal(false); setSelectedFaqs([]);}}>취소</button></div>
                        <div><button onClick={handleFaqSelect}>선택</button></div>
                    </div>
                </div>
            </div>
        }
    </div>
  )
}

export default AdminProductRead