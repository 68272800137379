import api from "./api";

export const pageSettingSelect = () => api.get('/api/admin/pageSettingSelect');

export const backgroundImgUpload = (imgUrl) => api.post(`/api/admin/backgroundImgUpload`, { imgUrl : imgUrl});
export const sideImgUpload = (imgUrl) => api.post(`/api/admin/sideImgUpload`, { imgUrl : imgUrl});
export const splashImgUpload = (imgUrl) => api.post(`/api/admin/splashImgUpload`, { imgUrl : imgUrl});

export const bannerAdd = (content) => api.post(`/api/admin/bannerAdd`, content);
export const bannerSelect = () => api.get('/api/admin/bannerSelect');
export const bannerUpdate = (editContent) => api.put(`/api/admin/bannerUpdate`, editContent);

export const exposureStateUpdate = (bannerNo) => api.put(`/api/admin/exposureStateUpdate/${bannerNo}`);
export const exposureStateUpdateSuccess = (bannerNo) => api.put(`/api/admin/exposureStateUpdateSuccess/${bannerNo}`);

export const exposureOrderUpdate = (value, bannerNo) => api.put(`/api/admin/exposureOrderUpdate`, {value : value, bannerNo : bannerNo});
export const bannerDelete = (checkedBanners) => api.delete('/api/admin/bannerDelete', {data: { bannerNos : checkedBanners}});

export const popupAdd = (content) => api.post(`/api/admin/popupAdd`, content);
export const popupSelect = () => api.get(`/api/admin/popupSelect`);
export const popupUpdate = (editContent) => api.post(`/api/admin/popupUpdate`, editContent);
export const pushExposureChange = (newItem) => api.post(`/api/admin/pushExposureChange`, newItem);
export const popupDelete = (checkedPopup) => api.delete(`/api/admin/popupDelete`, {data : {popupNos : checkedPopup}});

export const basicTermsAdd = (termsContent) => api.post(`/api/admin/basicTermsAdd`, termsContent);
export const customTermsAdd = (termsContent) => api.post(`/api/admin/customTermsAdd`, termsContent);
export const basicTermsSelect = () => api.get('/api/admin/basicTermsSelect');
export const customTermsSelect = () => api.get('/api/admin/customTermsSelect');

export const basicTermsEdit = (termsNo, termsContent) => api.put(`/api/admin/basicTermsEdit/${termsNo}`, termsContent);
export const customTermsEdit = (customTermsNo, customTermsContent) => api.put(`/api/admin/customTermsEdit/${customTermsNo}`, customTermsContent);
export const basicTermsDelete = (termsToDelete) => api.delete(`/api/admin/basicTermsDelete`,{ data : { termsNos : termsToDelete}});
export const basicSingleTermsDelete = (termsNo) => api.delete(`/api/admin/basicSingleTermsDelete/${termsNo}`);
export const customTermsDelete = (termsToDelete) => api.delete(`/api/admin/customTermsDelete`, { data : { termsNos : termsToDelete}});
export const customSingleTermsDelete = (termsNo) => api.delete(`/api/admin/customSingleTermsDelete/${termsNo}`);

export const inquirySelect= () => api.get(`/api/admin/inquirySelect`);
export const saveInquiryAnswer = (inquiryNo, inquiryAnswer) => api.put(`/api/admin/saveInquiryAnswer/${inquiryNo}`, {inquiryAnswer : inquiryAnswer});

export const favorityExcel = (formData, config) => api.post(`/api/admin/favorityExcel`, formData, config);
export const favoritySelect = () => api.get(`/api/admin/favoritySelect`);
export const editSave = (editContent) => api.put(`/api/admin/editSave`, editContent);
export const favorityAdd = (addContent) => api.post(`/api/admin/favorityAdd`, addContent);
export const artistDelete = (artistNo) => api.delete(`/api/admin/artistDelete/${artistNo}`);

export const orderSelect = (userId) => api.get(`/api/admin/orderSelect/${userId}`);
export const adminOrderSelect = () => api.get(`/api/admin/adminOrderSelect`);

export const deliveryCompanySelect = () => api.get(`/api/admin/deliveryCompanySelect`);
export const deliveryUpdate = (payload) => api.put(`/api/admin/deliveryUpdate`, payload);
export const deliveryInfoUpdate = (deliveryProcessing) => api.put(`/api/admin/deliveryInfoUpdate`, deliveryProcessing);

export const sellerRefundSelect = (paymentProductNumber) => api.get(`/api/admin/sellerRefundSelect/${paymentProductNumber}`);
export const paymentStateUpdate = (selectOrders) => api.put(`/api/admin/paymentStateUpdate`, selectOrders);
export const paymentCancelApproval = (selectedOrdersData) => api.put(`/api/admin/paymentCancelApproval`, selectedOrdersData);

export const forcedDeliveryCompleted = (selectedOrderDetails) => api.put(`/api/admin/forcedDeliveryCompleted`, selectedOrderDetails);
export const orderReturnCollection = (selectedOrder) => api.put(`/api/admin/orderReturnCollection`, selectedOrder);
export const orderReturnCollectionComp = (selectOrder) => api.put(`/api/admin/orderReturnCollectionComp`, selectOrder);
export const deliveryReturnCollectionUpdate = (deliveryProcessing) => api.put(`/api/admin/deliveryReturnCollectionUpdate`, deliveryProcessing);
export const deliveryReturnInfoUpdate = (deliveryProcessing) => api.put(`/api/admin/deliveryReturnInfoUpdate`, deliveryProcessing);
export const orderReturnApproval = (selectOrder) => api.put(`/api/admin/orderReturnApproval`, selectOrder);
export const returnRefuseUpdate = (refuseList) => api.put(`/api/admin/returnRefuseUpdate`, refuseList);
export const orderReturnComplete = (returnItem) => api.put(`/api/admin/orderReturnComplete`, returnItem);
export const orderReturnCompleteCheck = (selectedOrderDetails) => api.put(`/api/admin/orderReturnCompleteCheck`, selectedOrderDetails);
export const refundProcessing = (selectedOrderDetails) => api.put(`/api/admin/refundProcessing`, selectedOrderDetails);
export const returnRequest = (selectedOrderDetails) => api.put(`/api/admin/returnRequest`, selectedOrderDetails);
export const backCollecting = (selectedOrderDetails) => api.put(`/api/admin/backCollecting`, selectedOrderDetails);
export const backCollectComp = (selectedOrderDetails) => api.put(`/api/admin/backCollectComp`, selectedOrderDetails);

export const redeliveryUpdate = (deliveryProcessing) => api.put(`/api/admin/redeliveryUpdate`, deliveryProcessing);
export const redeliveryExchangeSuccess = (deliveryProcessing) => api.put(`/api/admin/redeliveryExchangeSuccess`, deliveryProcessing);
export const collectionDelivery = (deliveryProcessing) => api.put(`/api/admin/collectionDelivery`, deliveryProcessing);
export const collectionDeliveryComplete = (deliveryProcessing) => api.put(`/api/admin/collectionDeliveryComplete`, deliveryProcessing);

export const adminUserSelect = () => api.get('/api/admin/adminUserSelect');
export const pointUpdate = (pointInfo) => api.post(`/api/admin/pointUpdate`, pointInfo);
export const pointHistorySelect = () => api.get(`/api/admin/pointHistorySelect`);

export const couponAdd = (couponAddInfo) => api.post(`/api/admin/couponAdd`, couponAddInfo);
export const adminCouponSelect = () => api.get(`/api/admin/adminCouponSelect`);
export const couponUpdate = (couponUpdateInfo) => api.put(`/api/admin/couponUpdate`, couponUpdateInfo);

export const eventAdd = (eventInfo) => api.post(`/api/admin/eventAdd`, eventInfo);
export const eventSelect = () => api.get(`/api/admin/eventSelect`);
export const eventUpdate = (updateEvent) => api.put(`/api/admin/eventUpdate`, updateEvent);
export const deleteEvents = (selectedItem) => api.delete(`/api/admin/deleteEvents`, { data : selectedItem});

export const totalProductSelect = () => api.get(`/api/admin/totalProductSelect`);
export const planEventAdd = (newItem) => api.post(`/api/admin/planEventAdd`, newItem);

export const voteEventAdd = (newItem) => api.post(`/api/admin/voteEventAdd`, newItem);

export const participantSelect = (newData) => api.post(`/api/admin/participantSelect`, newData);
export const eventParticipantSelect = (eventNumber) => api.get(`/api/admin/eventParticipantSelect/${eventNumber}`);

export const voteParticipantSelect = (eventNumbers) => api.post(`/api/admin/voteParticipantSelect`, eventNumbers);
export const eventVoteParticipantSelect = (eventNumber) => api.get(`/api/admin/eventVoteParticipantSelect/${eventNumber}`);

export const commentParticipantSelect = (eventNumbers) => api.post(`/api/admin/commentParticipantSelect`, eventNumbers);
export const eventCommentParticipantSelect = (eventNumber) => api.get(`/api/admin/eventCommentParticipantSelect/${eventNumber}`);

export const cancleRefuseUpdate = (refusList) => api.put(`/api/admin/cancleRefuseUpdate`, refusList);
export const cancleProgressUpdate = (cancelList) => api.put(`/api/admin/cancleProgressUpdate`, cancelList);
export const cancleRequestUpdate = (cancelList) => api.put(`/api/admin/cancleRequestUpdate`, cancelList);
export const cancleForcedUpdate = (cancelList) => api.put(`/api/admin/cancleForcedUpdate`, cancelList);

export const exchangeApproval = (selectedOrderDetails) => api.put(`/api/admin/exchangeApproval`, selectedOrderDetails);
export const exchangeRefuseUpdate = (refuseList) => api.put(`/api/admin/exchangeRefuseUpdate`, refuseList);
export const exchangePendingUpdate = (refuseList) => api.put(`/api/admin/exchangePendingUpdate`, refuseList);
export const exchangeHoldUnlock = (refuseList) => api.put(`/api/admin/exchangeHoldUnlock`, refuseList);
export const exchangeRequest = (refuseList) => api.put(`/api/admin/exchangeRequest`, refuseList);
export const exchangeCollecting = (refuseList) => api.put(`/api/admin/exchangeCollecting`, refuseList);
export const exchangeCollectComp = (refuseList) => api.put(`/api/admin/exchangeCollectComp`, refuseList);
export const exchangeRedelivery = (refuseList) => api.put(`/api/admin/exchangeRedelivery`, refuseList);

export const suggestKeywordRead = () => api.get(`/api/admin/suggestKeywordRead`);
export const suggestKeywordAdd = (suggestKeyword) => api.post(`/api/admin/suggestKeywordAdd`, suggestKeyword);
export const suggestKeywordRemove = (userId, keyword) => api.delete(`/api/admin/suggestKeywordRemove/${userId}`, { params : {keyword}});

// 구매평
export const sellerReviewSelect = (userId) => api.get(`/api/admin/sellerReviewSelect/${userId}`);
export const allReviewSelect = () => api.get(`/api/admin/allReviewSelect`);

// 공지
export const notificationAdd = (notificationContent) => api.post(`/api/admin/notificationAdd`, notificationContent);
export const notificationSelect = () => api.get('/api/admin/notificationSelect');
export const notificationEdit = (notificationNo, notificationContent) => api.put(`/api/admin/notificationEdit/${notificationNo}`, notificationContent);
export const notificationDelete = (notificationToDelete) => api.delete(`/api/admin/notificationDelete`,{ data : { notificationNos : notificationToDelete}});
export const singleNotificationDelete = (notificationNo) => api.delete(`/api/admin/singleNotificationDelete/${notificationNo}`);

// 당첨자발표
export const particeSelect = (selectEventNumber) => api.get(`/api/admin/particeSelect/${selectEventNumber}`);
export const announcementCreate = (announcementInfo) => api.post(`/api/admin/announcementCreate`, announcementInfo);
export const announcementSelect = () => api.get(`/api/admin/announcementSelect`);

// 제외키워드
export const excludedKeywordRead = () => api.get(`/api/admin/excludedKeywordRead`);
export const excludedKeywordAdd = (excludedKeyword) => api.post(`/api/admin/excludedKeywordAdd`, excludedKeyword);
export const excludedKeywordRemove = (userId, keyword) => api.delete(`/api/admin/excludedKeywordRemove/${userId}`, { params : {keyword}});

// 인기상품 관련
export const popularProductAdd = (selectedProducts) => api.post(`/api/admin/popularProductAdd`, selectedProducts);
export const customProductSelect = () => api.get(`/api/admin/customProductSelect`);
export const customAutoProductSelect = () => api.get(`/api/admin/customAutoProductSelect`);
export const updatePopularExposure = (popularProductNo, newExposureStatus) => api.put(`/api/admin/updatePopularExposure`, {popularProductNo : popularProductNo, newExposureStatus : newExposureStatus});
export const popularOrderChange = (popularProductNo, newOrder) => api.put(`/api/admin/popularOrderChange`, {popularProductNo: popularProductNo, newOrder: newOrder});
export const popularSelect = () => api.get(`/api/admin/popularSelect`);
export const popularProductDelete = (popularProductNo) => api.delete(`/api/admin/popularProductDelete/${popularProductNo}`)

// 할인상품 관련
export const discountProductAdd = (selectedProducts) => api.post(`/api/admin/discountProductAdd`, selectedProducts);
export const discountProductSelect = () => api.get(`/api/admin/discountProductSelect`);
export const updateDiscountExposure = (discountProductNo, newExposureStatus) => api.put(`/api/admin/updateDiscountExposure`, {discountProductNo : discountProductNo, newExposureStatus : newExposureStatus});
export const discountOrderChange = (discountProductNo, newOrder) => api.put(`/api/admin/discountOrderChange`, {discountProductNo: discountProductNo, newOrder: newOrder});
export const discountProductDelete = (discountProductNo) => api.delete(`/api/admin/discountProductDelete/${discountProductNo}`)

// 예약상품 관련
export const totalSupportSelect = () => api.get(`/api/admin/totalSupportSelect`);
export const popularCustomSupportSelect = () => api.get(`/api/admin/popularCustomSupportSelect`);
export const popularAutoSupportSelect = () => api.get(`/api/admin/popularAutoSupportSelect`);
export const updateReservationCustomPopularExposure = (popularSupportNo, newExposureStatus) => api.put(`/api/admin/updateReservationCustomPopularExposure`, {popularSupportNo : popularSupportNo, newExposureStatus : newExposureStatus});
export const updateReservationAutoPopularExposure = (popularSupportNo, newExposureStatus) => api.put(`/api/admin/updateReservationAutoPopularExposure`, {popularSupportNo : popularSupportNo, newExposureStatus : newExposureStatus});
export const reservationCustomOrderChange = (productNo, newOrder) => api.put(`/api/admin/reservationCustomOrderChange`, {productNo : productNo , newOrder : newOrder});
export const reservationAutoOrderChange = (productNo, newOrder) => api.put(`/api/admin/reservationAutoOrderChange`, {productNo : productNo , newOrder : newOrder});
export const popularSupportAdd = (selectedSupports) => api.post(`/api/admin/popularSupportAdd`, selectedSupports);
export const popularSupportSelect = () => api.get(`/api/admin/popularSupportSelect`);
export const popularSupportDelete = (popularSupportNo) => api.delete(`/api/admin/popularSupportDelete/${popularSupportNo}`)

// 인기리뷰 관련
export const reviewItemSelect = () => api.get(`/api/admin/reviewItemSelect`);
export const reviewSupportItemSelect = () => api.get(`/api/admin/reviewSupportItemSelect`);
export const popularReviewAdd = (selectedReviews) => api.post(`/api/admin/popularReviewAdd`, selectedReviews);
export const popularReviewSelect = () => api.get(`/api/admin/popularReviewSelect`);
export const updateReviewExposure = (popularReviewNo, newExposureStatus) => api.put(`/api/admin/updateReviewExposure`, {popularReviewNo : popularReviewNo, newExposureStatus : newExposureStatus});
export const reviewOrderChange = (popularReviewNo, newOrder) => api.put(`/api/admin/reviewOrderChange`, {popularReviewNo: popularReviewNo, newOrder: newOrder});
export const popularReviewDelete = (popularReviewNo) => api.delete(`/api/admin/popularReviewDelete/${popularReviewNo}`)

// 추천상품 관련
export const recomandProductAdd = (selectedProducts) => api.post(`/api/admin/recomandProductAdd`, selectedProducts);
export const recomandProductSelect = () => api.get(`/api/admin/recomandProductSelect`);
export const updateRecomandExposure = (recomandProductNo, newExposureStatus) => api.put(`/api/admin/updateRecomandExposure`, {recomandProductNo : recomandProductNo, newExposureStatus : newExposureStatus});
export const recomandOrderChange = (recomandProductNo, newOrder) => api.put(`/api/admin/recomandOrderChange`, {recomandProductNo: recomandProductNo, newOrder: newOrder});
export const recomandProductDelete = (recomandProductNo) => api.delete(`/api/admin/recomandProductDelete/${recomandProductNo}`)

// 정산 관련
export const profitSelect = (userId) => api.get(`/api/admin/profitSelect/${userId}`);
export const profitAllSelect = () => api.get(`/api/admin/profitAllSelect`);
export const settleSelect = (userId) => api.get(`/api/admin/settleSelect/${userId}`);
export const settleAllSelect = () => api.get(`/api/admin/settleAllSelect`);
export const withdrawalRequest = (periodSttlement) => api.put(`/api/admin/withdrawalRequest`, periodSttlement);
export const withdrawalReady = (selectedItem) => api.put(`/api/admin/withdrawalReady`, selectedItem);
export const withdrawalConfirm = (selectedItem) => api.put(`/api/admin/withdrawalConfirm`, selectedItem);

// 수수료설정
export const sellerSelect = () => api.get(`/api/admin/sellerSelect`);
export const allUserFeeUpdate = (consignmentFee, sellerOwnFee, foreignPaymentFee) => api.put(`/api/admin/allUserFeeUpdate`, {consignmentFee: consignmentFee, sellerOwnFee: sellerOwnFee, foreignPaymentFee: foreignPaymentFee})
export const updateSellerFees = (selectedUpdate) => api.put(`/api/admin/updateSellerFees`, selectedUpdate);

export const settleExcelDown = (selectedItems) => api.post('/api/admin/settleExcelDown', selectedItems, {
    responseType: 'blob'
});
export const settleHistoryExcelDown = (selectedItems) => api.post('/api/admin/settleHistoryExcelDown', selectedItems, {
    responseType: 'blob'
});

// 포인트 확률 조정
export const pointRateAdd = (minPoint, maxPoint, divisionNumber) => api.post('/api/admin/pointRateAdd', {minPoint: minPoint, maxPoint: maxPoint, divisionNumber: divisionNumber})
export const pointRateSelect = () => api.get(`/api/admin/pointRateSelect`);
export const pointRateUpdate = (pointRateList) => api.put(`/api/admin/pointRateUpdate`, pointRateList);
export const paymentLogSelect = (userId) => api.get(`/api/admin/paymentLogSelect/${userId}`);
export const paymentLogAllSelect = () => api.get(`/api/admin/paymentLogAllSelect`);

// 대시보드
export const viewSelect = (userId) => api.get(`/api/admin/viewSelect/${userId}`);

export const viewAllSelect = () => api.get(`/api/admin/viewAllSelect`);

// 아티스트 조회
export const artistDBSelect = () => api.get(`/api/admin/artistDBSelect`);

// 상품 뱃지 등록
export const productBadgeAdd = (newItem) => api.post(`/api/admin/productBadgeAdd`, newItem);

// 상품 뱃지 조회
export const badgesSelect = () => api.get(`/api/admin/badgesSelect`);

// 셀러 공지사항 등록
export const sellerNoticeAdd = (noticeInfo) => api.post(`/api/admin/sellerNoticeAdd`, noticeInfo);

// 셀러 공지사항 조회
export const sellerNoticeSelect = () => api.get(`/api/admin/sellerNoticeSelect`);

// 셀러 공지사항 업데이트
export const sellerNoticeUpdate = (updateNotice) => api.put(`/api/admin/sellerNoticeUpdate`, updateNotice);

// 셀러 공지사항 삭제
export const sellerNoticeDelete = (no) => api.delete(`/api/admin/sellerNoticeDelete/${no}`);

// 최애 요청 조회
export const requestFavority = () => api.get(`/api/admin/requestFavority`);

// 최애 요청 승인
export const favorityRequestStateChange = (item) => api.put(`/api/admin/favorityRequestStateChange`, item);

// 셀러 아이디 중복체크
export const sellerIdDuplicate = (userId) => api.get(`/api/admin/sellerIdDuplicate/${userId}`);

// 셀러 이메일 중복 체크
export const sellerEmailDuplicate = (userEmail) => api.get(`/api/admin/sellerEmailDuplicate/${userEmail}`);

// 셀러 정보 등록
export const sellerInfoAdd = (sellerInfoList) =>api.post(`/api/admin/sellerInfoAdd`, sellerInfoList);
// 셀러 상점 정보 등록
export const sellerShopAdd = (shopInfo) => api.post(`/api/admin/sellerShopAdd`, shopInfo);

export const deliveryTemplate = (userId) => api.get(`/api/admin/deliveryTemplate/${userId}`);
export const adminDeliveryTemplate = () => api.get(`/api/admin/adminDeliveryTemplate`);
export const eventPlanProductSelect = (eventNumber) => api.get(`/api/admin/eventPlanProductSelect/${eventNumber}`);

export const noShopSelect = (userNo) => api.get(`/api/admin/noShopSelect/${userNo}`);
export const noUserSelect = (userNo) => api.get(`/api/admin/noUserSelect/${userNo}`);
export const sellerJoinApproval = (userNo) => api.put(`/api/admin/sellerJoinApproval/${userNo}`);
export const sellerJoinRefusal = (userNo, refusalContent) => {
    return api.put(`/api/admin/sellerJoinRefusal/${userNo}`, 
      { refusalContent: refusalContent },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  };

export const sellerInfoUpdate = (userInfo) => api.put(`/api/admin/sellerInfoUpdate`, userInfo);
export const sellerShopUpdate = (shopInfo) => api.put(`/api/admin/sellerShopUpdate`, shopInfo);

export const sellerShopFullAdd = (shopInfo) => api.post(`/api/admin/sellerShopFullAdd`, shopInfo);

export const depositWaitUpdate = (selectedOrderDetails) => api.put(`/api/admin/depositWaitUpdate`, selectedOrderDetails);
export const deliveryPreparingUpdate = (selectedOrderDetails) => api.put(`/api/admin/deliveryPreparingUpdate`, selectedOrderDetails);
export const paymentCompleUpdate = (selectedOrderDetails) => api.put(`/api/admin/paymentCompleUpdate`, selectedOrderDetails);
export const inDeliveryUpdate = (selectedOrderDetails) => api.put(`/api/admin/inDeliveryUpdate`, selectedOrderDetails);
export const deliveryDelaGuide = (deliveryDelayData) => api.put(`/api/admin/deliveryDelaGuide`, deliveryDelayData);

export const faqInsert = (faqContent) => api.post(`/api/admin/faqInsert`, faqContent);
export const faqSelect = () => api.get(`/api/admin/faqSelect`);
export const updateFaq = (faqNo, faqContent) => api.put(`/api/admin/updateFaq/${faqNo}`, faqContent);

export const userFcmToken = (userId, token) => api.post(`/api/admin/userFcmToken`, { userId: userId, token : token});
export const tokenUserSelect = () => api.get(`/api/admin/tokenUserSelect`);
export const appPushRequest = (pushRequest) => api.post(`/api/admin/appPushRequest`, pushRequest);

export const eventSingleDelete = (eventNo) => api.delete(`/api/admin/eventSingleDelete/${eventNo}`);

export const userExcel = (formData, config) => api.post(`/api/admin/userExcel`, formData, config);

export const sellerPwChange = (newInfo) => api.post(`/api/admin/sellerPwChange`, newInfo);

export const sellerConfirmPwChange = (userId, password) => api.post(`/api/admin/sellerConfirmPwChange`, {userId : userId, password : password});

export const kakaoBizTemplateSelect = () => api.post(`/api/admin/kakaoBizTemplateSelect`);
export const kakaoBizUserSelect = () => api.get(`/api/admin/kakaoBizUserSelect`);
export const kakaoBizRequest = (payload) => api.post(`/api/admin/kakaoBizRequest`, payload);

export const applicantExcelDown = (selectedOrders) => api.post(`/api/admin/applicantExcelDown`, selectedOrders, {
  responseType: 'blob'
});

export const deliveryExcelDown = (selectedOrders, dlvComp) => api.post(`/api/admin/deliveryExcelDown/${dlvComp}`, selectedOrders, {
  responseType: 'blob'
});

export const exposureStoreOrderUpdate = (value, categoryNo) => api.put(`/api/admin/exposureStoreOrderUpdate`, {value : value, categoryNo : categoryNo});
export const exposureSupportOrderUpdate = (value, categoryNo) => api.put(`/api/admin/exposureSupportOrderUpdate`, {value : value, categoryNo : categoryNo});
export const exposureStoreSubOrderUpdate = (value, subCategoryNo, mainCategoryNo) => api.put(`/api/admin/exposureStoreSubOrderUpdate`, {value : value, subCategoryNo : subCategoryNo, mainCategoryNo : mainCategoryNo});
export const exposureSupportSubOrderUpdate = (value, subCategoryNo, mainCategoryNo) => api.put(`/api/admin/exposureSupportSubOrderUpdate`, {value : value, subCategoryNo : subCategoryNo, mainCategoryNo : mainCategoryNo});