import React, { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // 메인 스타일 파일
import 'react-date-range/dist/theme/default.css'; // 테마 css 파일
import { format, differenceInDays, eachDayOfInterval } from 'date-fns';
import { ko } from 'date-fns/locale';

const CombinationDate = ({ transitions, animated, handleBack, dateRange, setDateRange, temporarys, holidays, ss, 
    venueDetail, lastOption, combinationPayment, setCartState, cartState, combinationCartAdd }) => {

    useEffect(() => {
        console.log('lastOption :', lastOption);
    }, [lastOption]);

    useEffect(() => {
        console.log('cartState : ', cartState);
    }, [cartState])
    
    const today = new Date();
    const [state, setState] = useState([
        {
            startDate: new Date(), // 초기값으로 오늘 날짜 설정
            endDate: new Date(),  // 초기값으로 오늘 날짜 설정
            key: 'selection',
        },
    ]);
    const [active, setActive] = useState(false);
    const calendarRef = useRef(null); // 달력 컨테이너 참조

    // holidays와 temporarys에서 날짜들을 추출하여 disabledDates 배열 생성
    // useEffect(() => {
    //     console.log('holidays : ', holidays);
    //     console.log('temporarys : ', temporarys);
    //     console.log('dateRange : ', dateRange);
    //     console.log('dateRange venueDetail : ', venueDetail);
    // }, []);
    
    const disabledDates = [
        ...holidays.map(item => new Date(item.holidayDate)),
        ...temporarys.flatMap(item => 
            eachDayOfInterval({ 
                start: new Date(item.temporaryDate), 
                end: new Date(item.temporaryEndDate) 
            })
        )
    ];

    // 예약 불가능한 날짜가 선택된 범위에 포함되는지 확인하는 함수
    const isWithinUnavailableDates = (start, end) => {
        for (let date of disabledDates) {
            if (start <= date && date <= end) {
                return true;
            }
        }
        return false;
    }

    // const handleDateChange = (ranges) => {
    //     const { startDate, endDate } = ranges.selection;
    //     console.log('handleDateChange startDate : ', startDate);
    //     console.log('handleDateChange endDate : ', endDate);
    
    //     // 선택된 일수 계산 (정상적인 범위 선택)
    //     const selectedDays = differenceInDays(endDate, startDate) + 1;
    
    //     // 최소일수 조건 확인
    //     if (venueDetail.minDateState && selectedDays < venueDetail.minDate) {
    //         alert(`최소 ${venueDetail.minDate}일 이상 선택해야 합니다.`);
    //         // 시작 날짜와 종료 날짜를 모두 초기화
    //         setDateRange([null, null]);
    //         setState([
    //             {
    //                 startDate: startDate,
    //                 endDate: startDate,
    //                 key: 'selection',
    //             },
    //         ]);
    //         setActive(false);
    //         return;
    //     }
    
    //     // 예약 불가능한 날짜 포함 여부 확인
    //     if (isWithinUnavailableDates(startDate, endDate)) {
    //         alert('선택하신 날짜 범위에 예약 불가능한 날짜가 포함되어 있습니다.');
    //         // 시작 날짜와 종료 날짜를 모두 초기화
    //         setDateRange([null, null]);
    //         setState([
    //             {
    //                 startDate: startDate,
    //                 endDate: startDate,
    //                 key: 'selection',
    //             },
    //         ]);
    //         setActive(false);
    //         return;
    //     }
    
    //     // 선택된 날짜 범위 설정
    //     setDateRange([startDate, endDate]);
    //     setState([
    //         {
    //             startDate,
    //             endDate,
    //             key: 'selection',
    //         },
    //     ]);
    //     setActive(true);

    //     // 종료 날짜로 스크롤 이동
    //     // if (calendarRef.current && endDate) {
    //     //     setTimeout(() => {
    //     //         const calendarContainer = calendarRef.current;
    //     //         const monthAndYearPickers = calendarContainer.querySelectorAll('.rdrMonthAndYearPickers');
            
    //     //         if (!monthAndYearPickers.length) {
    //     //             console.error('Month and year pickers not found.');
    //     //             return;
    //     //         }
            
    //     //         // 목표 연도와 월
    //     //         const targetYear = endDate.getFullYear();
    //     //         const targetMonth = endDate.getMonth(); // 0: January, 11: December
            
    //     //         for (let picker of monthAndYearPickers) {
    //     //             const monthPicker = picker.querySelector('.rdrMonthPicker select');
    //     //             const yearPicker = picker.querySelector('.rdrYearPicker select');
            
    //     //             if (monthPicker && yearPicker) {
    //     //                 // 년도와 월 설정
    //     //                 const yearOption = Array.from(yearPicker.options).find(
    //     //                     (option) => parseInt(option.value, 10) === targetYear
    //     //                 );
    //     //                 const monthOption = Array.from(monthPicker.options).find(
    //     //                     (option) => parseInt(option.value, 10) === targetMonth
    //     //                 );
            
    //     //                 if (yearOption && monthOption) {
    //     //                     // 값만 설정해도 적용되는지 테스트
    //     //                     yearPicker.value = targetYear.toString();
    //     //                     monthPicker.value = targetMonth.toString();

    //     //                     console.log('yearPicker.value : ', yearPicker.value);
    //     //                     console.log('monthPicker.value : ', monthPicker.value);
            
    //     //                     // React가 선택을 감지하도록 이벤트 트리거
    //     //                     yearPicker.dispatchEvent(new Event('change', { bubbles: true }));
    //     //                     monthPicker.dispatchEvent(new Event('change', { bubbles: true }));
            
    //     //                     console.log('Updated to:', targetYear, targetMonth + 1); // 1월부터 시작하므로 +1 출력
    //     //                     break;
    //     //                 } else {
    //     //                     console.error('Target year or month option not found.');
    //     //                 }
    //     //             }
    //     //         }
    //     //     }, 100);
            
            
    //     // }
    // };

    const handleDateChangeWithLastOption = (startDate) => {
        if (!lastOption) {
            console.error("lastOption이 없습니다.");
            return;
        }
    
        // "일" 또는 "개월" 여부 확인 및 숫자 추출
        const isDay = lastOption.includes("일");
        const isWeek = lastOption.includes("주");
        const isMonth = lastOption.includes("개월");
        const number = parseInt(lastOption.replace(/[^\d]/g, ""), 10); // 숫자만 추출
    
        if (isNaN(number)) {
            console.error("lastOption에서 숫자를 추출할 수 없습니다.");
            return;
        }
    
        // 곱하기 로직
        const daysToAdd = isDay ? number * 1 : isWeek ? number * 7 : isMonth ? number * 30 : 0;
    
        if (daysToAdd === 0) {
            console.error("lastOption이 유효하지 않습니다.");
            return;
        }
    
        // endDate 계산
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + daysToAdd -1);
    
        // state와 dateRange 업데이트
        setDateRange([startDate, endDate]);
        setState([
            {
                startDate,
                endDate,
                key: "selection",
            },
        ]);
    
        setActive(true);
        console.log(`startDate: ${startDate}, endDate: ${endDate}`);
    };
    
    // 기존 handleDateChange에서 시작일 선택 시 호출
    const handleDateChange = (ranges) => {
        const { startDate } = ranges.selection;
    
        console.log("handleDateChange startDate :", startDate);
    
        // lastOption 기반으로 종료일 설정
        handleDateChangeWithLastOption(startDate);

        // 선택 완료 상태로 설정
        setActive(true);
    };
    
    
    
    

    return (
        <div className={`${ss.support_date_range_piceker_container}`}>
            {transitions((style, item) =>
                item ?
                    <animated.div className={`${ss.support_date_range_piceker_box}`} style={style}>
                        <div>
                            <div className={`${ss.support_date_range_piceker_header}`}>
                                <div>
                                    <button onClick={() => handleBack()}>
                                        <span className="material-symbols-outlined">arrow_back_ios</span>
                                    </button>
                                </div>
                                <div><span>예약 일정 선택</span></div>
                                <div>
                                    <button onClick={() => handleBack()}>
                                        <span className="material-symbols-outlined">close</span>
                                    </button>
                                </div>
                            </div>
                            <div className={`${ss.support_date_range_piceker_text}`}><span>➡️ 광고 시작일을 선택해 주세요!</span></div>
                            <div className='position-relative' ref={calendarRef}>
                                <DateRange
                                    onChange={handleDateChange}
                                    moveRangeOnFirstSelection={false}
                                    ranges={state}
                                    minDate={new Date()}
                                    locale={ko}
                                    disabledDates={disabledDates}
                                    className={ss.reserve_date_range_picker}
                                    editableDateInputs={false} // 입력 필드 비활성화
                                    showDateDisplay={false} // 상단 날짜 표시 제거
                                />
                            </div>
                        </div>
                        <div className='datepick_footer py-4 border-top'>
                            <div className='px-4 pb-3'>
                                {dateRange[0] && dateRange[1] ?
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <span className='datepick bold'>
                                                {format(dateRange[0], 'yyyy.MM.dd(eee)', { locale: ko })} ~ {format(dateRange[1], 'yyyy.MM.dd(eee)', { locale: ko })}
                                            </span>
                                        </div>
                                        <div>
                                            <span className='datepick_date'>{differenceInDays(dateRange[1], dateRange[0]) + 1}일</span>
                                        </div>
                                    </div>
                                    : <span className='datepick'>가능한 일자를 선택해 주세요. </span>
                                }
                            </div>
                            <div className='mx-4'>
                                {
                                    cartState ?
                                    <button className={dateRange[0] && dateRange[1] ? 'datepick_btn py-3 datepick_active' : 'datepick_btn py-3'} onClick={() => combinationCartAdd()} disabled={!active}>장바구니 담기</button>
                                    :
                                    <button className={dateRange[0] && dateRange[1] ? 'datepick_btn py-3 datepick_active' : 'datepick_btn py-3'} onClick={() => combinationPayment()} disabled={!active}>결제하기</button>
                                }
                            </div>
                        </div>
                    </animated.div>
                    : null
            )}
        </div>
    )
}

export default CombinationDate;
