import React, { useContext, useEffect, useState } from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { Modal, Select } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dailyduck from './MyContainner.module.css'
import MyOrderDetail from './MyOrderDetail';
import MyReservationDetail from './MyReservationDetail';
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const { Option } = Select;

const MyOrderDelivery = ( {} ) => {

    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    // const { state : { users } } = useLocation();
    const { userInfo } = useContext(LoginContext);
    const [orderSelectedDate, setOrderSelectedDate] = useState('all');
    const [orderSelectedState, setOrderSelectedState] = useState('total_state');
    const [reservationSelectedDate, setReservationSelectedDate] = useState('all');
    const [reservationSelectedState, setReservationSelectedState] = useState('total_state');
    const [order, setOrder] = useState([]);
    const [reservation, setReservation] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [filteredReservations, setFilteredreservations] = useState([]);
    const [activeButton, setActiveButton] = useState('order');
    const [ showMyOrder, setShowMyOrder ] = useState(false);
    const [ selectOrder, setSelectOrder ] = useState('');
    const [ showExchange, setShowExchange ] = useState(false);
    const [ showMyReservation, setShowMyReservation ] = useState(false);
    const [ showRefund, setShowRefund ] = useState(false);
    const [ selectReservation, setSelectReservation] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [ returnRefuseText, setReturnRefuseText ] = useState('');

    const [ trackingInfo, setTrackingInfo] = useState({});
    const [ trackingItem, setTrackingItem] = useState({});
    const [ isLoading, setIsLoading ] = useState(false);
    const [ showTrackingModal, setShowTrackingModal ] = useState(false);

    useEffect(() => {
        storeOrderSelect();
        suppotOrderSelect();
    }, [userInfo]);

    const storeOrderSelect = async () => {
        const response = await auth.storeOrderSelect(userInfo?.userId);
        const data = response.data;

        // console.log('data : ', data);
        setOrder(data);
    }

    const suppotOrderSelect = async () => {
        const response = await auth.supportOrderSelect(userInfo?.userId);
        const data = response.data;

        // console.log('reservation data : ', data)
        setReservation(data);
    }

    const handleOdBack = () => {
        navigate('/my');
    }
    const orderItemSelect = (order) => {
        setShowMyOrder(true);
        setSelectOrder(order);
        // console.log(order);
    }
    
    const reservationItemSelect = (item) => {
        setShowMyReservation(true);
        setSelectReservation(item);
    }

    useEffect(() => {
        // setReservation([
        //     { reservationNo : 1, reservationName : "카페 비비드", shopName : "비비드", reservationState: "결제완료", reservationImg: "../img/support/venue1.png", reservationDate: "2024.01.11"},
        //     { reservationNo : 2, reservationName : "카페 프루티", shopName : "프루티", reservationState: "예약확정", reservationImg: "../img/support/venue2.png", reservationDate: "2024.02.11"},
        //     { reservationNo : 3, reservationName : "망원바이브", shopName : "바이브", reservationState: "제공완료", reservationImg: "../img/support/venue3.png", reservationDate: "2024.03.11"}
        // ]);
    }, []);

    const calculateDateRange = (selectedPeriod) => {
        const today = new Date();
        switch(selectedPeriod) {
        case 'one_month':
            today.setMonth(today.getMonth() - 1);
            break;
        case 'three_months':
            today.setMonth(today.getMonth() - 3);
            break;
        case 'six_months':
            today.setMonth(today.getMonth() - 6);
            break;
        case 'one_year':
            today.setFullYear(today.getFullYear() - 1);
            break;
        default:
            today.setFullYear(2000);
            break;
        }
        return today;
    };
    
    const filterOrders = (orders, selectedDate, selectedState) => {
        const startDate = calculateDateRange(selectedDate);
        return orders.filter((order) => {
            const orderDate = new Date(order.paymentRegDate);
            const isDateInRange = orderDate >= startDate;
            let isStateMatch = false;
            switch (selectedState) {
                case "total_state":
                    isStateMatch = true;
                    break;
                case "waiting_for_payment":
                    isStateMatch = order.paymentState === '입금대기';
                    break;
                case "payment_completed":
                    isStateMatch = order.paymentState === '결제완료';
                    break;
                case "preparing_for_delivery":
                    isStateMatch = order.paymentState === '배송준비';
                    break;
                case "in_delivery":
                    isStateMatch = order.paymentState === '배송중';
                    break;
                case "delivery_completed":
                    isStateMatch = order.paymentState === '배송완료' && order.purchaseConfirmation === false;
                    break;
                case "purchase_confirmed":
                    isStateMatch = order.purchaseConfirmation === true;
                    break;
                case "canceled":
                    isStateMatch = ['취소요청', '취소처리중', '취소처리실패', '취소완료'].includes(order.paymentState);
                    break;
                case "return":
                    isStateMatch = ['반품요청', '반품수거중', '반품수거완료', '환불진행중', '환불처리실패', '반품완료', '반품거부'].includes(order.paymentState);
                    break;
                case "exchange":
                    isStateMatch = ['교환요청', '교환수거중', '교환수거완료', '재배송중', '교환완료', '교환거부'].includes(order.paymentState);
                    break;
                case "refund":
                    isStateMatch = order.paymentState === '환불';
                    break;
                default:
                    break;
            }
    
            return isDateInRange && isStateMatch;
        });
    };
    
    
    const filterReservations = (reservations, selectedDate, selectedState) => {
        const startDate = calculateDateRange(selectedDate);
        return reservations.filter((reservation) => {
        const reservationDate = new Date(reservation.reservationRegDate);
        const isDateInRange = reservationDate >= startDate;
        let stateToCompare = '전체상태'; // 여기에 새로운 변수를 선언합니다.
        switch (selectedState) {
            case "reservation_total_state":             stateToCompare = '전체상태';     break;
            case "reservation_payment_completed":       stateToCompare = '예약대기'; break;
            case "reservation_preparing_for_delivery":  stateToCompare = '예약확정'; break;
            case "reservation_in_delivery":             stateToCompare = '제공완료'; break;
            case "reservation_purchase_confirmed":      stateToCompare = '구매확정'; break;
            case "reservation_canceled":                stateToCompare = '예약취소'; break;
        
            default:
                break;
        }

        const isStateMatch = stateToCompare === '전체상태' || reservation.reservationState === stateToCompare;
        return isDateInRange && isStateMatch;
        });
    };

    useEffect(() => {
        const filtered = filterOrders(order, orderSelectedDate, orderSelectedState);
        setFilteredOrders(filtered);
    }, [order, orderSelectedDate, orderSelectedState]);

    useEffect(() => {
        const filtered = filterReservations(reservation, reservationSelectedDate, reservationSelectedState);
        setFilteredreservations(filtered);
    }, [reservation, reservationSelectedDate, reservationSelectedState]);
    
    // // console.log(reservationSelectedState);
    const orderDateSelect = (value) => {
        setOrderSelectedDate(value);
    };

    const orderStateSelect = (value) => {
        setOrderSelectedState(value);
    };

    const reservationDateSelect = (value) => {
        setReservationSelectedDate(value);
    };

    const reservationStateSelect = (value) => {
        setReservationSelectedState(value);
    };    

    const orderButtonClass = () => {
        setActiveButton('order');
    }

    const reservationButtonClass = () => {
        setActiveButton('reservation');
    }

    useEffect(() => {
        const elements = document.getElementsByClassName('page_inner');
        if(showMyOrder | showMyReservation | showRefund) {
            for (let element of elements) {
                element.style.height = '100vh';
            }
        } else {
            for (let element of elements) {
                element.style.height = '';
            }
        }
        
        return () => {
            for (let element of elements) {
                element.style.height = '';
            }
        }
    }, [showMyOrder, showMyReservation]);

    const onRefund = (item) => {
        navigate(`/my/order/refund`, {state : { order : item}});
    }

    const onExchange = (item) => {
        // console.log(item);
        navigate(`/my/order/exchange`, {state : { order : item}});
    }

    const formatDate = (date) => {
        // return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
        
        // dateString이 null, undefined, 또는 빈 문자열인 경우 처리
        if (!date) {
            return ''; // 또는 다른 기본값을 반환할 수 있습니다.
        }
        return date.replace('T', ' ').substring(0, 19);
    };
    const formatDate2 = (date) => {
        return format(new Date(date), 'yyyy-MM-dd');
    };

    const onPaymentCancel = (item) => {
        navigate('/my/order/cancel', { state : { order : item}});
    }

    const showReturnRefuse = (text) => {
        setReturnRefuseText(text);
        setIsModalVisible(true);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onPurchaseConfirm = (item) => {
        MySwal.fire({
            customClass: {
                popup: `${dailyduck.order_cancel_swal_popup}`
            },
            html : 
            `
                <div>
                    <div class='${dailyduck.order_cancel_title}'><span>구매확정 처리하시겠습니까?</span></div>
                    <div class='${dailyduck.order_cancel_sub_title}'><span>구매확정 후 취소 및 환불이 어려울 수 있습니다.</span></div>
                    <div class='${dailyduck.order_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니요</button>
                      <button class='mx-1' id='seller_add_success'>네</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                try {
                    const response = await auth.purchaseConfirm(item);
                    const data = response.data;
            
                    // console.log('purchaseConfirm : ', data);
        
                    if(data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.order_cancel_swal_popup}`
                            },
                            html : 
                            `
                            <div>
                            <div class='${dailyduck.order_cancel_title}'><span>구매확정이 완료되었습니다.</span></div>
                            <div class='${dailyduck.order_cancel_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        
                        function sellerAddConfirm(e) {
                            if(e.target && e.target.id == 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                                document.removeEventListener('click', sellerAddConfirm);
                                
                                storeOrderSelect();
                                suppotOrderSelect();
                            }
                        }
                    }
                    
                } catch (error) {
                    console.error('cancel error : ', error);
                }
                
                
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }
    const onDeliveryPurchaseConfirm = (item) => {
        MySwal.fire({
            customClass: {
                popup: `${dailyduck.order_cancel_swal_popup}`
            },
            html : 
            `
                <div>
                    <div class='${dailyduck.order_cancel_title}'><span>배송중 상품을 구매확정 처리하시겠습니까?</span></div>
                    <div class='${dailyduck.order_cancel_sub_title}'><span>배송완료되지 않은 상품을 구매확정 처리하실 경우<br />교환 및 환불이 불가할 수 있습니다.</span></div>
                    <div class='${dailyduck.order_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니요</button>
                      <button class='mx-1' id='seller_add_success'>네</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                try {
                    const response = await auth.deliveryPurchaseConfirm(item);
                    const data = response.data;
            
                    // console.log('purchaseConfirm : ', data);
        
                    if(data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.order_cancel_swal_popup}`
                            },
                            html : 
                            `
                            <div>
                            <div class='${dailyduck.order_cancel_title}'><span>구매확정이 완료되었습니다.</span></div>
                            <div class='${dailyduck.order_cancel_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        
                        function sellerAddConfirm(e) {
                            if(e.target && e.target.id == 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                                document.removeEventListener('click', sellerAddConfirm);
                                
                                storeOrderSelect();
                                suppotOrderSelect();
                            }
                        }
                    }
                    
                } catch (error) {
                    console.error('cancel error : ', error);
                }
                
                
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }
    const onSelectPurchaseConfirm = (item) => {
        MySwal.fire({
            customClass: {
                popup: `${dailyduck.order_cancel_swal_popup}`
            },
            html : 
            `
                <div>
                    <div class='${dailyduck.order_cancel_title}'><span>구매확정 처리하시겠습니까?</span></div>
                    <div class='${dailyduck.order_cancel_sub_title}'><span>구매확정 후 취소 및 환불이 어려울 수 있습니다.</span></div>
                    <div class='${dailyduck.order_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니요</button>
                      <button class='mx-1' id='seller_add_success'>네</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                try {
                    const response = await auth.purchaseConfirm(item);
                    const data = response.data;
            
                    // console.log('purchaseConfirm : ', data);
        
                    if(data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.order_cancel_swal_popup}`
                            },
                            html : 
                            `
                            <div>
                            <div class='${dailyduck.order_cancel_title}'><span>구매확정이 완료되었습니다.</span></div>
                            <div class='${dailyduck.order_cancel_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        
                        function sellerAddConfirm(e) {
                            if(e.target && e.target.id == 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                                document.removeEventListener('click', sellerAddConfirm);

                                 // 주문 데이터 업데이트
                                const updatedOrders = order.map(o => 
                                    o.paymentNumber === item.paymentNumber ? { ...o, purchaseConfirmation: true } : o
                                );

                                // console.log('item.paymentNumber : ', item.paymentNumber);
                                // console.log('updatedOrders : ', updatedOrders);

                                // 전체 주문 목록 및 필터된 목록 업데이트
                                setOrder(updatedOrders);
                                setFilteredOrders(filterOrders(updatedOrders, orderSelectedDate, orderSelectedState));

                                // 업데이트된 주문 데이터 중에서 현재 선택된 주문을 찾음
                                const updatedOrder = updatedOrders.find(o => o.paymentNumber === item.paymentNumber);

                                // 현재 선택된 주문을 업데이트
                                if (updatedOrder) {
                                    setSelectOrder(updatedOrder);  // 선택된 주문 데이터를 최신으로 업데이트
                                }
                            }
                        }
                    }
                    
                } catch (error) {
                    console.error('cancel error : ', error);
                }
                
                
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }
    const onDeliverySelectPurchaseConfirm = (item) => {
        MySwal.fire({
            customClass: {
                popup: `${dailyduck.order_cancel_swal_popup}`
            },
            html : 
            `
                <div>
                    <div class='${dailyduck.order_cancel_title}'><span>배송중 상품을 구매확정 처리하시겠습니까?</span></div>
                    <div class='${dailyduck.order_cancel_sub_title}'><span>배송완료되지 않은 상품을 구매확정 처리하실 경우<br />교환 및 환불이 불가할 수 있습니다.</span></div>
                    <div class='${dailyduck.order_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니요</button>
                      <button class='mx-1' id='seller_add_success'>네</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                try {
                    const response = await auth.purchaseConfirm(item);
                    const data = response.data;
            
                    // console.log('purchaseConfirm : ', data);
        
                    if(data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.order_cancel_swal_popup}`
                            },
                            html : 
                            `
                            <div>
                            <div class='${dailyduck.order_cancel_title}'><span>구매확정이 완료되었습니다.</span></div>
                            <div class='${dailyduck.order_cancel_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        
                        function sellerAddConfirm(e) {
                            if(e.target && e.target.id == 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                                document.removeEventListener('click', sellerAddConfirm);

                                 // 주문 데이터 업데이트
                                const updatedOrders = order.map(o => 
                                    o.paymentNumber === item.paymentNumber ? { ...o, purchaseConfirmation: true } : o
                                );

                                // console.log('item.paymentNumber : ', item.paymentNumber);
                                // console.log('updatedOrders : ', updatedOrders);

                                // 전체 주문 목록 및 필터된 목록 업데이트
                                setOrder(updatedOrders);
                                setFilteredOrders(filterOrders(updatedOrders, orderSelectedDate, orderSelectedState));

                                // 업데이트된 주문 데이터 중에서 현재 선택된 주문을 찾음
                                const updatedOrder = updatedOrders.find(o => o.paymentNumber === item.paymentNumber);

                                // 현재 선택된 주문을 업데이트
                                if (updatedOrder) {
                                    setSelectOrder(updatedOrder);  // 선택된 주문 데이터를 최신으로 업데이트
                                }
                            }
                        }
                    }
                    
                } catch (error) {
                    console.error('cancel error : ', error);
                }
                
                
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const onSupportPurchaseConfirm = (item) => {
        MySwal.fire({
            customClass: {
                popup: `${dailyduck.order_cancel_swal_popup}`
            },
            html : 
            `
                <div>
                    <div class='${dailyduck.order_cancel_title}'><span>구매확정 처리하시겠습니까?</span></div>
                    <div class='${dailyduck.order_cancel_sub_title}'><span>구매확정 후 취소 및 환불이 어려울 수 있습니다.</span></div>
                    <div class='${dailyduck.order_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니요</button>
                      <button class='mx-1' id='seller_add_success'>네</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                try {
                    const response = await auth.supportPurchaseConfirm(item);
                    const data = response.data;
            
                    // console.log('purchaseConfirm : ', data);
        
                    if(data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.order_cancel_swal_popup}`
                            },
                            html : 
                            `
                            <div>
                            <div class='${dailyduck.order_cancel_title}'><span>구매확정이 완료되었습니다.</span></div>
                            <div class='${dailyduck.order_cancel_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        
                        function sellerAddConfirm(e) {
                            if(e.target && e.target.id == 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                                document.removeEventListener('click', sellerAddConfirm);
                                storeOrderSelect();
                                suppotOrderSelect();
                            }
                        }
                    }
                    
                } catch (error) {
                    console.error('cancel error : ', error);
                }
                
                
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const onReviewWrite = async (item) => {
        
        let productNumber = item.paymentProductNumber;
        // console.log('item : ', productNumber);

        const response = await auth.storeItemSelect(productNumber);
        const data = response.data;

        // // console.log(data);

        navigate('/my/review/write', { state : {storePayment : item, store : data}});
    }

    const onSupportReviewWrite = async (item) => {
        
        let productNumber = item.productNumber;
        // console.log('item : ', productNumber);

        const response = await auth.supportItemSelect(productNumber);
        const data = response.data;

        // console.log(data);

        navigate('/my/review/write', { state : {reservation : item, support : data}});
    }

    const onDeliverySearch = async (item) => {
        setIsLoading(true);
        setTrackingItem(item);
    
        try {
            const response = await auth.deliveryTracking(item);
            const data = response.data;
    
            // console.log('onDeliverySearch : ', data);
            // console.log('onDeliverySearch : ', response.status);
    
            if (response.status === 200) {
                setIsLoading(false);
                setShowTrackingModal(true);
                setTrackingInfo(data);
            }
        } catch (error) {
            setIsLoading(false); // 로딩 상태 해제
    
            if (error.response) {
                // 상태 코드에 따른 에러 처리
                if (error.response.status === 404) {
                    alert("운송장 번호를 찾을 수 없습니다. (증상이 반복될 경우 판매자에게 문의해주세요.)");
                } else if (error.response.status === 400) {
                    alert("요청 정보가 잘못되었습니다: " + error.response.data);
                } else if (error.response.status === 500) {
                    alert("서버 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.");
                } else {
                    alert("알 수 없는 오류가 발생했습니다: " + error.response.data);
                }
            } else {
                // 네트워크 오류
                alert("네트워크 오류가 발생했습니다. 인터넷 연결을 확인해 주세요.");
            }
        }
    };

    const deliveryFormatDate = (dateString) => {
        const date = new Date(dateString);

        // 날짜 부분 (예: 24-10-04)
        const formattedDate = date.toLocaleDateString('ko-KR', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        }).replace(/\s+/g, '').replace(/\.$/, '').replace(/\./g, '-');  // 공백 제거, 마지막 점 제거, 점을 대시로 변경
    
        // 요일 부분 (예: (금))
        const weekDay = date.toLocaleDateString('ko-KR', { weekday: 'short' });
    
        // 시간 부분 (예: 00:00:00)
        const formattedTime = date.toLocaleTimeString('ko-KR', { hour12: false });
    
        return `${formattedDate}(${weekDay}) ${formattedTime}`;
    };
    

    return (
        <>
            {
                isLoading &&
                <div className={`${dailyduck.loading_page}`}>
                    <div><img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" /></div>
                </div>
            }
            <div className={`${dailyduck.myorderdelivery_container} ${showMyOrder ? dailyduck.back_hidden : ''} ${showMyReservation ? dailyduck.back_hidden : ''} ${showRefund ? dailyduck.back_hidden : ''}`}>
                    <div className={dailyduck.myfavority_box}>
                        <div className={`${dailyduck.my_order_delivery_header}`}>
                            <div>
                                <div>
                                    <button onClick={() => handleOdBack()} className=''>
                                        <span className="material-symbols-outlined">arrow_back_ios</span>
                                    </button>
                                </div>
                                <div>
                                    <div>
                                        <span>주문·배송</span>
                                    </div>
                                </div>
                            </div>
                            <div className={`${dailyduck.my_order_delivery_tap}`}>
                                <div className='w-100 d-flex text-center'>
                                    <div className='w-100'>
                                    <button className={`w-100 ${dailyduck.orderButtonClass} ${activeButton === 'order' ? dailyduck.click_active : ''}`} onClick={() => orderButtonClass()}>
                                        <span>주문</span>
                                    </button>
                                    </div>
                                    <div className='w-100'>
                                    <button className={`w-100 ${dailyduck.reservationButtonClass} ${activeButton === 'reservation' ? dailyduck.click_active : ''}`} onClick={() => reservationButtonClass()}>
                                        <span>예약</span>
                                    </button>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-4 px-3 bg-white'>
                                {
                                    activeButton === 'order' &&
                                    <div className='w-100 d-flex text-center'>
                                        <div className={`w-100 px-2`}>
                                        <Select className='w-100' placeholder="기간 선택" onChange={(e) => orderDateSelect(e)}>
                                            <Option value="all">전체</Option>
                                            <Option value="one_month">1개월</Option>
                                            <Option value="three_months">3개월</Option>
                                            <Option value="six_months">6개월</Option>
                                            <Option value="one_year">1년</Option>
                                        </Select>
                                        </div>
                                        <div className={`w-100 px-2`}>
                                        <Select className='w-100' placeholder="상태 선택" onChange={(e) => orderStateSelect(e)}>
                                            <Option value="total_state">전체상태</Option>
                                            <Option value="waiting_for_payment">입금대기</Option>
                                            <Option value="payment_completed">결제완료</Option>
                                            <Option value="preparing_for_delivery">배송준비</Option>
                                            <Option value="in_delivery">배송중</Option>
                                            <Option value="delivery_completed">배송완료</Option>
                                            <Option value="purchase_confirmed">구매확정</Option>
                                            <Option value="canceled">취소</Option>
                                            <Option value="return">반품</Option>
                                            <Option value="exchange">교환</Option>
                                            <Option value="refund">환불</Option>
                                        </Select>
                                        </div>
                                    </div>
                                }
                                {
                                    activeButton === 'reservation' &&
                                    <div className='w-100 d-flex text-center'>
                                        <div className={`w-100 px-2`}>
                                        <Select className='w-100' placeholder="기간 선택" onChange={(e) => reservationDateSelect(e)}>
                                            <Option value="all">전체</Option>
                                            <Option value="one_month">1개월</Option>
                                            <Option value="three_months">3개월</Option>
                                            <Option value="six_months">6개월</Option>
                                            <Option value="one_year">1년</Option>
                                        </Select>
                                        </div>
                                        <div className={`w-100 px-2`}>
                                        <Select className='w-100' placeholder="상태 선택" onChange={(e) => reservationStateSelect(e)}>
                                            <Option value="reservation_total_state">전체</Option>
                                            <Option value="reservation_payment_completed">예약대기</Option>
                                            <Option value="reservation_preparing_for_delivery">예약확정</Option>
                                            <Option value="reservation_in_delivery">제공완료</Option>
                                            <Option value="reservation_purchase_confirmed">구매확정</Option>
                                            <Option value="reservation_canceled">예약취소</Option>
                                        </Select>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={`${dailyduck.my_order_delivery_detail_content_box}`}>
                            {
                                activeButton === 'order' && filteredOrders.length > 0 ? (
                                filteredOrders.map((item, index) => (
                                        <div className='bg-white mt-2 position-relative' key={index}>
                                            <Link onClick={() => orderItemSelect(item)}>
                                                <div className='d-flex justify-content-between py-3 mx-3 border-bottom align-items-center'>
                                                    <div className={`${dailyduck.order_delivery_detail_content_date_box}`}><span>{formatDate(item.paymentRegDate)}</span></div>
                                                    <div><span className="material-symbols-outlined">chevron_right</span></div>
                                                </div>
                                                <div>
                                                    <div className='py-3 px-3 signature_color'><span>{item.purchaseConfirmation === false ? item.paymentState : '구매확정'}</span></div>
                                                    <div className='d-flex pb-3 px-3'>
                                                        <div className={`${dailyduck.order_img_box}`}><img src={item.paymentImg} alt="상품이미지" /></div>
                                                        <div className={`${dailyduck.order_product_info_box} ps-2`}>
                                                            <div className='dd-fs-7 gray_color'><span>{item.shopName}</span></div>
                                                            <div><span>{item.paymentProductName}</span></div>
                                                            <div className={`${dailyduck.order_product_quantity_cnt}`}><span>수량 : {item.totalQuantity}개</span></div>
                                                            <div className={`dd-fs-7 gray_color ${dailyduck.order_product_option_info}`}>
                                                                {
                                                                    item && item.options && item.options.length > 0 && item.options.map((options, index) => (
                                                                        <div key={index}><span>{options.selectionOptionName} :</span><span> {options.selectionOptionValue} /</span><span></span><span> {options.quantity}개</span></div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div><span>{item.paymentType === '해외간편결제' ? '' : '\u20A9'} {item.paymentType !== '해외간편결제' ? (parseInt(item.paymentPrice)).toLocaleString() : item.paymentPrice + ' USD'}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                                {
                                                    item.paymentState == '결제완료' &&
                                                    <div className='d-flex w-100 justify-content-center px-3 pb-3 align-items-center'>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_check}`} onClick={() => onPaymentCancel(item)}>취소 요청</button></div>
                                                    </div>
                                                }
                                                {
                                                    item.paymentState == '배송준비' &&
                                                    <div className='d-flex w-100 justify-content-center px-3 pb-3'>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_check}`} onClick={() => onPaymentCancel(item)}>취소 요청</button></div>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_confirm}`}>배송지 변경</button></div>
                                                    </div>
                                                }
                                                {
                                                    item.paymentState == '배송중' &&
                                                    <div className='d-flex w-100 justify-content-center px-3 pb-3'>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_check}`} onClick={() => onDeliverySearch(item)}>배송 조회</button></div>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_confirm}`} onClick={() => onDeliveryPurchaseConfirm(item)}>구매 확정</button></div>
                                                    </div>
                                                }
                                                {
                                                    item.paymentState == '배송완료' && item.purchaseConfirmation === false &&
                                                    <div className='d-flex w-100 justify-content-center px-3 pb-3'>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_check}`} onClick={() => onRefund(item)}>반품신청</button></div>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_check}`} onClick={() => onExchange(item)}>교환신청</button></div>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_confirm}`} onClick={() => onPurchaseConfirm(item)}>구매 확정</button></div>
                                                    </div>
                                                }
                                                {
                                                    item.purchaseConfirmation === true &&
                                                    <div className='d-flex w-100 justify-content-center px-3 pb-3'>
                                                        {/* <div className='w-100 text-center'><button className={`${dailyduck.my_order_review_check}`} disabled={true}>환불 요청</button></div> */}
                                                        <div className='w-100 text-center'><button className={`w-100 ${item.reviewState === false ? dailyduck.my_order_review_confirm : dailyduck.my_order_review_check}`} disabled={item.reviewState} onClick={() => onReviewWrite(item)}>리뷰 작성</button></div>
                                                    </div>
                                                }
                                                    <div className={`${dailyduck.my_order_retrun_refuse_btn}`}>
                                                    {
                                                        item.cancelRefuseText != null &&
                                                        <div><button onClick={() => showReturnRefuse(item.cancelRefuseText)}>취소거절사유</button></div>
                                                    }
                                                    {
                                                        item.returnRefuseText != null &&
                                                        <div><button onClick={() => showReturnRefuse(item.returnRefuseText)}>반품거절사유</button></div>
                                                    }
                                                    {
                                                        item.exchangeRefuseText != null &&
                                                        <div><button onClick={() => showReturnRefuse(item.exchangeRefuseText)}>교환거절사유</button></div>
                                                    }
                                                    </div>
                                        </div>
                                    ))
                                ): activeButton === 'order' ? (
                                    <div className='text-center d-flex justify-content-center align-items-center bg-white' style={{ height: '500px'}}>
                                        <div>
                                            <div><img src="../img/my/none.png" alt="" /></div>
                                            <div className='py-3'>주문 · 배송 내역이 없어요.</div>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                activeButton === 'reservation' &&
                                filteredReservations.length > 0 ? (
                                    filteredReservations.map((item, index) => (
                                            <div className='bg-white mt-2' key={index}>
                                                <Link onClick={() => reservationItemSelect(item)}>
                                                    <div className='d-flex justify-content-between py-3 mx-3 border-bottom'>
                                                        <div><span>{formatDate(item.reservationRegDate)}</span></div>
                                                        <div><span className="material-symbols-outlined">chevron_right</span></div>
                                                    </div>
                                                    <div>
                                                        <div className='py-3 px-3'><span>{item.reservationState}</span></div>
                                                        <div className='d-flex pb-3 px-3'>
                                                            <div className={`${dailyduck.order_img_box}`}><img src={item.mainItemImg} alt="예약이미지" /></div>
                                                            <div className={`${dailyduck.order_product_info_box} ps-2`}>
                                                                <div className='dd-fs-7 gray_color'><span>{item.shopName}</span></div>
                                                                <div><span>{item.mainOptionName}</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                {
                                                    item.reservationState === '예약대기' &&
                                                    <div className='d-flex w-100 justify-content-center pb-3 align-items-center'>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_check}`} onClick={() => onPaymentCancel(item)}>취소 요청</button></div>
                                                    </div>
                                                }
                                                {
                                                    item.reservationState === '제공완료' &&
                                                    <div className='d-flex w-100 justify-content-center pb-3 align-items-center'>
                                                        <div className='w-100 text-center'><button className={`${dailyduck.my_order_delivery_check}`} onClick={() => onSupportPurchaseConfirm(item)}>구매확정</button></div>
                                                    </div>
                                                }
                                                {
                                                    item.reservationState === '구매확정' && item.purchaseConfirmation === true &&
                                                    <div className='d-flex w-100 justify-content-center px-3 pb-3'>
                                                        <div className='w-100 text-center'><button className={`w-100 ${item.reviewState === false ? dailyduck.my_order_review_confirm : dailyduck.my_order_review_check}`} disabled={item.reviewState} onClick={() => onSupportReviewWrite(item)}>리뷰 작성</button></div>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                ) : activeButton === 'reservation' ? (
                                    <div className='text-center d-flex justify-content-center align-items-center bg-white' style={{ height: '500px'}}>
                                        <div>
                                            <div><img src="../img/my/none.png" alt="" /></div>
                                            <div className='py-3'>예약 내역이 없어요.</div>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                { 
                    showMyOrder &&
                    <MyOrderDetail showMyOrder={showMyOrder} setShowMyOrder={setShowMyOrder} selectOrder={selectOrder} dailyduck={dailyduck} 
                                    onExchange={onExchange} formatDate={formatDate} onPaymentCancel={onPaymentCancel} onRefund={onRefund} onPurchaseConfirm={onPurchaseConfirm}
                                    onReviewWrite={onReviewWrite} showReturnRefuse={showReturnRefuse} onSelectPurchaseConfirm={onSelectPurchaseConfirm}
                                    onDeliverySelectPurchaseConfirm={onDeliverySelectPurchaseConfirm} onDeliverySearch={onDeliverySearch}
                    />
                }
                {
                    showMyReservation &&
                    <MyReservationDetail showMyReservation={showMyReservation} setShowMyReservation={setShowMyReservation} dailyduck={dailyduck}
                                            selectReservation={selectReservation} formatDate2={formatDate2}
                    />
                }
            </div>
            <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
                <div className={`${dailyduck.my_order_return_refuse_modal}`}>
                    <span>{returnRefuseText}</span>
                </div>
            </Modal>

            {
                showTrackingModal &&
                <div className={`${dailyduck.my_order_delivery_tracking_modal_container}`}>
                    <div className={`${dailyduck.my_order_delivery_tracking_modal_box}`}>
                        <div className={`${dailyduck.my_order_delivery_tracking_modal_header}`}>
                            <div><span>배송조회</span></div>
                            <div><button onClick={() => setShowTrackingModal(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${dailyduck.my_order_delivery_tracking_modal_content}`}>
                            <div className={`${dailyduck.my_order_delivery_tracking_modal_content_top}`}>
                                {
                                    trackingInfo.progresses.length === 0 && trackingInfo.state.text !== '배송완료' ?
                                    <div>
                                        <div><span>{trackingInfo.from.time}</span></div>
                                        <div><span>배송 상품이 택배사에 접수되었습니다.</span></div>
                                    </div>
                                    :
                                    <div>
                                        <div><span>고객님의 상품이</span></div>
                                        <div><span>{trackingInfo.to.time !== null ? deliveryFormatDate(trackingInfo.to.time) : ''}</span></div>
                                        <div><span className={`${dailyduck.my_order_delivery_tracking_bold}`}>{trackingInfo.state.text}</span><span> 되었습니다.</span></div>
                                    </div>
                                }
                            </div>
                            <div className={`${dailyduck.my_order_delivery_tracking_modal_content_middle}`}>
                                <div>
                                    <div>
                                        <div><span>보내는 분</span></div>
                                        <div><span>{trackingInfo.from.name}</span></div>
                                    </div>
                                    <div>
                                        <div><span>받으시는 분</span></div>
                                        <div><span>{trackingInfo.to.name}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${dailyduck.my_order_delivery_tracking_modal_content_bottom}`}>
                                {
                                trackingInfo.progresses.length !== 0 &&
                                trackingInfo.progresses
                                    .sort((a, b) => new Date(b.time) - new Date(a.time)) // 시간을 기준으로 최신이 위로 정렬
                                    .map((item, index) => {
                                    return (
                                        <div key={index} className={`${dailyduck.my_order_delivery_tracking_modal_content_bottom_item} ${index === 0 ? dailyduck.first_item : dailyduck.other_items}`}>
                                            <div>
                                                <span className="material-symbols-outlined">
                                                {index === 0 ? 'adjust' : 'circle'} {/* 첫 번째 항목은 adjust, 나머지는 circle */}
                                                </span>
                                            </div>
                                            <div>
                                                <div><span>{item.description}</span></div>
                                                <div className={`${dailyduck.tracking_modal_content_bottom_location_date_state}`}>
                                                    <div>
                                                        <div><span>{item.location.name}</span></div>
                                                        <div><span>{deliveryFormatDate(item.time)}</span></div>
                                                    </div>
                                                    <div><span>{item.status.text}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MyOrderDelivery