import React, { useEffect, useRef, useState } from 'react';
import ss from './support_shop.module.css';
import { useNavigate } from 'react-router-dom';
import * as supportApi from '../../../../apis/support.js';

const VenueMapSearch = () => {
    const navigate = useNavigate();
    const [venue, setVenue] = useState([]);
    const mapRef = useRef(null); // 지도 DOM 참조
    const googleMapRef = useRef(null); // Google Map 객체 참조
    const markerRefs = useRef([]); // 여러 마커 참조

    useEffect(() => {
        venueData();
    }, [])

    useEffect(() => {
        // Google Maps API 스크립트 로드 및 지도 초기화
        loadGoogleMapsScript(() => {
            if (mapRef.current) {
                initializeMap();
            }
        });
    }, [venue]);

    const venueData = async () => {
        try {
            const response = await supportApi.supportItemSelect();
            const data = response.data;

            console.log('서포트 데이터 : ', data);
            setVenue(data);

        } catch (error) {
          console.error('Error fetching venue detail:', error);
        }
    }

    const handleBack = () => {
        navigate(-1);
    }

    const loadGoogleMapsScript = (callback) => {
        const existingScript = document.getElementById("googleMapsScript");
        if (!existingScript) {
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
            script.id = "googleMapsScript";
            script.async = true;
            script.defer = true;
            script.onload = callback;
            document.body.appendChild(script);
        } else {
            if (callback) callback();
        }
    };

    const initializeMap = () => {
        const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 37.5665, lng: 126.9780 }, // 서울 중심
            zoom: 10,
        });
        googleMapRef.current = map;

        // 지도에 데이터 기반으로 마커 추가
        addCustomMarkersToMap(map, venue);
    };

    const addCustomMarkersToMap = (map, venues) => {
        // 기존 마커 초기화
        markerRefs.current.forEach((marker) => marker.setMap(null));
        markerRefs.current = [];

        // 커스텀 마커 클래스
        class CustomMarker extends window.google.maps.OverlayView {
            constructor(position, map, content, infoContent) {
                super();
                this.position = position;
                this.map = map;
                this.content = content;
                this.infoContent = infoContent;
                this.setMap(map); // 마커를 지도에 추가
            }

            onAdd() {
                const div = document.createElement("div");
                div.innerHTML = this.content;
                div.style.position = "absolute";
                div.style.cursor = "pointer";
                div.style.zIndex = "1000"; // 항상 최상위
                
                // CSS 클래스 추가
                div.classList.add("custom-marker");

                this.div = div;

                const panes = this.getPanes();
                panes.overlayMouseTarget.appendChild(div);

                // InfoWindow 생성
                const infoWindow = new window.google.maps.InfoWindow({
                    content: this.infoContent,
                });

                // 마우스 오버 애니메이션
                div.addEventListener("mouseenter", () => {
                    div.classList.add("hover");
                });

                // 마우스 아웃 애니메이션
                div.addEventListener("mouseleave", () => {
                    div.classList.remove("hover");
                });

                // 마커 클릭 이벤트
                div.addEventListener("click", (e) => {
                    e.stopPropagation();
                    infoWindow.setPosition(this.position);
                    infoWindow.open(this.map);
                });
            }

            draw() {
                const overlayProjection = this.getProjection();
                const position = overlayProjection.fromLatLngToDivPixel(this.position);

                const div = this.div;
                div.style.left = `${position.x - 25}px`; // 가로 위치 조정
                div.style.top = `${position.y - 70}px`; // 세로 위치 조정
            }

            onRemove() {
                if (this.div) {
                    this.div.parentNode.removeChild(this.div);
                    this.div = null;
                }
            }
        }

        // CSS 애니메이션 정의
        const style = document.createElement("style");
        style.textContent = `
            .custom-marker {
                transition: transform 0.3s ease;
            }

            .custom-marker.hover {
                animation: bounce 1s infinite;
            }

            @keyframes bounce {
                0%, 100% {
                    transform: translateY(0);
                }
                50% {
                    transform: translateY(-10px);
                }
            }
        `;
        document.head.appendChild(style);

        // 마커 추가
        venues.forEach((venue) => {
            if (!venue.lat || !venue.lng) return; // 좌표 없는 데이터 건너뜀

            const position = {
                lat: parseFloat(venue.lat),
                lng: parseFloat(venue.lng),
            };

            // 커스텀 마커 HTML/CSS
            const markerContent = `
                <div style="
                    width: 50px;
                    height: 50px;
                    background-color: rgba(255, 3, 102, 1);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
                    cursor: pointer;
                ">
                    <img
                        src="${venue.representativeImage || ""}"
                        alt="${venue.productName}"
                        style="width: 40px; height: 40px; border-radius: 50%;"
                    />
                    <div style="
                        position: absolute;
                        bottom: -8.5px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 10px solid rgba(255, 3, 102, 1);
                    "></div>
                </div>
            `;
            
            // InfoWindow에 표시할 내용
            const infoContent = `
                <div>
                    <h3>${venue.productName}</h3>
                    <p>${venue.address || "주소 정보 없음"} ${venue.detailAddress || ""}</p>
                </div>
            `;

            const customMarker = new CustomMarker(
                new window.google.maps.LatLng(position.lat, position.lng),
                map,
                markerContent,
                infoContent
            );

            markerRefs.current.push(customMarker);
        });
    };

    return (
        <div className={`${ss.support_map_search_container}`}>
            <div className={`${ss.support_map_search_box}`}>
                <div className={`${ss.support_map_search_header}`}>
                    <div><button onClick={() => handleBack()}><span class="material-symbols-outlined">arrow_back_ios</span></button></div>
                    <div><span>지도에서 상품 찾기</span></div>
                </div>
                <div className={`${ss.support_map_search_content}`}>
                    <div
                        id="map"
                        ref={mapRef}
                    />
                </div>
            </div>
        </div>
    )
}

export default VenueMapSearch