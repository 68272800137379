import React, { useContext, useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import { CSSTransition } from 'react-transition-group';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import * as adminApi from '../../../../apis/admin';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Pagination } from 'antd';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

const AdminCancel = () => {
    const { userInfo, roles } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const [activeButton, setActiveButton] = useState('newCancel');
    const [cancelProgress, setCancelProgress] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(7);
    const [totalItems, setTotalItems] = useState(0);
    const [checkedOrders, setCheckedOrders] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});
    const [showCancleRefuse, setShowCancleRefuse] = useState(false);
    const [refuseList, setRefuseList] = useState([]);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const onProgress = () => {
        setCancelProgress(!cancelProgress);
    }

    useEffect(() => {
        if(roles.isAdmin) {
            adimnOrderSelect();
        } else {
            orderSelect();
        }
    }, [userInfo]);

    const orderSelect = async () => {
        const response = await adminApi.orderSelect(userInfo?.userId);
        const data = response.data.filter(order => 
            ["신규취소", "취소요청", "취소처리중", "취소처리실패", "취소완료"].includes(order.paymentState)
        );

        // console.log('orderData : ', data);

        setOrderList(data);
        setTotalItems(data.length);
    };

    const adimnOrderSelect = async () => {
        const response = await adminApi.adminOrderSelect();
        const data = response.data.filter(order => 
            ["신규취소", "취소요청", "취소처리중", "취소처리실패", "취소완료"].includes(order.paymentState)
        );

        // console.log('orderData : ', data);

        setOrderList(data);
        setTotalItems(data.length);
    }

    const getFilteredOrders = () => {
        switch(activeButton) {
            case 'newCancel':
                return orderList.filter(order => order.paymentState === '신규취소' || order.paymentState === '취소요청');
            case 'requestCancel':
                return orderList.filter(order => order.paymentState === '취소요청');
            case 'progressCancel':
                return orderList.filter(order => order.paymentState === '취소처리중');
            case 'failedancel':
                return orderList.filter(order => order.paymentState === '취소처리실패');
            case 'completeCancel':
                return orderList.filter(order => order.paymentState === '취소완료');
            default:
                return orderList;
        }
    }

    const getFilteredAndPaginatedOrders = () => {
        return getFilteredOrders().slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    };

    const totalItemsCount = getFilteredOrders().length;

    const calculateSubtotal = (order) => {
        if(order.option && order.options.length !== 0) {
            const optionsTotal = order.options.reduce((total, option) => {
                return total + (order.principalAmount * option.quantity) + (option.addPrice * option.quantity);
            }, 0);
            return optionsTotal + order.deliveryPrice;
        } else {
            return (parseInt(order.productPrice));
        }
    };

    const handleOrderCheck = (paymentNumber) => {
        setCheckedOrders(prev => {
            if (prev.includes(paymentNumber)) {
                return prev.filter(number => number !== paymentNumber);
            } else {
                return [...prev, paymentNumber];
            }
        });
    };

    const handleItemCheck = (paymentNumber, itemId) => {
        setCheckedItems(prev => ({
            ...prev,
            [paymentNumber]: {
                ...prev[paymentNumber],
                [itemId]: !prev[paymentNumber]?.[itemId]
            }
        }));
    };

    const handleAllOrdersCheck = () => {
        const currentOrders = getFilteredAndPaginatedOrders();
        if (checkedOrders.length === currentOrders.length) {
            setCheckedOrders([]);
        } else {
            setCheckedOrders(currentOrders.map(order => order.paymentNumber));
        }
    };

    const handleAllItemsCheck = (paymentNumber) => {
        const allChecked = checkedItems[paymentNumber] && Object.keys(checkedItems[paymentNumber]).length === orderList.find(order => order.paymentNumber === paymentNumber).options.length;
        setCheckedItems(prev => ({
            ...prev,
            [paymentNumber]: allChecked ? {} : orderList.find(order => order.paymentNumber === paymentNumber).options.reduce((acc, item, index) => {
                acc[index] = true;
                return acc;
            }, {})
        }));
    };

    const handleCancelAction = (actionType) => {
        if (checkedOrders.length === 0) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>선택된 취소 주문이 없습니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        }

        const hasInvalidState = checkedOrders.some(paymentNumber => {
            const order = orderList.find(order => order.paymentNumber === paymentNumber);
            if(actionType === 'approve') {
                return ['취소완료', '취소처리중', '취소처리실패'].includes(order.paymentState);
            } else if(actionType === 'reject') {
                return ['취소완료'].includes(order.paymentState);
            }
        });

        if (hasInvalidState) {
            if(actionType === 'approve') {
                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `<div><div class='${admin.admin_seller_add_title}'><span>상태가 취소완료, 취소처리중, 취소처리실패인 상품이 포함되었습니다.</span></div></div>`,
                    showConfirmButton: false,
                    showCancelButton: false,
                    timer: 2000
                });
            } else if(actionType === 'reject') {
                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `<div><div class='${admin.admin_seller_add_title}'><span>상태가 취소완료인 상품이 포함되었습니다.</span></div></div>`,
                    showConfirmButton: false,
                    showCancelButton: false,
                    timer: 2000
                });
            }
            return;
        } else {
            // 취소승인 또는 취소거절 로직 추가
            // console.log('Action Type:', actionType);
            if (actionType === 'approve') {
                // 취소승인 로직 추가
                const selectedOrdersData = checkedOrders.map(paymentNumber => {
                    const order = orderList.find(order => order.paymentNumber === paymentNumber);
                    return {
                        paymentNo: order.paymentNo,
                        paymentPrice: parseInt(order.paymentPrice),
                        paymentKey: order.paymentKey,
                        cancelType: order.cancelType,
                        cancelContent: order.cancelContent,
                        point : order.point,
                        couponNo : order.couponNo,
                        userId : order.userId,
                        productNumber : order.paymentProductNumber,
                        paymentNumber : order.paymentNumber,
                        totalQuantity : order.totalQuantity
                    };
                });
                // console.log('Selected Orders Data:', selectedOrdersData);
                // API 호출 등 필요한 로직 추가
                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                        <div>
                            <div class='${admin.admin_seller_add_title}'><span>${checkedOrders.length}개 상품을 취소승인 처리 하시겠습니까?</span></div>
                            <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_cancel'>취소</button>
                              <button class='mx-1' id='seller_add_success'>승인처리</button>
                            </div>
                        </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
                document.addEventListener('click', sellerAddSuccess);
                document.addEventListener('click', sellerAddCancel);
            
                async function sellerAddSuccess(e) {
                    if (e.target && e.target.id === 'seller_add_success') {
                        try {
                            const response = await adminApi.paymentCancelApproval(selectedOrdersData);
                            const data = response.data;
                            // const data = 'SUCCESS';
                
                            if (data === 'SUCCESS') {
                                MySwal.fire({
                                    customClass: {
                                        popup: `${admin.admin_seller_add_popup}`
                                    },
                                    html: `
                                    <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${checkedOrders.length}개 상품이 취소승인 되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                    </div>
                                    `,
                                    showConfirmButton: false,
                                    showCancelButton: false,
                                });
                                document.addEventListener('click', sellerAddConfirm);
                
                                function sellerAddConfirm(e) {
                                    if (e.target && e.target.id === 'seller_add_confirm') {
                                        if(roles.isAdmin) {
                                            adimnOrderSelect();
                                        } else {
                                            orderSelect();
                                        }
                                        MySwal.close();
                                        document.removeEventListener('click', sellerAddConfirm);
                                        document.removeEventListener('click', sellerAddSuccess);
                                        document.removeEventListener('click', sellerAddCancel);
                                    }
                                }
                            } else {
                                MySwal.fire({
                                    customClass: {
                                        popup: `${admin.admin_seller_add_popup}`
                                    },
                                    html: `
                                    <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${checkedOrders.length}개 상품 취소승인 처리에 실패했습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                    </div>
                                    `,
                                    showConfirmButton: false,
                                    showCancelButton: false,
                                });
                                document.addEventListener('click', sellerAddConfirm);
                
                                function sellerAddConfirm(e) {
                                    if (e.target && e.target.id === 'seller_add_confirm') {
                                        if(roles.isAdmin) {
                                            adimnOrderSelect();
                                        } else {
                                            orderSelect();
                                        }
                                        MySwal.close();
                                        document.removeEventListener('click', sellerAddConfirm);
                                        document.removeEventListener('click', sellerAddSuccess);
                                        document.removeEventListener('click', sellerAddCancel);
                                    }
                                }
                            }
                        } catch (error) {
                            console.error('error : ', error);
                        }
                    }
                }
            
                function sellerAddCancel(e) {
                    if (e.target && e.target.id === 'seller_add_cancel') {
                        MySwal.close();
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                    }
                }
            } else if (actionType === 'reject') {
                // 취소거절 로직 추가
                // console.log('취소거절 로직');
                const selectedOrdersData = checkedOrders.map(paymentNumber => {
                    const order = orderList.find(order => order.paymentNumber === paymentNumber);
                    return {
                        ...order,
                        cancelRefuseText: ''  // 추가된 필드
                    };
                });

                // console.log('Selected Orders Data:', selectedOrdersData);
                setRefuseList(selectedOrdersData);
                // API 호출 등 필요한 로직 추가
                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                        <div>
                            <div class='${admin.admin_seller_add_title}'><span>${checkedOrders.length}개 상품을 취소거절 처리 하시겠습니까?</span></div>
                            <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_cancel'>취소</button>
                              <button class='mx-1' id='seller_add_success'>취소거절</button>
                            </div>
                        </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });

                
                document.addEventListener('click', sellerAddSuccess);
                document.addEventListener('click', sellerAddCancel);

                async function sellerAddSuccess(e) {
                    if (e.target && e.target.id === 'seller_add_success') {
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                        MySwal.close();
                        setShowCancleRefuse(true);
                    }
                }
            
                function sellerAddCancel(e) {
                    if (e.target && e.target.id === 'seller_add_cancel') {
                        MySwal.close();
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                    }
                }
            } 
        }
    };

    const onCancleRefuseText = (e, item) => {
        const updatedRefuseList = refuseList.map(refuseItem => {
            if (refuseItem.paymentNumber === item.paymentNumber) {
                return { ...refuseItem, cancelRefuseText: e.target.value };
            }
            return refuseItem;
        });
        setRefuseList(updatedRefuseList);
    }

    useEffect(() => {
        // console.log('refuseList : ', refuseList);
    },[refuseList]);

    const onCancleRefuseBtn = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>취소거절 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_sub_title}'><span>취소거절 시 배송준비중 상태로 되돌아갑니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니오</button>
                      <button class='mx-1' id='seller_add_success'>예</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.cancleRefuseUpdate(refuseList);
                    const data = response.data;
            
                    // console.log('refuseData : ', data);
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>취소 거절이 완료 되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                setShowCancleRefuse(false);
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>취소거절 처리에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const handleCancelAction2 = (actionType) => {
        if (checkedOrders.length === 0) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>선택된 취소 주문이 없습니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        }

        const hasInvalidState = checkedOrders.some(paymentNumber => {
            const order = orderList.find(order => order.paymentNumber === paymentNumber);
            return ['취소완료'].includes(order.paymentState);
        });

        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>상태가 취소완료인 상품이 포함되었습니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        } else {
            // console.log('Action Type:', actionType);
            if (actionType === 'progress') {
                // 취소처리중으로 되돌리기
                const selectedOrdersData = checkedOrders.map(paymentNumber => {
                    const order = orderList.find(order => order.paymentNumber === paymentNumber);
                    return order;
                });

                // console.log('selectedOrdersData : ', selectedOrdersData);

                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                        <div>
                            <div class='${admin.admin_seller_add_title}'><span>취소처리중 상태로 되돌리시겠습니까?</span></div>
                            <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_cancel'>아니오</button>
                              <button class='mx-1' id='seller_add_success'>예</button>
                            </div>
                        </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
        
                document.addEventListener('click', sellerAddSuccess);
                document.addEventListener('click', sellerAddCancel);
                
                async function sellerAddSuccess(e) {
                    if (e.target && e.target.id === 'seller_add_success') {
                        try {
                            const response = await adminApi.cancleProgressUpdate(selectedOrdersData);
                            const data = response.data;
                    
                            // console.log('refuseData : ', data);
                
                            if (data === 'SUCCESS') {
                                MySwal.fire({
                                    customClass: {
                                        popup: `${admin.admin_seller_add_popup}`
                                    },
                                    html: `
                                    <div>
                                        <div class='${admin.admin_seller_add_title}'><span>취소처리중 상태로 변경 되었습니다.</span></div>
                                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                                        </div>
                                    </div>
                                    `,
                                    showConfirmButton: false,
                                    showCancelButton: false,
                                });
                                document.addEventListener('click', sellerAddConfirm);
                
                                function sellerAddConfirm(e) {
                                    if (e.target && e.target.id === 'seller_add_confirm') {
                                        if(roles.isAdmin) {
                                            adimnOrderSelect();
                                        } else {
                                            orderSelect();
                                        }
                                        setShowCancleRefuse(false);
                                        MySwal.close();
                                        document.removeEventListener('click', sellerAddConfirm);
                                        document.removeEventListener('click', sellerAddSuccess);
                                        document.removeEventListener('click', sellerAddCancel);
                                    }
                                }
                            } else {
                                MySwal.fire({
                                    customClass: {
                                        popup: `${admin.admin_seller_add_popup}`
                                    },
                                    html: `
                                    <div>
                                        <div class='${admin.admin_seller_add_title}'><span>취소처리중 상태 변경에 실패했습니다.</span></div>
                                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                                        </div>
                                    </div>
                                    `,
                                    showConfirmButton: false,
                                    showCancelButton: false,
                                });
                                document.addEventListener('click', sellerAddConfirm);
                
                                function sellerAddConfirm(e) {
                                    if (e.target && e.target.id === 'seller_add_confirm') {
                                        if(roles.isAdmin) {
                                            adimnOrderSelect();
                                        } else {
                                            orderSelect();
                                        }
                                        MySwal.close();
                                        document.removeEventListener('click', sellerAddConfirm);
                                        document.removeEventListener('click', sellerAddSuccess);
                                        document.removeEventListener('click', sellerAddCancel);
                                    }
                                }
                            }
                        } catch (error) {
                            console.error('error : ', error);
                        }
                    }
                }
            
                function sellerAddCancel(e) {
                    if (e.target && e.target.id === 'seller_add_cancel') {
                        MySwal.close();
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                    }
                }

            } else if(actionType === 'request') {
                // 취소요청으로 되돌리기
                const selectedOrdersData = checkedOrders.map(paymentNumber => {
                    const order = orderList.find(order => order.paymentNumber === paymentNumber);
                    return order;
                });

                // console.log('selectedOrdersData : ', selectedOrdersData);

                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                        <div>
                            <div class='${admin.admin_seller_add_title}'><span>취소요청 상태로 되돌리시겠습니까?</span></div>
                            <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_cancel'>아니오</button>
                              <button class='mx-1' id='seller_add_success'>예</button>
                            </div>
                        </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
        
                document.addEventListener('click', sellerAddSuccess);
                document.addEventListener('click', sellerAddCancel);
                async function sellerAddSuccess(e) {
                    if (e.target && e.target.id === 'seller_add_success') {
                        try {
                            const response = await adminApi.cancleRequestUpdate(selectedOrdersData);
                            const data = response.data;
                    
                            // console.log('refuseData : ', data);
                
                            if (data === 'SUCCESS') {
                                MySwal.fire({
                                    customClass: {
                                        popup: `${admin.admin_seller_add_popup}`
                                    },
                                    html: `
                                    <div>
                                        <div class='${admin.admin_seller_add_title}'><span>취소요청 상태로 변경 되었습니다.</span></div>
                                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                                        </div>
                                    </div>
                                    `,
                                    showConfirmButton: false,
                                    showCancelButton: false,
                                });
                                document.addEventListener('click', sellerAddConfirm);
                
                                function sellerAddConfirm(e) {
                                    if (e.target && e.target.id === 'seller_add_confirm') {
                                        orderSelect();
                                        setShowCancleRefuse(false);
                                        MySwal.close();
                                        document.removeEventListener('click', sellerAddConfirm);
                                        document.removeEventListener('click', sellerAddSuccess);
                                        document.removeEventListener('click', sellerAddCancel);
                                    }
                                }
                            } else {
                                MySwal.fire({
                                    customClass: {
                                        popup: `${admin.admin_seller_add_popup}`
                                    },
                                    html: `
                                    <div>
                                        <div class='${admin.admin_seller_add_title}'><span>취소요청 상태 변경에 실패했습니다.</span></div>
                                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                                        </div>
                                    </div>
                                    `,
                                    showConfirmButton: false,
                                    showCancelButton: false,
                                });
                                document.addEventListener('click', sellerAddConfirm);
                
                                function sellerAddConfirm(e) {
                                    if (e.target && e.target.id === 'seller_add_confirm') {
                                        if(roles.isAdmin) {
                                            adimnOrderSelect();
                                        } else {
                                            orderSelect();
                                        }
                                        MySwal.close();
                                        document.removeEventListener('click', sellerAddConfirm);
                                        document.removeEventListener('click', sellerAddSuccess);
                                        document.removeEventListener('click', sellerAddCancel);
                                    }
                                }
                            }
                        } catch (error) {
                            console.error('error : ', error);
                        }
                    }
                }
            
                function sellerAddCancel(e) {
                    if (e.target && e.target.id === 'seller_add_cancel') {
                        MySwal.close();
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                    }
                }

            } else if(actionType === 'retry') {

            } else if(actionType === 'force_complete') {
                // 취소승인 로직 추가
                const selectedOrdersData = checkedOrders.map(paymentNumber => {
                    const order = orderList.find(order => order.paymentNumber === paymentNumber);
                    return {
                        paymentNo: order.paymentNo,
                        paymentPrice: parseInt(order.paymentPrice),
                        paymentKey: order.paymentKey,
                        cancelType: order.cancelType,
                        cancelContent: order.cancelContent
                    };
                });
                // console.log('Selected Orders Data:', selectedOrdersData);
                // API 호출 등 필요한 로직 추가
                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                        <div>
                            <div class='${admin.admin_seller_add_title}'><span>${checkedOrders.length}개 상품을 취소승인 처리 하시겠습니까?</span></div>
                            <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>승인처리</button>
                            </div>
                        </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
                document.addEventListener('click', sellerAddSuccess);
                document.addEventListener('click', sellerAddCancel);

                async function sellerAddSuccess(e) {
                    if (e.target && e.target.id === 'seller_add_success') {
                        try {
                            const response = await adminApi.paymentCancelApproval(selectedOrdersData);
                            const data = response.data;

                            if (data === 'SUCCESS') {
                                MySwal.fire({
                                    customClass: {
                                        popup: `${admin.admin_seller_add_popup}`
                                    },
                                    html: `
                                    <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${checkedOrders.length}개 상품이 취소승인 되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                    </div>
                                    `,
                                    showConfirmButton: false,
                                    showCancelButton: false,
                                });
                                document.addEventListener('click', sellerAddConfirm);

                                function sellerAddConfirm(e) {
                                    if (e.target && e.target.id === 'seller_add_confirm') {
                                        if(roles.isAdmin) {
                                            adimnOrderSelect();
                                        } else {
                                            orderSelect();
                                        }
                                        MySwal.close();
                                        document.removeEventListener('click', sellerAddConfirm);
                                        document.removeEventListener('click', sellerAddSuccess);
                                        document.removeEventListener('click', sellerAddCancel);
                                    }
                                }
                            } else {
                                MySwal.fire({
                                    customClass: {
                                        popup: `${admin.admin_seller_add_popup}`
                                    },
                                    html: `
                                    <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${checkedOrders.length}개 상품 취소승인 처리에 실패했습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                    </div>
                                    `,
                                    showConfirmButton: false,
                                    showCancelButton: false,
                                });
                                document.addEventListener('click', sellerAddConfirm);

                                function sellerAddConfirm(e) {
                                    if (e.target && e.target.id === 'seller_add_confirm') {
                                        if(roles.isAdmin) {
                                            adimnOrderSelect();
                                        } else {
                                            orderSelect();
                                        }
                                        MySwal.close();
                                        document.removeEventListener('click', sellerAddConfirm);
                                        document.removeEventListener('click', sellerAddSuccess);
                                        document.removeEventListener('click', sellerAddCancel);
                                    }
                                }
                            }
                        } catch (error) {
                            console.error('error : ', error);
                        }
                    }
                }

                function sellerAddCancel(e) {
                    if (e.target && e.target.id === 'seller_add_cancel') {
                        MySwal.close();
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                    }
                }
            }
        }
    }

    const formatDate = (date) => {
        if (!date) return '';
        const parsedDate = new Date(date);
      
        if (isNaN(parsedDate)) {
          console.warn('Invalid date value:', date);
          return '';
        }
      
        // UTC 시간 그대로 표시
        return parsedDate.toISOString().replace('T', ' ').substring(0, 19);
    };

    return (
        <div className={`${admin.admin_shop_cancel_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_shop_cancel_header}`}><span>취소</span></div>
                <div className={`${admin.admin_shop_cancel_tap}`}>
                    <div className={`${activeButton === 'newCancel' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('newCancel')}>신규취소 ({orderList.filter(order => order.paymentState === '신규취소' || order.paymentState === '취소요청').length})</button></div>
                    <div className={`${activeButton === 'requestCancel' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('requestCancel')}>취소요청 ({orderList.filter(order => order.paymentState === '취소요청').length})</button></div>
                    <div className={`${activeButton === 'progressCancel' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('progressCancel')}>취소처리중 ({orderList.filter(order => order.paymentState === '취소처리중').length})</button></div>
                    <div className={`${activeButton === 'failedancel' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('failedancel')}>취소처리실패 ({orderList.filter(order => order.paymentState === '취소처리실패').length})</button></div>
                    <div className={`${activeButton === 'completeCancel' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('completeCancel')}>취소완료 ({orderList.filter(order => order.paymentState === '취소완료').length})</button></div>
                </div>
                <div className={`${admin.admin_shop_cancel_mobile_tap}`}>
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={10}
                        freeMode={true}
                        className={`${admin.admin_shop_product_tap_swiper}`}
                    >
                        <SwiperSlide>
                            <div className={`${activeButton === 'newCancel' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('newCancel')}>신규취소 ({orderList.filter(order => order.paymentState === '신규취소' || order.paymentState === '취소요청').length})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'requestCancel' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('requestCancel')}>취소요청 ({orderList.filter(order => order.paymentState === '취소요청').length})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'progressCancel' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('progressCancel')}>취소처리중 ({orderList.filter(order => order.paymentState === '취소처리중').length})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'failedancel' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('failedancel')}>취소처리실패 ({orderList.filter(order => order.paymentState === '취소처리실패').length})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'completeCancel' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('completeCancel')}>취소완료 ({orderList.filter(order => order.paymentState === '취소완료').length})</button></div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className={`${admin.admin_shop_cancel_progress_btn}`}>
                    <div><button onClick={() => handleCancelAction('approve')}>취소승인</button></div>
                    <div className='ps-1'><button onClick={() => handleCancelAction('reject')}>취소거절</button></div>
                    <div className='ps-1 position-relative'>
                        <div><button className='d-flex align-items-center' onClick={onProgress}>기타 취소처리<span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>arrow_drop_down</span></button></div>
                        <CSSTransition in={cancelProgress} timeout={300} classNames="review" unmountOnExit>
                            <div className={`${admin.admin_shop_cancel_progress_other_box}`}>
                                <div><button onClick={() => handleCancelAction2('progress')}>취소처리중으로 되돌리기</button></div>
                                <div><button onClick={() => handleCancelAction2('request')}>취소요청으로 되돌리기</button></div>
                                <div><button onClick={() => handleCancelAction2('retry')}>자동환불 재시도</button></div>
                                <div><button onClick={() => handleCancelAction2('force_complete')}>강제 취소 (환불) 완료 처리</button></div>
                            </div>
                        </CSSTransition>
                    </div>
                </div>
                <div className={`${admin.admin_shop_cancel_content_box}`}>
                    <div>
                        <div className={`${admin.admin_shop_cancel_content_header}`}>
                            <div><input type="checkbox" checked={checkedOrders.length === getFilteredAndPaginatedOrders().length} onChange={handleAllOrdersCheck} /></div>
                            <div><span>주문번호/시각</span></div>
                            <div><span>주문상품</span></div>
                            <div><span>상품금액</span></div>
                            <div><span>수량</span></div>
                            <div><span>상태</span></div>
                            <div><span>배송방법</span></div>
                            <div><span>취소요청일</span></div>
                            <div><span>환불정보</span></div>
                            <div><span>배송정보</span></div>
                            <div><span>결제금액</span></div>
                        </div>
                        {getFilteredAndPaginatedOrders().map((order, index) => (
                            <div key={index + 1 + (currentPage - 1) * itemsPerPage} className={`${admin.admin_shop_cancel_content_content}`}>
                                <div><input type="checkbox" checked={checkedOrders.includes(order.paymentNumber)} onChange={() => handleOrderCheck(order.paymentNumber)} /></div>
                                <div className={`${admin.admin_shop_cancel_order_number}`}>
                                    <div><span>{order.paymentNumber}</span></div>
                                    <div><span>{formatDate(order.paymentRegDate)}</span></div>
                                    <div><button><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>smartphone</span>{order.deliveryUser}</button></div>
                                    <div><button>주문서 출력</button></div>
                                </div>
                                <div className={`${admin.admin_shop_cancel_order_payment_info_box}`}>
                                    <div><input type="checkbox" checked={checkedItems[order.paymentNumber] && Object.keys(checkedItems[order.paymentNumber]).length === order.options.length} onChange={() => handleAllItemsCheck(order.paymentNumber)} /></div>
                                    <div className='ps-1'><img src={order.paymentImg} alt="취소이미지" /></div>
                                    <div className='ps-1'>
                                        <div><span>{order.paymentProductNumber}</span></div>
                                        <div><span>[상품]{order.paymentProductName}</span></div>
                                        <div>
                                            {
                                                order && order.options && order.options.map((option, index) => (
                                                    <div key={index}><span>{option.selectionOptionName} :</span><span> {option.selectionOptionValue}</span></div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div><span>{parseInt(order.paymentPrice).toLocaleString()}원</span></div>
                                <div><span>{order && order.options && order.options.reduce((total, option) => total + option.quantity, 0)}</span></div>
                                <div><span>{order.paymentState}</span></div>
                                <div><span>{order.deliveryType}</span></div>
                                <div>
                                    <div><span>{formatDate(order.paymentUpdDate)}</span></div>
                                    <div>
                                        <div><span>취소사유</span></div>
                                        <div><span>{order.cancelType}</span></div>
                                        <div><span>{order.cancelContent}</span></div>
                                    </div>
                                    <div><button>관리자 메모</button></div>
                                    <div></div>
                                </div>
                                <div>
                                    <div className='d-flex justify-content-between'>
                                        <div><span>총 환불금액</span></div>
                                        <div><span>{(parseInt(order.paymentPrice)).toLocaleString()}원</span></div>
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <div><span>소계</span></div>
                                            <div><span>{calculateSubtotal(order).toLocaleString()}원</span></div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div><span>배송비 합계</span></div>
                                            <div><span>{order.deliveryPrice.toLocaleString()}원</span></div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div><span>{order.paymentType === '카드' ? '카드 결제 취소' : '환불계좌'}</span></div>
                                        <div className='text-end'>
                                            <div><span>{order.paymentAccountNumber}</span></div>
                                            <div><span>{order.paymentBankName}</span></div>
                                            {
                                                order.paymentType !== '카드' &&
                                                <div><button>수정</button></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='px-2'>
                                    <div><span>{order.deliveryUser}</span><span>/</span><span>{order.deliveryPhone}</span></div>
                                    <div><span>{order.deliveryAddress}</span></div>
                                    <div><span>우) {order.zipcode}</span></div>
                                </div>
                                <div>
                                    <div className='d-flex justify-content-between'>
                                        <div><span>총 결제금액</span></div>
                                        <div><span>{(parseInt(order.paymentPrice)).toLocaleString()}원</span></div>
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <div><span>소계</span></div>
                                            <div><span>{calculateSubtotal(order).toLocaleString()}원</span></div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div><span>배송비</span></div>
                                            <div><span>{order.deliveryPrice.toLocaleString()}원</span></div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div><span>포인트</span></div>
                                            <div><span>{order.point.toLocaleString()}원</span></div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div><span>쿠폰</span></div>
                                            <div><span>{order.coupon.toLocaleString()}원</span></div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div><span>결제방법</span></div>
                                            <div className='text-end'>
                                                <div><span>{order.paymentType}</span></div>
                                                <div><span>{order.paymentBankName ? order.paymentBankName : ''}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {
                showCancleRefuse &&
                <div className={`${admin.admin_shop_cancel_refuse_modal_container}`}>
                    <div className={`${admin.admin_shop_cancel_refuse_modal_box}`}>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_header}`}>
                            <div><span>취소거절사유</span></div>
                            <div><button onClick={() => setShowCancleRefuse(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_txt}`}>
                            <div><span>*선택하신 {refuseList && refuseList.length}개의 취소 주문을 처리합니다.</span></div>
                        </div>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_content}`}>
                            <div>
                                <div>
                            {
                                Array.isArray(refuseList) && refuseList.map((item, index) => (
                                    <div key={index} className={`${admin.admin_shop_cancel_refuse_modal_refuse_box}`}>
                                        <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_info}`}>
                                            <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_order}`}>
                                                <div><span>주문정보</span></div>
                                                <div>
                                                    <div><img src={item.paymentImg} alt="" /></div>
                                                    <div>
                                                        <div><span>{item.paymentProductNumber}</span></div>
                                                        <div><span>{item.paymentProductName}</span></div>
                                                        <div><span>{parseInt(item.paymentPrice).toLocaleString()}원</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel}`}>
                                                <div><span>취소정보</span></div>
                                                <div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>주문번호</span></div>
                                                        <div><span>{item.paymentNumber}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>취소타입</span></div>
                                                        <div><span>{item.cancelType}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>취소사유</span></div>
                                                        <div><span>{item.cancelContent}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>환불금액</span></div>
                                                        <div><span>{parseInt(item.paymentPrice).toLocaleString()}원</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>취소요청일</span></div>
                                                        <div><span>{formatDate(item.paymentUpdDate)}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_textarea}`}>
                                            <div><span>취소 거절사유</span></div>
                                            <div><textarea name="" id="" onChange={(e) => onCancleRefuseText(e, item)}></textarea></div>
                                        </div>
                                    </div>
                                ))
                            }
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_footer}`}>
                            <div><button onClick={() => setShowCancleRefuse(false)}>취소</button></div>
                            <div><button onClick={() => onCancleRefuseBtn()}>취소거절</button></div>
                        </div>
                    </div>
                </div>
            }
            <Pagination current={currentPage} total={totalItemsCount} onChange={paginate} pageSize={itemsPerPage} />
        </div>
    )
}

export default AdminCancel;
