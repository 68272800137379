import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../contexts/LoginContextProvider';
import * as storeapi from '../../apis/store';
import * as support from '../../apis/support';

const DailyduckHeader = () => {
    const { isLogin, sellerlogout, userInfo, loginCheck, roles } = useContext(LoginContext);

    const [ cartList, setCartList ] = useState([]);
    const [ supportCartList, setSupportCartList ] = useState([]);
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        const cartData = async () => {
            const response = await storeapi.cartSelect(userInfo && userInfo.userId);
            const data = await response.data;

            // console.log(data);
            setCartList(data);
        }
        const supportCartData = async () => {
            const response = await support.supportCartSelect(userInfo && userInfo.userId);
            const data = response.data;

            // console.log('supportCartList : ', data);

            setSupportCartList(data);
        };
        supportCartData();
        cartData();
    }, [userInfo]);

    useEffect(() => {
        // console.log('cartLength : ', cartList.length+supportCartList.length);
    }, [cartList, supportCartList]);

    return (
        <>
            <div className='dailyduck_header'>
                <div className='header_left'>
                    <Link to="">
                        <img src={`${process.env.PUBLIC_URL}/img/icon/alert.png`} alt="알림아이콘" />
                    </Link>
                </div>
                <div className='header_center'>
                    <Link to="/market">
                        <img src={`${process.env.PUBLIC_URL}/img/logo_black.png`} alt="데일리덕로고" />
                    </Link>
                </div>
                <div className='header_right'>
                    <div className='d-flex justify-content-end'>
                        <Link to="" >
                            <img src={`${process.env.PUBLIC_URL}/img/icon/lang.png`} alt="언어선택" onClick={() => setOpen(!isOpen)} />
                        </Link>
                        
                        <Link to={`/cart`} className='position-relative'>
                            <img src={`${process.env.PUBLIC_URL}/img/icon/cart.png`} alt="장바구니" />
                            {
                                (cartList.length !== 0 || supportCartList.length !== 0) &&
                                <div className='dailyduck_header_cart_number'>{cartList.length + supportCartList.length}</div>
                            }
                        </Link>
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className="lang-dropdown">
                    <Link className="lang-dropdown-item" to="">
                        <div><img src={`${process.env.PUBLIC_URL}/img/icon/country/South_Korea_(KR).png`} alt="" /></div>
                        <div><span>한국어</span></div>
                    </Link>
                    <Link className="lang-dropdown-item" to="">
                        <div><img src={`${process.env.PUBLIC_URL}/img/icon/country/United_States_of_America_(US).png`} alt="" /></div>
                        <div><span>English</span></div>
                    </Link>
                    <Link className="lang-dropdown-item" to="">
                        <div><img src={`${process.env.PUBLIC_URL}/img/icon/country/China(CN).png`} alt="" /></div>
                        <div><span>中文 (简体)</span></div>
                    </Link>
                    <Link className="lang-dropdown-item" to="">
                        <div><img src={`${process.env.PUBLIC_URL}/img/icon/country/China(CN).png`} alt="" /></div>
                        <div><span>中文 (繁體)</span></div>
                    </Link>
                    <Link className="lang-dropdown-item" to="">
                        <div><img src={`${process.env.PUBLIC_URL}/img/icon/country/Japan(JP).png`} alt="" /></div>
                        <div><span>日本語</span></div>
                    </Link>
                    <Link className="lang-dropdown-item" to="">
                        <div><img src={`${process.env.PUBLIC_URL}/img/icon/country/Vietnam(VN).png`} alt="" /></div>
                        <div><span>Tiếng Việt</span></div>
                    </Link>
                    <Link className="lang-dropdown-item" to="">
                        <div><img src={`${process.env.PUBLIC_URL}/img/icon/country/Spain(ES).png`} alt="" /></div>
                        <div><span>Español</span></div>
                    </Link>
                    {/* <Link className="lang-dropdown-item" to="">언어 6</Link> */}
                </div>
            )}
        </>

    )
}

export default DailyduckHeader